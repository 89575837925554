<template>
  <div class="sign">
    <div class="sign--title">Подписание файлов ЭП</div>
    <table-sign></table-sign>
    <actions-sign v-if="false"></actions-sign>
    <loader-ui v-if="false"></loader-ui>
  </div>
</template>

<script>
import TableSign from "@/components/sign/TableSign";
import {mapActions} from "vuex";
import dbAdapter from "@/mixins/dbAdapter";
import ActionsSign from "@/components/sign/ActionsSign";
import LoaderUi from "@/components/ui/LoaderUi.vue";

export default {
  name: "IndexSign",
  mixins: [dbAdapter],
  props: {
    docId: {
      required: true,
      type: Number,
    }
  },
  components: {
    LoaderUi,
    ActionsSign,
    TableSign,
  },
  async beforeMount() {
    const getFieldName = this.dbAdapter[this.generateKey];
    const getBoolValue = this.dbAdapter[this.getBoolValue];

    const where = getFieldName('Индикатор') + ' = ' + getBoolValue(1);

    this.getFilesForSignAction({
      doc_id: this.docId,
      where,
      orderby: this.dbAdapter[this.getOrder]('Создан', 'DESC')
    }).then(() => {})
      .catch(() => {})
      .finally(() => this.$emit('loaded'));
  },
  beforeDestroy() {
    this.clearSignBlockAction();
  },
  methods: {
    ...mapActions(['getFilesForSignAction', 'clearSignBlockAction'])
  },
}
</script>
