<template>
  <modal-ui ref="modal" title="Добавление банковских реквизитов" :error-message="errorMessage">
    <template #body>
      <form-builder ref="formBuilder" margins :fields="fields" v-model="form"></form-builder>
    </template>

    <template #footer>
      <close-button @click="hide"></close-button>
      <add-button-legacy @click="apply"></add-button-legacy>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import CloseButton from '@/components/buttons/CloseButton';
import AddButtonLegacy from '@/components/buttons/AddButtonLegacy.vue';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {CLIENTS_BANK_DETAILS_ADD, CLIENTS_BANK_DETAILS_GET} from '@/configs/endPoints';
import FormBuilder from '@/components/form/FormBuilder';
import bankDetails from '@/configs/forms/clients/bankDetails';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import {ErrorHelper} from '@/services/errorHelper';

export default defineComponent({
  name: 'AddBankDetailsModal',
  components: {ModalUi, FormBuilder, AddButtonLegacy, CloseButton},
  mixins: [modal],
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    parentTableId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: null,
      fields: bankDetails(this),
      form: {
        parent_id: this.parentId,
        parent_table_id: this.parentTableId,
      },
    };
  },
  methods: {
    ...mapActions({
      refreshPartlyPreviewAction: 'refreshPartlyPreviewAction',
      addClientBankDetailsAction: VuexAdapter.getNameAction(CLIENTS_BANK_DETAILS_ADD),
    }),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      this.showLoader();

      const data = {...this.form};

      this.addClientBankDetailsAction(data).then(() => {
        this.refreshPartlyPreviewAction(CLIENTS_BANK_DETAILS_GET);
        this.hide();
      }).catch(error => {
        this.errorMessage = ErrorHelper.format(error);
        this.hideLoader();
      });
    },
  },
});
</script>
