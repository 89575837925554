<template>
  <div class="checkbox-form">
    <label class="label-general" v-if="label" v-html="label"/>
    <div class="checkbox-row">
      <label v-for="value in values"
             :key="value.code"
             class="checkbox-form-element"
             :class="classes"
             :for="`checkbox-switch-${value.code}`">
        <input @change="setResponsibleFrom"
               type="checkbox"
               :disabled="disabled"
               :id="`checkbox-switch-${value.code}`"
               :value="value.code"
               v-model="proxyValue"
        >
        <span class="checkmark"/>
        <span class="checkbox-label">{{value.label}}</span>
      </label>
    </div>
    <span
        v-if="hint || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : hint }}
		</span>
  </div>
</template>

<script>
export default {
  name: "CheckboxSwitchForm",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Значение чекбоксов [{code, label}]
     * @type {values}
     */
    values: {
      type: Array,
      required: true,
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Сообщение об ошибке. (отображается под селектом, заменяет hint)
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    proxyValue: [],
  }),
  created() {
    this.proxyValue = [this.modelValue || this.values[0]?.code];
    if (!this.modelValue && this.proxyValue) {
      this.$emit('change', this.proxyValue[0] || undefined);
    }
  },
  computed: {
    classes() {
      return {
        error: this.error,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    setResponsibleFrom(e) {
      this.proxyValue = [e.target.value];
      this.$emit('change', this.proxyValue[0] || undefined);
      this.$emit('blur');
    },
  },
  watch: {
    modelValue(modelValue) {
      if (modelValue) {
        this.proxyValue = [modelValue];

        return;
      }

      this.proxyValue = [];
    },
  }
}
</script>