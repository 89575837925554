<template>
  <modal-ui
    ref="modal"
    title="Загрузка файлов"
    :error-message="errorMessage"
    hidden-spinner
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #body>
      <file-drop-zone
        class="mt-4"
        ref="fileDropZone"
        :comment="comment"
        @close="closeAfterUpload"
        @hasError="hasError"
        @hasErrorFilesUploaded="hasErrorFilesUploaded"
        :files_pre="files"
        :doc_id="docId"
      />

      <textarea-ui v-model="comment" placeholder="Примечание" class="mt-4"></textarea-ui>
    </template>

    <template #footer>
      <close-button @click="hide"/>
      <upload-alpha-button @click="upload"/>
    </template>
  </modal-ui>
</template>

<script>
import FileDropZone from '@/components/doc/files/FileDropZone';
import CloseButton from '@/components/buttons/CloseButton';
import UploadAlphaButton from '@/components/buttons/UploadAlphaButton';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import {mapActions} from 'vuex';
import {DOC_FILES_GET} from '@/configs/endPoints';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: 'UploadFileModal',
  components: {
    ModalUi,
    TextareaUi,
    UploadAlphaButton,
    CloseButton,
    FileDropZone,
  },
  mixins: [modal],
  props: {
    files: {
      type: Array,
    },
    docId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    comment: '',
  }),
  methods: {
    ...mapActions(['refreshPartlyPreviewAction']),
    upload() {
      this.errorMessage = null;
      this.showLoader();
      this.$refs.fileDropZone.upload();
    },
    hasError() {
      this.hideLoader();
      this.errorMessage = 'Ошибка при загрузке файлов';
    },
    hasErrorFilesUploaded(err = null) {
      this.hideLoader();
      this.errorMessage = err ? err : 'Ошибка загрузки файла';
    },
    onShown() {
      this.$on('hasErrorFilesUploaded', this.hasErrorFilesUploaded);
    },
    onHidden() {
      this.$off('hasErrorFilesUploaded', this.hasErrorFilesUploaded);
      this.errorMessage = null;
    },
    closeAfterUpload() {
      this.$eventBus.$emit('table-start-reload-files');

      this.refreshPartlyPreviewAction(DOC_FILES_GET);
      this.hide();
    },
  },
};
</script>
