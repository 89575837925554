<template>
  <preview-block title="Ближайшие документы" :amount="amount" :loading="loading">
    <uploader-block-client-preview
      :end-point="endPoint"
      :order-by="'DOC_ID DESC'"
      :client-id="clientId"
      :component="() => import('@/components/table/grouped/DocumentsLinks')"
    >
      <template #list-is-empty>Список контактов пуст</template>
    </uploader-block-client-preview>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import {CLIENTS_DOCS_GET} from '@/configs/endPoints';
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';

export default defineComponent({
  name: 'NearestDocsClientPreview',
  components: {
    PreviewBlock,
    UploaderBlockClientPreview,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clientsPreviewBlock],
  computed: {
    endPoint() {
      return CLIENTS_DOCS_GET;
    },
  },
});
</script>
