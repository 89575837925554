<template>
  <div class="document-sheet--header d-flex w-100">
    <div class="mr-4">
      <img src="/img/logo-icon.svg?v2" alt="АЛЬФА ДОК" class="document-sheet-logo"/>
    </div>
    <div class="d-flex justify-content-between w-100 flex-wrap">

      <div class="d-flex align-items-start flex-wrap" style="max-width: 280px">

        <div class="d-flex align-items-center flex-wrap">
          <div class="grey-text ml-2">№</div>
          <a class="ml-2" :href="'/document/' + getNumber" target="_blank">{{ getNumber }}</a>

          <div v-if="hasDateDoc" class="grey-text ml-2">от</div>
          <div v-if="hasDateDoc" class="ml-2 black-text">{{ getDateDoc }}</div>
        </div>

        <div v-if="accessToRegistrationData" class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <div v-if="hasRegDate" class="ml-2 grey-text">Регистрация</div>
            <div v-if="hasRegDate" class="ml-2 black-text">{{ getRegDate }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="hasRegNumber" class="ml-2 grey-text">Рег. №</div>
            <div v-if="hasRegNumber" class="ml-2 black-text">{{ getRegNumber }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div v-if="hasTerm" class="ml-2 grey-text">Срок:</div>
        <div v-if="hasTerm" class="ml-2 black-text">{{ getTerm }}</div>
      </div>
      <div class="d-flex">
        <div v-if="hasStatus" class="ml-2 grey-text">Статус:</div>
        <div v-if="hasStatus" class="ml-2 black-text">{{ getStatus }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {getDate} from "@/services/utilsFunctions";
import {mapGetters} from "vuex";
import {DOCUMENTS__REGISTRATION_DATA} from "@/configs/events";

export default {
  name: "HeaderDocumentPreview",
  props: ['doc'],
  methods: {
    getDate,
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    accessToRegistrationData() {
      return this.accessToEvent(DOCUMENTS__REGISTRATION_DATA)
    },
    hasRegNumber() {
      return this.doc['Рег.номер'];
    },
    getRegNumber() {
      return this.doc['Рег.номер'];
    },
    hasTerm() {
      return this.doc['Срок'] && this.doc['Срок'] !== '';
    },
    hasStatus() {
      return this.doc['Статус'] && this.doc['Статус'] !== '';
    },
    getNumber() {
      return this.doc['Номер'];
    },
    hasRegDate() {
      return this.doc['Регистрация'] !== null && this.doc['Регистрация'] !== '';
    },
    getRegDate() {
      return this.getDate(this.doc['Регистрация'], 'dd.MM.yyyy');
    },
    hasDateDoc() {
      return this.doc['Дата'] !== null && this.doc['Дата'] !== '';
    },
    getDateDoc() {
      return this.getDate(this.doc['Дата'], 'dd.MM.yyyy, HH:mm');
    },
    getTerm() {
      return this.getDate(this.doc['Срок'], 'dd.MM.yyyy');
    },
    getStatus() {
      return this.doc['Статус'];
    }
  }
}
</script>