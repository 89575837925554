import Constants from "@/configs/tables/constants";
import ConstantsRPS from "@/configs/routePointStatuses";

const RPS = {}
RPS[String(ConstantsRPS.rp_status_type_performed_id)] = {
    title: 'Выполняется',
    icon: 'play'
}
RPS[String(ConstantsRPS.rp_status_type_completed_id)] = {
    title: 'Завершено',
    icon: 'flag',
}
RPS[String(ConstantsRPS.rp_status_type_expired_id)] = {
    title: 'Просрочено',
    icon: 'exclamation-triangle',
}
RPS[String(ConstantsRPS.rp_status_type_pending_id)] = {
    title: 'Ожидает',
}
RPS[String(ConstantsRPS.rp_status_type_approved_id)] = {
    title: 'Согласовано',
    icon: 'check'
}
RPS[String(ConstantsRPS.rp_status_type_rejected_id)] = {
    title: 'Отклонено',
    icon: 'times'
}
RPS[String(ConstantsRPS.rp_status_type_completion_confirmed_id)] = {
    title: 'Завершение подтверждено',
}
RPS[String(ConstantsRPS.rp_status_type_stopped_id)] = {
    title: 'Остановлено',
}
RPS[String(ConstantsRPS.rp_status_type_accepted_id)] = {
    title: 'Принято',
}

const data = {
    visible: true,
    system: false,
    title: 'Статус',
    classes: {'text-center': true},
    type: Constants.identifier,
    values: RPS,
    description: 'ID статуса поручения',
    component: () => import('@/components/table/flags/StatusFlag'),
    order: 4,
    conditions: 1,
}

export default function (mix = {}, type = '') {
    if (type == 'commissions') {
        let values = {};
        values[String(ConstantsRPS.rp_status_type_performed_id)] = RPS[String(ConstantsRPS.rp_status_type_performed_id)]
        values[String(ConstantsRPS.rp_status_type_completed_id)] = RPS[String(ConstantsRPS.rp_status_type_completed_id)]
        values[String(ConstantsRPS.rp_status_type_expired_id)] = RPS[String(ConstantsRPS.rp_status_type_expired_id)]

        mix['values'] = values;
    }
    return Object.assign(Object.create(data), mix)
}