<template>
  <dropdown-ui tag="ul" placement="bottom-end" :offset="4" close-if-inner-click close-if-outside-click>
    <template #anchor="{toggle}">
      <button class="toggle" :class="{_small: isCollapsed}" @click="toggle">
        <user-icon class="toggle-icon"/>
      </button>

      <settings-system-modal ref="settingsSystemModal"/>
    </template>

    <dropdown-item-ui tag="li">
      <user-icon class="icon icon_prefix"/>
      {{ userName }}
    </dropdown-item-ui>

    <dropdown-item-ui tag="li" @click="showSettings">
      <gear-icon class="icon icon_prefix"/>
      Настройки
    </dropdown-item-ui>

    <dropdown-item-ui tag="li" @click="logout">
      <log-out-icon class="icon icon_prefix"/>
      Выход
    </dropdown-item-ui>
  </dropdown-ui>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import UserIcon from '@/assets/svg/user.svg?component';
import GearIcon from '@/assets/svg/gear.svg?component';
import LogOutIcon from '@/components/icons/LogOutIcon';
import SettingsSystemModal from '@/components/modals/System/SettingsSystemModal';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';

export default {
  name: 'ToolbarRight',
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DropdownItemUi,
    SettingsSystemModal,
    UserIcon,
    GearIcon,
    LogOutIcon,
    DropdownUi,
  },
  computed: {
    ...mapState('user', ['userName']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    showSettings() {
      this.$refs.settingsSystemModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
.toggle {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--color-gray-200);
  transition: background-color var(--transition-fast);

  &._small {
    width: 28px;
    height: 28px;
  }

  &:hover {
    background-color: var(--color-gray-300);
  }
}
</style>
