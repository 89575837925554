<template>
  <tbody class="table-component-tbody">
  <component v-for="(row, index) in dataTable"
             v-bind:key="getKey(row)"
             :is="component"
             :row="row"
             :index="index"
             :componentWidth="componentWidth"
             :configs="configs"
  ></component>
  </tbody>
</template>

<script>
import TableRowSmall from "@/components/table/TableRowSmall";
import TableRow from "@/components/table/TableRow";

export default {
  name: 'TableBody',
  components: {TableRow, TableRowSmall},
  props: [
    'dataTable',
    'configs',
    'small',
    'componentWidth',
  ],
  computed: {
    component() {
      return this.small ? 'table-row-small' : 'TableRow';
    }
  },
  methods: {
    getKey(row) {
      return JSON.stringify(row);
    },
  }
};
</script>