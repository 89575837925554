<template>
  <div class="alert alert-danger">
    <h4 class="alert-heading">
      <font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']"/>
      Ошибка!
    </h4>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "ErrorAlertUi"
}
</script>