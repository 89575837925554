<template>
  <section class="step-content">
    <div class="left">
      <slot name="header"></slot>

      <div class="scroll">
        <loader-ui v-if="isLoading" color="white" position="static" text="Загрузка параметров"></loader-ui>

        <form-builder
          v-else
          ref="formMask"
          :class="{'_mask': mask}"
          :fields="fieldsMask"
          v-model="formDataProxy"
          @change="change"
        ></form-builder>

        <form-builder ref="form" :fields="fields" v-model="formDataProxy" @change="change"></form-builder>
      </div>
    </div>

    <div class="right">
      <file-viewer
        :form-data-proxy="formDataProxy"
        @show-loader="showLoader"
        @hide-loader="hideLoader"
        @change="fileViewerChange"
      ></file-viewer>

      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script>
import {defineComponent} from 'vue';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_DATE, TYPE_INTEGER, TYPE_SEARCH_MULTIPLE, TYPE_SWITCH, TYPE_TEXT} from '@/configs/form';
import {mapActions, mapGetters} from 'vuex';
import {
  CLIENT_CHECK,
  CLIENTS_GET,
  DOC_CHECK,
  DOC_TYPE_MASK_PARAM_VALUES_FREQUENT,
  DOCS_GET,
  SYSTEM_GET_DEADLINE,
} from '@/configs/endPoints';
import VuexAdapter from '@/services/vuexAdapter';
import {CLIENTS_TABLE, DOCS_TABLE} from '@/configs/dbTables';
import {
  CALENDAR_DAYS_TERM_FROM_MASTER,
  DATE_TERM_FROM_MASTER,
  NONE_TERM_FROM_MASTER,
  WORKING_DAYS_TERM_FROM_MASTER,
} from '@/configs/master/termFromMaster';
import {getDateFromJSDate} from '@/services/utilsFunctions';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW} from '@/configs/events';
import FileViewer from '@/components/file-viewer/FileViewer.vue';
import LinkIcon from '@/assets/svg/link.svg?component';
import AddLinkIcon from '@/assets/svg/add-link.svg?component';
import CounterpartyIcon from '@/assets/svg/counterparty.svg?component';
import AddCounterpartyIcon from '@/assets/svg/add-counterparty.svg?component';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import {CanceledError} from 'axios';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import {ErrorHelper} from '@/services/errorHelper';
import stepWithLinkTypes from '@/mixins/document-master/stepWithLinkTypes';

export default defineComponent({
  name: 'StepContent',
  mixins: [stepWithLinkTypes],
  components: {
    LoaderUi,
    FileViewer,
    FormBuilder,
  },
  async mounted() {
    void this.getFields();
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    mask() {
      return this.formDataProxy?.mask;
    },
    documentLinkTypes() {
      return this.linkTypes.filter(linkType => !linkType.appliedIds || linkType.appliedIds.includes(DOCS_TABLE));
    },
    counterpartyLinkTypes() {
      return this.linkTypes.filter(linkType => !linkType.appliedIds || linkType.appliedIds.includes(CLIENTS_TABLE));
    },
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    fieldsMask() {
      if (this.formDataProxy.mask) {
        return this.formDataProxy.parameters
          ?.map(parameter => [parameter.getFormField(this.formDataProxy.type.code)]) || [];
      } else {
        return [
          {
            type: TYPE_TEXT,
            name: 'content',
            required: true,
            length: 255,
            rows: 12,
            placeholder: 'Введите содержание документа',
          },
        ];
      }
    },
    fields() {
      const fields = [];

      fields.push([
        {
          type: TYPE_SWITCH,
          name: 'termFrom',
          options: [
            {
              label: 'Без срока',
              value: NONE_TERM_FROM_MASTER,
            },
            {
              label: 'В рабочих днях',
              value: WORKING_DAYS_TERM_FROM_MASTER,
            },
            {
              label: 'В днях',
              value: CALENDAR_DAYS_TERM_FROM_MASTER,
            },
            {
              label: 'Дата',
              value: DATE_TERM_FROM_MASTER,
            },
          ],
          classes: (data) => {
            const classes = ['mt-4'];

            if (data.termFrom === NONE_TERM_FROM_MASTER) {
              classes.push('none_term_from_master');
            }

            return classes;
          },
        },
      ]);

      fields.push([
        {
          type: TYPE_INTEGER,
          name: WORKING_DAYS_TERM_FROM_MASTER,
          min: 1,
          max: 360,
          initValue: 1,
          hidden: (data) => {
            return data.termFrom !== WORKING_DAYS_TERM_FROM_MASTER;
          },
        },
        {
          type: TYPE_DATE,
          name: WORKING_DAYS_TERM_FROM_MASTER + '_VIEW',
          disabled: true,
          onlyInput: true,
          hidden: (data) => {
            return data.termFrom !== WORKING_DAYS_TERM_FROM_MASTER;
          },
        },
        {
          type: TYPE_INTEGER,
          name: CALENDAR_DAYS_TERM_FROM_MASTER,
          min: 1,
          max: 360,
          initValue: 1,
          hidden: (data) => {
            return data.termFrom !== CALENDAR_DAYS_TERM_FROM_MASTER;
          },
        },
        {
          type: TYPE_DATE,
          name: CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW',
          disabled: true,
          onlyInput: true,
          hidden: (data) => {
            return data.termFrom !== CALENDAR_DAYS_TERM_FROM_MASTER;
          },
        },
        {
          type: TYPE_DATE,
          name: DATE_TERM_FROM_MASTER,
          hidden: (data) => data.termFrom !== DATE_TERM_FROM_MASTER,
          required: true,
        },
      ]);

      fields.push([{
        type: TYPE_SEARCH_MULTIPLE,
        name: 'documents',
        harmonic: 'Связанные документы',
        icon: LinkIcon,
        addIcon: AddLinkIcon,
        addText: 'Добавить ссылки',
        searchHandler: async (id) => {
          return await this.docAction({where: 'DOC_ID = ' + id})
            .then((r) => {
              return r.data.items[0];
            });
        },
        itemTitleHandler: (item) => {
          const length = 40;
          const content = item['Содержание'].length > length ? item['Содержание'].substring(0, length) + '...' : item['Содержание'];

          return `<span>${content}</span><br>№${item['Номер']}`;
        },
        itemTitleHrefHandler: (item) => {
          return '/document/' + item['DOC_ID'];
        },
        afterAddHandler: (item) => {
          const ltIdsInSelect = this.documentLinkTypes
            .map(linkType => linkType.code)
            .filter(linkType => linkType !== 1);

          if (!ltIdsInSelect.length) {
            this.$set(item, 'LT_ID', 1);
          } else {
            const key = this.formDataProxy.documents.length;
            this.$set(item, 'LT_ID', ltIdsInSelect[key] || 1);
          }

          return item;
        },
        searchPlaceholder: 'Введите номер документа',
        searchEndPoint: DOC_CHECK,
        disabledFormatter: item => item['Доступен'] !== 1,
        searchKeyField: 'DOC_ID',
        searchValueField: 'Содержание',
        selectKey: 'LT_ID',
        options: this.documentLinkTypes,
      }]);

      if (this.accessToClients) {
        fields.push([{
          type: TYPE_SEARCH_MULTIPLE,
          name: 'clients',
          harmonic: 'Контрагенты',
          icon: CounterpartyIcon,
          addIcon: AddCounterpartyIcon,
          addText: 'Добавить контрагента',
          searchHandler: async (id) => {
            return await this.clientAction({where: 'CLIENT_ID = ' + id})
              .then((r) => {
                return r.data.items[0];
              });
          },
          itemTitleHandler: (item) => {
            return `${item['Название']}<br>№${item['Номер']}`;
          },
          itemTitleHrefHandler: (item) => {
            return '/client/' + item['CLIENT_ID'];
          },
          afterAddHandler: (item) => {
            const ltIdsInSelect = this.counterpartyLinkTypes
              .map(linkType => linkType.code)
              .filter(linkType => linkType !== 1);

            if (!ltIdsInSelect?.length) {
              this.$set(item, 'LT_ID', 1);
            } else {
              const key = this.formDataProxy.clients.length;
              this.$set(item, 'LT_ID', ltIdsInSelect[key] || 1);
            }

            return item;
          },
          searchPlaceholder: 'Поиск контрагента',
          searchEndPoint: CLIENT_CHECK,
          disabledFormatter: item => item['Доступен'] !== 1,
          searchKeyField: 'CLIENT_ID',
          searchValueField: 'Название',
          selectKey: 'LT_ID',
          options: this.counterpartyLinkTypes,
        }]);
      }

      fields.push([
        {
          type: TYPE_TEXT,
          harmonic: 'Примечание',
          name: 'comment',
          length: 255,
          rows: 7,
          placeholder: 'Введите комментарий к документу',
        },
      ]);

      return fields;
    },
  },
  methods: {
    ...mapActions({
      fetchDeadlineAction: VuexAdapter.getNameAction(SYSTEM_GET_DEADLINE),
      docAction: VuexAdapter.getNameAction(DOCS_GET),
      clientAction: VuexAdapter.getNameAction(CLIENTS_GET),
      paramValuesAction: VuexAdapter.getNameAction(DOC_TYPE_MASK_PARAM_VALUES_FREQUENT),
    }),
    fileViewerChange(event) {
      Object.entries(event).forEach(([key, value]) => this.$set(this.formDataProxy, key, value));
      this.change();
    },
    async getFields() {
      if (!this.formDataProxy.mask || this.formDataProxy.parameters) {
        return;
      }

      this.isLoading = true;

      try {
        const parameters = await DocumentMasterApiService.getParameters(
          this.modelValue.type.code,
          this.abortController.signal,
        );

        this.$set(this.formDataProxy, 'parameters', parameters);
        this.change();
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          title: 'Ошибка получения параметров типа документа',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
        this.back();
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    ['modelValue.' + CALENDAR_DAYS_TERM_FROM_MASTER]: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + newVal);

          const date = getDateFromJSDate(currentDate, 'yyyy-MM-dd');

          this.$set(this.formDataProxy, CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW', date);
          this.change();
        }
      },
      deep: true,
      immediate: true,
    },
    ['modelValue.' + WORKING_DAYS_TERM_FROM_MASTER]: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal && this.modelValue.termFrom === WORKING_DAYS_TERM_FROM_MASTER) {
          this.fetchDeadlineAction({
            count_day: newVal,
          }).then(r => {
            const date = r.data.date.split('.').reverse().join('-');
            this.$set(this.formDataProxy, WORKING_DAYS_TERM_FROM_MASTER + '_VIEW', date);
            this.change();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss">
.step-content {
  min-height: 600px;
  max-height: calc(100vh - 80px);
  display: flex;
}

.left {
  flex: 0 0 320px;
  min-width: 0;

  margin-right: 8px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;
}

.document-master-header {
  margin-bottom: 16px;
}

.scroll {
  flex: 1 1 100%;
  overflow-y: auto;

  margin: -12px -16px -16px -16px;
  padding: 12px 16px 16px 16px;
}

.loader-ui {
  max-height: 250px;
}

.form {
  &._mask {
    background-color: var(--color-gray-050);
    padding: 12px;
    border-radius: 6px;
  }
}

.right {
  flex: 1 1 100%;

  display: flex;
  flex-direction: column;
}

.file-viewer {
  flex: 1 1 100%;
  margin-bottom: 16px;
}
</style>
