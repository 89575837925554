<template>
  <div ref="resize" class="carousel-ui">
    <section class="container" :class="{_transition: transition}" :style="containerStyle">
      <div v-for="(item, index) in slides" ref="slides" class="slide" :key="item.key">
        <slot :item="item" :index="index"></slot>
      </div>
    </section>

    <footer class="footer" v-if="slides.length > 1">
      <div v-if="slides.length > PAGINATION_TEXT_MIN_PAGES" class="number-pagination">
        {{ modelValue }} из {{ slides.length }}
      </div>

      <div class="dots" v-else>
        <button
          v-for="index in slides.length"
          :key="index"
          class="dot"
          :disabled="index === modelValue"
          @click="goTo(index - 1)"
        ></button>
      </div>
    </footer>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import EmblaCarousel from 'embla-carousel';
import {keys} from '@/common/utils/props-validators';
import {PAGINATION_TEXT_MIN_PAGES} from '@/common/consts/carousel.consts';
import {TRANSITION_DURATION} from '@/common/consts/transition.consts';
import resize from '@/mixins/resize';

export default defineComponent({
  name: 'CarouselUi',
  mixins: [resize],
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    slides: {
      type: Array,
      required: true,
      validator: keys('key'),
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      embla: null,
      width: null,
      height: null,
      transition: true,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$emit('update:model-value', 1);
    setTimeout(() => this.embla?.destroy(), TRANSITION_DURATION);
  },
  computed: {
    PAGINATION_TEXT_MIN_PAGES() {
      return PAGINATION_TEXT_MIN_PAGES;
    },
    containerStyle() {
      return {
        height: this.height && `${this.height}px`,
      };
    },
  },
  watch: {
    modelValue() {
      this.recalculate();
    },
  },
  methods: {
    init() {
      if (this.slides.length > 1) {
        this.embla = EmblaCarousel(this.$refs.resize, {
          skipSnaps: true,
        });

        this.embla.on('select', event => this.onSelect(event));
      }
    },
    onSelect(event) {
      this.$emit('update:model-value', event.selectedScrollSnap() + 1);
    },
    onResize({width}) {
      if (width === this.width) {
        return;
      }
      this.width = width;

      this.transition = false;
      this.$nextTick(() => {
        this.recalculate();
        this.transition = true;
      });
    },
    recalculate() {
      this.height = this.$refs.slides[this.modelValue - 1].getBoundingClientRect().height;
    },
    goTo(page) {
      this.embla.scrollTo(page);
    },
    goToPrev() {
      this.embla.scrollPrev();
    },
    goToNext() {
      this.embla.scrollNext();
    },
  },
});
</script>

<style scoped lang="scss">
.carousel-ui {
  overflow: hidden;
  background-color: var(--color-gray-075);
}

.container {
  padding: 0; // TODO: Избавиться от перекрытия стилей

  display: flex;
  align-items: center;

  &._transition {
    transition: height var(--transition);
  }
}

.slide {
  flex: 0 0 100%;
  min-width: 0;
}

.footer {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.number-pagination {
  width: 80px;

  text-align: center;
  border-radius: 8px;
  background-color: var(--color-white);
}

.dots {
  display: flex;
}

.dot {
  width: 8px;
  height: 8px;

  border-radius: 4px;
  background-color: var(--color-gray-100);

  transition: background-color var(--transition-fast);

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--color-gray-500);
  }

  &:disabled {
    background-color: var(--color-gray-600);
  }
}
</style>
