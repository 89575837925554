<template>
  <div :class="classWrapper">
    <div class="mobile-menu-bg"
         @click="hideMobileMenu"></div>
    <div class="mobile-menu-content"
         v-touch:swipe="swipeHandler"
         @click="clickHandler">
      <router-link class="sidebar-logo"
                   :to="'/'"
                   @click.native.stop="hideMobileMenu">
        <img src="/img/logo-icon.svg?v2" alt="АЛЬФА ДОК"/>
        <span>
                    АЛЬФАДОК
                    <git-release-data class="sidebar-logo-git-release-data"/>
                </span>
      </router-link>

      <router-link class="sidebar-link position-relative"
                   to="/commissions"
                   @click.native.stop="hideMobileMenu">
        <commissions-icon/>
        <span class="sidebar-link-text">Поручения</span>
        <menu-counter getter="commissionsCountGetter"/>
      </router-link>
      <router-link class="sidebar-link position-relative"
                   v-if="accessToControls"
                   to="/controls"
                   @click.native.stop="hideMobileMenu">
        <controls-icon/>
        <span class="sidebar-link-text">Контроль</span>
        <menu-counter getter="controlsCountGetter"/>
      </router-link>
      <router-link class="sidebar-link"
                   v-if="accessToDocuments"
                   to="/search"
                   @click.native.stop="hideMobileMenu">
        <search-doc-icon/>
        <span class="sidebar-link-text">Поиск документов</span>
      </router-link>
      <router-link class="sidebar-link"
                   v-if="accessToClients"
                   to="/clients"
                   @click.native.stop="hideMobileMenu">
        <contractor-icon/>
        <span class="sidebar-link-text">Контрагенты</span>
      </router-link>

      <a @click.stop="hideMobileMenu"
         class="sidebar-link sidebar-toggler">
        <span class="sidebar-link-text">Скрыть</span>
        <collapse-icon/>
      </a>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import GitReleaseData from '@/components/GitReleaseData';
import CommissionsIcon from '@/components/icons/CommissionsIcon.vue';
import MenuCounter from '@/components/widgets/MenuCounter.vue';
import ControlsIcon from '@/components/icons/ControlsIcon.vue';
import SearchDocIcon from '@/components/icons/SearchDocIcon.vue';
import ContractorIcon from '@/components/icons/ContractorIcon';
import CollapseIcon from '@/components/icons/CollapseIcon';
import {mapGetters} from 'vuex';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__CONTROLS, DOCUMENTS__VIEW} from '@/configs/events';

export default {
  name: 'MobileMenu',
  inject: [
    'app'
  ],
  components: {
    GitReleaseData,
    CommissionsIcon,
    MenuCounter,
    ControlsIcon,
    SearchDocIcon,
    ContractorIcon,
    CollapseIcon,
  },
  data: () => ({
    isShown: false,
    clickCount: 0,
    clickDelay: 700,
    clickTimer: null,
  }),
  mounted() {
    this.$eventBus.$on('mobile-menu-show', this.showMobileMenu);
  },
  methods: {
    showMobileMenu() {
      this.isShown = true;
    },
    hideMobileMenu() {
      this.isShown = false;
    },
    swipeHandler(direction) {
      switch (direction) {
        case 'left':
          this.hideMobileMenu();
          break;
      }
    },
    clickHandler() {
      this.clickCount++;
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.hideMobileMenu();
        this.clickCount = 0;
      }
    },
  },
  computed: {
    ...mapGetters([
      'accessToEvent',
      'commissionsCountGetter',
      'controlsCountGetter',
    ]),
    accessToControls() {
      return this.accessToEvent(DOCUMENTS__CONTROLS);
    },
    accessToDocuments() {
      return this.accessToEvent(DOCUMENTS__VIEW);
    },
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    isHeightTiny() {
      return this.app.appHeight <= Constants.resolutionPoints.tiny;
    },
    isHeightBaby() {
      return this.app.appHeight <= Constants.resolutionPoints.baby;
    },
    classWrapper() {
      return {
        'mobile-menu-wrapper': true,
        'mobile-menu-height-tiny': this.isHeightTiny,
        'mobile-menu-height-baby': this.isHeightBaby,
        'shown': this.isShown,
      };
    },
  },
  destroyed() {
    this.$eventBus.$off('mobile-menu-show', this.showMobileMenu);
  },
}
</script>
