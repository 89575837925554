import {IgnorableException} from '@/services/exception/ignorableException';
import Vue from 'vue';
import {CanceledError} from 'axios';

export class ExceptionService {
  static init() {
    ExceptionService.originalPromiseHandler = window.onunhandledrejection;
    window.onunhandledrejection = ExceptionService.onPromiseError;

    ExceptionService.originalVueHadler = Vue.config.errorHandler;
    Vue.config.errorHandler = ExceptionService.onVueError;
  }

  static onPromiseError(event) {
    const error = event.reason;

    if (error instanceof IgnorableException || error instanceof CanceledError) {
      event.preventDefault();
      return;
    }

    ExceptionService.originalPromiseHandler(event);
  }

  static onVueError(error, component, info) {
    if (error instanceof IgnorableException || error instanceof CanceledError) {
      return;
    }

    ExceptionService.originalVueHadler(error, component, info);
  }
}
