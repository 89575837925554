<template>
  <div class="radio-card-form">
    <div class="radio-card-form__container">
      <div v-for="option in options" :key.prop="option.code"
           class="radio-card-form__container--item"
           :class="{
              'radio-card-form__container--item__disabled': disabled,
           }"
           @click="select(option)"
           :style="optionStyle(option)">
           {{ option.label }}
      </div>
    </div>
    <span
        v-if="hint || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : hint }}
		</span>
  </div>
</template>

<script>

export default {
  name: "RadioCardsForm",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  data() {
    return {
      modelProxy: null,
      activeColor: '#DAF6CA'
    }
  },
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    optionStyle() {
      return (option) => {
        const styles = {};
        if (option.code === this.modelProxy) {
          styles['background-color'] = option?.activeColor || this.activeColor
        }

        if (option?.full) {
          styles['grid-column'] = 'span 2';
        }

        return styles;
      }
    }
  },
  created() {
    this.modelProxy = this.modelValue;
  },
  methods: {
    select(option) {
      if (this.disabled) {
        return;
      }
      this.modelProxy = option.code;
      this.$emit('change', this.modelProxy);
    }
  }
}
</script>