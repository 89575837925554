export default {
    rp_status_type_pending_id: 1, // Ожидает
    rp_status_type_performed_id: 2, // Выполняется
    rp_status_type_approved_id: 3, // Одобрено
    rp_status_type_rejected_id: 4, // Отклонено
    rp_status_type_completed_id: 5, // Завершено
    rp_status_type_completion_confirmed_id: 6, // Завершение подтверждено
    rp_status_type_stopped_id: 7, // Остановлено
    rp_status_type_accepted_id: 8, // Принято
    rp_status_type_expired_id: 9, // Просрочено
};