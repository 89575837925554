import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import {COUNTERPARTIES__EDIT} from '@/configs/events';

export default defineComponent({
  computed: {
    ...mapGetters(['accessToEvent']),
    showAdd() {
      return this.accessToEvent(COUNTERPARTIES__EDIT);
    },
    amount() {
      const getterName = this.$vuexAdapter.getNameCountGetter(this.endPoint);
      return this.$store.getters[getterName];
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
  },
});
