<template>
  <thead class="table-component-thead sticky">
  <tr class="columns">
    <th
      class="th"
      v-for="(col, index) in columns"
      scope="col"
      :colspan="getColSpan(col, index)"
      :id="'col-sticky-head-' + index"
      :class="{...hideTableHeader(col), ...getTableFixColClasses(index)}"
      :style.camel="{left: col.left}"
      v-bind:key.prop="col"
    >
      <table-head-dropdown :data="data" :col="col"/>
    </th>
  </tr>
  </thead>
</template>

<script>
import TableHeadDropdown from '@/components/table/TableHeadDropdown';
import VuexAdapter from '@/services/vuexAdapter';

export default {
  name: 'TableHead',
  components: {
    TableHeadDropdown,
  },
  props: [
    'data',
  ],
  data: () => ({
    startFixWidth: 0,
  }),
  computed: {
    tableFixCols() {
      return this.$store.getters[VuexAdapter.fixColsTableNameGetter(this.data.name)];
    },
    preferences() {
      return this.$store.getters[VuexAdapter.visibleColumnsTableNameGetter(this.data.name)] || {};
    },
    sortColumns() {
      return this.$store.getters[VuexAdapter.sortColumnsTableNameGetter(this.data.name)] || [];
    },
    columns() {
      const array = [...this.sortColumns],
        result = {};

      if (!array.length) {
        for (let key in this.data.info) {
          let order = !this.data.info[key]?.order ? 100 : parseInt(this.data.info[key].order);
          do {
            order += 1;
          } while (array[order + 1] !== undefined);
          array[order + 1] = key;
        }
      }
      for (let keyRow in this.data.info) {
        if (this.data.info[keyRow]?.configTitle) {
          result[0] = {
            keyRow: keyRow,
            value: this.data.info[keyRow],
            left: 0,
          };
          continue;
        }
        if (
          (this.preferences[keyRow] !== undefined && this.preferences[keyRow] === false)
          || (!this.data.info[keyRow].visible && this.preferences[keyRow] === undefined)
        ) {
          continue;
        }

        array.forEach((valueOrder, keyOrder) => {
          if (valueOrder === keyRow) {
            result[keyOrder + 1] = {
              keyRow: keyRow,
              value: this.data.info[keyRow],
            }
          }
        });
      }
      return result;
    },
  },
  methods: {
    getTableFixColClasses(keyOrder) {
      if (this.tableFixCols && keyOrder < this.tableFixCols.code) {
        return {
          'col-sticky-head': true,
          ['col-sticky-num-' + keyOrder]: true
        };
      }
      return {};
    },

    getColSpan(col) {
      return this.data.info[col.keyRow].colspan;
    },

    hideTableHeader(col) {
      return {
        'table-design-hide-th': !!this.data.info[col.keyRow].invisibleTh,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.th {
  background: #f3f6fb;

  &:not(:first-child) {
    border-left: 1px solid #e2e7ef;
  }
}
</style>
