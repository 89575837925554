import mssqlAdapter from '@/services/adaptersSql/mssql';
import postgresqlAdapter from '@/services/adaptersSql/postgresql';

export default {
  namespaced: true,
  state: () => ({
    dbType: null,
    storageUrl: null,
  }),
  getters: {
    isMssql(state) {
      return state.dbType === 'mssql';
    },
    dbAdapter(_, getters) {
      return getters.isMssql ? mssqlAdapter : postgresqlAdapter;
    }
  },
  mutations: {
    set(state, payload) {
      state.dbType = payload.db_type;
      state.storageUrl = payload.storage_url;
    },
    clear(state) {
      state.dbType = null;
      state.storageUrl = null;
    }
  }
};
