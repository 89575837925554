<template>
  <div class="sign--table">
    <header-table-sign/>
    <body-table-sign/>
  </div>
</template>

<script>
import HeaderTableSign from "@/components/sign/HeaderTableSign";
import BodyTableSign from "@/components/sign/BodyTableSign";

export default {
  name: "TableSign",
  components: {
    BodyTableSign,
    HeaderTableSign,
  }
}
</script>