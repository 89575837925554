import {DateTime} from "luxon";
import Vue from "vue";

/**
 * @param {number} decimals
 * @param {string} dec_point
 * @param {string} separator
 */
export function number_format(number, decimals, dec_point, separator ) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof separator === 'undefined') ? ',' : separator ,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}

/**
 * @param {number} value
 * @param {string[]} words
 */
export function morph(value, words) {
    value = Math.abs(value) % 100;
    let num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
}

export function getDate(date, f = 'dd.MM.yyyy') {
    if (date) {
        return new DateTime.fromSQL(date).toFormat(f);
    }
    return ''
}

export function getDateFromJSDate(date, f = 'dd.MM.yyyy') {
    if (date) {
        return new DateTime.fromJSDate(date).toFormat(f);
    }
    return ''
}

export function notLike(str, pattern) {
    return str.indexOf(pattern) === -1;
}

export function fullTrim(str) {
    return str.replace(/ +/g, ' ').replace(/ ;+/g, ';').trim();
}

export function fileSize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function tColorToRGB(c) {
    let bigint = c.split('#')[1];
    let r = bigint & 255 ;
    let g = bigint >> 8 & 255;
    let b = bigint >> 16 & 255;

    return 'rgba(' + r + ',' + g + ',' + b + ', 0.4)';
}

export function upperCaseFirst(str) {
    if (str && str.length > 0) {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }
    return str;
}

export function fromObjectToArray(obj) {
    return Object.values(obj).flat();
}

export function fromArrayToObject(arr, key) {
    const obj = {};

    for (const el of arr) {
        if (obj[el[key]] === undefined) {
            obj[el[key]] = el;
            continue;
        }

        if (!(obj[el[key]] instanceof Array)) {
            obj[el[key]] = [obj[el[key]]];
        }

        obj[el[key]].push(el);
    }

    return obj;
}


export function getValue(obj, prop, safety = true, value = {}) {
    if (!safety) return getOrSetValue(obj, prop, value);

    if (!isObject(obj))
        throw new Error('"obj" argument must be an Object instance.');

    if (prop instanceof Object)
        throw new Error('"prop" argument must be a primitive type.');

    if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

    const regex = /(?:\[(\d+)\])|(?:\.?([\w\u0410-\u044F]+))/g;
    const props = [];
    let match;

    while ((match = regex.exec(prop)) !== null) {
        props.push(match[1] !== undefined ? parseInt(match[1]) : match[2]);
    }

    // If no elements matched, prop is not well-formed
    if (props.length === 0) throw new Error('invalid "prop" argument');

    let temp = obj;
    try {
        while (props.length > 1) {
            temp = temp[props.shift()];
        }

        return temp[props.shift()];
    } catch (err) {
        return undefined;
    }
}

export function setValue(obj, prop, value) {
    if (!isObject(obj))
        throw new Error('"obj" prop must be an Object instance.');

    if (prop instanceof Object)
        throw new Error('"prop" argument must be a primitive type.');

    if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

    if (prop.indexOf('.') === -1) return Vue.set(obj, prop, value);

    const props = prop.split('.');
    let temp = obj;

    while (props.length > 1) {
        const p = props.shift();

        if (temp[p] === undefined) {
            Vue.set(temp, p, {});
        }

        if (!isObject(temp[p]))
            throw new Error(
                `Invalid path. Property "${p}" already defined as a primitive value ${temp[p]}.`
            );

        temp = temp[p];
    }

    Vue.set(temp, props.shift(), value);
}

export function getOrSetValue(obj, prop, value) {
    if (!isObject(obj))
        throw new Error('"obj" argument must be an Object instance.');

    if (prop instanceof Object)
        throw new Error('"prop" argument must be a primitive type.');

    if (prop.indexOf('.') === 0) throw new Error('invalid "prop" argument');

    const props = prop.split('.');
    let temp = obj;

    while (props.length > 0) {
        const key = props.shift();

        if (temp[key]) {
            temp = temp[key];
        } else {
            Vue.set(temp, key, value);
            temp = temp[key];
        }
    }

    return temp;
}

export function isObject(obj) {
    return (
        obj instanceof Object &&
        !(obj instanceof Array) &&
        !(obj instanceof Function)
    );
}

export function getHash(target) {
    try {
        if (isObject(target)) {
            return getObjectHash(target);
        }
    } catch (error) {
        throw new Error('Invalid object. Can not get hash.');
    }
}

export function getObjectHash(obj) {
    let res = 0;

    for (const v of Object.values(obj)) {
        if (v == null || typeof v.toString !== 'function') continue;

        res += Array.from(v.toString()).reduce(
            (sum, char, index) =>
                sum + ((37 ** index * char.codePointAt(0)) % 9973),
            0
        );
    }

    return res;
}

export function randomKey(prefix = "#") {
    return prefix + (Math.random() * 2147483648 | 0).toString();
}

export function compareValues(val1, val2) {
    if (val1 == null && val2 == null) return true;
    if (val1 === undefined && val2 === undefined) return true;
    let t1 = typeof val1;
    let t2 = typeof val2;
    if (t1 !== t2) return false;
    if (t1 === "object") return compareObjects(val1, val2);
    return val1 === val2;
}

export function compareObjects(obj1, obj2, fieldFilter = null) {
    if (!obj1 || !obj2) return false;
    if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;
    if (Array.isArray(obj1) || Array.isArray(obj2)) {
        if (Array.isArray(obj1) && Array.isArray(obj2)) return compareArrays(obj1, obj2);
        return false;
    }
    let keys1 = fieldFilter ? Object.keys(obj1).filter(k => fieldFilter(k)) : Object.keys(obj1);
    let keys2 = fieldFilter ? Object.keys(obj2).filter(k => fieldFilter(k)) : Object.keys(obj2);
    if (keys1.length === keys2.length) {
        for (let k of keys1) {
            if (!compareValues(obj1[k], obj2[k])) return false;
        }
        return true;
    }
    return false;
}

export function compareArrays(arr1, arr2, mapper = null) {
    if (!arr1 || !arr2) return false;
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (mapper == null || typeof mapper === "function") {
        if (arr1.length === arr2.length) {
            for (let i = 0; i < arr1.length; i++) {
                if (mapper) {
                    if (!compareValues(mapper(arr1[i]), mapper(arr2[i]))) return false;
                } else {
                    if (!compareValues(arr1[i], arr2[i])) return false;
                }
            }
            return true;
        }
    }
    return false;
}
