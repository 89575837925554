import {defineComponent} from 'vue';
import {BUTTON_TITLE_MIN_WIDTH} from '@/common/consts/preview.consts';

export default defineComponent({
  data() {
    return {
      showButtonTitle: true,
    };
  },
  methods: {
    onResize({width}) {
      this.showButtonTitle = width > BUTTON_TITLE_MIN_WIDTH;
    },
  },
});
