<template>
  <div v-if="show" class="files-preview">
    <file-preview v-for="file in files" :key="file.id" :file="file"></file-preview>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import FilePreview from '@/components/doc/file-preview/FilePreview.vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'FilePreviewList',
  components: {
    FilePreview,
  },
  data() {
    return {
      displayedLength: 1,
    };
  },
  mounted() {
    this.$eventBus.$on('document-preview-scroll-bottom', this.addFile);
  },
  beforeDestroy() {
    this.$eventBus.$off('document-preview-scroll-bottom', this.addFile);
  },
  computed: {
    ...mapGetters('previewFiles', ['previewLastRevisionList', 'isReady']),
    show() {
      return this.isReady && this.files.length > 0;
    },
    files() {
      return this.previewLastRevisionList.slice(0, this.displayedLength);
    },
  },
  methods: {
    addFile() {
      if (this.displayedLength < this.previewLastRevisionList.length) {
        this.displayedLength++;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.file-preview {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>

