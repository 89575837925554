import {defineComponent} from 'vue';

export default defineComponent({
  data() {
    return {
      abortController: new AbortController(),
    };
  },
  beforeDestroy() {
    this.abortController.abort('Компонент уничтожен');
  },
  methods: {
    abort(reason = 'Вызвано прерывание') {
      this.abortController.abort(reason);
      this.abortController = new AbortController();
    },
  },
});
