<template>
  <span v-if="value > 0" class="badge-ui" :class="[`_${color}`, {_wide: isExceed}]">{{ text }}</span>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'BadgeUi',
  props: {
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'gray',
    },
  },
  computed: {
    isExceed() {
      return this.value > 99;
    },
    text() {
      return this.isExceed ? '99+' : this.value.toString();
    },
  },
});
</script>

<style scoped lang="scss">
.badge-ui {
  display: block;

  width: 17px;
  height: 17px;

  color: var(--color-white);
  font-size: 10px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;

  border-radius: 10px;

  &._wide {
    width: 25px;
  }

  &._gray {
    background-color: var(--color-gray-400);
  }

  &._blue {
    background-color: var(--color-blue-600);
  }

  &._red {
    background-color: var(--color-red-1000);
  }
}
</style>
