export default {
    props: {
        maxLength: {
            type: [Number, null],
            default: null,
            validator: value => value === null || value > 0,
        },
    },
    computed: {
        /* Используется в стилях */
        gradient() {
            const start = this.maxLength ? this.modelValue.length / this.maxLength * 100 : 0;
            const end = start > 0 ? Math.min(start + 5, 100) : 0;

            return {
                start: `${start}%`,
                end: `${end}%`,
            };
        },
    },
    methods: {
        getCursor() {
            return this.control.selectionStart;
        },
        setCursor(position) {
            this.control.setSelectionRange(position, position);
        },
        onInput(event) {
            const value = event.target.value;

            if (this.maxLength === null || value.length <= this.maxLength) {
                this.$emit('update:model-value', value);
            } else {
                const cursor = this.getCursor();
                this.$emit('update:model-value', value.slice(0, this.maxLength));
                this.$forceUpdate();
                this.$nextTick(() => this.setCursor(cursor));
            }
        },
    },
};
