<template>
  <combobox-ui ref="combobox" placeholder="Исполнитель" :options="users" v-model="user" no-clear></combobox-ui>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {USERS_ACTIVE} from '@/configs/endPoints';
import {TABLE_SORT_ASC} from '@/configs/tables/table';
import dbAdapter from '@/mixins/dbAdapter';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';

export default {
  name: 'SolutionAutocorrectUsers',
  components: {ComboboxUi},
  mixins: [dbAdapter],
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    const getFieldName = this.dbAdapter[this.generateKey];
    this.usersActiveGetAction({
      orderby: `${getFieldName('ФИО')} ${TABLE_SORT_ASC}`,
    }).then(() => {
      this.usersAutocorrectGetter.forEach(user_id => {
        if (this.usersActiveObjListGetter[user_id] === undefined) {
          this.delUserAutocorrect(user_id);
        }
      });
    });
  },
  computed: {
    ...mapGetters(['usersAutocorrectGetter']),
    ...mapGetters({
      users: VuexAdapter.getNameSelectGetter(USERS_ACTIVE),
      usersActiveObjListGetter: VuexAdapter.getNameObjGetter(USERS_ACTIVE),
    }),
  },
  watch: {
    user(user) {
      if (user) {
        this.addUserAutocorrect(user.code);
        this.$emit('add-solution-autocorrect', user.label);
        this.$refs.combobox.clear();
      }
    },
  },
  methods: {
    ...mapActions(['addUserAutocorrect', 'delUserAutocorrect']),
    ...mapActions({
      usersActiveGetAction: VuexAdapter.getNameAction(USERS_ACTIVE),
    }),
  },
};
</script>
