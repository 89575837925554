<template>
  <drop-zone-ui class="file-viewer" @drop="addFile">
    <input ref="input" hidden type="file" multiple @change="onChange">

    <file-viewer-empty v-if="isEmpty" @add="onAdd"></file-viewer-empty>

    <tabs-ui v-else ref="tabs" with-add @add="onAdd" @header-resize="onHeaderResize">
      <template #title="{active, title, data}">
        <component :is="data" class="icon icon_prefix-s" :class="{_active: active}"></component>
        <span>{{ title }}</span>
      </template>

      <tab-ui v-for="file in userFiles" :key="file.F_ID" :title="file['Название']" :data="iconFile(file)">
        <file-pdf-preview v-if="availableExtensionCheck(file)" :file="file"></file-pdf-preview>

        <file-error v-else text="Предпросмотр для данного типа файла недоступен" no-retry></file-error>
      </tab-ui>
    </tabs-ui>
  </drop-zone-ui>
</template>

<script>
import {defineComponent} from 'vue';
import TabUi from '@/components/ui/TabUi.vue';
import FilePdfPreview from '@/components/doc/files/FilePdfPreview.vue';
import TabsUi from '@/components/ui/TabsUi.vue';
import UploadIcon from '@/assets/svg/upload.svg?component';
import CreateDocIcon from '@/assets/svg/create-doc.svg?component';
import FileApiService from '@/services/api/file/file-api.service';
import {USERS_TABLE} from '@/configs/dbTables';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {mapState} from 'vuex';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import abort from '@/mixins/abort';
import FileViewerEmpty from '@/components/file-viewer/FileViewerEmpty.vue';
import FileError from '@/components/doc/files/FileError.vue';
import DocIcon from '@/assets/svg/files/doc.svg?component';
import XlsIcon from '@/assets/svg/files/xls.svg?component';
import PdfIcon from '@/assets/svg/files/pdf.svg?component';
import UnkonownIcon from '@/assets/svg/files/unknown.svg?component';
import {ErrorHelper} from '@/services/errorHelper';

// TODO: Рефакторинг
const AVAILABLE_EXTENSIONS = ['pdf', 'doc', 'docx', 'docm'];

// TODO: Рассмотреть использование модели DocumentFile
const ICONS = {
  doc: DocIcon,
  docx: DocIcon,
  docm: DocIcon,
  xls: XlsIcon,
  xlsm: XlsIcon,
  xlsx: XlsIcon,
  xltx: XlsIcon,
  xlsb: XlsIcon,
  xlam: XlsIcon,
  pdf: PdfIcon,
  default: UnkonownIcon,
};

export default defineComponent({
  name: 'FileViewer',
  mixins: [abort],
  components: {
    FileError,
    FileViewerEmpty,
    DropZoneUi,
    ButtonUi,
    TabsUi,
    FilePdfPreview,
    UploadIcon,
    TabUi,
    CreateDocIcon,
  },
  props: {
    formDataProxy: {
      type: Object,
      required: true,
    },
  },
  emits: ['show-loader', 'hide-loader', 'change'],
  data() {
    return {
      headerHeight: 40,
    };
  },
  computed: {
    ...mapState('user', ['userId']),
    userFiles() {
      return this.formDataProxy.user_files;
    },
    isEmpty() {
      return !this.userFiles.length;
    },
    height() {
      return `calc(100vh - ${140 + this.headerHeight}px)`;
    },
    minHeight() {
      return `${540 - this.headerHeight}px`;
    },
  },
  methods: {
    onAdd() {
      this.$refs.input.dispatchEvent(new MouseEvent('click'));
    },
    onChange(event) {
      const files = Array.from(event.target.files);

      if (files.length) {
        this.addFile(files);
      }
    },
    onHeaderResize({height}) {
      this.headerHeight = height;
    },
    getExtension(fileName) {
      return fileName.split('.').pop();
    },
    iconFile(file) {
      const ext = this.getExtension(file['Название']);
      return ICONS[ext] || ICONS.default;
    },
    availableExtensionCheck(file) {
      return AVAILABLE_EXTENSIONS.includes(this.getExtension(file['Название']));
    },
    async addFile(files) {
      this.$emit('show-loader');

      try {
        const response = await FileApiService.uploadList({
          parentTableId: USERS_TABLE,
          parentId: this.userId,
          files,
        }, this.abortController.signal);

        const uploaded = await FileApiService.getUserFileList(Object.keys(response));

        const filesCountBefore = this.userFiles.length;

        // TODO
        this.$emit('change', {
          user_files: [
            ...this.userFiles,
            ...uploaded.map(item => ({
              ...item,
              ...response[item['F_ID']],
            })),
          ],
          files: [
            ...this.formDataProxy.files.filter(item => item.F_ID === undefined),
            ...this.userFiles,
          ],
        });

        // TODO
        this.$nextTick(() => this.$refs.tabs?.setActive(filesCountBefore));
      } catch (error) {
        this.$notify({
          title: 'Ошибка загрузки файла',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
      } finally {
        this.$refs.input.value = '';
        this.$emit('hide-loader');
      }
    },
  },
});
</script>

<style scoped lang="scss">
.file-pdf-preview {
  height: v-bind(height);
}

.file-pdf-preview,
.file-error {
  min-height: v-bind(minHeight);
}
</style>
