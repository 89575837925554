export default {
  data() {
    const events = [
      'counter-reload-controls',
      'table-trigger-refresh-only-state',
    ]
    return {
      events,
      route_point_solution: {
        pause: {
          icon: () => import('@/components/icons/PauseIcon'),
          title: ' Поставить на паузу',
          class_button: 'btn-warning',
          class_text: 'text-warning',
          method: () => this.$refs.pauseDocModal.show(),
        },
        start: {
          icon: () => import('@/components/icons/PlayCircleIcon'),
          title: 'Запустить документ',
          class_button: 'btn-warning',
          class_text: 'text-warning',
          method: () => this.$refs.startDocModal.show(),
        },
        stop: {
          icon: () => import('@/components/icons/StopCircleIcon'),
          title: 'Остановить',
          class_button: 'btn-add-grey',
          class_text: 'text-danger',
          method: () => this.$refs.stopDocModal.show(),
        },
      }
    };
  },
}
