<template>
  <div id="app"
       ref="app">
    <component :is="layout">
      <transition name="fade">
        <router-view/>
      </transition>
    </component>
    <version-control-system-modal ref="versionControlSystemModal"/>
    <notify-custom/>
    <portal-target v-if="isAuthorized" name="modal" multiple/>
  </div>
</template>

<script>
import {useColorMode, useWindowSize} from '@vueuse/core';
import EmptyLayout from '@/layouts/EmptyLayout';
import ExtendedViewLayout from '@/layouts/ExtendedViewLayout';
import StandartViewLayout from '@/layouts/StandartViewLayout';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import {library as fontLibrary} from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleUp,
  faBan,
  faBars,
  faCalendar,
  faCalendarAlt,
  faCertificate,
  faCheck,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCity,
  faClock,
  faCloudDownloadAlt,
  faCog,
  faComment,
  faCompressArrowsAlt,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faFileAlt,
  faFileSignature,
  faFileUpload,
  faFolderOpen,
  faGavel,
  faHistory,
  faIdCard,
  faLaptop,
  faList,
  faLock,
  faMagic,
  faMailBulk,
  faPaperclip,
  faPhone,
  faPlus,
  faProjectDiagram,
  faRedo,
  faSave,
  faSearch,
  faSignOutAlt,
  faSortAmountAsc,
  faSortAmountDesc,
  faSyncAlt,
  faTable,
  faTag,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import VersionControlSystemModal from "@/components/modals/System/VersionControlSystemModal";
import NotifyCustom from "@/components/NotifyCustom.vue";

fontLibrary.add(faBars, faTimes, faUsers, faUser, faCog, faSignOutAlt, faChevronCircleLeft, faChevronCircleRight, faMailBulk, faList, faAngleUp, faAngleDown, faFileAlt, faHistory, faClock, faAlignLeft, faSave,
  faExclamationTriangle, faComment, faCalendarAlt, faFolderOpen, faAddressCard, faAlignRight, faExpandArrowsAlt, faCompressArrowsAlt, faCertificate, faBan, faFileUpload, faTag, faPhone, faSyncAlt,
  faGavel, faSearch, faPlus, faLaptop, faMagic, faTable, faCheck, faRedo, faCloudDownloadAlt, faCalendar, faProjectDiagram, faFileSignature, faLock, faIdCard, faCity, faPaperclip, faTrashAlt,
  faSortAmountAsc, faSortAmountDesc, faTimesCircle
);

export default {
  name: 'App',
  components: {
    NotifyCustom,
    VersionControlSystemModal,
    EmptyLayout,
    ExtendedViewLayout,
    StandartViewLayout,
  },
  metaInfo() {
    let title = 'АЛЬФА ДОК - Автоматизация бизнес-процессов';

    if (this.$route.meta.title !== undefined) {
      title = 'АЛЬФА ДОК - ' + this.$route.meta.title;
    }

    return {
      title
    }
  },
  provide() {
    const provider = {};
    const self = this;
    Object.defineProperty(provider, 'app', {
      enumerable: true,
      get: () => self,
    });
    return provider;
  },
  setup() {
    const {width: appWidth, height: appHeight} = useWindowSize();
    const {system: colorModeSystem, store: colorModeStore} = useColorMode({attribute: 'theme'});

    return {
      appWidth,
      appHeight,
      colorModeSystem,
      colorModeStore,
      error_message: false,
    }
  },
  created() {
    this.initPersistedStore();
    this.clearRefreshPromise();
    this.startVersionControlAction();
    let vw = window.innerWidth * 0.01,
      vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    /* Events */
    window.addEventListener('resize', this.windowResizeHandle);
    this.windowResizeHandle();

    if (!this.isAuthorized && this.$route.name !== 'login') {
      this.$router.replace('/login').catch(e => this.error_message = e.error_message);
    }
  },
  beforeUpdate() {
    this.windowResizeHandle();
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResizeHandle);
  },
  computed: {
    ...mapGetters('auth', ['isAuthorized']),
    ...mapGetters(['versionControlChangedGetter']),
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    colorMode() {
      return this.colorModeStore === 'auto'
        ? this.colorModeSystem
        : this.colorModeStore
    },
  },
  methods: {
    ...mapMutations('auth', ['clearRefreshPromise']),
    ...mapActions([
      'startVersionControlAction',
      'initPersistedStore',
      'cleanAllStoresAction',
    ]),
    windowResizeHandle() {
      let vw = window.innerWidth * 0.01,
        vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  watch: {
    isAuthorized(val) {
      if (!val && this.$route.name !== 'login') {
        this.cleanAllStoresAction();
        this.$router.replace('/login').catch(e => this.error_message = e.error_message);
      }
    },
    versionControlChangedGetter(val) {
      if (val) {
        this.$refs.versionControlSystemModal.show()
      }
    },
  },
}
</script>
