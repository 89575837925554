import PREFIX from "@/configs/prefixVuex";

class VuexAdapter {

    static _cashEndPointNames = {};

    static getDataTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ACTION;
    }

    static loadTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.LOADING + PREFIX.ACTION;
    }

    static refreshOnlyStateTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ONLY_STATE + PREFIX.REFRESH + PREFIX.ACTION;
    }

    static visibleColumnsTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.VISIBLE_COLUMNS + PREFIX.ACTION;
    }

    static visibleColumnsTableClearNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.VISIBLE_COLUMNS + PREFIX.CLEAR + PREFIX.ACTION;
    }

    static visibleColumnsTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.VISIBLE_COLUMNS + PREFIX.GETTER;
    }

    static sortColumnsTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SORT_COLUMNS + PREFIX.ACTION;
    }

    static sortColumnsTableClearNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SORT_COLUMNS + PREFIX.CLEAR + PREFIX.ACTION;
    }

    static sortColumnsTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SORT_COLUMNS + PREFIX.GETTER;
    }

    static abortTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ABORT + PREFIX.ACTION;
    }

    static filterTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.FILTER + PREFIX.ACTION;
    }

    static filterAndDeleteTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.FILTER_AND_DELETE_OVERS + PREFIX.ACTION;
    }

    static delOrderByTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.DEL + PREFIX.ORDER_BY + PREFIX.ACTION;
    }

    static orderByTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ORDER_BY + PREFIX.ACTION;
    }

    static fixColsTableNameMutation(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.FIX_COLS + PREFIX.MUTATION;
    }

    static delFilterTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.DEL  + PREFIX.FILTER + PREFIX.ACTION;
    }

    static delFilterAndOrderByTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.DEL  + PREFIX.FILTER  + PREFIX.ORDER_BY  + PREFIX.ACTION;
    }

    static conditionTableNameAction(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.CONDITION + PREFIX.ACTION;
    }

    static activeRowTableNameMutation(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ACTIVE_ROW + PREFIX.MUTATION;
    }

    static sortableStatusTableNameMutation(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SORTABLE_STATUS + PREFIX.MUTATION;
    }

    static activeRowTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ACTIVE_ROW + PREFIX.GETTER;
    }

    static activeRowIndexTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ACTIVE_ROW + PREFIX.INDEX + PREFIX.GETTER;
    }

    static filterTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.FILTER + PREFIX.GETTER;
    }

    static conditionTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.CONDITION + PREFIX.GETTER;
    }

    static sqlQueryTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SQL_QUERY + PREFIX.GETTER;
    }

    static orderByTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ORDER_BY + PREFIX.GETTER;
    }

    static orderByTSqlableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ORDER_BY + PREFIX.ORDER_BY + PREFIX.GETTER;
    }

    static loaderGeneralTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.LOADER_GENERAL + PREFIX.GETTER;
    }

    static subLoaderTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.LOADER + PREFIX.GETTER;
    }

    static fixColsTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.FIX_COLS + PREFIX.GETTER;
    }

    static errorTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.ERROR + PREFIX.GETTER;
    }

    static sortableStatusTableNameGetter(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.SORTABLE_STATUS + PREFIX.GETTER;
    }

    static clearTableNameMutation(tableName) {
        return tableName + PREFIX.TABLE + PREFIX.CLEAR + PREFIX.MUTATION;
    }

    static getNameAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ACTION;
    }

    static getNameRowAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.ACTION;
    }

    static getNameRowOnlyFetchAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.ONLY_FETCH + PREFIX.ACTION;
    }

    static getNameAbortAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ABORT + PREFIX.ACTION;
    }

    static getNameAbortRowAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.ABORT + PREFIX.ACTION;
    }

    static getNameLoadingAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADING + PREFIX.ACTION;
    }

    static getNameRowLoadingAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.LOADING + PREFIX.ACTION;
    }

    static getNameClearAction(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.CLEAR + PREFIX.ACTION;
    }

    static getNameMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.MUTATION;
    }

    static getNameErrorMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ERROR + PREFIX.MUTATION;
    }

    static getNameRowMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.MUTATION;
    }

    static getNameLoadingMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADING + PREFIX.MUTATION;
    }

    static getNameClearMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.CLEAR + PREFIX.MUTATION;
    }

    static getNameClearRowMutation(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW  + PREFIX.CLEAR + PREFIX.MUTATION;
    }

    static getNameState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.STATE;
    }

    static getNameLoadingState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADING + PREFIX.STATE;
    }

    static getNameRowState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.STATE;
    }

    static getLoaderGeneralState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADER_GENERAL + PREFIX.STATE;
    }

    static getNameErrorState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ERROR + PREFIX.STATE;
    }

    static getLoaderRowGeneralState(endPointName) {
        return this._endPointFormatter(endPointName)  + PREFIX.ROW + PREFIX.LOADER_GENERAL + PREFIX.STATE;
    }

    static getNameCountState(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.COUNT + PREFIX.STATE;
    }

    static getNameGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.GETTER;
    }

    static getNameRowGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.GETTER;
    }

    static getNameErrorGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ERROR + PREFIX.GETTER;
    }

    static getNameLoadingGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADING + PREFIX.GETTER;
    }

    static getNameObjGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.OBJECT + PREFIX.GETTER;
    }

    static getNameSelectGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.SELECT + PREFIX.GETTER;
    }

    static getNameSelectSimpleGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.SELECT + PREFIX.SIMPLE + PREFIX.GETTER;
    }

    static getNameCountGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.COUNT + PREFIX.GETTER;
    }

    static getNameLoaderGeneralGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.LOADER_GENERAL + PREFIX.GETTER;
    }

    static getNameRowLoaderGeneralGetter(endPointName) {
        return this._endPointFormatter(endPointName) + PREFIX.ROW + PREFIX.LOADER_GENERAL + PREFIX.GETTER;
    }

    static _endPointFormatter(endPointName) {
        if (this._cashEndPointNames[endPointName]) {
            return this._cashEndPointNames[endPointName]
        }

        const name = endPointName.split('/')
            .filter(value => value.length)
            .map((value) => value[0].toUpperCase() + value.substring(1))
            .join('');

        return this._cashEndPointNames[endPointName] = name;
    }
}

export default VuexAdapter;