import HttpService from '@/services/api/http.service';
import Vue from "vue";
import {
  DOC_GANTT_ITEMS,
  DOC_TYPES_GET,
  END_POINT_CASH_DATA,
  END_POINT_GETTER_OBJECT_LISTS,
  END_POINT_GETTER_SELECT_LISTS,
  END_POINT_GETTER_SELECT_SIMPLE_LISTS,
  END_POINT_LIST_UNIQUE_KEY,
  END_POINT_LISTS
} from "@/configs/endPoints";
import VuexAdapter from "@/services/vuexAdapter";
import axios from "axios";
import ConstantsRPS from "@/configs/routePointStatuses";

const crc32 = require('js-crc').crc32;

const actions = {};
const mutations = {};
const states = {};
const getters = {};

const clearMutations = [];

END_POINT_LISTS.forEach(endPoint => {

  const actionName = VuexAdapter.getNameAction(endPoint);
  const actionAbortName = VuexAdapter.getNameAbortAction(endPoint);
  const actionLoadingName = VuexAdapter.getNameLoadingAction(endPoint);
  const actionClearName = VuexAdapter.getNameClearAction(endPoint);

  const mutationName = VuexAdapter.getNameMutation(endPoint);
  const mutationLoadingName = VuexAdapter.getNameLoadingMutation(endPoint);
  const mutationClearName = VuexAdapter.getNameClearMutation(endPoint);
  const mutationErrorName = VuexAdapter.getNameErrorMutation(endPoint);

  const stateName = VuexAdapter.getNameState(endPoint);
  const stateCountName = VuexAdapter.getNameCountState(endPoint);
  const stateLoaderGeneralName = VuexAdapter.getLoaderGeneralState(endPoint);
  const stateErrorName = VuexAdapter.getNameErrorState(endPoint);
  const stateLoadingName = VuexAdapter.getNameLoadingState(endPoint);

  const getterName = VuexAdapter.getNameGetter(endPoint);
  const getterErrorName = VuexAdapter.getNameErrorGetter(endPoint);
  const getterLoadingName = VuexAdapter.getNameLoadingGetter(endPoint);
  const getterCountName = VuexAdapter.getNameCountGetter(endPoint);
  const getterLoaderGeneralName = VuexAdapter.getNameLoaderGeneralGetter(endPoint);

  const cashDataIsEnable = END_POINT_CASH_DATA.includes(endPoint);

  const uniqueKey = END_POINT_LIST_UNIQUE_KEY[endPoint] || null;

  /** action получения данных */
  actions[actionName] = (ctx, data) => {

    const hash = crc32(JSON.stringify(data) + actionName);

    Vue.set(ctx.state.abortControllersListsState, actionName, new AbortController());
    Vue.set(ctx.state, stateLoaderGeneralName, true);

    return new Promise((resolve, reject) => {

      if (cashDataIsEnable && ctx.state.cashListsState[hash] !== undefined) {

        const r = ctx.state.cashListsState[hash];
        ctx.commit(mutationName, r);
        resolve(r);

        Vue.set(ctx.state, stateLoaderGeneralName, false);

        return;
      }

      return HttpService.post(endPoint, data, ctx.state.abortControllersListsState[actionName].signal, false).then((r) => {
        if (cashDataIsEnable) {
          ctx.state.cashListsState[hash] = r;
        }

        ctx.commit(mutationName, r);
        ctx.commit(mutationErrorName, null);
        resolve(r);
      }).catch(e => {
        if (!axios.isCancel(e)) {
          ctx.commit(mutationErrorName, e);
          reject(e);
        }
      }).finally(() => {
        Vue.set(ctx.state, stateLoaderGeneralName, false);
      });
    });

  }

  /** Остановить запрос action получения данных */
  actions[actionAbortName] = (ctx) => {
    if (ctx.state.abortControllersListsState[actionName]) {
      ctx.state.abortControllersListsState[actionName].abort();
    }
    Vue.set(ctx.state, stateLoaderGeneralName, false);
  }

  /** action подгрузки данных */
  actions[actionLoadingName] = (ctx, data) => {
    Vue.set(ctx.state, stateLoadingName, true);
    return new Promise((resolve, reject) => {
      return HttpService.post(endPoint, data, undefined, false).then((r) => {
        ctx.commit(mutationLoadingName, r);
        resolve(r);
      }).catch(e => {
        reject(e);
      }).finally(() => {
        Vue.set(ctx.state, stateLoadingName, false);
      });
    });
  }

  /** action очистки данных */
  actions[actionClearName] = (ctx) => {
    ctx.commit(mutationClearName);
  }

  /** mutation данных */
  mutations[mutationName] = (state, data) => {
    state[stateName] = [...data.data.items];
    if (Number.isInteger(data.data.count)) {
      Vue.set(state, stateCountName, data.data.count);
    }
  };

  /** mutation подгрузки данных */
  mutations[mutationLoadingName] = (state, data) => {
    let length = state[stateName].length;
    data.data.items.forEach((value, key) => {
      Vue.set(state[stateName], length + key, value);
    })
  };

  /** mutation для обработки ошибок */
  mutations[mutationErrorName] = (state, error) => {
    state[stateErrorName] = error;
  };

  /** mutation очистки данных */
  mutations[mutationClearName] = (state) => {
    state[stateName] = [];
    state[stateCountName] = null;
    state[stateErrorName] = null;
    state[stateLoadingName] = false;
  };

  clearMutations.push(mutationClearName);

  /** state */
  states[stateName] = [];
  states[stateCountName] = null;
  states[stateErrorName] = null;
  states[stateLoadingName] = false;

  /** getter данных с костылем убирания дублирующих строк */
  getters[getterName] = (state) => {
    if (!uniqueKey) {
      return state[stateName];
    }

    return state[stateName].reduce((o, i) => {
      if (!o.find(v => v[uniqueKey] == i[uniqueKey])) {
        o.push(i);
      }
      return o;
    }, []);
  };

  /** getter количества */
  getters[getterCountName] = (state) => {
    return state[stateCountName];
  };

  /** getter крутилки запроса */
  getters[getterLoaderGeneralName] = (state) => {
    return state[stateLoaderGeneralName];
  };

  /** getter крутилки подгрузки */
  getters[getterLoadingName] = (state) => {
    return state[stateLoadingName];
  };

  /** getter ошибки */
  getters[getterErrorName] = (state) => {
    return state[stateErrorName];
  };

  /** getter получения данных в виде объекта */
  if (END_POINT_GETTER_OBJECT_LISTS[endPoint] !== undefined) {
    const getterObgName = VuexAdapter.getNameObjGetter(endPoint);
    const identField = END_POINT_GETTER_OBJECT_LISTS[endPoint];

    getters[getterObgName] = (state) => {
      let obg = {}
      for (let n in state[stateName]) {
        obg[state[stateName][n][identField]] = state[stateName][n];
      }
      return obg;
    };
  }

  /** getter получения данных для select */
  if (END_POINT_GETTER_SELECT_LISTS[endPoint] !== undefined) {
    const getterSelectName = VuexAdapter.getNameSelectGetter(endPoint);
    const codeField = END_POINT_GETTER_SELECT_LISTS[endPoint]['codeField'];
    const valueField = END_POINT_GETTER_SELECT_LISTS[endPoint]['valueField'];

    getters[getterSelectName] = (state) => {
      let select = [];
      for (let n in state[stateName]) {
        select.push({
          code: state[stateName][n][codeField],
          label: state[stateName][n][valueField],
        });
      }
      return select;
    };
  }

  /** getter получения данных для select как массив значений */
  if (END_POINT_GETTER_SELECT_SIMPLE_LISTS[endPoint] !== undefined) {
    const getterSelectSimpleName = VuexAdapter.getNameSelectSimpleGetter(endPoint);
    const codeFieldSimple = END_POINT_GETTER_SELECT_SIMPLE_LISTS[endPoint];

    getters[getterSelectSimpleName] = (state) => {
      let select = [];

      for (let n in state[stateName]) {
        select.push(state[stateName][n][codeFieldSimple]);
      }

      return select;
    };
  }
});

export default {
  actions: {
    ...actions,

    /** Очисть все хранилище */
    clearListsAction(ctx) {
      clearMutations.forEach(mutation => {
        ctx.commit(mutation);
      })
      ctx.commit('clearListsMutation');
    },
  },
  mutations: {
    ...mutations,

    clearListsMutation(state) {
      state.cashListsState = {};
      state.abortControllersListsState = {};
    }
  },
  state: {
    cashListsState: {},
    abortControllersListsState: {},
    ...states
  },
  getters: {
    ...getters,

    docTypesActiveSelectGetter(state) {

      const stateName = VuexAdapter.getNameState(DOC_TYPES_GET);

      let select = []
      for (let n in state[stateName]) {
        if (state[stateName][n]['Активен']) {
          select.push({
            code: state[stateName][n]['DT_ID'],
            label: state[stateName][n]['Название'],
          })
        }
      }
      return select
    },

    docLastRoutePointDeclineGetter(state) {
      const stateName = VuexAdapter.getNameState(DOC_GANTT_ITEMS);

      for (let n in state[stateName]) {
        if (state[stateName][n]['RPS_ID'] === ConstantsRPS.rp_status_type_rejected_id) {
          return state[stateName][n]['RP_ID'];
        }
      }

      return false;

    },
  },
}
