<template>
  <button class="btn btn-black"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <slot name="icon">
      <close-icon/>
    </slot>
    <slot name="title">
      Не сохранять
    </slot>
  </button>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue';

export default {
  name: "DiscardButton",
  components: {CloseIcon},
  inheritAttrs: false,
}
</script>
