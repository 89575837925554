export class Validator {
  static time(value) {
    const regExp = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (value && !regExp.test(value)) {
      return 'Введите валидное время в формате HH:MM';
    }
    return true;
  }
}
