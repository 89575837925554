<template>
  <div class="setting-deadline-step-master">
    <form-builder
      ref="form"
      :fields="fields"
      v-model="formDataProxy"
      @change="change"
    ></form-builder>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_BOOL_TILE, TYPE_DATE, TYPE_INTEGER, TYPE_SELECT, TYPE_SWITCH} from '@/configs/form';
import {mapActions, mapGetters, mapState} from 'vuex';
import {SYSTEM_GET_DEADLINE, USERS_ACTIVE} from '@/configs/endPoints';
import VuexAdapter from '@/services/vuexAdapter';
import {
  CALENDAR_DAYS_TERM_FROM_MASTER,
  DATE_TERM_FROM_MASTER,
  NONE_TERM_FROM_MASTER,
  WORKING_DAYS_TERM_FROM_MASTER,
} from '@/configs/master/termFromMaster';
import {getDateFromJSDate} from '@/services/utilsFunctions';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW} from '@/configs/events';
import AttachmentIcon from '@/assets/svg/attachment.svg?component';
import FileLinkIcon from '@/assets/svg/file-link.svg?component';
import BarcodeIcon from '@/assets/svg/barcode.svg?component';
import CounterpartyLinkIcon from '@/assets/svg/counterparty-link.svg?component';
import ResolutionIcon from '@/assets/svg/resolution.svg?component';

export default defineComponent({
  name: 'StepDeadline',
  mixins: [step],
  components: {FormBuilder},
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_SELECT,
            name: 'controller_id',
            placeholder: 'Выберите контролера',
            options: () => this.controllers,
            codeOnlyValue: true,
            required: true,
          },
        ],
        [
          {
            type: TYPE_SWITCH,
            name: 'termFrom',
            options: [
              {
                label: 'Без срока',
                value: NONE_TERM_FROM_MASTER,
              },
              {
                label: 'В рабочих днях',
                value: WORKING_DAYS_TERM_FROM_MASTER,
              },
              {
                label: 'В днях',
                value: CALENDAR_DAYS_TERM_FROM_MASTER,
              },
              {
                label: 'Дата',
                value: DATE_TERM_FROM_MASTER,
              },
            ],
            classes: ['mt-4'],
          },
        ],
        [
          {
            type: TYPE_INTEGER,
            name: WORKING_DAYS_TERM_FROM_MASTER,
            min: 1,
            max: 360,
            initValue: 1,
            hidden: (data) => {
              return data.termFrom !== WORKING_DAYS_TERM_FROM_MASTER;
            },
          },
          {
            type: TYPE_DATE,
            name: WORKING_DAYS_TERM_FROM_MASTER + '_VIEW',
            disabled: true,
            onlyInput: true,
            hidden: (data) => {
              return data.termFrom !== WORKING_DAYS_TERM_FROM_MASTER;
            },
          },
          {
            type: TYPE_INTEGER,
            name: CALENDAR_DAYS_TERM_FROM_MASTER,
            min: 1,
            max: 360,
            initValue: 1,
            hidden: (data) => {
              return data.termFrom !== CALENDAR_DAYS_TERM_FROM_MASTER;
            },
          },
          {
            type: TYPE_DATE,
            name: CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW',
            disabled: true,
            onlyInput: true,
            hidden: (data) => {
              return data.termFrom !== CALENDAR_DAYS_TERM_FROM_MASTER;
            },
          },
          {
            type: TYPE_DATE,
            name: DATE_TERM_FROM_MASTER,
            classes: ['w-50'],
            hidden: (data) => data.termFrom !== DATE_TERM_FROM_MASTER,
            required: true,
          },
        ],
        [
          {
            type: TYPE_BOOL_TILE,
            name: 'step_attach_files',
            icon: AttachmentIcon,
            label: 'Прикрепить<br>файлы ',
          },
          {
            type: TYPE_BOOL_TILE,
            name: 'step_link_document',
            icon: FileLinkIcon,
            label: 'Связать этот<br>документ',
          },
          {
            type: TYPE_BOOL_TILE,
            name: 'step_assign_barcode',
            icon: BarcodeIcon,
            label: 'Назначить штрих<br>код',
          },
        ],
        [
          {
            type: TYPE_BOOL_TILE,
            name: 'step_add_counterparty_links',
            icon: CounterpartyLinkIcon,
            label: 'Добавить ссылки<br>на контрагентов',
            hidden: () => !this.accessToClients,
          },
          {
            type: TYPE_BOOL_TILE,
            name: 'step_add_resolution',
            icon: ResolutionIcon,
            label: 'Добавить<br>резолюцию',
          },
        ],
      ],
    };
  },
  created() {
    if (!this.formDataProxy?.controller_id) {
      this.$set(this.formDataProxy, 'controller_id', Number(this.userId));
    }

    if (this.formDataProxy.clients.length) {
      this.$set(this.formDataProxy, 'step_add_counterparty_links', true);
    }

    if (this.formDataProxy.documents.length) {
      this.$set(this.formDataProxy, 'step_link_document', true);
    }

    if (this.formDataProxy.files.length) {
      this.$set(this.formDataProxy, 'step_attach_files', true);
    }

    this.change();
  },
  async mounted() {
    this.showLoader();
    await this.usersActiveAction();
    this.hideLoader();
  },
  computed: {
    ...mapState('user', ['userId']),
    ...mapGetters({
      usersActiveGetter: VuexAdapter.getNameGetter(USERS_ACTIVE),
      accessToEvent: 'accessToEvent',
    }),
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    controllers() {
      return this.usersActiveGetter.filter(user => Number(user['Активный'])).map(user => {
        return {
          code: user['LOGIN_ID'],
          label: user['ЛогинФИО'],
        };
      });
    },
  },
  methods: {
    ...mapActions({
      usersActiveAction: VuexAdapter.getNameAction(USERS_ACTIVE),
      fetchDeadlineAction: VuexAdapter.getNameAction(SYSTEM_GET_DEADLINE),
    }),
  },
  watch: {
    ['modelValue.' + CALENDAR_DAYS_TERM_FROM_MASTER]: {
      handler(newVal) {
        if (newVal) {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + newVal);

          const date = getDateFromJSDate(currentDate, 'yyyy-MM-dd');

          this.$set(this.formDataProxy, CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW', date);
          this.change();
        }
      },
      deep: true,
      immediate: true,
    },
    ['modelValue.' + WORKING_DAYS_TERM_FROM_MASTER]: {
      handler(newVal) {
        if (newVal && this.modelValue.termFrom === WORKING_DAYS_TERM_FROM_MASTER) {
          this.fetchDeadlineAction({
            count_day: newVal,
          }).then(r => {
            const date = r.data.date.split('.').reverse().join('-');
            this.$set(this.formDataProxy, WORKING_DAYS_TERM_FROM_MASTER + '_VIEW', date);
            this.change();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>
