import StepFiles from '@/components/document-master/steps/StepFiles.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Files,
  title: 'Прикрепите файлы',
  tooltip: 'Добавьте файлы — главную часть документа. Учтите, что удалять файлы из системы нельзя',
  menuTitle: 'Файлы',
  component: StepFiles,
};
