<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30126 0.833009L10.0267 0.833009C10.3922 0.832998 10.7076 0.832989 10.9687 0.850801C11.2436 0.869554 11.5185 0.910807 11.7901 1.02331C12.4027 1.27705 12.8893 1.76373 13.1431 2.3763C13.2556 2.64791 13.2968 2.92283 13.3156 3.19769C13.3334 3.45874 13.3334 3.77414 13.3334 4.13969V4.16634C13.3334 4.62658 12.9603 4.99968 12.5 4.99968C12.0398 4.99968 11.6667 4.62658 11.6667 4.16634C11.6667 3.76666 11.6663 3.50863 11.6528 3.31114C11.6398 3.12114 11.6176 3.04878 11.6033 3.01411C11.5187 2.80992 11.3565 2.64769 11.1523 2.56311C11.1176 2.54875 11.0452 2.52656 10.8552 2.5136C10.6578 2.50013 10.3997 2.49968 10 2.49968H4.33338C3.85291 2.49968 3.54269 2.50032 3.30657 2.51962C3.08019 2.53811 2.99625 2.56951 2.95505 2.5905C2.79825 2.6704 2.67076 2.79788 2.59087 2.95468C2.56988 2.99589 2.53848 3.07982 2.51998 3.30621C2.50069 3.54232 2.50004 3.85255 2.50004 4.33301V9.99968C2.50004 10.3994 2.50049 10.6574 2.51397 10.8549C2.52693 11.0449 2.54911 11.1172 2.56348 11.1519C2.64805 11.3561 2.81028 11.5183 3.01447 11.6029C3.04915 11.6173 3.12151 11.6395 3.31151 11.6524C3.50899 11.6659 3.76702 11.6663 4.16671 11.6663C4.62695 11.6663 5.00004 12.0394 5.00004 12.4997C5.00004 12.9599 4.62695 13.333 4.16671 13.333H4.14005C3.77451 13.333 3.4591 13.333 3.19806 13.3152C2.9232 13.2965 2.64827 13.2552 2.37667 13.1427C1.7641 12.889 1.27741 12.4023 1.02368 11.7897C0.911173 11.5181 0.86992 11.2432 0.851167 10.9683C0.833356 10.7073 0.833365 10.3919 0.833375 10.0263L0.833375 4.30089C0.83336 3.8616 0.833347 3.48263 0.85885 3.17049C0.885768 2.84103 0.945198 2.51335 1.10586 2.19803C1.34554 1.72763 1.72799 1.34518 2.1984 1.10549C2.51372 0.944831 2.8414 0.885401 3.17085 0.858484C3.483 0.832981 3.86196 0.832994 4.30126 0.833009Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1345 6.66699L12.9166 6.66699C13.3769 6.66699 13.75 7.04009 13.75 7.50033C13.75 7.96056 13.3769 8.33366 12.9166 8.33366H10.1666C9.68617 8.33366 9.37594 8.33431 9.13982 8.3536C8.91344 8.3721 8.82951 8.40349 8.7883 8.42449C8.6315 8.50438 8.50402 8.63187 8.42412 8.78867C8.40313 8.82987 8.37173 8.9138 8.35323 9.14019C8.33394 9.3763 8.33329 9.68653 8.33329 10.167V15.667C8.33329 16.1475 8.33394 16.4577 8.35323 16.6938C8.37173 16.9202 8.40313 17.0041 8.42412 17.0453C8.50402 17.2021 8.6315 17.3296 8.7883 17.4095C8.82951 17.4305 8.91344 17.4619 9.13982 17.4804C9.37594 17.4997 9.68617 17.5003 10.1666 17.5003H12.9166C13.3769 17.5003 13.75 17.8734 13.75 18.3337C13.75 18.7939 13.3769 19.167 12.9166 19.167H10.1345C9.69523 19.167 9.31625 19.167 9.0041 19.1415C8.67465 19.1146 8.34697 19.0552 8.03165 18.8945C7.56125 18.6548 7.1788 18.2724 6.93911 17.802C6.77845 17.4867 6.71902 17.159 6.6921 16.8295C6.6666 16.5174 6.66661 16.1384 6.66663 15.6991V10.1349C6.66661 9.69558 6.6666 9.31661 6.6921 9.00447C6.71902 8.67502 6.77845 8.34733 6.93911 8.03202C7.17879 7.56161 7.56125 7.17916 8.03165 6.93948C8.34697 6.77882 8.67465 6.71939 9.0041 6.69247C9.31625 6.66697 9.69522 6.66698 10.1345 6.66699Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6935 8.3536C16.4574 8.33431 16.1472 8.33366 15.6667 8.33366H12.9167C12.4565 8.33366 12.0834 7.96056 12.0834 7.50033C12.0834 7.04009 12.4565 6.66699 12.9167 6.66699L15.6988 6.66699C16.1381 6.66698 16.5171 6.66697 16.8292 6.69247C17.1587 6.71939 17.4864 6.77882 17.8017 6.93948C18.2721 7.17916 18.6545 7.56161 18.8942 8.03202C19.0549 8.34733 19.1143 8.67502 19.1412 9.00447C19.1667 9.31661 19.1667 9.69558 19.1667 10.1349V15.6991C19.1667 16.1384 19.1667 16.5174 19.1412 16.8295C19.1143 17.159 19.0549 17.4867 18.8942 17.802C18.6545 18.2724 18.2721 18.6548 17.8017 18.8945C17.4864 19.0552 17.1587 19.1146 16.8292 19.1415C16.5171 19.167 16.1381 19.167 15.6988 19.167H12.9167C12.4565 19.167 12.0834 18.7939 12.0834 18.3337C12.0834 17.8734 12.4565 17.5003 12.9167 17.5003H15.6667C16.1472 17.5003 16.4574 17.4997 16.6935 17.4804C16.9199 17.4619 17.0038 17.4305 17.045 17.4095C17.2018 17.3296 17.3293 17.2021 17.4092 17.0453C17.4302 17.0041 17.4616 16.9202 17.4801 16.6938C17.4994 16.4577 17.5 16.1475 17.5 15.667V10.167C17.5 9.68653 17.4994 9.3763 17.4801 9.14019C17.4616 8.9138 17.4302 8.82987 17.4092 8.78867C17.3293 8.63187 17.2018 8.50438 17.045 8.42449C17.0038 8.40349 16.9199 8.3721 16.6935 8.3536Z"/>
  </svg>
</template>

<script>
export default {
  name: "CopyIcon"
}
</script>