<template>
  <div ref="dropZoneRef" class="drop-zone-ui">
    <slot></slot>

    <transition name="opacity">
      <div v-if="isOverDropZone" class="overlay">
        <p class="text">Перетащите сюда файл</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useDropZone} from '@vueuse/core';

const emit = defineEmits(['drop']);

const dropZoneRef = ref(null);

const {isOverDropZone} = useDropZone(dropZoneRef, {
  onDrop: (files) => emit('drop', files),
});
</script>

<style scoped lang="scss">
.drop-zone-ui {
  position: relative;
}

.overlay {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-drop);
}

.text {
  margin-bottom: 0; // TODO: Перекрытие стилей

  color: var(--color-black);
  font-size: var(--font-size-xxxl);
  line-height: var(--line-height-xxxl);

  opacity: 0.5;
}
</style>
