import HttpService from '@/services/api/http.service';
import {USER_FIO, USER_ID} from '@/configs/endPoints';

export default class UserApiService {
  static async getId() {
    const {login_id} = await HttpService.post(USER_ID);
    return login_id;
  }

  static async getName(id) {
    const {user_fio} = await HttpService.post(USER_FIO, {
      login_id: id,
    });
    return user_fio;
  }
}
