<template>
  <div class="PreferencesList">
    <draggable class="PreferencesList_container" v-model="configOrderArray">
      <li class="list-group-item d-flex justify-content-between"
          v-for="key in configOrderArray" v-bind:key.prop="key"
      >
        <span class="PreferencesList_bars">
          <menu-table-setting-bars-icon/>
          {{ configs.info[key].title }}
        </span>
        <checkbox-form v-model="form[key]"/>
      </li>
    </draggable>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
import MenuTableSettingBarsIcon from '@/components/icons/MenuTableSettingBarsIcon';
import CheckboxForm from "@/components/form/CheckboxForm";
import VuexAdapter from "@/services/vuexAdapter";

export default {
  name: "PreferencesList",
  props: [
    'configs',
  ],
  data: () => ({
    form: {},
    preferences: {},
    configOrderArray: [],
  }),
  beforeMount() {
    this.setPreferences();
    this.setConfigOrderList();
  },
  mounted() {
    this.$parent.$on('apply_table_' + this.configs.name, this.setPreferencesList);
    this.$parent.$on('default_table_' + this.configs.name, this.cleanPreferencesList);
    this.$parent.$on('on_all_table_' + this.configs.name, this.onAllCheckbox);
    this.$parent.$on('off_all_table_' + this.configs.name, this.offAllCheckbox);

    this.$eventBus.$on('table-set-refresh-total-' + this.configs.name, () => {
      this.cleanPreferencesList();
    });
  },
  beforeDestroy() {
    this.$parent.$off('apply_table_' + this.configs.name);
    this.$parent.$off('default_table_' + this.configs.name);
    this.$parent.$off('on_all_table_' + this.configs.name);
    this.$parent.$off('off_all_table_' + this.configs.name);
  },
  computed: {
    visibleColumns() {
      return this.$store.getters[VuexAdapter.visibleColumnsTableNameGetter(this.configs.name)] || {}
    },
    sortColumns() {
      return this.$store.getters[VuexAdapter.sortColumnsTableNameGetter(this.configs.name)] || []
    }
  },
  methods:{
    setPreferences() {
      this.preferences = this.visibleColumns ? {...this.visibleColumns} : {};
    },
    setConfigOrderList(reload = false){
      let array = this.configOrderArray.length === 0 || reload
          ?  [...this.sortColumns]
          :  this.configOrderArray
      if (array.length === 0){
        array = Object.keys(this.configs.info)
      }

      array.forEach(key => {
        let value = this.preferences[key] === undefined || reload ? this.configs.info[key].visible : this.preferences[key];
        this.$set(this.form, key, value)
      });

      this.configOrderArray = array.filter(value => !this.configs.info[value].system && !this.configs.info[value].configTitle);
    },
    setPreferencesList(){
      this.$store.dispatch(VuexAdapter.visibleColumnsTableNameAction(this.configs.name), this.form);
      this.$store.dispatch(VuexAdapter.sortColumnsTableNameAction(this.configs.name), this.configOrderArray);
    },
    cleanPreferencesList(){
      this.$store.dispatch(VuexAdapter.visibleColumnsTableClearNameAction(this.configs.name));
      this.$store.dispatch(VuexAdapter.sortColumnsTableClearNameAction(this.configs.name));
      this.setConfigOrderList(true);
      this.setPreferences();
    },
    onAllCheckbox(){
       for (let key in this.form){
         this.$set(this.preferences, key, true)
         this.$set(this.form, key, true)
       }
    },
    offAllCheckbox(){
      for (let key in this.form) {
        this.$set(this.preferences, key, false)
        this.$set(this.form, key, false)
      }
    }
  }, components: {
    CheckboxForm,
    draggable,
    MenuTableSettingBarsIcon
  },
}
</script>