<template>
  <ul class="card-list-ui">
    <card-ui
      v-for="item in list"
      :key="getKey(item)"
      tag="li"
      :title="getTitle(item)"
      :href="getHref(item)"
      :icon="icon"
      :disabled="disabled"
      :options="options"
      :option="getOption(item)"
      @change="$emit('change', $event, item)"
      @remove="$emit('remove', item)"
    ></card-ui>
  </ul>
</template>

<script>
import {defineComponent} from 'vue';
import CardUi from '@/components/ui/CardUi.vue';

export default defineComponent({
  name: 'CardListUi',
  components: {
    CardUi,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    icon: Object,
    getKey: {
      type: Function,
      required: true,
    },
    getTitle: {
      type: Function,
      required: true,
    },
    getHref: {
      type: Function,
      required: true,
    },
    getOption: {
      type: Function,
      required: true,
    },
  },
  emits: ['change', 'remove'],
});
</script>

<style scoped lang="scss">
.card-list-ui {
  flex-grow: 1;
  padding: 8px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}
</style>
