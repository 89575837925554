<template>
  <component :is="tag" class="chip-ui">
    <slot></slot>
    <close-icon class="icon" @click="$emit('close', $event)"></close-icon>
  </component>
</template>

<script>
import CloseIcon from '@/assets/svg/close.svg?component';
import {only} from '@/common/utils/props-validators';

export default {
  name: 'ChipUi',
  components: {
    CloseIcon,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
  },
  emits: ['close'],
};
</script>

<style scoped lang="scss">
.chip-ui {
  height: 24px;
  padding: 0 4px 0 8px;

  display: flex;
  align-items: center;

  color: var(--color-gray-600);
  font-size: var(--font-size);
  line-height: var(--line-height);

  background-color: var(--color-white);
  border: var(--border-gray-200);
  border-radius: 8px;
}

.icon {
  margin: -4px -4px -4px 0;
  padding: 4px;

  width: 20px;
  height: 20px;
}
</style>
