<template>
  <tr class="w-100">
    <td colspan="1000" class="p-0 w-100 table-active-filter-sear">
      <div class="filters-form">
        <div class="filters-form-header">
          <div class="title">Где мы ищем?</div>
          <div :class="{selected: !optionsSearchSelected.length}"
               @click="clearSearchSelected"
               class="filters-form-badge-search">
            Везде
          </div>
          <div v-for="option in optionsSearch"
               class="filters-form-badge-search"
               :class="{selected: optionsSearchSelected.includes(option.code)}"
               @click="selectedSearchFilter(option.code)"
               :key.prop="option">{{ option.label }}
          </div>
        </div>
        <hr>
        <div v-if="optionsDictionarySearchLength">
          <div class="title d-flex">
            <div class="mr-4">Фильтры:</div>
          </div>
          <dictionary-filter-small v-for="(option, key) in optionsDictionarySearch"
                                   :col="option"
                                   :key="key"
                                   :init_selected="optionsFilterSelected[key] || null"
                                   :init_reverse="optionsFilterReverse[key] || false"
                                   @change="setOptionsFilterSelected(key, $event)"
          />
          <hr>
        </div>
        <div class="filters-form-footer">
          <a href="#" @click.prevent="clearFilter">Сбросить</a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import Constants from "@/configs/tables/constants";
import DictionaryFilterSmall from "@/components/table/filters/small/DictionaryFilterSmall";
import dbAdapter from "@/mixins/dbAdapter";
import VuexAdapter from "@/services/vuexAdapter";

const NOT_IN = 'not in';

export default {
  name: "TableActiveFilterSmallSearch",
  components: {DictionaryFilterSmall},
  mixins: [dbAdapter],
  props: ['configs_table', 'searchValue', 'searchShow'],
  data: () => ({
    searchKey: 'search',
    keyFilter: 'LIKE',
    optionsSearchSelected: [],
    optionsFilterSelected: {},
    optionsFilterReverse: {},
    keyupEnter: null,
  }),
  created() {
    this.$parent.$on('applyFilter', this.applyFilter);

    this.keyupEnter = (e) => {
      const key = e.which || e.keyCode || 0;
      if (key == 13 && this.searchShow) {
        this.$parent.$emit('applyFilter');
      }
    }

    document.addEventListener('keyup', this.keyupEnter);

    for (let key in this.tableFilterData) {

      if (this.tableFilterData[key][0] === undefined) {
        continue;
      }

      let data = this.tableFilterData[key][0];

      if (key == this.searchKey) {

        if (data.search_fields.length !== this.optionsSearchSelected.length) {
          this.optionsSearchSelected = data.search_fields;
        }

        continue;
      }

      if ([Constants.asyncIdentifier, Constants.identifier].includes(this.configs_table.info[key].type)) {
        this.optionsFilterSelected[key] = data?.input || [];
        this.optionsFilterReverse[key] = data?.select?.code === NOT_IN;

        continue;
      }
      if ([Constants.flag].includes(this.configs_table.info[key].type)) {
        this.optionsFilterSelected[key] = [data.select] || [];
      }
    }

    if (this.optionsSearchSelected.length === this.optionsSearch.length) {
      this.optionsSearchSelected = [];
    }
  },
  destroyed() {
    this.$parent.$off('applyFilter');
    document.removeEventListener('keyup', this.keyupEnter);
  },
  methods: {
    tableDeleteALLFilters() {
      this.$store.dispatch(VuexAdapter.delFilterAndOrderByTableNameAction(this.configs_table.name));
    },
    async tableSetFilter(data) {
      await this.$store.dispatch(VuexAdapter.filterTableNameAction(this.configs_table.name), data);
    },
    getSQLForString(searchField) {
      return this.dbAdapter[this.stringSqlSearch](this, searchField);
    },
    getSQLForInt(searchField) {
      return this.dbAdapter[this.intSqlSearch](this, searchField);
    },
    getSQLForIdentifier(searchField, values) {
      return this.dbAdapter[this.identifierSqlSearch](this, searchField, values);
    },
    getSQLForNotInIdentifier(searchField, values) {
      return this.dbAdapter[this.identifierSqlNotInSearch](this, searchField, values);
    },
    getSQLForFlag(searchField, value) {
      return this.dbAdapter[this.flagSqlSearch](this, searchField, value);
    },
    async applyFilter() {
      this.tableDeleteALLFilters();

      const data = [];

      if (this.searchValue.length) {
        let search_fields = this.optionsSearchSelected.length ? this.optionsSearchSelected : this.optionsSearchKeys

        const searchSql = [];

        search_fields.forEach(searchField => {
          searchSql.push(this.getSQLForString(searchField));
        });

        if (searchSql.length) {
          data.push({
            key: 'search',
            whereSQL: ' ( ' + searchSql.join(' ) OR ( ') + ' ) ',
            whereData: [{
              algorithm: null,
              select: null,
              input: null,
              search_value: this.searchValue,
              search_fields,
            }],
          });
        }
      }

      for (let key in this.optionsFilterSelected) {
        if (!this.optionsFilterSelected[key].length) {
          continue;
        }

        const reverse = this.optionsFilterReverse[key] || false;

        const select = reverse
          ? {code: NOT_IN, label: 'Ни один из',}
          : {code: 'in', label: 'Один из',};

        if ([Constants.asyncIdentifier, Constants.identifier].includes(this.configs_table.info[key].type)) {
          let ids = [];

          this.optionsFilterSelected[key].forEach(id => {
            const code = this.configs_table.info[key]?.optionKeyValue ? "'" + id.code + "'" : id.code;
            ids.push(code)
          });

          const whereSQL = reverse
            ? this.getSQLForNotInIdentifier(key, ids)
            : this.getSQLForIdentifier(key, ids)

          data.push({
            key,
            whereSQL,
            whereData: [{
              algorithm: null,
              select,
              input: this.optionsFilterSelected[key],
            }],
          });

        }

        if ([Constants.flag].includes(this.configs_table.info[key].type) && this.optionsFilterSelected[key][0] !== undefined) {
          data.push({
            key,
            whereSQL: this.getSQLForFlag(key, this.optionsFilterSelected[key][0]['code']),
            whereData: [{
              algorithm: null,
              select: this.optionsFilterSelected[key][0],
              input: null,
            }],
          });
        }
      }

      for (const row of data) {
        await this.tableSetFilter(row);
      }

      this.$emit('close');
    },
    selectedSearchFilter(option_key) {
      if (this.optionsSearchSelected.includes(option_key)) {
        this.optionsSearchSelected = this.optionsSearchSelected.filter((key => option_key != key));
        return;
      }
      this.optionsSearchSelected.push(option_key);
      if (this.optionsSearchSelected.length === this.optionsSearch.length) {
        this.optionsSearchSelected = [];
      }
    },
    clearSearchSelected() {
      this.optionsSearchSelected = [];
    },
    clearFilter() {
      this.optionsSearchSelected = [];
      this.optionsFilterSelected = {};
      this.optionsFilterReverse = {};
      this.$emit('clear');
      this.tableDeleteALLFilters();
    },
    setOptionsFilterSelected(key, {optionsSearchSelected, reverseFilter}) {
      this.optionsFilterSelected[key] = optionsSearchSelected;
      this.optionsFilterReverse[key] = reverseFilter;
    },
  },
  computed: {
    tableFilterData() {
      return this.$store.getters[VuexAdapter.filterTableNameGetter(this.configs_table.name)];
    },
    optionsSearch() {
      const options = [];

      for (let code in this.configs_table.info) {
        if (this.configs_table.info[code].searchSmallField !== undefined && this.configs_table.info[code].searchSmallField) {
          let label = this.configs_table.info[code].title !== undefined ? this.configs_table.info[code].title : code;
          let data = {
            code,
            label,
          };
          options.push(data);
        }
      }

      return options;
    },
    optionsSearchKeys() {
      const keys = [];

      this.optionsSearch.forEach(option => keys.push(option.code))

      return keys;
    },
    optionsDictionarySearch() {
      const options = {};
      for (let code in this.configs_table.info) {
        if (this.configs_table.info[code].searchSmallFieldDictionary !== undefined && this.configs_table.info[code].searchSmallFieldDictionary === true) {
          options[code] = this.configs_table.info[code];
        }
      }

      return options;
    },
    optionsDictionarySearchLength() {
      return Object.keys(this.optionsDictionarySearch).length;
    },
    optionsSort() {
      const options = [];

      for (let key in this.configs_table.info) {
        if (this.configs_table.info[key].visible !== undefined && this.configs_table.info[key].title !== undefined && this.configs_table.info[key].visible) {
          options.push({
            code: key,
            label: this.configs_table.info[key].title
          })
        }
      }

      return options;
    },
  }
}
</script>
