import CacheGroup from '@/common/consts/cacheGroup';

export default {
  namespaced: true,
  state: {
    values: {},
  },
  getters: {
    get: (state) => ({group, key}) => state.values[group || CacheGroup.Common]?.[key],
  },
  mutations: {
    add(state, {group, key, value}) {
      const groupKey = group || CacheGroup.Common;

      state.values = {
        ...state.values,
        [groupKey]: {
          ...state.values[groupKey],
          [key]: value
        },
      };
    },
    remove(state, {group, key}) {
      const groupKey = group || CacheGroup.Common;

      const {[groupKey]: groupState, ...rest} = state.values;

      if (!key) {
        state.values = rest;
        return;
      }

      const {[key]: _, ...groupRest} = groupState;

      state.values = {
        ...rest,
        [groupKey]: groupRest
      }
    },
    clear(state) {
      state.values = {};
    },
  },
};
