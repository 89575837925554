export const TABLE_SORT_ASC = 'ASC';
export const TABLE_SORT_DESC = 'DESC';
export const TABLE_CONDITION_AND = 'AND';
export const TABLE_CONDITION_OR = 'OR';

export const COMMISSIONS_TABLE_NAME = 'commissions';
export const VIEWS_DOC_TABLE_NAME = 'viewsDoc';
export const ROUTES_POINTS_TABLE_NAME = 'routesPoints';
export const FILES_SIGNATURES_TABLE_NAME = 'filesSignatures';
export const FILES_TABLE_NAME = 'files';
export const DOCUMENTS_LINKS_TABLE_NAME = 'documentsLinks';
export const DOCUMENTS_TABLE_NAME = 'documents';
export const DOC_HISTORY_TABLE_NAME = 'docHistory';
export const DOC_CONTRACTORS_TABLE_NAME = 'docContractors';
export const CONTROLS_TABLE_NAME = 'controls';
export const COMMENTS_TABLE_NAME = 'comments';
export const CLIENTS_TABLE_NAME = 'clients';
export const ACCESS_DOC_TABLE_NAME = 'accessDoc';