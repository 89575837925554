<template>
  <button class="button-link-ui" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ButtonLinkUi',
});
</script>

<style scoped lang="scss">
.button-link-ui {
  display: flex;
  align-items: center;

  color: var(--color-blue-700);
  font-size: var(--font-size);
  line-height: var(--line-height);

  &:hover {
    text-decoration: underline;
  }
}
</style>
