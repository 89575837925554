const downloadFile = {
    data() {
        return {
            isFileDownloading: false,
        };
    },
    methods: {
        downloadFile() {
            this.isFileDownloading = true;
            this.file.download();
            this.isFileDownloading = false;
        },
    },
};

export default downloadFile;
