<template>
  <modal-ui ref="modal" :title="configs?.title" :error-message="errorMessage" :loader-text="loaderText">
    <template #body>
      <solution-autocorrect class="form-add-comment" @add-solution-autocorrect="addSolutionAutocorrect"/>

      <textarea-ui
        class="mt-5 mb-3"
        ref="textarea"
        v-model="comment"
        placeholder="Текст комментария"
        focus-on-mount
      ></textarea-ui>

      <file-drop-zone
        v-show="showFileDropZone"
        class="mt-5"
        ref="fileDropZone"
        @loaded="reload_files = true"
        @close="$emit('filesUploaded')"
        @hasErrorFilesUploaded="$emit('hasErrorFilesUploaded')"
        :doc_id="configs.doc_id"
      />
      <index-sign
        v-if="showSignBlock"
        v-show="filesForSignLengthGetter"
        @loaded="loaded_sign = true"
        :doc-id="configs.doc_id"
      />
    </template>

    <template #footer>
      <close-button @click="hide"/>

      <button class="btn btn-big" :class="[configs.class_button]" :disabled="disableApplyAgreement" @click="apply">
        <component :is="configs.icon" class="mr-2"/>
        {{ configs.name_button }}
      </button>
    </template>
  </modal-ui>
</template>

<script>
import route_point_types from '@/configs/routePointTypes';
import SolutionAutocorrect from '@/components/doc/solution/SolutionAutocorrect';
import CloseButton from '@/components/buttons/CloseButton';
import FileDropZone from '@/components/doc/files/FileDropZone';
import {mapActions, mapGetters} from 'vuex';
import IndexSign from '@/components/sign/IndexSign';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import {DOC_COMMENTS_GET, DOC_FILES_GET, DOC_GANTT_ITEMS, DOCS_GET} from '@/configs/endPoints';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: 'SolutionRoutePointModal',
  mixins: [modal],
  components: {
    ModalUi,
    TextareaUi,
    IndexSign,
    FileDropZone,
    CloseButton,
    SolutionAutocorrect,
  },
  props: [
    'configs',
  ],
  data: () => ({
    comment: '',
    has_error_files_uploaded: false,
    reload_files: false,
    loaded_sign: false,
  }),
  mounted() {
    if (this.isAgreement) {
      this.systemAutoSignAction();
    }

    this.$on('hasErrorFilesUploaded', () => {
      this.has_error_files_uploaded = true;
      this.errorMessage = 'Ошибка загрузки файла, продолжить?';
      this.$off('filesUploaded');
      this.hideLoader();
    });
  },
  computed: {
    ...mapGetters([
      'AutoSignEnableGetter',
      'hasFilesForSignSelectedGetter',
      'filesForSignAllLoadGetter',
      'filesForSignLengthGetter',
    ]),
    disableApplyAgreement() {
      if (this.apply_disabled) {
        return true;
      }
      if (!this.showSignBlock) {
        return false;
      }

      return !this.filesForSignAllLoadGetter;
    },
    isAgreement() {
      return route_point_types.agreement === this.configs?.routePointType && this.configs?.name_button === 'Одобрить';
    },
    showSignBlock() {
      return this.isAgreement && this.AutoSignEnableGetter;
    },
    showFileDropZone() {
      return !this.showSignBlock || (!this.filesForSignLengthGetter && this.loaded_sign);
    },
    loaderText() {
      return this.hasFilesForSignSelectedGetter ? 'Подписание файлов / согласование' : null;
    },
  },
  methods: {
    ...mapActions([
      'systemAutoSignAction',
      'applyFilesForSignAction',
      'refreshPartlyPreviewAction',
    ]),
    addSolutionAutocorrect(text) {
      this.comment = this.comment + text + ' ';
      this.$refs.textarea.focus();
    },
    apply() {
      this.errorMessage = null;
      this.showLoader();

      if (this.hasFilesForSignSelectedGetter && this.isAgreement && this.AutoSignEnableGetter) {
        this.applyFilesForSignAction(this.configs.doc_id).then(() => {
          this.applyGeneral();
        }).catch(() => {
          this.errorMessage = 'Ошибка подписания файлов';
          this.hideLoader();
        });

        return;
      }

      this.applyGeneral();
    },
    applyGeneral() {
      let comment = '';

      if (this.isAgreement) {
        comment = 'Согласовано';
      }
      if (route_point_types.agreement === this.configs.routePointType && this.configs.name_button === 'Отклонить') {
        comment = 'Не согласовано';
      }
      if (route_point_types.notification === this.configs.routePointType) {
        comment = 'Принято';
      }
      if (route_point_types.assignment === this.configs.routePointType) {
        comment = 'Выполнено';
      }
      if (this.comment.length > 0) {
        comment = comment + ' / ' + this.comment + ' /';
      }

      this.$on('filesUploaded', () => {
        this.$off('filesUploaded');

        this.$store.dispatch(this.configs.action, {
          rp_id: this.configs.rp_id,
          comment,
        }).then(() => {
          this.configs.events.forEach((value) => {
            this.$eventBus.$emit(value);
          });
          this.refreshPartlyPreviewAction(DOCS_GET);
          this.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
          this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);
          this.refreshPartlyPreviewAction(DOC_FILES_GET);
          this.hide();
        }).catch(e => {
          this.errorMessage = e.error_message;
          this.hideLoader();
        });
      });

      if (this.has_error_files_uploaded) {
        this.$emit('filesUploaded');
      } else {
        this.$refs.fileDropZone.upload();
      }
    },
  },
};
</script>
