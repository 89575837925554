<template>
  <div :class="classDocumentSheetContainer"
       v-infinite-scroll="containerScroll"
       :infinite-scroll-disabled="scrollDisable"
       infinite-scroll-distance="100"
       ref="componentContainer">
    <upload-file-modal ref="uploadFileModal" :doc-id="entityIdPreviewGetter" :files="dropFiles"/>
    <div v-if="!initFirstDataPreviewGetter"
         class="document-init-attempted p-5">
      <div class="spinner-alpha-container mb-3">
        <i class="spinner-alpha"></i>
      </div>
      <span class="text-muted">Загрузка данных</span>
    </div>

    <div v-else>
      <sheet-document-preview :ready="!docGetterLoader" class="mt-0">
        <div class="w-100">
          <header-document-preview :doc="docGetter"></header-document-preview>
          <common-document-preview :doc="docGetter"></common-document-preview>
          <solution-commissions-buttons
            v-if="!docGetterLoader && showSolutionCommissionsButtons"
            :key="renderKeyCommissions"
            :doc="docGetter"
          ></solution-commissions-buttons>

          <div class="w-100" v-if="!docGetterLoader && (showSolutionControlsButtons || showButtonRoutePointRestart)">
            <div class="d-flex justify-content-end align-self-center flex-wrap">

              <solution-controls-buttons
                v-if="showSolutionControlsButtons"
                :key="renderKeyControls"
                :doc="docGetter"
              ></solution-controls-buttons>

              <button-route-point-restart
                v-if="showButtonRoutePointRestart"
                class="mt-2"
                :rp_id="docLastRoutePointDeclineGetter"
                :key="renderKeyRoutePoint"
                :doc_id="docGetter['DOC_ID']"
              ></button-route-point-restart>
            </div>
          </div>
        </div>
      </sheet-document-preview>

      <comments-document-preview
        :doc_id="entityIdPreviewGetter"
        :show-add="accessToEdit"
        :loading="docCommentsGetGetterLoader"
      ></comments-document-preview>

      <files-document-preview
        :doc_id="entityIdPreviewGetter"
        :show-add="accessToEdit"
        :loading="isFilesLoading"
      ></files-document-preview>

      <gantt-document-preview
        v-if="docGanttItemsGetter.length"
        :tasks="docGanttItemsGetter"
        :doc="docGetter"
        :loading="ganttGetLoader"
        :show-add="showRoutPointAdd"
        :doc_id="entityIdPreviewGetter"
        :rp_id="rpIdMark"
      ></gantt-document-preview>

      <additional-info-document-preview :doc_id="entityIdPreviewGetter" :loading="!readyAdditionalInfo"/>

      <file-preview-list v-if="!isFilesLoading"></file-preview-list>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters, mapMutations, mapState} from 'vuex';
import FilePreviewList from '@/components/doc/file-preview/FilePreviewList.vue';
import SolutionCommissionsButtons from '@/components/doc/solution/SolutionCommissionsButtons';
import SolutionControlsButtons from '@/components/doc/solution/SolutionControlsButtons';
import preview from '@/mixins/preview';
import ButtonRoutePointRestart from '@/components/doc/preview/ButtonRoutePointRestart';
import HeaderDocumentPreview from '@/components/doc/preview/HeaderDocumentPreview.vue';
import CommonDocumentPreview from '@/components/doc/preview/CommonDocumentPreview.vue';
import SheetDocumentPreview from '@/components/doc/preview/SheetDocumentPreview.vue';
import CommentsDocumentPreview from '@/components/doc/preview/CommentsDocumentPreview.vue';
import FilesDocumentPreview from '@/components/doc/preview/FilesDocumentPreview';
import GanttDocumentPreview from '@/components/doc/preview/GanttDocumentPreview';
import AdditionalInfoDocumentPreview from '@/components/doc/preview/AdditionalInfoDocumentPreview';
import VuexAdapter from '@/services/vuexAdapter';
import {DocumentPreview} from '@/services/preview/DocumentPreview';
import {DOCUMENTS__EDIT} from '@/configs/events';

import {
  DOC_COMMENTS_GET,
  DOC_GANTT_ITEMS,
  DOC_LINKS_GET,
  DOCS_CLIENTS_GET,
  DOCS_GET,
  DOCS_VIEWS_ALL,
} from '@/configs/endPoints';
import {randomKey} from '@/services/utilsFunctions';
import UploadFileModal from '@/components/modals/File/UploadFileModal';

export default defineComponent({
  name: 'DocumentPreview',
  components: {
    UploadFileModal,
    AdditionalInfoDocumentPreview,
    GanttDocumentPreview,
    FilesDocumentPreview,
    CommentsDocumentPreview,
    SheetDocumentPreview,
    CommonDocumentPreview,
    HeaderDocumentPreview,
    ButtonRoutePointRestart,
    SolutionControlsButtons,
    SolutionCommissionsButtons,
    FilePreviewList,
  },
  data: () => ({
    scrollDisable: false,
    dropFiles: null,
  }),
  mixins: [preview],
  created() {
    this.initPreviewAction(DocumentPreview.intefaceIdent);

    this.documentPreviewDropFileFn = (files) => {
      if (this.entityIdPreviewGetter) {
        this.dropFiles = files;

        this.$refs.uploadFileModal.show();
      }
    };

    this.$eventBus.$on('document-preview-drop-file', this.documentPreviewDropFileFn);
  },
  computed: {
    renderKeyCommissions() {
      return randomKey(JSON.stringify(this.docGetter));
    },
    renderKeyControls() {
      return randomKey(JSON.stringify(this.docGetter));
    },
    renderKeyRoutePoint() {
      return randomKey(JSON.stringify(this.docGetter));
    },
    showSolutionCommissionsButtons() {
      return this.$route.name === 'commissions' && !this.docGetterLoader && this.docGetter['DOC_ID'] === this.entityIdPreviewGetter;
    },
    showSolutionControlsButtons() {
      return this.$route.name === 'controls' && !this.docGetterLoader && this.docGetter['DOC_ID'] === this.entityIdPreviewGetter && this.docGetter['DS_ID'] !== 6;
    },
    showButtonRoutePointRestart() {
      return this.$route.name === 'controls' && !this.ganttGetLoader && this.docGetter['DOC_ID'] === this.entityIdPreviewGetter && this.docLastRoutePointDeclineGetter;
    },
    readyAdditionalInfo() {
      return !this.docViewsGetGetterLoader && !this.docContractorsGetGetterLoader && !this.docDocumentsLinksGetterLoader;
    },
    stopDocumentStatus() {
      return this.docGetter['DS_ID'] == 5;
    },
    accessToEdit() {
      return this.accessToEvent(DOCUMENTS__EDIT) && !this.stopDocumentStatus;
    },
    showRoutPointAdd() {
      return this.docGetter['CONTROLLER_ID'] === this.userId && this.accessToEdit;
    },
    rpIdMark() {
      if (this.$route.meta?.table === 'commissions') {
        return this.commissionsActiveRow?.RP_ID;
      }
      return null;
    },
    ...mapState('user', ['userId']),
    ...mapState('previewFiles', {
      isFilesLoading: 'isLoading',
    }),
    ...mapGetters([
      'docLastRoutePointDeclineGetter',
      'entityIdPreviewGetter',
      'initFirstDataPreviewGetter',
      'accessToEvent',
    ]),
    ...mapGetters({
      docGetter: VuexAdapter.getNameRowGetter(DOCS_GET),
      docGetterLoader: VuexAdapter.getNameRowLoaderGeneralGetter(DOCS_GET),
      docViewsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOCS_VIEWS_ALL),
      docCommentsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_COMMENTS_GET),
      docDocumentsLinksGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_LINKS_GET),
      docContractorsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOCS_CLIENTS_GET),
      docGanttItemsGetter: VuexAdapter.getNameGetter(DOC_GANTT_ITEMS),
      ganttGetLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_GANTT_ITEMS),
      commissionsActiveRow: VuexAdapter.activeRowTableNameGetter('commissions'),
    }),
  },
  methods: {
    ...mapMutations('previewFiles', {
      clearFiles: 'clear',
    }),
  },
  beforeDestroy() {
    this.$eventBus.$off('document-preview-drop-file', this.documentPreviewDropFileFn);
    this.clearFiles();
  },
});
</script>

<style scoped lang="scss">
.preview-block {
  margin-bottom: 16px;
}

.files-preview {
  margin-bottom: 16px;
}
</style>
