import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import VuexAdapter from "@/services/vuexAdapter";
import {DOCS_CLIENTS_GET} from "@/configs/endPoints";
import {DOC_CONTRACTORS_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: DOC_CONTRACTORS_TABLE_NAME,
    title: 'Контрагенты',
    action: VuexAdapter.getNameAction(DOCS_CLIENTS_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOCS_CLIENTS_GET),
    getter: VuexAdapter.getNameGetter(DOCS_CLIENTS_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOCS_CLIENTS_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOCS_CLIENTS_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOCS_CLIENTS_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOCS_CLIENTS_GET),
    generalField: 'CLIENT_ID',
    searchSmallSelectedField: 'Название',
    smallGroped: () => import('@/components/table/grouped/DocContractors'),
    limit: 20,
    info: {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
        },
        'LINK_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID ссылки',
            conditions: 1
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            conditions: 1
        },
        'CLIENT_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID контрагента',
            conditions: 1,
            order: 0
        },
        'Ссылка': {
            visible: true,
            title: 'Ссылка',
            classes: {},
            type: Constants.string,
            description: 'Ссылка',
            conditions: 3,
            order: 1,
        },
        'Номер': {
            visible: true,
            searchSmallField: true,
            title: 'Номер',
            type: Constants.integer,
            classes: {},
            description: 'Номер',
            conditions: 3,
            order: 2,
        },
        'Тип': {
            visible: true,
            searchSmallField: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип',
            conditions: 3,
            order: 3,
        },
        'Название': {
            visible: true,
            searchSmallField: true,
            title: 'Название',
            classes: {},
            type: Constants.string,
            description: 'Название',
            conditions: 3,
            order: 4,
        },
        'Менеджер': User({
            title: 'Менеджер',
            searchSmallFieldDictionary: true,
            order: 5,
            fieldKeyValue: 'Менеджер',
        }),
        'Создана': {
            visible: false,
            title: 'Создана',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Создана',
            conditions: 3,
            order: 6,
        },
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание',
            order: 7,
            conditions: 3,
        },
    },
};