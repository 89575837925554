<template>
  <search-multiple-ui
    v-model="formDataProxy.clients"
    @change="change"
    search-placeholder="Поиск контрагента"
    :search-end-point="CLIENT_CHECK"
    :disabled-formatter="item => item['Доступен'] !== 1"
    search-key-field="CLIENT_ID"
    search-value-field="Название"
    :search-handler="searchHandler"
    :icon="icon"
    :add-icon="addIcon"
    add-text="Добавить контрагента"
    :item-title-handler="itemTitleHandler"
    :item-title-href-handler="itemTitleHrefHandler"
    :options="counterpartyLinkTypes"
    select-key="LT_ID"
    :after-add-handler="afterAddHandler"
    :max-height="326"
  ></search-multiple-ui>
</template>

<script>
import {defineComponent} from 'vue';
import {CLIENT_CHECK, CLIENTS_GET} from '@/configs/endPoints';
import SearchMultipleUi from '@/components/ui/SearchMultipleUi.vue';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import CounterpartyIcon from '@/assets/svg/counterparty.svg?component';
import AddCounterpartyIcon from '@/assets/svg/add-counterparty.svg?component';
import stepWithLinkTypes from '@/mixins/document-master/stepWithLinkTypes';
import {CLIENTS_TABLE} from '@/configs/dbTables';

export default defineComponent({
  name: 'StepCounterparties',
  mixins: [stepWithLinkTypes],
  components: {
    SearchMultipleUi,
  },
  data() {
    return {
      CLIENT_CHECK,
      icon: CounterpartyIcon,
      addIcon: AddCounterpartyIcon,
      searchHandler: async (id) => {
        return await this.clientAction({where: 'CLIENT_ID = ' + id})
          .then((r) => {
            return r.data.items[0];
          });
      },
      itemTitleHandler: (item) => {
        return `${item['Название']}<br>№${item['Номер']}`;
      },
      itemTitleHrefHandler: (item) => {
        return '/client/' + item['CLIENT_ID'];
      },
      // TODO: Дублирование StepContent
      afterAddHandler: (item) => {
        const ltIdsInSelect = this.counterpartyLinkTypes
          .map(linkType => linkType.code)
          .filter(linkType => linkType !== 1);

        if (!ltIdsInSelect.length) {
          this.$set(item, 'LT_ID', 1);
        } else {
          const key = this.formDataProxy.clients.length;
          this.$set(item, 'LT_ID', ltIdsInSelect[key] || 1);
        }

        return item;
      },
    };
  },
  computed: {
    counterpartyLinkTypes() {
      return this.linkTypes.filter(linkType => !linkType.appliedIds || linkType.appliedIds.includes(CLIENTS_TABLE));
    },
  },
  methods: {
    ...mapActions({
      'clientAction': VuexAdapter.getNameAction(CLIENTS_GET),
    }),
  },
});
</script>
