import {AbstarctPreview} from "@/services/preview/AbstarctPreview";
import store from "@/store";
import VuexAdapter from "@/services/vuexAdapter";
import {CLIENTS_ADDRESSES_GET, CLIENTS_BANK_DETAILS_GET, CLIENTS_COMMENTS_GET, CLIENTS_CONTACTS_GET, CLIENTS_DOCS_GET, CLIENTS_GET} from "@/configs/endPoints";
import {COUNTERPARTIES__BANK_DETAILS} from "@/configs/events";

export class ClientPreview extends AbstarctPreview {

    static intefaceIdent = 'CLIENT_PREVIEW';

    fetchDataPreview() {
        return new Promise((resolve, reject) => {
            this._abort().finally(() => {
                this._fetch()
                    .then(() => resolve())
                    .catch(e => reject(e));
            });
        });
    }

    fetchPartlyPreview(endPoint) {

        switch (endPoint) {
            case CLIENTS_ADDRESSES_GET:
            case CLIENTS_CONTACTS_GET:
            case CLIENTS_COMMENTS_GET:
            case CLIENTS_BANK_DETAILS_GET:
                return store.dispatch(VuexAdapter.getNameAction(endPoint), {
                    where: 'CLIENT_ID = ' + this._entityId,
                    limit: this._limitLoad,
                });
        }
    }

    _fetch() {

        const data = {
            where: 'CLIENT_ID = ' + this._entityId,
            limit: this._limitLoad,
        }

        const promices = [
            store.dispatch(VuexAdapter.getNameRowAction(CLIENTS_GET), this._entityId),
            store.dispatch(VuexAdapter.getNameAction(CLIENTS_ADDRESSES_GET), data),
            store.dispatch(VuexAdapter.getNameAction(CLIENTS_COMMENTS_GET), data),
            store.dispatch(VuexAdapter.getNameAction(CLIENTS_CONTACTS_GET), data),
            store.dispatch(VuexAdapter.getNameAction(CLIENTS_DOCS_GET), {...data, orderby: 'DOC_ID DESC'}),
        ];

        if (store.getters.accessToEvent(COUNTERPARTIES__BANK_DETAILS)) {
            promices.push(
                store.dispatch(VuexAdapter.getNameAction(CLIENTS_BANK_DETAILS_GET), data)
            )
        }

        return Promise.all(promices);
    }

    _abort() {

        const promices = [
            store.dispatch(VuexAdapter.getNameAbortRowAction(CLIENTS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(CLIENTS_ADDRESSES_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(CLIENTS_COMMENTS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(CLIENTS_CONTACTS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(CLIENTS_DOCS_GET)),
        ];

        if (store.getters.accessToEvent(COUNTERPARTIES__BANK_DETAILS)) {
            promices.push(
                store.dispatch(VuexAdapter.getNameAbortAction(CLIENTS_BANK_DETAILS_GET))
            )
        }

        return Promise.all(promices);
    }

}