<template>
  <footer class="document-master-footer">
    <button-ui v-if="isLast" :disabled="disabled" @click="$emit('ready')">
      Готово
    </button-ui>

    <template v-else>
      <template v-if="!onlyNext">
        <button-ui color="white" :disabled="disabled" @click="$emit('close')">
          <template #prefix>
            <circle-close-icon></circle-close-icon>
          </template>
          <span>Отмена</span>
        </button-ui>

        <button-ui v-if="!isFirst" color="black" :disabled="disabled" @click="$emit('back')">
          Назад
        </button-ui>
      </template>

      <button-ui :disabled="disabled" @click="$emit('next')">
        Далее
      </button-ui>
    </template>
  </footer>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/svg/circle-close.svg?component'

export default defineComponent({
  name: 'DocumentMasterFooter',
  components: {
    ButtonUi,
    CircleCloseIcon,
  },
  props: {
    onlyNext: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'ready', 'back', 'next'],
});
</script>

<style scoped lang="scss">
.document-master-footer {
  display: flex;
  justify-content: flex-end;
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
