<template>
  <preview-block title="Вложения" :amount="lastRevisionList.length" :loading="loading" @resize="onResize">
    <template #header-postfix>
      <add-button v-if="showAdd" :show-title="showButtonTitle" @click="add"></add-button>
      <upload-file-modal ref="addModal" :doc-id="doc_id"></upload-file-modal>
    </template>

    <div v-if="isReady && lastRevisionList.length" class="files-document-preview">
      <div v-for="file in filesView" :key.prop="file" class="item">
        <div class="d-flex">
          <div class="black-text-bold">{{ file.user }}</div>
          <div class="grey-text ml-2">{{ file.createdDate }}</div>
        </div>

        <div>
          <div class="d-flex align-items-center justify-content-start mt-1">
            <file-name-button v-if="file.hash" :file="file" with-sign></file-name-button>

            <div
              class="file-redaction-button"
              v-if="file.revision.value > 1"
              @click.prevent="filesPrevRevisionActive = filesPrevRevisionActive === file.id ? null : file.id"
            >
              {{ file.revision.text }}
            </div>

            <file-note v-if="file.comment" :note="file.comment"/>
          </div>

          <div v-if="file.revision.value > 1 && filesPrevRevisionActive === file.id"
               class="pl-3 mt-2 prev-files-block">
            <div v-for="prevFile in getOldRevisionListByName(file.nameWithExtension)" :key="prevFile.id">
              <div class="d-flex mt-1">
                <div class="black-text-bold">{{ prevFile.user }}</div>
                <div class="grey-text ml-2">{{ prevFile.createdDate }}</div>
              </div>

              <div class="d-flex align-items-center justify-content-start mt-1">
                <file-name-button v-if="prevFile.hash" :file="prevFile" with-sign></file-name-button>

                <div v-if="prevFile.revision.value > 1" class="file-redaction">
                  {{ prevFile.revision.text }}
                </div>

                <file-note v-if="prevFile.comment" :note="prevFile.comment" sub></file-note>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uploader-block-footer">
        <div
          v-if="loader"
          class="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100"
          aria-valuemin="0"
          aria-valuemax="100"
          style="height: 5px"
          role="progressbar"
        ></div>
        <show-more-button v-if="showMoreButton" @click="load"></show-more-button>
      </div>
    </div>
    <div v-else-if="error" class="grey-text mt-3 mb-3">
      Ошибка получения данных
    </div>
    <div v-else class="grey-text mt-3 mb-3">
      Список вложений пуст
    </div>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import {getDate} from '@/services/utilsFunctions';
import ShowMoreButton from '@/components/buttons/ShowMoreButton';
import FileNote from '@/components/doc/files/FileNote';
import UploadFileModal from '@/components/modals/File/UploadFileModal';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import FileNameButton from '@/components/doc/files/FileNameButton.vue';
import {ErrorHelper} from '@/services/errorHelper';
import {mapGetters, mapState} from 'vuex';
import previewBlock from '@/mixins/previewBlock';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddButton from '@/components/buttons/AddButton.vue';

export default defineComponent({
  name: 'FilesDocumentPreview',
  mixins: [previewBlock],
  components: {
    AddButton,
    PreviewBlock,
    FileNameButton,
    UploadFileModal,
    FileNote,
    ShowMoreButton,
  },
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filesPrevRevisionActive: null,
    loader: false,
    limit: 5,
    views: 0,
    viewsAll: 5,
    filesView: [],
  }),
  mounted() {
    this.getFilesLastRevision();
  },
  methods: {
    getDate,
    getFilesLastRevision() {
      this.lastRevisionList.forEach((file, key) => {
        if (this.views < this.viewsAll && key === this.views) {
          this.views++;
          this.filesView.push(file);
        }
      });
    },
    load() {
      this.loader = true;
      this.viewsAll = this.viewsAll + this.limit;
      this.getFilesLastRevision();
      this.loader = false;
    },
    add() {
      this.$refs.addModal.show();
    },
  },
  computed: {
    ...mapState('previewFiles', [
      'error',
    ]),
    ...mapGetters('previewFiles', [
      'isReady',
      'lastRevisionList',
      'getOldRevisionListByName',
    ]),
    showMoreButton() {
      return this.lastRevisionList.length > this.viewsAll;
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.$notify({
          title: 'Ошибка получения данных',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(val, false) || 'Неизвестная ошибка', // TODO: 'Неизвестная ошибка' никогда не отобразится
        });
      }
    },
    isReady(value) {
      if (value) {
        this.filesView = [];
        this.views = 0;
        this.getFilesLastRevision();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.file-name-button {
  &:not(:last-child) {
    margin-right: 12px;
  }
}
</style>
