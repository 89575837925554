<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1567_1954)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99996 1.99996C4.68625 1.99996 1.99996 4.68625 1.99996 7.99996C1.99996 11.3137 4.68625 14 7.99996 14C8.36815 14 8.66663 14.2984 8.66663 14.6666C8.66663 15.0348 8.36815 15.3333 7.99996 15.3333C3.94987 15.3333 0.666626 12.05 0.666626 7.99996C0.666626 3.94987 3.94987 0.666626 7.99996 0.666626C8.36815 0.666626 8.66663 0.965103 8.66663 1.33329C8.66663 1.70148 8.36815 1.99996 7.99996 1.99996Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.99996C11.3137 1.99996 14 4.68625 14 7.99996C14 11.3137 11.3137 14 8.00004 14C7.63185 14 7.33337 14.2984 7.33337 14.6666C7.33337 15.0348 7.63185 15.3333 8.00004 15.3333C12.0501 15.3333 15.3334 12.05 15.3334 7.99996C15.3334 3.94987 12.0501 0.666626 8.00004 0.666626C7.63185 0.666626 7.33337 0.965103 7.33337 1.33329C7.33337 1.70148 7.63185 1.99996 8.00004 1.99996Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33329 5.99996C6.1492 5.99996 5.99996 6.1492 5.99996 6.33329V9.66663C5.99996 9.85072 6.1492 9.99996 6.33329 9.99996H7.99996C8.36815 9.99996 8.66663 10.2984 8.66663 10.6666C8.66663 11.0348 8.36815 11.3333 7.99996 11.3333H6.33329C5.41282 11.3333 4.66663 10.5871 4.66663 9.66663V6.33329C4.66663 5.41282 5.41282 4.66663 6.33329 4.66663H7.99996C8.36815 4.66663 8.66663 4.9651 8.66663 5.33329C8.66663 5.70148 8.36815 5.99996 7.99996 5.99996H6.33329Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66671 10C9.8508 10 10 9.8508 10 9.66671L10 6.33337C10 6.14928 9.8508 6.00004 9.66671 6.00004L8.00004 6.00004C7.63185 6.00004 7.33337 5.70156 7.33337 5.33337C7.33337 4.96518 7.63185 4.66671 8.00004 4.66671L9.66671 4.66671C10.5872 4.66671 11.3334 5.4129 11.3334 6.33337L11.3334 9.66671C11.3334 10.5872 10.5872 11.3334 9.66671 11.3334L8.00004 11.3334C7.63185 11.3334 7.33337 11.0349 7.33337 10.6667C7.33337 10.2985 7.63185 10 8.00004 10L9.66671 10Z"/>
    </g>
    <defs>
      <clipPath id="clip0_1567_1954">
        <rect width="16" height="16" fill="none"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "StopCircleIcon"
}
</script>