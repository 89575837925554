import Constants from '@/configs/tables/constants';
import Controller from "@/configs/tables/cols/Controller";
import Registrator from "@/configs/tables/cols/Registrator";
import Project from "@/configs/tables/cols/Project";
import User from "@/configs/tables/cols/User";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_COMMENTS_GET} from "@/configs/endPoints";
import {COMMENTS_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: COMMENTS_TABLE_NAME,
    title: 'Резолюции',
    action: VuexAdapter.getNameAction(DOC_COMMENTS_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_COMMENTS_GET),
    getter: VuexAdapter.getNameGetter(DOC_COMMENTS_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_COMMENTS_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_COMMENTS_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_COMMENTS_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOC_COMMENTS_GET),
    generalField: 'COMMENT_ID',
    searchSmallSelectedField: 'Пользователь',
    smallGroped: () => import('@/components/table/grouped/Comments'),
    limit: 20,
    info: {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
        },
        'COMMENT_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID резолюции/комментария',
            order: 0,
            conditions: 3,
        },
        'PARENT_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы сущности',
            classes: {},
        },
        'PARENT_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы сущности',
            classes: {},
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
        },
        'Активен': {
            visible: false,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Флаг: активен ли докумнт или нет (документ может быть неактивным, если это черновик или на паузе)',
            conditions: 1,
            order: 1,
        },
        'Номер': {
            visible: false,
            title: 'Номер документа',
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 2,
        },
        'Дата': {
            visible: false,
            title: 'Дата документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата документа',
            order: 3,
            conditions: 3,
        },
        'Срок': {
            visible: false,
            title: 'Срок поручения',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Срок поручения',
            order: 4,
            conditions: 3,
        },
        'Исполнен': {
            visible: false,
            title: 'Дата остановки документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 5,
        },
        'Контролер': Controller({
            visible: false,
            order: 6,
        }),
        'Регистратор': Registrator({
            visible: false,
            order: 7,
        }),
        'Проект': Project({
            visible: false,
            order: 8,
        }),
        'Содержание': {
            visible: false,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание документа',
            order: 9,
            conditions: 3,
        },
        'Дата время': {
            visible: true,
            title: 'Дата время',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 10,
        },
        'Текст': {
            visible: true,
            searchSmallField: true,
            title: 'Текст резолюции/комментария',
            classes: {},
            type: Constants.string,
            description: 'Текст резолюции/комментария',
            component: () => import('@/components/doc/comments/FormatterComment'),
            order: 11,
            conditions: 3,
        },
        'Пользователь': User({
            order: 12,
            searchSmallFieldDictionary: true,
        }),
        'Элемент': {
            visible: true,
            title: 'Элемент',
            classes: {},
            type: Constants.string,
            description: 'Описательная часть поручения, к которой относится резолюция/комментарий',
            order: 13,
            conditions: 3,
        },
    },
};