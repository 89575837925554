import HttpService from '@/services/api/http.service';
import Vue from 'vue';
import {COMMISSIONS_COLORS, COMMISSIONS_COUNT} from "@/configs/endPoints";
import {COMMISSIONS_TABLE_NAME} from "@/configs/tables/table";

export default {
  actions: {
    /** Счетчик поручений */
    async getCommissionsCountAction(ctx, data = {}) {
      return await HttpService.post(COMMISSIONS_COUNT, data).then(response => {
        if ((ctx.state.commissionsCount.ids !== null && response.ids !== ctx.state.commissionsCount.ids) ||
          (ctx.state.commissionsCount.ids_new !== null && response.ids_new !== ctx.state.commissionsCount.ids_new)) {
          ctx.dispatch('refreshDataTable', COMMISSIONS_TABLE_NAME);
        }
        ctx.commit('commissionsCountMutations', response);
      })
    },

    /** Цвета поручений */
    async CommissionsColorAction(ctx, data = {}) {
      return await HttpService.post(COMMISSIONS_COLORS, data).then(response => {
        ctx.commit('commissionsColorMutations', response);
      })
    },

    /** Очисть хранилище */
    clearCommissionsAction(ctx) {
      ctx.commit('clearCommissionsMutations');
    }
  },
  mutations: {
    commissionsCountMutations(state, data) {
      if (data !== undefined) {
        Vue.set(state.commissionsCount, 'count', data.count);
        Vue.set(state.commissionsCount, 'count_new', data.count_new);
        Vue.set(state.commissionsCount, 'count_expired', data.count_expired);
        Vue.set(state.commissionsCount, 'ids', data.ids);
        Vue.set(state.commissionsCount, 'ids_new', data.ids_new);
      }
    },
    commissionsColorMutations(state, data) {
      if (data !== undefined) {
        data.items.forEach((value) => {
          Vue.set(state.commissionsColors, value.RP_ID, value);
        })
      }
    },
    clearCommissionsMutations(state) {
      state.commissionsColors = {};
      state.commissionsCount = {
        count: 0,
        count_new: 0,
        count_expired: 0,
        ids: null,
        ids_new: null,
      };
    },
  },
  state: {
    commissionsCount: {
      count: 0,
      count_new: 0,
      count_expired: 0,
      ids: null,
      ids_new: null,
    },
    commissionsColors: {}
  },
  getters: {
    commissionsCountGetter(state) {
      return state.commissionsCount
    },
    commissionsColorGetter(state) {
      return state.commissionsColors
    },
  },
}
