import UserApiService from '@/services/api/user/user-api.service';

export default {
  namespaced: true,
  state: {
    userId: null,
    userName: null,
  },
  actions: {
    async fetch({commit}) {
      const userId = await UserApiService.getId();
      const userName = await UserApiService.getName(userId);

      commit('set', {userId, userName});
    },
  },
  mutations: {
    set(state, payload) {
      state.userId = payload.userId;
      state.userName = payload.userName;
    },
    clear(state) {
      state.userId = null;
      state.userName = null;
    },
  },
};
