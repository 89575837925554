import Constants from "@/configs/tables/constants";
import {RECIPIENTS_GET} from "@/configs/endPoints";
import VuexAdapter from "@/services/vuexAdapter";

const data = {
    visible: true,
    system: false,
    title: 'Исполнитель',
    classes: {},
    type: Constants.asyncIdentifier,
    optionAction: VuexAdapter.getNameAction(RECIPIENTS_GET),
    optionGetter: VuexAdapter.getNameGetter(RECIPIENTS_GET),
    description: 'ID сущности исполнителя (пользователь/должность/группа)',
    conditions: 1,
    fieldKeyValue: false,
    optionKeyValue: false,
}

export default function (mix = {}) {
    return Object.assign(Object.create(data), mix)
}