<template>
  <div v-center class="error">
    {{ text }}
  </div>
</template>

<script>
import Center from '@/directives/center';

export default {
  name: "ErrorUi",
  props: {
    text: {
      type: String,
    }
  },
  directives: {
    Center,
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.error {
  margin-bottom: 16px;
  padding: 8px 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  color: $red-error-color;

  border: 1px solid $red-error-color;
  border-radius: 3px;
}
</style>
