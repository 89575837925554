import {
  CALENDAR_DAYS_TERM_FROM_MASTER,
  DATE_TERM_FROM_MASTER,
  NONE_TERM_FROM_MASTER,
  WORKING_DAYS_TERM_FROM_MASTER,
} from '@/configs/master/termFromMaster';
import DOCUMENT_TYPE from '@/common/consts/document-master/types/typeDocument';
import {STANDARD_ROUTE} from '@/configs/routeTypes';
import {START_NOW_CONTROL_TYPE} from '@/configs/controlTypes';
import {AT_ONCE_START_CONTROL_TYPE} from '@/configs/master/startControlTypeMaster';
import {LOADING_MASTER_STEP_STATUS} from '@/configs/master/masterStepLoadingStatus';

export default {
  termFrom: NONE_TERM_FROM_MASTER,
  skip_barcode: true,
  controller_id: null,
  step_attach_files: false,
  step_link_document: false,
  step_assign_barcode: false,
  step_add_counterparty_links: false,
  step_add_resolution: false,
  created: false,
  clear_control_start: false,
  route_none: false,
  errors: false,
  resolution: null,
  type: DOCUMENT_TYPE,
  p_id: null,
  p_name: null,
  reg_number: null,
  reg_date: null,
  content: null,
  mask: null,
  parameters: null,
  parametersFormData: {},
  user_files: [],
  route_copy_doc_id: null,
  files: [],
  clients: [],
  documents: [],
  routes_responsible_unspecified_steps: [],
  type_route: STANDARD_ROUTE,
  control_type: START_NOW_CONTROL_TYPE,
  start_control_type: AT_ONCE_START_CONTROL_TYPE,
  [WORKING_DAYS_TERM_FROM_MASTER]: null,
  [WORKING_DAYS_TERM_FROM_MASTER + '_VIEW']: null,
  [CALENDAR_DAYS_TERM_FROM_MASTER]: null,
  [CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW']: null,
  [DATE_TERM_FROM_MASTER]: null,
  loadingStatuses: {
    doc: LOADING_MASTER_STEP_STATUS,
    links: LOADING_MASTER_STEP_STATUS,
    temps: LOADING_MASTER_STEP_STATUS,
    resolution: LOADING_MASTER_STEP_STATUS,
    files: LOADING_MASTER_STEP_STATUS,
    routes: LOADING_MASTER_STEP_STATUS,
    copy_route: LOADING_MASTER_STEP_STATUS,
  },
};
