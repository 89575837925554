export default [
    {
        label: 'Автоматическая',
        code: 'auto',
        default: true,
    },
    {
        label: 'Светлая',
        code: 'light',
    },
    {
        label: 'Сепия',
        code: 'cafe',
    },
    {
        label: 'Тёмная',
        code: 'dark',
    },
];
