<template>
  <button v-bind="$attrs"
          v-on="$listeners"
          class="btn-sign">
     <stroke-icon/> Подписать
  </button>
</template>

<script>
import StrokeIcon from "@/components/icons/StrokeIcon";
export default {
  name: "SignButton",
  components: {StrokeIcon},
}
</script>