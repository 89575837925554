<template>
  <div class="filter-dictionary" v-show="optionsLength > 1 || load">
    <loader-ui v-if="load"></loader-ui>
    <div class="d-flex align-items-end mb-1">
      <div class="title">{{ col.title }}</div>
      <div class="search"><input v-if="showSearch" v-model="search" @keyup="searchInOptions" placeholder="поиск"/></div>
    </div>
    <div class="filters-form-content">
      <div :class="{selected: !optionsSearchSelected.length}"
           @click="clearSelected"
           class="filters-form-badge-search mr-2 mt-1">
        Все
      </div>
      <div v-if="!isTypeFlag"
           :class="{
              selected: reverseFilter,
              disabled: disabledReverseFilter
           }"
           @click="setReverseFilter"
           class="filters-form-badge-search mr-2 mt-1">
        Кроме
      </div>
      <div v-for="(option, key) in options" v-bind:key="key"
           :class="{
              selected: optionsSearchSelected.includes(option),
              'd-none': search.length && !searchInOptionsKeys.includes(key)
            }"
           @click="selectedSearchFilter(option)"
           class="filters-form-badge-search mr-2 mt-1">
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import Constants from '@/configs/tables/constants';
import LoaderUi from '@/components/ui/LoaderUi.vue';

export default defineComponent({
  name: 'DictionaryFilterSmall',
  components: {LoaderUi},
  props: {
    col: Object,
    init_selected: Array,
    init_reverse: Boolean,
  },
  data: () => ({
    optionsSearchSelected: [],
    searchInOptionsKeys: [],
    search: '',
    load: false,
    reverseFilter: false,
  }),
  async beforeMount() {
    if (Constants.asyncIdentifier === this.col.type) {
      this.load = !this.load;
      await this.$store.dispatch(this.col.optionAction).then(() => this.load = !this.load);
    }

    if (this.init_reverse) {
      this.reverseFilter = this.init_reverse;
    }

    if (this.init_selected) {
      this.init_selected.forEach(option => {
        if (this.options[option.code] !== undefined) {
          this.selectedSearchFilter(this.options[option.code]);
        }
      });
    }

    if (this.optionsLength == this.optionsSearchSelected.length) {
      this.optionsSearchSelected = [];
    }

    this.$parent.$on('clear', () => {
      this.optionsSearchSelected = [];
      this.search = '';
      this.reverseFilter = false;
    });
  },
  beforeDestroy() {
    this.$parent.$off('clear');
  },
  methods: {
    setReverseFilter() {
      if (!this.disabledReverseFilter) {
        this.reverseFilter = !this.reverseFilter;
        this.changeEvent();
      }
    },
    clearSelected() {
      this.reverseFilter = false;
      this.optionsSearchSelected = [];
      this.changeEvent();
    },
    selectedSearchFilter(option) {
      if (this.isTypeFlag && !this.optionsSearchSelected.includes(option)) {
        this.optionsSearchSelected = [option];
      } else if (this.optionsSearchSelected.includes(option)) {
        this.optionsSearchSelected = this.optionsSearchSelected.filter((key => option != key));
      } else {
        this.optionsSearchSelected.push(option);
      }

      if (this.optionsLength == this.optionsSearchSelected.length) {
        this.optionsSearchSelected = [];
        this.reverseFilter = false;
      }

      this.changeEvent();
    },
    changeEvent() {
      this.$emit('change', {
        optionsSearchSelected: this.optionsSearchSelected,
        reverseFilter: this.reverseFilter,
      });
    },
    searchInOptions() {
      const keys = [];
      if (this.search.length) {
        for (let key in this.options) {
          let label = this.options[key].label.toLowerCase();
          if (label.indexOf(this.search.toLowerCase()) >= 0) {
            keys.push(key);
          }
        }
      }
      this.searchInOptionsKeys = keys;
    },
  },
  computed: {
    isTypeFlag() {
      return Constants.flag === this.col.type;
    },
    disabledReverseFilter() {
      return !this.optionsSearchSelected.length;
    },
    getter() {
      return this.col.optionSmallFilterGetter || this.col.optionGetter;
    },
    showSearch() {
      return Constants.asyncIdentifier === this.col.type && this.optionsLength > 10;
    },
    options() {
      let options = {};

      switch (this.col.type) {
        case Constants.identifier:
          for (let key in this.col.values) {
            options[key] = {
              code: key,
              label: this.col.values[key].title,
              selected: false,
            };
          }
          break;

        case Constants.flag:
          options['= 1'] = {
            code: '= 1',
            label: 'Да',
            selected: false,
          };
          options['= 0'] = {
            code: '= 0',
            label: 'Нет',
            selected: false,
          };
          break;

        case Constants.asyncIdentifier:
          this.$store.getters[this.getter].forEach(option => {
            if (option.optionKeyValue !== undefined && option.optionKeyValue) {
              options[option.code] = {
                code: '\'' + option.label + '\'',
                label: option.label,
                selected: false,
              };
              return;
            }
            options[option.code] = {
              code: option.code,
              label: option.label,
              selected: false,
            };
          });
          break;
        default:
          console.error('Неизвестный тип поля: ' + this.col.type);
      }

      return options;
    },
    optionsLength() {
      return Object.keys(this.options).length;
    },
  },
  watch: {
    optionsLength() {
      if (this.optionsLength == this.optionsSearchSelected.length) {
        this.optionsSearchSelected = [];
      }
      this.searchInOptions();
    },
  },
});
</script>
