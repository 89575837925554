import HttpService from '@/services/api/http.service';
import {DOC_FILES_GET, DOC_TYPE_MASK_PARAM_VALUES_FREQUENT} from '@/configs/endPoints';
import DocumentFile from '@/common/models/document-file';
import {GET_HINTS_COUNT} from '@/services/api/document/document-api.consts.ts';
import TextOption from '@/common/models/option/text-option';

export default class DocumentApiService {
  static async getFiles(id, signal) {
    const response = await HttpService.post(DOC_FILES_GET, {
      doc_id: id,
    }, signal);

    return response.items.map(item => new DocumentFile(item));
  }

  static async getHints(request, signal) {
    const response = await HttpService.post(DOC_TYPE_MASK_PARAM_VALUES_FREQUENT, {
      dt_id: request.id,
      number: request.number,
      count: GET_HINTS_COUNT,
      value: request.query,
    }, signal);

    return response.items.map(item => new TextOption(item));
  }
}
