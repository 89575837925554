<template>
  <date-form placeholder="Дата"
             only-calendar
             @change="updateDate"/>
</template>

<script>
import {DateTime} from "luxon";
import DateForm from "@/components/form/DateForm";

export default {
  name: "SolutionAutocorrectDate",
  components: {DateForm},
  methods: {
    updateDate(value) {
       let date = new DateTime.fromJSDate(new Date(value)).toFormat('dd.MM.yyyy');
       this.$emit('add-solution-autocorrect', date);
    },
  },
}
</script>