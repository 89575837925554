import HttpService from '@/services/api/http.service';
import {SYSTEM_DOC_MASK_PARAMS} from "@/configs/endPoints";
import {fullTrim} from "@/services/utilsFunctions";

export default {
  actions: {
    async fetchMaskAction(ctx, mask) {
      if (!mask || ctx.state.masks[mask] !== undefined) {
        return;
      }
      ctx.state.masks[mask] = [];

      return await HttpService.post(SYSTEM_DOC_MASK_PARAMS, {
        mask,
      }).then(response => {
        ctx.state.masks = {
          ...ctx.state.masks,
          [mask]: response.items
        }
      });
    },
  },
  state: {
    masks: {},
  },
  getters: {
    maskStringGetter(state) {
      return (mask, string) => {
        if (!mask || !string || !state.masks[mask] || !state.masks[mask].length) {
          return `<span class="mask">${fullTrim(string)}</span>`
        }
        const maskData = state.masks[mask];

        const replase = [];
        const replaseValueKeys = [];

        let start = 0
        maskData.forEach(data => {
          replase.push(
            string.slice(start ? start + 1 : start, data.start - 1)
          );
          replaseValueKeys.push(
            replase.push(
              string.slice(data.start - 1, data.end)
            ) - 1
          )
          start = data.end - 1;
        });

        if (start + 1 < string.length) {
          replase.push(
            string.slice(start + 1, string.length)
          );
        }

        for (let key = 0; key < replase.length; key++) {
          if (replaseValueKeys.includes(key)) {
            replase[key] = `<span class="mask--value">${fullTrim(replase[key])}</span>`;
          }
        }


        return `<span class="mask">${replase.join('')}</span>`
      }
    }
  },
}
