<template>
  <tr @click="clickRow(false)"
      ref="rowTable"
      :key.prop="row"
      :class="{
        'table-row-active': isSelectedRow,
        'table-design-tr': true
      }">
    <td v-for="value in columns"
        :style="getRowStyles"
        :key.camel="value"
        :class="getClasses(value)"
    >
      <component :is="configs.info[value.keyRow].component || configs.info[value.keyRow].type"
                 :config="configs.info[value.keyRow]"
                 :config_g="configs"
                 :small="false"
                 :data="value.value"
                 :dataRow="row"
                 :keyRow="value.keyRow"
                 :indexRow="index"
      ></component>
    </td>
  </tr>
</template>

<script>
import VuexAdapter from "@/services/vuexAdapter";
import {tColorToRGB} from "@/services/utilsFunctions";

export default {
  name: "TableRow",
  inject: [
    'layout',
  ],
  props: [
    'row',
    'index',
    'configs',
    'componentWidth',
  ],
  mounted() {
    if (this.isSelectedRow || !this.hasTableActiveRow) {
      this.clickRow(true)
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('change_table_preferences_list_info_' + this.configs.name);
  },
  components: {
    Date: () => import('@/components/table/columns/DateColumn'),
    Flag: () => import('@/components/table/columns/FlagColumn'),
    Integer: () => import('@/components/table/columns/IntegerColumn'),
    Identifier: () => import('@/components/table/columns/IdentifierColumn'),
    String: () => import('@/components/table/columns/StringColumn'),
    Eye: () => import('@/components/table/columns/EyeColumn'),
    AsyncIdentifier: () => import('@/components/table/columns/AsyncIdentifier'),
  },
  computed: {
    preferences() {
      return this.$store.getters[VuexAdapter.visibleColumnsTableNameGetter(this.configs.name)] || {};
    },
    tableFixCols() {
      return this.$store.getters[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
    sortColumns() {
      return this.$store.getters[VuexAdapter.sortColumnsTableNameGetter(this.configs.name)] || [];
    },
    getRowStyles() {
      let styles = {};

      if (this.configs.getterColor !== undefined && this.configs.previewField !== undefined && this.configs.getterColor) {

        if (this.row[this.configs.generalField] !== undefined && this.$store.getters[this.configs.getterColor][this.row[this.configs.generalField]] !== undefined) {
          let colorConfig = this.$store.getters[this.configs.getterColor][this.row[this.configs.generalField]];
          if (!colorConfig['БлокЦвета'] && colorConfig['Цвет']) {
            styles['background-color'] = this.tColorToRGB(`#${colorConfig['Цвет']}`)
          }
        }
      }

      return styles;
    },
    tableActiveRow() {
      return this.$store.getters[VuexAdapter.activeRowTableNameGetter(this.configs.name)];
    },
    hasTableActiveRow() {
      return this.tableActiveRow && Object.keys(this.tableActiveRow).length > 0;
    },
    isSelectedRow() {
      return this.hasTableActiveRow && this.tableActiveRow[this.configs.generalField] === this.row[this.configs.generalField]
    },
    columns() {
      let array = [...this.sortColumns],
          result = {};
      if (!array.length) {
        for (let key in this.configs.info) {
          let order = !this.configs.info[key]?.order ? 100 : parseInt(this.configs.info[key].order);
          do {
            order += 1;
          } while (array[order + 1] !== undefined);
          array[order + 1] = key;
        }
      }
      for (let keyRow in this.configs.info) {
        if (this.configs.info[keyRow]?.configTitle) {
          result[0] = {
            keyRow: keyRow,
            value: this.row[keyRow],
            keyOrder: 0,
          };

          continue;
        }

        if (
            (this.preferences[keyRow] !== undefined && this.preferences[keyRow] === false)
            || (!this.configs.info[keyRow].visible && this.preferences[keyRow] === undefined)
        ) {
          continue;
        }

        array.forEach((valueOrder, keyOrder) => {
          if (valueOrder === keyRow) {
            result[keyOrder + 1] = {
              keyRow: keyRow,
              value: this.row[keyRow],
              keyOrder: keyOrder + 1,
            }
          }
        });
      }
      return result;
    },
  },
  methods: {
    tColorToRGB,
    clickRow(scroll = false) {
      this.tableSetActiveRow({
        row: this.row,
        index: this.index,
      });
      if (this.configs?.previewField && this.row[this.configs?.previewField]) {
        this.$store.dispatch('setEntityIdPreviewAction', this.row[this.configs.previewField]);
      }
      if (scroll) {
        this.$nextTick(() => {
          if (this.$refs.rowTable?.scrollIntoView) {
            this.$refs.rowTable.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'start',
            });
          }
          if (undefined !== this.layout) {
            this.layout.frameLeft.scrollLeft = -50;
          }
        });
      }
    },
    getTableFixColClasses(keyOrder) {
        if (this.tableFixCols && keyOrder < this.tableFixCols.code) {
            return {
                'col-sticky-body': true,
                ['col-sticky-num-' + keyOrder]: true
            };
        }
        return {}
    },
    getClasses(value) {
      if (Array.isArray(this.configs.info[value.keyRow].classes)) {
        return this.configs.info[value.keyRow].classes + ' table-design-td ' + this.getTableFixColClasses(value.keyOrder);
      }
      return {
        ...this.configs.info[value.keyRow].classes,
        ...this.getTableFixColClasses(value.keyOrder),
        'table-design-td': true
      };
    },
    tableSetActiveRow(data) {
      this.$store.commit(VuexAdapter.activeRowTableNameMutation(this.configs.name), data)
    },
  },
}
</script>
