<template>
  <preview-block title="Резолюции" :amount="amount" :loading="loading" @resize="onResize">
    <template #header-postfix>
      <add-button v-if="showAdd" :show-title="showButtonTitle" @click="add"></add-button>
      <add-comment-modal ref="addModal" :parent-id="doc_id" :parent-table-id="parentTableId"></add-comment-modal>
    </template>

    <uploader-block-document-preview
      :end-point="endPoint"
      :doc-id="doc_id"
      :component="() => import('@/components/doc/preview/rows/CommentRowDocumentPreview.vue')"
    >
      <template #list-is-empty>Список резолюций пуст</template>
    </uploader-block-document-preview>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import UploaderBlockDocumentPreview from '@/components/doc/preview/UploaderBlockDocumentPreview.vue';
import {DOC_COMMENTS_GET} from '@/configs/endPoints';
import AddCommentModal from '@/components/modals/Comment/AddCommentModal';
import {DOCS_TABLE} from '@/configs/dbTables';
import previewBlock from '@/mixins/previewBlock';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddButton from '@/components/buttons/AddButton.vue';

export default defineComponent({
  name: 'CommentsDocumentPreview',
  mixins: [previewBlock],
  components: {
    AddButton,
    PreviewBlock,
    AddCommentModal,
    UploaderBlockDocumentPreview,
  },
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parentTableId() {
      return DOCS_TABLE;
    },
    endPoint() {
      return DOC_COMMENTS_GET;
    },
    amount() {
      const getterName = this.$vuexAdapter.getNameCountGetter(this.endPoint);
      return this.$store.getters[getterName];
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
  },
});
</script>
