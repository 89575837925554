<template>
  <button class="collapse-button" v-bind="$attrs" v-on="$listeners">
    Свернуть
  </button>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CollapseButton',
});
</script>

<style scoped lang="scss">
.collapse-button {
  height: 32px;
  width: 100%;

  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-bold);
}
</style>
