<template>
  <ul class="chip-list-ui">
    <chip-ui v-for="item in options" :key="item.code" tag="li" @close="$emit('close', $event, item)">
      {{ item.label }}
    </chip-ui>
  </ul>
</template>

<script>
import ChipUi from '@/components/ui/ChipUi.vue';
import {keys} from '@/common/utils/props-validators';

export default {
  name: 'ChipListUi',
  components: {ChipUi},
  props: {
    options: {
      type: Array,
      default: () => [],
      validator: keys('label', 'code'),
    },
  },
  emits: ['close'],
};
</script>

<style scoped lang="scss">
.chip-list-ui {
  margin-bottom: -4px;

  display: flex;
  flex-wrap: wrap;
}

.chip-ui {
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>
