<template>
  <div>
    <form-builder
      ref="form"
      :fields="fields"
      v-model="formDataProxy"
      @change="change"
    ></form-builder>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_TEXT} from '@/configs/form';

export default defineComponent({
  name: 'AddResolutionStepMaster',
  mixins: [step],
  components: {FormBuilder},
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_TEXT,
            name: 'resolution',
            placeholder: 'Введите резолюцию',
            required: true,
            resize: false,
            length: 2048,
            rows: 21,
          },
        ],
      ],
    };
  },
});
</script>
