import Constants from "@/configs/tables/constants";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_TYPES_GET} from "@/configs/endPoints";

let data = {
    visible: true,
    searchSmallFieldDictionary: true,
    title: 'Тип',
    classes: {},
    type: Constants.asyncIdentifier,
    optionAction: VuexAdapter.getNameAction(DOC_TYPES_GET),
    optionGetter: VuexAdapter.getNameSelectGetter(DOC_TYPES_GET),
    optionSmallFilterGetter: 'docTypesActiveSelectGetter',
    description: 'ID типа документа',
    conditions: 1,
    fieldKeyValue: 'Тип',
    optionKeyValue: false,
}

export default function (mix = {}) {
    return Object.assign({ ...data }, mix)
}