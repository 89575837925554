<template>
  <svg class="icon" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91668 2.33317C4.92513 2.33317 2.50001 4.7583 2.50001 7.74984C2.50001 9.24226 3.10248 10.5924 4.07947 11.5729C4.40431 11.899 4.40334 12.4266 4.07731 12.7514C3.75128 13.0763 3.22364 13.0753 2.8988 12.7493C1.62328 11.4691 0.833344 9.70092 0.833344 7.74984C0.833344 3.83782 4.00466 0.666504 7.91668 0.666504C10.454 0.666504 12.6787 2.00102 13.9287 4.00273C16.8375 4.09702 19.1667 6.48476 19.1667 9.4165C19.1667 11.0989 18.3987 12.6026 17.1973 13.5947C16.8425 13.8878 16.3172 13.8377 16.0241 13.4829C15.7311 13.128 15.7812 12.6027 16.136 12.3097C16.9705 11.6205 17.5 10.5807 17.5 9.4165C17.5 7.34544 15.8211 5.6665 13.75 5.6665C13.2803 5.6665 12.8331 5.42178 12.5872 5.00437C11.6441 3.40398 9.90497 2.33317 7.91668 2.33317ZM9.41075 8.41058C9.73619 8.08514 10.2638 8.08514 10.5893 8.41058L13.9226 11.7439C14.248 12.0694 14.248 12.597 13.9226 12.9224C13.5972 13.2479 13.0695 13.2479 12.7441 12.9224L10.8333 11.0117V16.4998C10.8333 16.9601 10.4602 17.3332 10 17.3332C9.53977 17.3332 9.16668 16.9601 9.16668 16.4998V11.0117L7.25593 12.9224C6.9305 13.2479 6.40286 13.2479 6.07742 12.9224C5.75198 12.597 5.75198 12.0694 6.07742 11.7439L9.41075 8.41058Z"/>
  </svg>
</template>

<script>
export default {
  name: "UploadIcon"
}
</script>