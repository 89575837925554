<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52864 5.52876C5.78899 5.26841 6.2111 5.26841 6.47145 5.52876L10.4714 9.52876C10.7318 9.78911 10.7318 10.2112 10.4714 10.4716C10.2111 10.7319 9.78899 10.7319 9.52864 10.4716L5.52864 6.47157C5.26829 6.21122 5.26829 5.78911 5.52864 5.52876Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4714 5.52876C10.7318 5.78911 10.7318 6.21122 10.4714 6.47157L6.47145 10.4716C6.2111 10.7319 5.78899 10.7319 5.52864 10.4716C5.26829 10.2112 5.26829 9.78911 5.52864 9.52876L9.52864 5.52876C9.78899 5.26841 10.2111 5.26841 10.4714 5.52876Z"/>
  </svg>
</template>

<script>
export default {
  name: "CanceledIcon"
}
</script>