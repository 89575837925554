<template>
  <div class="assign-barcode-step-master">
    <form-builder
      ref="form"
      :fields="fields"
      v-model="formDataProxy"
      @change="change"
    ></form-builder>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_BOOL, TYPE_STRING} from '@/configs/form';

export default defineComponent({
  name: 'StepBarcode',
  mixins: [step],
  components: {FormBuilder},
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_STRING,
            name: 'barcode',
            placeholder: 'Введите значение штрих-кода',
            disabled: true,
          },
        ],
        [
          {
            type: TYPE_BOOL,
            name: 'skip_barcode',
            label: 'Пропустить этот шаг. Вы можете сделать это позднее.',
            disabled: true,
          },
        ],
      ],
    };
  },
});
</script>
