import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__CONTROLS, DOCUMENTS__VIEW} from "@/configs/events";

Vue.use(Router);

const RouterObg = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/commissions',
    },
    {
      path: '/commissions',
      name: 'commissions',
      meta: {
        layout: 'extended-view',
        auth: true,
        faIcon: false,
        image: false,
        informer: () => import('@/components/widgets/CounterCommissions.vue'),
        title: 'Список поручений',
        titleSmall: 'Поручения',
        table: 'commissions',
      },
      component: () => import('../views/CommissionsPage'),
    },
    {
      path: '/controls',
      name: 'controls',
      meta: {
        layout: 'extended-view',
        auth: true,
        access: DOCUMENTS__CONTROLS,
        faIcon: false,
        image: false,
        informer: () => import('@/components/widgets/CounterControls.vue'),
        title: 'Документы на контроле',
        titleSmall: 'Контроль',
        table: 'controls',
      },
      component: () => import('../views/ControlsPage'),
    },
    {
      path: '/search',
      name: 'search',
      meta: {
        layout: 'extended-view',
        auth: true,
        faIcon: false,
        access: DOCUMENTS__VIEW,
        image: '/icons/doc_search.svg',
        title: 'Поиск документов',
        titleSmall: 'Поиск',
        table: 'documents',
      },
      component: () => import('../views/DocumentsPage'),
    },
    {
      path: '/clients',
      name: 'clients',
      meta: {
        layout: 'extended-view',
        auth: true,
        faIcon: false,
        access: COUNTERPARTIES_REGIONS_SEGMENTS__VIEW,
        image: '/icons/clients.svg',
        title: 'Контрагенты',
        preview: 'client',
        titleSmall: 'Контрагенты',
        table: 'clients',
      },
      component: () => import('../views/ClientsPage'),
    },
    {
      path: '/new-document',
      name: 'new_document',
      meta: {
        layout: 'standart-view',
        auth: true,
        faIcon: 'plus-circle',
        title: 'Создание нового документа',
        titleSmall: 'Новый документ',
      },
      component: () => import('../views/NewDocument'),
    },
    {
      path: '/document/:id',
      name: 'document',
      meta: {
        layout: 'empty',
        auth: true,
        access: DOCUMENTS__VIEW,
        faIcon: 'file',
        title: 'Документ',
        titleSmall: 'Документ',
      },
      component: () => import('../views/DocumentPage'),
    },
    {
      path: '/client/:id',
      name: 'client',
      meta: {
        layout: 'empty',
        auth: true,
        access: COUNTERPARTIES_REGIONS_SEGMENTS__VIEW,
        faIcon: 'file',
        title: 'Контрагент',
        titleSmall: 'Контрагент',
      },
      component: () => import('../views/ClientPage'),
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'empty',
        auth: false,
        title: 'Авторизация',
        titleSmall: 'Авторизация',
      },
      component: () => import('../views/LoginPage'),
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      meta: {
        layout: 'empty',
        auth: true,
        title: 'Нет доступа к разделу',
        titleSmall: 'Нет доступа',
      },
      component: () => import('../views/ForbiddenPage'),
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: () => import('../views/NotFound'),
      meta: {
        title: 'Страница не найдена',
        titleSmall: 'Не найдено',
      },
    },
    {
      path: '/404',
      name: 'DocumentNotFound',
      component: () => import('../views/DocumentNotFound'),
      meta: {
        title: 'Документ не найден',
        titleSmall: 'Не найдено',
      },
    }
  ],
});

RouterObg.beforeEach((to, from, next) => {
  let nextObg = {};

  store.dispatch('setEntityIdPreviewAction', null).then(() => {
    const isAuthorized = store.getters['auth/isAuthorized'];

    if (to.matched.some(record => record.meta.auth === true)) {
      if (!isAuthorized) {
        nextObg = {
          path: '/login',
          query: {
            redirect: to.fullPath,
          },
        }
      } else if (to.meta.access !== undefined && !store.getters.accessToEvent(to.meta.access)) {
        nextObg = {
          path: '/forbidden',
          query: {
            redirect: to.fullPath,
          },
        }
      }
    } else if (isAuthorized && to.matched.some(record => record.meta.auth === false)) {
      nextObg = {
        path: '/',
        query: {
          redirect: to.fullPath,
        },
      };
    }

    next(nextObg);
  });
});

export default RouterObg;
