<template>
  <tfoot class="table-footer-component">
  <tr>
    <td colspan="100" :style="tdGeneralLoadStyle">
      <div v-if="generalLoader"
           :style="emptyContainerStyle"
           class="table-footer-component__empty-container">
        <table-sheet-loader/>
      </div>
      <div v-else-if="empty && !generalLoader"
           :style="emptyContainerStyle"
           class="table-footer-component__empty-container">
        <component :is="emptyComponent" :configs="configs"/>
      </div>
      <div v-else-if="loader"
           class="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100"
           aria-valuemin="0"
           aria-valuemax="100"
           role="progressbar"></div>
    </td>
  </tr>
  </tfoot>
</template>
<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VuexAdapter from '@/services/vuexAdapter';
import TableSheetLoader from "@/components/table/TableSheetLoader";

export default {
  name: 'TableFooter',
  components: {
    TableSheetLoader,
    FontAwesomeIcon,
  },
  props: {
    configs: {
      type: Object,
      required: true
    },
    headerHeight: {
      type: Number,
      required: true
    },
    tableInitData: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    tdGeneralLoadStyle() {
      if (this.generalLoader && !this.tableInitData) {
        return {
          background: '#E2E7EF'
        }
      }

      return {};
    },
    emptyComponent() {
      if (this.configs.name === 'commissions') {
        if (this.hasFilters) {
          return () => import('@/components/table/emptyFooter/CommissionsEmptyByFilterTable');
        }
        return () => import('@/components/table/emptyFooter/CommissionsEmptyTable');
      }
      if (this.configs.name === 'controls') {
        if (this.hasFilters) {
          return () => import('@/components/table/emptyFooter/ControlsEmptyByFilterTable');
        }
        return () => import('@/components/table/emptyFooter/ControlsEmptyTable');
      }

      if (this.configs.name === 'documents') {
        if (this.hasFilters) {
          return () => import('@/components/table/emptyFooter/DocumentEmptyByFilterTable');
        }
        return () => import('@/components/table/emptyFooter/DocumentEmptyTable');
      }

      if (this.configs.name === 'clients') {
        if (this.hasFilters) {
          return () => import('@/components/table/emptyFooter/ClientsByFilterTable');
        }
        return () => import('@/components/table/emptyFooter/ClientsEmptyTable');
      }

      return () => import('@/components/table/emptyFooter/DefaultEmptyTable')
    },
    empty() {
      return this.$store.getters[this.configs.getterCountByFilter] === 0;
    },
    loader() {
      return this.$store.getters[VuexAdapter.subLoaderTableNameGetter(this.configs.name)];
    },
    generalLoader() {
      return this.$store.getters[VuexAdapter.loaderGeneralTableNameGetter(this.configs.name)];
    },
    hasFilters() {
      return Object.keys(this.$store.getters[VuexAdapter.filterTableNameGetter(this.configs.name)]).length > 0;
    },
    emptyContainerStyle() {
      return {
        'height': `calc(100vh - ${this.headerHeight + 162}px)`
      }
    },
  },
}
</script>