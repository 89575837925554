<template>
  <preview-block title="Контакты" :amount="amount" :loading="loading" @resize="onResize">
    <template #header-postfix>
      <add-button v-if="showAdd" :show-title="showButtonTitle" @click="add"></add-button>
      <add-contact-modal ref="addModal" :parent_id="clientId" :parent_table_id="parentTableId"></add-contact-modal>
    </template>

    <uploader-block-client-preview
      :end-point="endPoint"
      :client-id="clientId"
      :component="() => import('@/components/clients/rows/ContactRow')"
    >
      <template #list-is-empty>Список контактов пуст</template>
    </uploader-block-client-preview>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import {CLIENTS_CONTACTS_GET} from '@/configs/endPoints';
import AddContactModal from '@/components/modals/Contact/AddContactModal';
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import {CLIENTS_TABLE} from '@/configs/dbTables';
import previewBlock from '@/mixins/previewBlock';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddButton from '@/components/buttons/AddButton.vue';

export default defineComponent({
  name: 'ContactsClientPreview',
  mixins: [previewBlock, clientsPreviewBlock],
  components: {
    AddButton,
    PreviewBlock,
    AddContactModal,
    UploaderBlockClientPreview,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_CONTACTS_GET;
    },
  },
});
</script>
