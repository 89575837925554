<template>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9772 2.16797L30.3333 2.16797C30.908 2.16797 31.4591 2.39624 31.8654 2.80257L44.8654 15.8026C45.2717 16.2089 45.5 16.76 45.5 17.3346V27.3013C45.5 28.4979 44.5299 29.468 43.3333 29.468C42.1367 29.468 41.1667 28.4979 41.1667 27.3013V18.2321L29.4359 6.5013H19.0667C17.2107 6.5013 15.9491 6.50299 14.9739 6.58267C14.024 6.66028 13.5382 6.80095 13.1994 6.97361C12.384 7.38906 11.7211 8.05198 11.3056 8.86735C11.133 9.20621 10.9923 9.69199 10.9147 10.6419C10.835 11.6171 10.8333 12.8787 10.8333 14.7346V26.0013C10.8333 27.1979 9.86329 28.168 8.66667 28.168C7.47005 28.168 6.5 27.1979 6.5 26.0013L6.5 14.6452C6.49997 12.9011 6.49995 11.4616 6.59575 10.289C6.69526 9.07114 6.90882 7.95161 7.44461 6.90005C8.27551 5.26932 9.60135 3.94348 11.2321 3.11258C12.2836 2.57678 13.4032 2.36323 14.621 2.26372C15.7936 2.16792 17.2331 2.16794 18.9772 2.16797Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3346 2.75C31.5313 2.75 32.5013 3.72005 32.5013 4.91667V13.8662C32.5013 14.5086 32.503 14.8602 32.5236 15.1128C32.5245 15.1229 32.5253 15.1324 32.5261 15.1414C32.5351 15.1422 32.5446 15.143 32.5547 15.1438C32.8073 15.1645 33.1588 15.1662 33.8013 15.1662H42.7508C43.9474 15.1662 44.9175 16.1362 44.9175 17.3328C44.9175 18.5295 43.9474 19.4995 42.7508 19.4995H33.8013C33.7783 19.4995 33.7552 19.4995 33.7322 19.4995C33.1854 19.4996 32.6528 19.4996 32.2018 19.4628C31.7065 19.4223 31.1221 19.3268 30.534 19.0272C29.7186 18.6117 29.0557 17.9488 28.6403 17.1335C28.3406 16.5454 28.2452 15.961 28.2047 15.4657C28.1678 15.0147 28.1679 14.4821 28.168 13.9353C28.168 13.9123 28.168 13.8892 28.168 13.8662V4.91667C28.168 3.72005 29.138 2.75 30.3346 2.75Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.3333 25.1328C44.53 25.1328 45.5 26.1029 45.5 27.2995V37.3557C45.5 39.0998 45.5001 40.5392 45.4042 41.7118C45.3047 42.9296 45.0912 44.0492 44.5554 45.1007C43.7245 46.7315 42.3987 48.0573 40.7679 48.8882C39.7164 49.424 38.5968 49.6376 37.379 49.7371C36.2064 49.8329 34.7669 49.8328 33.0228 49.8328H28.1667C26.97 49.8328 26 48.8628 26 47.6661C26 46.4695 26.97 45.4995 28.1667 45.4995H32.9333C34.7893 45.4995 36.0509 45.4978 37.0261 45.4181C37.976 45.3405 38.4618 45.1998 38.8006 45.0272C39.616 44.6117 40.2789 43.9488 40.6944 43.1334C40.867 42.7946 41.0077 42.3088 41.0853 41.3589C41.165 40.3837 41.1667 39.1221 41.1667 37.2661V27.2995C41.1667 26.1029 42.1367 25.1328 43.3333 25.1328Z" fill="#DADFF1"/>
    <path d="M6.5 34.6667C6.5 33.47 7.47005 32.5 8.66667 32.5C9.86328 32.5 10.8333 33.47 10.8333 34.6667V47.6667C10.8333 48.8633 9.86328 49.8333 8.66667 49.8333C7.47005 49.8333 6.5 48.8633 6.5 47.6667V34.6667Z" fill="#DADFF1"/>
    <path d="M17.3333 32.5C18.53 32.5 19.5 33.47 19.5 34.6667C19.5 35.8633 18.53 36.8333 17.3333 36.8333L8.66667 36.8333C7.47005 36.8333 6.5 35.8633 6.5 34.6667C6.5 33.47 7.47005 32.5 8.66667 32.5L17.3333 32.5Z" fill="#DADFF1"/>
    <path d="M17.3333 45.5C18.53 45.5 19.5 46.47 19.5 47.6667C19.5 48.8633 18.53 49.8333 17.3333 49.8333L8.66667 49.8333C7.47005 49.8333 6.5 48.8633 6.5 47.6667C6.5 46.47 7.47005 45.5 8.66667 45.5L17.3333 45.5Z" fill="#DADFF1"/>
    <path d="M17.3333 39C18.53 39 19.5 39.97 19.5 41.1667C19.5 42.3633 18.53 43.3333 17.3333 43.3333L10.8333 43.3333C9.63672 43.3333 8.66667 42.3633 8.66667 41.1667C8.66667 39.97 9.63672 39 10.8333 39L17.3333 39Z" fill="#DADFF1"/>
  </svg>
</template>

<script>
export default {
  name: "ExelFormFileIcon"
}
</script>
