import STEP_TYPE from '@/common/consts/document-master/steps/stepType';
import STEP_CONTENT from '@/common/consts/document-master/steps/stepContent';
import STEP_DEADLINE from '@/common/consts/document-master/steps/stepDeadline';
import STEP_ROUTE from '@/common/consts/document-master/steps/stepRoute';
import STEP_EXECUTION from '@/common/consts/document-master/steps/stepExecution';
import STEP_LAUNCH from '@/common/consts/document-master/steps/stepLaunch';

export default [
  STEP_TYPE,
  STEP_CONTENT,
  STEP_DEADLINE,
  STEP_ROUTE,
  STEP_EXECUTION,
  STEP_LAUNCH,
];
