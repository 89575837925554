<template>
  <div class="sign--actions">
    <sign-button @click="applyFilesForSignAction"/>
  </div>
</template>

<script>
import SignButton from "@/components/buttons/SignButton";
import {mapActions} from "vuex";

export default {
  name: "ActionsSign",
  components: {SignButton},
  methods: {
    ...mapActions([
      'applyFilesForSignAction'
    ]),
  }
}
</script>