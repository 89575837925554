<template>
  <svg style="margin-right: 6px; width: 20px; height: 20px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.57757 0.744078C8.90301 0.418641 9.43065 0.418641 9.75608 0.744078L11.4228 2.41074C11.7482 2.73618 11.7482 3.26382 11.4228 3.58926L9.75608 5.25592C9.43065 5.58136 8.90301 5.58136 8.57757 5.25592C8.25214 4.93049 8.25214 4.40285 8.57757 4.07741L8.7874 3.86758C5.91607 4.43219 3.75016 6.96316 3.75016 10C3.75016 12.077 4.76245 13.9175 6.32421 15.0553C6.69619 15.3263 6.77804 15.8476 6.50703 16.2195C6.23602 16.5915 5.71477 16.6734 5.34278 16.4024C3.36898 14.9643 2.0835 12.6322 2.0835 10C2.0835 6.02708 5.01003 2.73769 8.82495 2.16996L8.57757 1.92259C8.25214 1.59715 8.25214 1.06951 8.57757 0.744078ZM13.4933 3.78045C13.7643 3.40847 14.2856 3.32662 14.6575 3.59763C16.6313 5.03568 17.9168 7.36776 17.9168 10C17.9168 13.9729 14.9903 17.2623 11.1754 17.83L11.4228 18.0774C11.7482 18.4028 11.7482 18.9305 11.4228 19.2559C11.0973 19.5814 10.5697 19.5814 10.2442 19.2559L8.57757 17.5893C8.25214 17.2638 8.25214 16.7362 8.57757 16.4107L10.2442 14.7441C10.5697 14.4186 11.0973 14.4186 11.4228 14.7441C11.7482 15.0695 11.7482 15.5972 11.4228 15.9226L11.2129 16.1324C14.0843 15.5678 16.2502 13.0368 16.2502 10C16.2502 7.92301 15.2379 6.08254 13.6761 4.9447C13.3041 4.67369 13.2223 4.15243 13.4933 3.78045Z" fill="#1D2939"/>
  </svg>
</template>

<script>
export default {
  name: "MenuTableSettingArrowCycleIcon"
}
</script>