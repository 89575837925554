import {DateTime} from "luxon";

const postgresql = {
    integerSql(Vue) {
        let sqlGeneral = '';
        for (let n in Vue.conditions) {
            let sql = '';

            if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
                sql += ` ${this.generateKey(Vue.key_column)} ${Vue.conditions[n].select.code} `
            } else if (Vue.conditions[n].input) {
                sql += ` ${this.generateKey(Vue.key_column)} ${Vue.conditions[n].select.code} ${Number(Vue.conditions[n].input)} `
            }

            if (sql.length > 0) {
                if (Vue.conditions[n].algorithm !== null) {
                    sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
                }
                sqlGeneral += sql
            } else {
                Vue.$delete(Vue.conditions, n)
            }
        }
        if (Vue.conditions.length === 0) {
            Vue.cleanConditions();
        }

        return sqlGeneral;
    },

    stringSqlSearch(Vue, code) {
        let sql = '';

        if (Vue.searchValue.length) {
            sql += ` ${this.generateKey(code)}::varchar ILIKE '%${Vue.searchValue}%' `;
        }

        return sql;
    },
    intSqlSearch(Vue, code, contradiction = '=') {
        let sql = '';

        if (Vue.searchValue.length && Number(Vue.searchValue)) {
            sql += ` ${this.generateKey(code)} ${contradiction} ${Number(Vue.searchValue)} `;
        }

        return sql;
    },
    identifierSqlSearch(Vue, code, values) {
        return ` ${this.generateKey(code)} in (${values.join(',')}) `;
    },
    identifierSqlNotInSearch(Vue, code, values) {
        return ` ${this.generateKey(code)} not in (${values.join(',')}) `;
    },
    flagSqlSearch(Vue, code, value) {
        return ` ${this.generateKey(code)}::integer ${value} `;
    },
    stringSql(Vue) {
        let sqlGeneral = '';
        for (let n in Vue.conditions) {
            let sql = '';

            if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
                sql += ` ${Vue.key_column} ${Vue.conditions[n].select.code} `
            } else if (Vue.conditions[n].input) {
                switch (Vue.conditions[n].select.code) {
                    case 'NOT LIKE':
                        sql += ` ${this.generateKey(Vue.key_column)}::varchar NOT ILIKE '%${(Vue.conditions[n].input)}%' `;
                        break;
                    case 'LIKE':
                        sql += ` ${this.generateKey(Vue.key_column)}::varchar ILIKE '%${Vue.conditions[n].input}%' `;
                        break;
                    case 'MATCH LIKE':
                        sql += ` ${this.generateKey(Vue.key_column)}::varchar ILIKE '${Vue.conditions[n].input}' `;
                        break;
                    case 'START LIKE':
                        sql += ` ${this.generateKey(Vue.key_column)}::varchar ILIKE '${Vue.conditions[n].input}%' `;
                        break;
                    case 'END LIKE':
                        sql += ` ${this.generateKey(Vue.key_column)}::varchar ILIKE '%${Vue.conditions[n].input}' `
                        break;
                }
            }

            if (sql.length > 0) {
                if (Vue.conditions[n].algorithm !== null) {
                    sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
                }
                sqlGeneral += sql
            } else {
                Vue.$delete(Vue.conditions, n)
            }
        }
        if (Vue.conditions.length === 0) {
            Vue.cleanConditions();
        }

        return sqlGeneral;
    },
    identifierSql(Vue) {
        let sqlGeneral = '';
        for (let n in Vue.conditions) {

            if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
                sqlGeneral += ` ${this.generateKey(Vue.key_column)} ${Vue.conditions[n].select.code} `
            } else if (Vue.conditions[n].input && Vue.conditions[n].input.length > 0) {
                let options = [];
                for (let optionN in Vue.conditions[n].input) {
                    options.push(Vue.conditions[n].input[optionN].code)
                }

                sqlGeneral += ` ${Vue.key_column} ${Vue.conditions[n].select.code} (${options.join(',')}) `
            }

            if (sqlGeneral.length === 0) {
                Vue.cleanConditions();
            }
        }

        return sqlGeneral;
    },
    flagSQL(Vue) {
        let sqlGeneral = '';
        for (let n in Vue.conditions) {
            if (Vue.conditions[n].select) {
                sqlGeneral += ` ${this.generateKey(Vue.key_column)}::integer ${Vue.conditions[n].select.code} `
            }
            if (sqlGeneral.length === 0) {
                Vue.cleanConditions();
            }
        }
        return sqlGeneral;
    },
    dateSQL(Vue) {
        let sqlGeneral = '';

        for (let n in Vue.conditions) {
            let sql = '';

            switch (Vue.conditions[n].select.code) {
                case 'between':

                    var startDate = DateTime.fromJSDate(
                        Vue.conditions[n].input.startDate instanceof Date
                            ? Vue.conditions[n].input.startDate
                            : new Date(Vue.conditions[n].input.startDate)
                    ).toFormat('yyyy-MM-dd');

                    var endDate = DateTime.fromJSDate(
                        Vue.conditions[n].input.endDate instanceof Date
                            ? Vue.conditions[n].input.endDate
                            : new Date(Vue.conditions[n].input.endDate)
                    ).toFormat('yyyy-MM-dd');

                    sql += ` (${this.generateKey(Vue.key_column)} >= '${startDate}' AND ${this.generateKey(Vue.key_column)} < (date '${endDate}' + interval '1 day') ) `;
                    break;
                case 'date_today':
                    sql += ` ( ${this.generateKey(Vue.key_column)}>= now()::date AND ${this.generateKey(Vue.key_column)} < (now()::date + interval '1 day') ) `;
                    break;
                case 'date_yesterday':
                    sql += ` ( ${this.generateKey(Vue.key_column)} >= (now()::date - interval '1 day') AND ${this.generateKey(Vue.key_column)} < now()::date ) `;
                    break;
                case 'date_seven_days':
                    sql += ` ( ${this.generateKey(Vue.key_column)} >= (now()::date - interval '7 day') AND ${this.generateKey(Vue.key_column)} < now()::date ) `;
                    break;
                case 'date_thirty_days':
                    sql += ` ( ${this.generateKey(Vue.key_column)} >= (now()::date - interval '30 day') AND ${this.generateKey(Vue.key_column)} < now()::date ) `;
                    break;
                case 'is not null':
                    sql += ` ${this.generateKey(Vue.key_column)} is not null `;
                    break;
                case 'is null':
                    sql += ` ${this.generateKey(Vue.key_column)} is null `;
                    break;
            }

            if (sql.length > 0) {
                if (Vue.conditions[n].algorithm !== null) {
                    sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
                }
                sqlGeneral += sql
            } else {
                Vue.$delete(Vue.conditions, n)
            }
        }
        if (Vue.conditions.length === 0) {
            Vue.cleanConditions();
        }

        return sqlGeneral;
    },

    generateKey(key_column){
        if (/^\w+$/.test(key_column)) {
            return `"${key_column.toLowerCase()}"`;
        }

        return `"${key_column}"`;
    },

    getOrder(key_column, sort) {
        key_column = this.generateKey(key_column);
        return ` ${key_column} ${sort} `
    },

    getBoolValue(value) {
        return `${value === 1 ? 'true' : 'false'}`
    }
}

export default postgresql;