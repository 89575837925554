<template>
  <notifications
      position="top right"
      classes="custom-notify"
      :duration="8000"
  >
    <template #body="{ item, close }">
      <div @click="close" :class="getClasses(item)">
        <div class="notification-title">{{ item.title }}</div>
        <div v-if="item?.text" class="notification-content">
          {{ item.text }}
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import {ERROR_NOTIFY_TYPE, SUCCESS_NOTIFY_TYPE, WARN_NOTIFY_TYPE} from "@/configs/notifyTypes";

export default {
	name: 'NotifyCustom',
	methods: {
		getClasses(notification) {
			return [
				'vue-notification-template custom-notify',
				{
					warn: notification.type === WARN_NOTIFY_TYPE,
					error: notification.type === ERROR_NOTIFY_TYPE,
					success: notification.type === SUCCESS_NOTIFY_TYPE,
				},
			];
		},
	},
};
</script>
