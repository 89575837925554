<template>
    <div class="layout-wrapper"
         ref="layout">
        <sidebar-general/>
        <mobile-menu/>
        <div class="layout-content">
            <toolbar-general ref="toolbar"/>
            <div class="layout-content-frame"
                 ref="frame">
                <div class="layout-content-frame-center"
                     :style="styleFrameC">
                    <router-view :componentWidth="frameWidth"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import {useElementBounding} from '@vueuse/core';
import SidebarGeneral from '@/components/general/SidebarGeneral';
import MobileMenu from '@/components/general/MobileMenu';
import ToolbarGeneral from '@/components/general/ToolbarGeneral';
import {mapActions} from 'vuex';

export default {
    name: 'StandartViewLayout',
    inject: [
        'app',
    ],
    components: {
        SidebarGeneral,
        MobileMenu,
        ToolbarGeneral,
    },
    data: () => ({
        timerCounterId: null,
        timerCounterLimit: 1000 * 60,
    }),
    provide() {
        const provider = {};
        const self = this;
        Object.defineProperty(provider, 'layout', {
            enumerable: true,
            get: () => self,
        });
        return provider;
    },
    setup() {
        const layout = ref(null);
        const {width: layoutWidth, height: layoutHeight} = useElementBounding(layout);

        const toolbar = ref(null);
        const {height: toolbarHeight} = useElementBounding(toolbar);

        const frame = ref(null);
        const {width: frameWidth} = useElementBounding(frame);

        return {
            layout,
            layoutWidth,
            layoutHeight,
            toolbar,
            toolbarHeight,
            frame,
            frameWidth,
        }
    },
    mounted() {
        /* Counters */
        this.getCommissionsCountAction();
        this.getControlsCountAction();
        this.timerCounterId = setInterval(() => {
            this.getCommissionsCountAction();
            this.getControlsCountAction();
        }, this.timerCounterLimit);
    },
    destroyed() {
        /* Counters */
        clearInterval(this.timerCounterId);
    },
    computed: {
        styleFrameC() {
            return {
                'height': this.layoutHeight - this.toolbarHeight + 'px',
            }
        },
    },
    methods: {
        ...mapActions([
            'cleanAllStoresAction',
            'getCommissionsCountAction',
            'getControlsCountAction',
        ]),
        logOut() {
            this.cleanAllStoresAction();
        },
    },
}
</script>