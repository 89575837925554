import {TYPE_SELECT, TYPE_STRING, TYPE_TEXT} from "@/configs/form";

export default (Vue) => ([
    [
        {
            type: TYPE_SELECT,
            name: "address_type",
            input: true,
            label: "Тип",
            placeholder: "Введите тип",
            options: () => Vue.clientAddressTypesGetter,
            required: true,
        },
    ],
    [
        {
            type: TYPE_STRING,
            name: "address",
            label: "Адрес",
            placeholder: "Введите адрес",
            max: 255,
            required: true,
        },
    ],
    [
        {
            type: TYPE_TEXT,
            name: "comment",
            label: "Примечание",
            placeholder: "Введите примечание",
            max: 255,
            required: false,
        }
    ]
]);