import StepType from '@/components/document-master/steps/StepType.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Type,
  title: 'Создание документа',
  tooltip: 'Этот мастер поможет выполнить ввод данных. Для начала укажите тип и проект, к которому следует отнести документ, и нажмите на кнопку «Далее»',
  menuTitle: 'Тип документа и проект',
  component: StepType,
};
