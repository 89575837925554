<template>
  <div class="checkbox-form">
    <div class="checkbox-row">
      <label class="checkbox-form-element"
             :class="classesAdditional">
        <input @change="change"
               type="checkbox"
               :disabled="disabled"
               v-model="proxyValue"
        >
        <span class="checkmark"/>
        <span class="checkbox-label" v-if="label" v-html="label"/>
      </label>
    </div>
    <div
        v-if="hint || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : hint }}
		</div>
  </div>
</template>

<script>
import {isObject} from "@/services/utilsFunctions";

export default {
  name: "CheckboxForm",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    classes: {},
  },
  data: () => ({
    proxyValue: [],
  }),
  computed: {
    classesAdditional() {
      let classes = {
        error: this.error,
        disabled: this.disabled,
      };

      if (this.classes && Array.isArray(this.classes)) {
        this.classes.forEach(c => classes[c] = true);
      } else if (this.classes && isObject(this.classes)) {
        classes = {
          ...classes,
          ...this.classes,
        }
      }

      return classes;
    },
  },
  created() {
    this.proxyValue = !!this.modelValue;
  },
  methods: {
    change() {
      this.$emit('change', this.proxyValue || false);
    },
  },
  watch: {
    modelValue(modelValue) {
      if (modelValue) {
        this.proxyValue = true;

        return;
      }

      this.proxyValue = false;
    },
  }
}
</script>