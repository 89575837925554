import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import Controller from "@/configs/tables/cols/Controller";
import Registrator from "@/configs/tables/cols/Registrator";
import Project from "@/configs/tables/cols/Project";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_FILES_GET} from "@/configs/endPoints";
import {FILES_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: FILES_TABLE_NAME,
    title: 'Файлы',
    action: VuexAdapter.getNameAction(DOC_FILES_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_FILES_GET),
    getter: VuexAdapter.getNameGetter(DOC_FILES_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_FILES_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_FILES_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_FILES_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOC_FILES_GET),
    generalField: 'F_ID',
    searchSmallSelectedField: 'Название',
    smallGroped: () => import('@/components/table/grouped/FilesList'),
    limit: 20,
    info: {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
        },
        'F_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            component: () => import('@/components/table/columns/files/EyeFile'),
            classes: {'table-design-rpID': true},
            description: 'ID файла',
            conditions: 3,
            order: 0
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
        },
        'PARENT_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы сущности',
            classes: {},
        },
        'PARENT_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы сущности',
            classes: {},
        },
        'Активен': {
            visible: false,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Флаг: активен ли документ или нет (может быть неактивным, если это черновик или на паузе)',
            conditions: 1,
            order: 1,
        },
        'НомерДокумента': {
            visible: false,
            title: 'Номер документа',
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 2,
        },
        'ДатаДокумента': {
            visible: false,
            title: 'Дата документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата документа',
            order: 3,
            conditions: 3,
        },
        'Срок': {
            visible: false,
            title: 'Срок поручения',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Срок поручения',
            order: 4,
            conditions: 3,
        },
        'Исполнен': {
            visible: false,
            title: 'Дата остановки документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 5,
        },
        'Контролер': Controller({
            visible: false,
            order: 6,
        }),
        'Регистратор': Registrator({
            visible: false,
            order: 7,
        }),
        'Проект': Project({
            visible: false,
            order: 8,
        }),
        'Содержание': {
            visible: false,
            searchSmallField: true,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание документа',
            order: 9,
            conditions: 3,
        },
        'Номер': {
            visible: false,
            title: 'Номер',
            classes: {'text-center': true},
            type: Constants.integer,
            description: 'Номер файла в документе',
            order: 19,
            conditions: 3,
        },
        'Название': {
            visible: true,
            searchSmallField: true,
            title: 'Название файла',
            component: () => import('@/components/table/columns/files/NameFile'),
            classes: {},
            type: Constants.string,
            description: 'Название файла',
            order: 11,
            conditions: 3,
        },
        'Редакция': {
            visible: true,
            title: 'Редакция',
            classes: {'text-center': true},
            type: Constants.integer,
            description: 'Редакция файла',
            order: 13,
            conditions: 3,
        },
        'Пользователь': User({
            order: 14,
            searchSmallFieldDictionary: true,
        }),
        'Файл': {
            visible: false,
            system: true,
            title: 'Файл',
            classes: {},
            type: Constants.string,
            description: 'Путь к файлу в хранилище данных',
            order: 15,
            conditions: 3,
        },
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание к файлу',
            order: 16,
            conditions: 3,
        },
        'Создан': {
            visible: true,
            title: 'Создан',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата и время создания файла',
            order: 17,
            conditions: 3
        },
        'Скопирован': {
            visible: true,
            title: 'Скопирован',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата и время завершения копирования файла в хранилище',
            order: 18,
            conditions: 3
        },
        'Индикатор': {
            visible: true,
            title: 'Последняя редакция',
            classes: {'text-center': true, 'table-design-new': true},
            type: Constants.flag,
            component: () => import('@/components/table/columns/files/LastRevisionIndicatorFile'),
            description: 'Индикатор, является ли данный файл последней редакцией в данном документе',
            order: 10,
            conditions: 3,
        },
        'Атрибуты': {
            visible: false,
            system: true,
            title: 'Атрибуты',
            classes: {},
            type: Constants.string,
            description: 'Атрибуты файла, флаги архивирования и шифрования',
            order: 20,
            conditions: 3,
        },
        'Размер': {
            visible: true,
            title: 'Размер',
            classes: {},
            component: () => import('@/components/table/columns/files/SizeFile'),
            type: Constants.integer,
            description: 'Размер файла в байтах',
            order: 21,
            conditions: 3,
        },
        'ЭП': {
            visible: false,
            system: true,
            title: 'ЭП',
            classes: {},
            type: Constants.string,
            description: 'Метка простой цифровой подписи файла',
            order: 22,
            conditions: 3,
        },
    },
};