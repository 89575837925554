<template>
  <button class="button-ui" :class="classes" v-bind="$attrs" v-on="$listeners">
    <span v-if="$slots.prefix" class="icon icon_prefix">
      <slot name="prefix"></slot>
    </span>

    <slot></slot>

    <span v-if="$slots.postfix" class="icon icon_postfix">
      <slot name="postfix"></slot>
    </span>
  </button>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'ButtonUi',
  props: {
    size: {
      type: String,
      default: 'm',
      validator: only('s', 'm', 'l'),
    },
    color: {
      type: String,
      default: 'yellow',
      validator: only('yellow', 'black', 'white', 'gray'),
    },
    mode: {
      type: String,
      default: 'fill',
      validator: only('fill', 'outline'),
    },
  },
  computed: {
    classes() {
      return [`_size-${this.size}`, `_color-${this.color}`, `_mode-${this.mode}`];
    },
  },
});
</script>

<style scoped lang="scss">
.button-ui {
  display: flex;
  align-items: center;

  font-weight: var(--font-weight-bold);

  transition: color var(--transition-fast), background-color var(--transition-fast), border-color var(--transition-fast);

  &._size-s {
    height: 24px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    border-radius: 4px;

    .icon {
      height: 16px;
      width: 16px;

      &_prefix {
        &:not(:last-child) {
          margin-right: 4px;
        }
      }

      &_postfix {
        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    }

    &._mode-fill {
      padding: 0 8px;
    }

    &._mode-outline {
      padding: 0 7px;
    }
  }

  &._size-m {
    height: 36px;

    font-size: var(--font-size-l);
    line-height: var(--line-height-l);

    border-radius: 8px;

    .icon {
      height: 20px;
      width: 20px;

      &_prefix {
        &:not(:last-child) {
          margin-right: 6px;
        }
      }

      &_postfix {
        &:not(:first-child) {
          margin-left: 6px;
        }
      }
    }

    &._mode-fill {
      padding: 0 12px;
    }

    &._mode-outline {
      padding: 0 11px;
    }
  }

  &._size-l {
    height: 48px;

    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);

    border-radius: 8px;

    .icon {
      height: 24px;
      width: 24px;

      &_prefix {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      &_postfix {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }

      &._mode-outline {
        padding: 0 15px;
      }
    }

    &._mode-fill {
      padding: 0 16px;
    }
  }

  &._color-yellow {
    &._mode-fill {
      background-color: var(--color-yellow-600);

      .icon {
        :deep(svg) {
          color: var(--color-gray-1000); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-gray-1000);
        }
      }

      &:hover {
        background-color: var(--color-yellow-500);
      }
    }
  }

  &._color-black {
    &._mode-fill {
      color: var(--color-white);
      background-color: var(--color-gray-800);

      .icon {
        :deep(svg) {
          color: var(--color-white); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-white);
        }
      }

      &:hover {
        background-color: var(--color-gray-600);
      }
    }

    &._mode-outline {
      color: var(--color-gray-1000);
      border: var(--border-gray-1000);

      .icon {
        :deep(svg) {
          color: var(--color-gray-1000); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-gray-1000);
        }
      }

      &:hover {
        color: var(--color-gray-600);
        border: var(--border-gray-800);

        .icon {
          :deep(svg) {
            color: var(--color-gray-600); /* TODO: Отказаться от перекрытия стилей */
            fill: var(--color-gray-600);
          }
        }
      }
    }
  }

  &._color-white {
    &._mode-fill {
      background-color: var(--color-white);

      .icon {
        :deep(svg) {
          color: var(--color-gray-1000); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-gray-1000);
        }
      }

      &:hover {
        color: var(--color-gray-600);

        .icon {
          :deep(svg) {
            color: var(--color-gray-600); /* TODO: Отказаться от перекрытия стилей */
            fill: var(--color-gray-600);
          }
        }
      }
    }
  }

  &._color-gray {
    &._mode-outline {
      color: var(--color-gray-500);
      border: var(--border-gray-500);

      .icon {
        :deep(svg) {
          color: var(--color-gray-500); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-gray-500);
        }
      }

      &:hover {
        color: var(--color-gray-600);
        border: var(--border-gray-600);

        .icon {
          :deep(svg) {
            color: var(--color-gray-600); /* TODO: Отказаться от перекрытия стилей */
            fill: var(--color-gray-600);
          }
        }
      }
    }
  }
}

.icon {
  margin: 0;

  &:empty {
    display: none;
  }

  :deep(svg) {
    /* TODO: Отказаться от перекрытия стилей */
    display: block;
    width: auto;
    transition: fill var(--transition-fast), color var(--transition-fast), transform var(--transition-fast);
  }
}
</style>
