import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import DT_ID from "@/configs/tables/cols/DT_ID";
import Controller from "@/configs/tables/cols/Controller";
import Registrator from "@/configs/tables/cols/Registrator";
import Project from "@/configs/tables/cols/Project";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_LINKS_GET} from "@/configs/endPoints";
import {DOCUMENTS_LINKS_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: DOCUMENTS_LINKS_TABLE_NAME,
    title: 'Документы документов',
    action: VuexAdapter.getNameAction(DOC_LINKS_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_LINKS_GET),
    getter: VuexAdapter.getNameGetter(DOC_LINKS_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_LINKS_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_LINKS_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_LINKS_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOC_LINKS_GET),
    generalField: 'LINK_ID',
    searchSmallSelectedField: 'Содержание',
    smallGroped: () => import('@/components/table/grouped/DocumentsLinks'),
    limit: 20,
    info: {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
        },
        'LINK_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            component: () => import('@/components/table/columns/EyeDocumentsLinks'),
            classes: {'table-design-rpID': true},
            description: 'ID ссылки',
            conditions: 1,
            order: 0
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            conditions: 1
        },
        'Ссылка': {
            visible: true,
            title: 'Тип связи',
            classes: {},
            type: Constants.string,
            description: 'Тип связи',
            conditions: 3,
            order: 1,
        },
        'Активен': {
            visible: true,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Активен',
            conditions: 1,
            order: 2,
        },

        'Тип': DT_ID({
            order: 4,
            optionKeyValue: true
        }),
        'Номер': {
            visible: true,
            searchSmallField: true,
            title: 'Номер',
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 5,
        },
        'Дата': {
            visible: true,
            title: 'Дата',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата создания черновика документа',
            conditions: 3,
            order: 7,
        },
        'Контролер': Controller({
            order: 8,
            searchSmallField: true,
        }),
        'Регистратор': Registrator({
            order: 9,
            searchSmallField: true,
        }),
        'Проект': Project({
            order: 10,
            searchSmallField: true,
        }),
        'Содержание': {
            visible: true,
            searchSmallField: true,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание документа',
            conditions: 3,
            order: 11,
        },
        'Срок': {
            visible: true,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Общий срок документа (не путать со сроком поручения)',
            conditions: 3,
            order: 12,
        },
        'Исполнен': {
            visible: false,
            title: 'Исполнен',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 13,
        },
        'Пользователь': User({order: 14}),
        'Создана': {
            visible: false,
            title: 'Создана',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Создана',
            conditions: 3,
            order: 15,
        },
    },
};