import {defineComponent} from 'vue';
import DocumentMasterApiService from '@/services/api/document-master/document-master-api.service';
import {CanceledError} from 'axios';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {ErrorHelper} from '@/services/errorHelper';
import abort from '@/mixins/abort';
import step from '@/mixins/document-master/step';

export default defineComponent({
  mixins: [abort, step],
  data() {
    return {
      linkTypes: [],
    };
  },
  async mounted() {
    void this.getLinkTypes();
  },
  methods: {
    // TODO: Перенести получение опций в SearchMultipleUi
    async getLinkTypes() {
      this.showLoader();

      try {
        this.linkTypes = await DocumentMasterApiService.getLinkTypes(
          this.formDataProxy.lt_ids,
          this.abortController.signal,
        );
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          title: 'Ошибка получения типов ссылок',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
        this.back();
      } finally {
        this.hideLoader();
      }
    },
  },
});
