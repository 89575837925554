<template>
  <modal-ui ref="modal" title="Настройки приложения">
    <template #body>
      <tabs-ui>
        <tab-ui title="Параметры">
          <combobox-ui label="Цветовая схема" :options="colorModes" v-model="colorMode" no-clear></combobox-ui>
        </tab-ui>

        <tab-ui title="Разрешения">
          <table class="table table-hover position-relative table-resizable table-no-first-border w-100 mb-0">
            <tr v-for="(perm, title) in getPermissions" :key="title">
              <td>{{ title }}</td>
              <th class="w-25">
                <badge-success v-if="perm === 'granted'" :value="'Разрешено'" class="small"></badge-success>
                <badge-warning v-else-if="perm === 'prompt'" :value="'По запросу'" class="small"></badge-warning>
                <badge-light v-else :value="perm" class="small text-capitalize"></badge-light>
              </th>
            </tr>
          </table>
        </tab-ui>

        <tab-ui title="Статистика">
          <table class="table table-hover position-relative table-resizable table-no-first-border w-100 mb-0">
            <tbody>
            <tr>
              <td>Статус:</td>
              <th class="w-25">
                <badge-success v-if="isOnline" :value="'Онлайн'" class="small"></badge-success>
                <badge-danger v-else :value="'Оффлайн'" class="small"></badge-danger>
              </th>
            </tr>
            <template v-if="memoryIsSupported && memory">
              <tr>
                <th colspan="2">Информация о расходе памяти вкладки</th>
              </tr>
              <tr>
                <td>Использовано</td>
                <th><strong>{{ size(memory.usedJSHeapSize) }}</strong></th>
              </tr>
              <tr>
                <td>Выделено</td>
                <th><strong>{{ size(memory.totalJSHeapSize) }}</strong></th>
              </tr>
              <tr>
                <td>Лимит</td>
                <th><strong>{{ size(memory.jsHeapSizeLimit) }}</strong></th>
              </tr>
            </template>
            </tbody>
          </table>
        </tab-ui>
      </tabs-ui>
    </template>

    <template #footer>
      <close-button @click="hide">
        <template #title>Закрыть</template>
      </close-button>
    </template>
  </modal-ui>
</template>

<script>
import {useArrayFind, useMemory, useOnline, usePermission, useVibrate} from '@vueuse/core';
import {fileSize} from '@/services/utilsFunctions';
import COLOR_MODES from '@/configs/colorModes';
import CloseButton from '@/components/buttons/CloseButton';
import BadgeLight from '@/components/table/badges/BadgeLight';
import BadgeSuccess from '@/components/table/badges/BadgeSuccess';
import BadgeWarning from '@/components/table/badges/BadgeWarning';
import BadgeDanger from '@/components/table/badges/BadgeDanger';
import TabsUi from '@/components/ui/TabsUi.vue';
import TabUi from '@/components/ui/TabUi.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';

export default {
  name: 'SettingsSystemModal',
  inject: [
    'app',
  ],
  mixins: [modal],
  components: {
    ComboboxUi,
    ModalUi,
    TabUi,
    TabsUi,
    CloseButton,
    BadgeLight,
    BadgeSuccess,
    BadgeWarning,
    BadgeDanger,
  },
  setup() {
    const {isSupported: memoryIsSupported, memory} = useMemory();

    const accelerometer = usePermission('accelerometer');
    const accessibilityEvents = usePermission('accessibility-events');
    const ambientLightSensor = usePermission('ambient-light-sensor');
    const backgroundSync = usePermission('background-sync');
    const camera = usePermission('camera');
    const clipboardRead = usePermission('clipboard-read');
    const clipboardWrite = usePermission('clipboard-write');
    const gyroscope = usePermission('gyroscope');
    const magnetometer = usePermission('magnetometer');
    const microphone = usePermission('microphone');
    const notifications = usePermission('notifications');
    const paymentHandler = usePermission('payment-handler');
    const persistentStorage = usePermission('persistent-storage');
    const push = usePermission('push');
    const speaker = usePermission('speaker');

    const {vibrate, stop: vibrateStop, isSupported: vibrateIsSupported} = useVibrate({pattern: [300, 100, 300]});

    const isOnline = useOnline();

    return {
      memoryIsSupported,
      memory,

      accelerometer,
      accessibilityEvents,
      ambientLightSensor,
      backgroundSync,
      camera,
      clipboardRead,
      clipboardWrite,
      gyroscope,
      magnetometer,
      microphone,
      notifications,
      paymentHandler,
      persistentStorage,
      push,
      speaker,

      vibrate,
      vibrateStop,
      vibrateIsSupported,

      isOnline,
    };
  },
  data: () => ({
    comment: '',
    colorMode: null,
    colorModes: COLOR_MODES,
  }),
  computed: {
    getPermissions() {
      return {
        'Акселерометр': this.accelerometer,
        'События доступа': this.accessibilityEvents,
        'Сенсор подсветки': this.ambientLightSensor,
        'Фоновая синхронизация': this.backgroundSync,
        'Камера': this.camera,
        'Чтение буфера обмена': this.clipboardRead,
        'Запись в буфер обмена': this.clipboardWrite,
        'Гироскоп': this.gyroscope,
        'Магнетометр': this.magnetometer,
        'Микрофон': this.microphone,
        'Уведомления': this.notifications,
        'Обработчик платежей': this.paymentHandler,
        'Постоянное хранилище': this.persistentStorage,
        'Пуш-уведомления': this.push,
        'Спикер': this.speaker,
      };
    },
  },
  mounted() {
    if (this.vibrateIsSupported) {
      this.vibrate();
    }
    const appColorMode = useArrayFind(COLOR_MODES, mode => mode.code === this.app.colorMode);
    this.colorMode = appColorMode.value;
  },
  watch: {
    colorMode(mode) {
      this.app.colorModeStore = mode.code;
    },
  },
  methods: {
    size(v) {
      return fileSize(v, 2, '.', ' ');
    },
  },
};
</script>
