<template>
  <form-builder ref="form" :fields="fields" v-model="formDataProxy" @change="changeFiles"></form-builder>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_FILE} from '@/configs/form';

export default defineComponent({
  name: 'StepFiles',
  mixins: [step],
  components: {FormBuilder},
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_FILE,
            name: 'files',
            maxHeight: 365,
          },
        ],
      ],
    };
  },
  methods: {
    changeFiles() {
      this.$set(this.formDataProxy, 'user_files', [
        ...this.formDataProxy.files.filter(file => file.F_ID !== undefined),
      ]);
      this.change();
    },
  },
});
</script>
