<template>
  <div class="file-preview-pagination">
    <button-icon-ui :disabled="current === 1" @click="$emit('prev')">
      <chevron-icon class="chevron chevron_left"></chevron-icon>
    </button-icon-ui>

    <button-icon-ui :disabled="current === count" @click="$emit('next')">
      <chevron-icon class="chevron chevron_right"></chevron-icon>
    </button-icon-ui>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import ChevronIcon from '@/assets/svg/chevron.svg?component'

export default defineComponent({
  name: 'FilePreviewPagination',
  components: {
    ButtonIconUi,
    ChevronIcon,
  },
  props: {
    current: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  emits: ['prev', 'next'],
});
</script>

<style scoped lang="scss">
.file-preview-pagination {
  display: flex;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.chevron {
  &_left {
    transform: rotate(90deg);
  }

  &_right {
    transform: rotate(-90deg);
  }
}
</style>
