<template>
  <div class="sign--table--header">
    <div class="sign--table--col--actions">
      <label class="checkbox-container-custom" for="select-sign-files-all">
        <input type="checkbox"
               v-model="checkbox"
               @change="checkboxChange"
               id="select-sign-files-all">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="sign--table--col--description">
      Описание
    </div>
    <div class="sign--table--col--redaction">
      Редакция
    </div>
    <div v-if="filesForSignHasRemarkGetter" class="sign--table--col--remark">
      Примечание
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "HeaderTableSign",
  data: () => ({
    checkbox: false,
  }),
  methods: {
    ...mapMutations(['filesForSinSetActive']),
    checkboxChange() {
      this.filesForSinSetActive(this.checkbox);
    },
  },
  computed: {
    ...mapGetters(['filesForSignHasRemarkGetter'])
  }
}
</script>