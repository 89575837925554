import {mapGetters} from "vuex";

export default {
  data: () => ({
    stringSqlSearch: 'stringSqlSearch',
    flagSqlSearch: 'flagSqlSearch',
    identifierSqlSearch: 'identifierSqlSearch',
    identifierSqlNotInSearch: 'identifierSqlNotInSearch',
    intSqlSearch: 'intSqlSearch',
    generateKey: 'generateKey',
    getBoolValue: 'getBoolValue',
    getOrder: 'getOrder',
  }),
  computed: {
    ...mapGetters('server', ['dbAdapter']),
  },
}
