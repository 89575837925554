import accessDoc from "@/configs/tables/accessDoc";
import clients from "@/configs/tables/clients";
import comments from "@/configs/tables/comments";
import commissions from "@/configs/tables/commissions";
import controls from "@/configs/tables/controls";
import docContractors from "@/configs/tables/docContractors";
import docHistory from "@/configs/tables/docHistory";
import documents from "@/configs/tables/documents";
import documentsLinks from "@/configs/tables/documentsLinks";
import files from "@/configs/tables/files";
import filesSignatures from "@/configs/tables/filesSignatures";
import viewsDoc from "@/configs/tables/viewsDoc";
import routesPoints from "@/configs/tables/routesPoints";
import VuexAdapter from "@/services/vuexAdapter";
import Vue from 'vue';
import {TABLE_CONDITION_AND} from "@/configs/tables/table";

const tables = [
  accessDoc,
  clients,
  comments,
  commissions(),
  controls(),
  docContractors,
  docHistory,
  documents(),
  documentsLinks,
  files,
  filesSignatures,
  routesPoints,
  viewsDoc,
];

const actions = {};
const mutations = {};
const states = {};
const getters = {};

const clearMutations = [];

tables.forEach(table => {

  const tableName = table.name;

  const abortTableNameAction = VuexAdapter.abortTableNameAction(tableName);

  /**
   * Действие прерывания запроса
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[abortTableNameAction] = async (ctx, data) => {
    return await ctx.dispatch(table.actionAbort, data);
  }

  const tableWhereSQLQueryMutations = (state) => {
    const whereSQLArray = [];
    for (let whereSQLKey in state.tableWhereSQL[tableName]) {
      whereSQLArray.push(state.tableWhereSQL[tableName][whereSQLKey]);
    }

    const query = whereSQLArray.length
      ? ' ( ' + whereSQLArray.join(' ) ' + state.tableWhereCondition[tableName] + ' ( ') + ' ) '
      : '';

    Vue.set(state.tableWhereSQLQuery, tableName, query);
  }

  const tableClearRowMutations = (state) => {
    Vue.set(state.tableActiveRow, tableName, {});
    Vue.set(state.tableActiveRowIndex, tableName, null);
  }

  const filterTableNameAction = VuexAdapter.filterTableNameAction(tableName);

  /**
   * Действие установки фильтра
   *
   * @param ctx
   * @param whereSQL
   * @param whereData
   * @param key
   */
  actions[filterTableNameAction] = (ctx, {whereSQL, whereData, key}) => {
    return new Promise(resolve => {
      ctx.dispatch(table.actionClearStore);

      Vue.set(ctx.state.tableWhereSQL[tableName], key, JSON.parse(JSON.stringify(whereSQL)));
      Vue.set(ctx.state.tableWhereData[tableName], key, JSON.parse(JSON.stringify(whereData)));

      tableWhereSQLQueryMutations(ctx.state);
      tableClearRowMutations(ctx.state);
      resolve();
    });
  }

  const delFilterTableNameAction = VuexAdapter.delFilterTableNameAction(tableName);

  /**
   * Действие удаления фильтра
   *
   * @param ctx
   * @param keyCol
   */
  actions[delFilterTableNameAction] = (ctx, keyCol) => {
    return new Promise(resolve => {

      if (ctx.state.tableWhereData[tableName][keyCol] === undefined) {
        return resolve();
      }

      ctx.dispatch(table.actionClearStore);

      Vue.delete(ctx.state.tableWhereSQL[tableName], keyCol);
      Vue.delete(ctx.state.tableWhereData[tableName], keyCol);
      tableWhereSQLQueryMutations(ctx.state);
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }

  const filterAndDeleteTableNameAction = VuexAdapter.filterAndDeleteTableNameAction(tableName);

  /**
   * Действие добавления одного фильтра с удалением других
   *
   * @param ctx
   * @param whereSQL
   * @param whereData
   * @param key
   */
  actions[filterAndDeleteTableNameAction] = (ctx, {whereSQL, whereData, key}) => {
    return new Promise(resolve => {
      ctx.dispatch(table.actionClearStore);

      Vue.set(ctx.state.tableWhereSQL, tableName, {});
      Vue.set(ctx.state.tableWhereData, tableName, {});
      Vue.set(ctx.state.tableWhereCondition, tableName, TABLE_CONDITION_AND);

      Vue.set(ctx.state.tableWhereSQL[tableName], key, JSON.parse(JSON.stringify(whereSQL)));
      Vue.set(ctx.state.tableWhereData[tableName], key, JSON.parse(JSON.stringify(whereData)));

      tableWhereSQLQueryMutations(ctx.state);
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }


  const delOrderByTableNameAction = VuexAdapter.delOrderByTableNameAction(tableName);

  /**
   * Действие удаления сортировки
   *
   * @param ctx
   */
  actions[delOrderByTableNameAction] = (ctx) => {
    return new Promise(resolve => {
      ctx.dispatch(table.actionClearStore);

      Vue.set(ctx.state.tableOrderBy, tableName, {});
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }

  const orderByTableNameAction = VuexAdapter.orderByTableNameAction(tableName);

  /**
   * Действие добавления сортировки
   *
   * @param ctx
   * @param key
   * @param value
   * @param fieldSort
   */
  actions[orderByTableNameAction] = (ctx, {key, value, fieldSort}) => {
    return new Promise(resolve => {
      ctx.dispatch(table.actionClearStore);
      Vue.set(ctx.state.tableOrderBy, tableName, {
        key, value, fieldSort
      });
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }

  const fixColsTableNameMutation = VuexAdapter.fixColsTableNameMutation(tableName);

  /**
   * Мутация установки фиксированных колонок
   *
   * @param state
   * @param fix
   */
  mutations[fixColsTableNameMutation] = (state, fix) => {
    Vue.set(state.tableFixCols, tableName, fix);
  }

  const conditionTableNameAction = VuexAdapter.conditionTableNameAction(tableName);

  /**
   * Действие установки общего условия фильтрации
   *
   * @param ctx
   * @param condition
   */
  actions[conditionTableNameAction] = (ctx, condition) => {
    return new Promise(resolve => {
      ctx.dispatch(table.actionClearStore);

      Vue.set(ctx.state.tableWhereCondition, tableName, condition);

      tableWhereSQLQueryMutations(ctx.state);
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }

  const delFilterAndOrderByTableNameAction = VuexAdapter.delFilterAndOrderByTableNameAction(tableName);

  /**
   * Действие удаление всех фильтров и сортировки
   *
   * @param ctx
   */
  actions[delFilterAndOrderByTableNameAction] = (ctx) => {
    return new Promise(resolve => {

      ctx.dispatch(conditionTableNameAction, TABLE_CONDITION_AND);
      ctx.dispatch(delOrderByTableNameAction);
      Vue.set(ctx.state.tableWhereSQL, tableName, {});
      Vue.set(ctx.state.tableWhereData, tableName, {});
      Vue.set(ctx.state.tableWhereCondition, tableName, TABLE_CONDITION_AND);

      tableWhereSQLQueryMutations(ctx.state);
      tableClearRowMutations(ctx.state);

      resolve();
    });
  }

  const activeRowTableNameMutation = VuexAdapter.activeRowTableNameMutation(tableName);

  /**
   * Мутация установки активной строки
   *
   * @param state
   * @param row
   * @param index
   */
  mutations[activeRowTableNameMutation] = (state, {row, index}) => {
    Vue.set(state.tableActiveRow, tableName, {...row});
    Vue.set(state.tableActiveRowIndex, tableName, index);
  }

  const sortableStatusTableNameMutation = VuexAdapter.sortableStatusTableNameMutation(tableName);

  /**
   * Мутация установки статуса сортировки
   *
   * @param state
   * @param bool
   */
  mutations[sortableStatusTableNameMutation] = (state, bool) => {
    Vue.set(state.tableSortableShown, tableName, bool);
  }

  const clearTableNameMutation = VuexAdapter.clearTableNameMutation(tableName);

  /**
   * Мутация очистки хранилища
   *
   * @param state
   */
  mutations[clearTableNameMutation] = (state) => {
    Vue.set(state.tableActiveRow, tableName, {});
    Vue.set(state.tableActiveRowIndex, tableName, null);
    Vue.set(state.tableWhereSQL, tableName, {});
    Vue.set(state.tableWhereData, tableName, {});
    Vue.set(state.tableOrderBy, tableName, {});
    Vue.set(state.tableWhereSQLQuery, tableName, {});
    Vue.set(state.tableWhereCondition, tableName, TABLE_CONDITION_AND);
    Vue.set(state.tableFixCols, tableName, null);
    Vue.set(state.tableLoader, tableName, true);
    Vue.set(state.tableSortableShown, tableName, true);
    Vue.set(state.tableVisibleColumns, tableName, null);
    Vue.set(state.tableSortColumns, tableName, null);
  }

  clearMutations.push(clearTableNameMutation);

  /**
   * state
   */
  if (!Object.keys(states).length) {
    states.tableActiveRow = {};
    states.tableActiveRowIndex = {};
    states.tableWhereSQL = {};
    states.tableWhereData = {};
    states.tableOrderBy = {};
    states.tableWhereSQLQuery = {};
    states.tableWhereCondition = {};
    states.tableFixCols = {};
    states.tableLoader = {};
    states.tableSubLoader = {};
    states.tableSortableShown = {};
    states.tableVisibleColumns = {};
    states.tableSortColumns = {};
  }

  states.tableActiveRow[tableName] = {};
  states.tableActiveRowIndex[tableName] = null;
  states.tableWhereSQL[tableName] = {};
  states.tableWhereData[tableName] = {};
  states.tableOrderBy[tableName] = {};
  states.tableWhereSQLQuery[tableName] = '';
  states.tableWhereCondition[tableName] = TABLE_CONDITION_AND;
  states.tableFixCols[tableName] = null;
  states.tableLoader[tableName] = true;
  states.tableSubLoader[tableName] = false;
  states.tableSortableShown[tableName] = true;
  states.tableVisibleColumns[tableName] = null;
  states.tableSortColumns[tableName] = null;

  const activeRowTableNameGetter = VuexAdapter.activeRowTableNameGetter(tableName);

  /**
   * Getter получения активной строки
   * @param state
   * @returns {*}
   */
  getters[activeRowTableNameGetter] = (state) => {
    return state.tableActiveRow[tableName];
  }

  const activeRowIndexTableNameGetter = VuexAdapter.activeRowIndexTableNameGetter(tableName);

  /**
   * Getter получения индекса активной строки
   * @param state
   * @returns {*}
   */
  getters[activeRowIndexTableNameGetter] = (state) => {
    return state.tableActiveRowIndex[tableName];
  }

  const filterTableNameGetter = VuexAdapter.filterTableNameGetter(tableName);

  /**
   * Getter получения объекта фильтрации
   * @param state
   * @returns {*}
   */
  getters[filterTableNameGetter] = (state) => {
    return state.tableWhereData[tableName];
  }

  const conditionTableNameGetter = VuexAdapter.conditionTableNameGetter(tableName);

  /**
   * Getter получения условия фильтрации
   * @param state
   * @returns {*}
   */
  getters[conditionTableNameGetter] = (state) => {
    return state.tableWhereCondition[tableName];
  }

  const sqlQueryTableNameGetter = VuexAdapter.sqlQueryTableNameGetter(tableName);

  /**
   * Getter получения условия фильтрации в sql
   * @param state
   * @returns {*}
   */
  getters[sqlQueryTableNameGetter] = (state) => {
    return state.tableWhereSQLQuery[tableName];
  }

  const orderByTableNameGetter = VuexAdapter.orderByTableNameGetter(tableName);

  /**
   * Getter получения объекта сортировки
   * @param state
   * @returns {*}
   */
  getters[orderByTableNameGetter] = (state) => {
    return state.tableOrderBy[tableName];
  }

  const orderByTSqlableNameGetter = VuexAdapter.orderByTSqlableNameGetter(tableName);

  /**
   * Getter получения SQL запроса сортировки
   * @param state
   * @param getters
   * @returns {*}
   */
  getters[orderByTSqlableNameGetter] = (state, getters) => {

    const orderBy = state.tableOrderBy[tableName];

    if (Object.keys(orderBy).length) {
      return getters['server/dbAdapter'].getOrder(orderBy?.fieldSort || orderBy.key, orderBy.value);
    }
    return '';
  }

  const loaderTableNameGetter = VuexAdapter.loaderGeneralTableNameGetter(tableName);

  /**
   * Getter статуса загрузки
   * @param state
   * @returns {*}
   */
  getters[loaderTableNameGetter] = (state) => {
    return state.tableLoader[tableName];
  }

  const subLoaderTableNameGetter = VuexAdapter.subLoaderTableNameGetter(tableName);

  /**
   * Getter статуса поднрузки
   * @param state
   * @returns {*}
   */
  getters[subLoaderTableNameGetter] = (state) => {
    return state.tableSubLoader[tableName];
  }

  const fixColsTableNameGetter = VuexAdapter.fixColsTableNameGetter(tableName);

  /**
   * Getter фиксированные колонки
   * @param state
   * @returns {*}
   */
  getters[fixColsTableNameGetter] = (state) => {
    return state.tableFixCols[tableName];
  }

  const errorTableNameGetter = VuexAdapter.errorTableNameGetter(tableName);

  /**
   * Getter ошибок
   * @param state
   * @param getters
   * @returns {*}
   */
  getters[errorTableNameGetter] = (state, getters) => {
    return getters[table.getterError] || null;
  }

  const sortableStatusTableNameGetter = VuexAdapter.sortableStatusTableNameGetter(tableName);

  /**
   * Getter статус сортировки
   * @param state
   * @returns {*}
   */
  getters[sortableStatusTableNameGetter] = (state) => {
    return state.tableSortableShown[tableName];
  }

  const getDataNameAction = VuexAdapter.getDataTableNameAction(tableName);

  /**
   * Проверка наличия активной строки в списке
   * @param ctx
   * @param data
   */
  const activeRowCheck = (ctx, data) => {
    const index = ctx.getters[activeRowIndexTableNameGetter];
    const activeRow = ctx.getters[activeRowTableNameGetter];
    const items = data.data.items;
    const zeroIndex = 0;

    if (!items.length) {
      ctx.commit(activeRowTableNameMutation, {row: {}, index: null})

      return null;
    }

    if (!index) {
      ctx.commit(activeRowTableNameMutation, {row: items[zeroIndex], index: zeroIndex});

      return items[zeroIndex][table.previewField];
    }

    if (!items[index]) {
      const newIndex = items.length - 1;
      ctx.commit(activeRowTableNameMutation, {row: items[newIndex], index: newIndex});

      return items[newIndex][table.previewField];
    }

    if (items[index] && items[index][table.previewField] !== activeRow[table.previewField]) {
      for (let i = 0; i < items.length; i++) {
        if (items[i][table.previewField] === activeRow[table.previewField]) {
          ctx.commit(activeRowTableNameMutation, {row: items[i], index: i});

          return items[i][table.previewField];
        }
      }

      for (let i = index; i >= 0; i--) {
        if (items[i]) {
          ctx.commit(activeRowTableNameMutation, {row: items[i], index: i});

          return items[i][table.previewField];
        }
      }
    }

    return items[index][table.previewField] || null;
  }

  /**
   * Действие получение данных
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[getDataNameAction] = async (ctx, data) => {

    let limit = table.limit;
    const where = ctx.getters[sqlQueryTableNameGetter];
    const orderby = ctx.getters[orderByTSqlableNameGetter];

    const activeRowIndex = ctx.getters[activeRowIndexTableNameGetter];

    if (activeRowIndex) {
      let max = Number(activeRowIndex) + 1;
      for (let i = table.limit; i <= max + table.limit; i = i + table.limit) {
        limit = i;
      }
    }

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    ctx.state.tableLoader[tableName] = true;

    return new Promise(resolve => {
      return ctx.dispatch(table.action, {limit, where, orderby, ...data}).then((r) => {
        /** Закрузка цветов */
        if (ctx.getters[table.getter].length && table?.actionColor) {
          ctx.dispatch(table.actionColor);
        }

        const id = activeRowCheck(ctx, r);

        ctx.dispatch('setEntityIdPreviewAction', id);

        resolve(id);
      }).finally(() => {
        ctx.state.tableLoader[tableName] = false;
      });
    });
  }

  const loadTableNameAction = VuexAdapter.loadTableNameAction(tableName);

  /**
   * Действие подгрузки данных
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[loadTableNameAction] = async (ctx, data) => {
    const length = ctx.getters[table.getter].length;
    const limit = table.limit > length ? table.limit : length;
    const offset = ctx.getters[table.getter].length;
    const where = ctx.getters[sqlQueryTableNameGetter];
    const orderby = ctx.getters[orderByTSqlableNameGetter];

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    ctx.state.tableSubLoader[tableName] = true;
    return await ctx.dispatch(table.actionLoading, {
      limit, offset, where, orderby, ...data
    }).finally(() => {
      ctx.state.tableSubLoader[tableName] = false;
    });
  }

  const refreshNameAction = VuexAdapter.refreshOnlyStateTableNameAction(tableName);

  /**
   * Действие обновления данных (фоновая без загрузчика)
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[refreshNameAction] = async (ctx, data = {}) => {

    const length = ctx.getters[table.getter].length;
    const limit = table.limit > length ? table.limit : length;
    const where = ctx.getters[sqlQueryTableNameGetter];
    const orderby = ctx.getters[orderByTSqlableNameGetter];

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    return new Promise(resolve => {
      return ctx.dispatch(table.action, {limit, where, orderby, ...data}).then((r) => {
        const id = activeRowCheck(ctx, r);

        ctx.dispatch('setEntityIdPreviewAction', id);

        resolve(id);
      });
    });
  }

  const visibleColumnsAction = VuexAdapter.visibleColumnsTableNameAction(tableName);

  /**
   * Отображаемые колонки
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[visibleColumnsAction] = async (ctx, data = {}) => {
    ctx.state.tableVisibleColumns[tableName] = {...data};
  }

  const visibleColumnsClearAction = VuexAdapter.visibleColumnsTableClearNameAction(tableName);

  actions[visibleColumnsClearAction] = async (ctx) => {
    ctx.state.tableVisibleColumns[tableName] = null;
  }

  const visibleColumnsGetter = VuexAdapter.visibleColumnsTableNameGetter(tableName);

  getters[visibleColumnsGetter] = (state) => {
    return state.tableVisibleColumns[tableName];
  }

  const sortColumnsAction = VuexAdapter.sortColumnsTableNameAction(tableName);

  /**
   * Сортировка колонк
   *
   * @param ctx
   * @param data
   * @returns {Promise<*>}
   */
  actions[sortColumnsAction] = async (ctx, data = []) => {
    ctx.state.tableSortColumns[tableName] = [...data];
  }

  const sortColumnsClearAction = VuexAdapter.sortColumnsTableClearNameAction(tableName);

  actions[sortColumnsClearAction] = async (ctx) => {
    ctx.state.tableSortColumns[tableName] = null;
  }

  const sortColumnsGetter = VuexAdapter.sortColumnsTableNameGetter(tableName);

  getters[sortColumnsGetter] = (state) => {
    return state.tableSortColumns[tableName];
  }
});

export default {
  actions: {
    ...actions,
    refreshDataTable(ctx, tableName) {
      const loadTableNameAction = VuexAdapter.loadTableNameAction(tableName);

      ctx.dispatch(loadTableNameAction, {});
    },

    tableClearAllAction(ctx) {
      clearMutations.forEach(mutationName => {
        ctx.commit(mutationName);
      })
    },
  },
  mutations: {
    ...mutations,
  },
  state: {
    ...states,
  },
  getters: {
    ...getters
  },
}
