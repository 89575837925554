import {TYPE_STRING, TYPE_TEXT} from "@/configs/form";

export default () => ([
    [
        {
            type: TYPE_STRING,
            name: "bank_name",
            label: "Банк",
            placeholder: "Укажите банк",
            required: true,
        },
    ],
    [
        {
            type: TYPE_STRING,
            name: "bik",
            label: "БИК",
            placeholder: "Укажите БИК",
            required: true,
            max: 9,
        },
    ],
    [
        {
            type: TYPE_STRING,
            name: "kc",
            label: "КС",
            placeholder: "Укажите КС",
            required: true,
        },
    ],
    [
        {
            type: TYPE_STRING,
            name: "account",
            label: "РС",
            placeholder: "Укажите РС",
            required: true,
        },
    ],
    [
        {
            type: TYPE_TEXT,
            name: "comment",
            label: "Примечание",
            placeholder: "Введите примечание",
            max: 2048,
            required: false,
        }
    ]
])