import {TYPE_SELECT, TYPE_STRING, TYPE_TEXT} from "@/configs/form";
export default (Vue) => ([
    [
        {
            type: TYPE_SELECT,
            name: "contact_type",
            input: true,
            label: "Тип",
            placeholder: "Введите тип",
            options: () => Vue.clientContactTypesSelectGetter,
            required: true,
        },
    ],
    [
        {
            type: TYPE_STRING,
            name: "contact",
            label: "Контакт",
            placeholder: "Введите контакт",
            max: 255,
            required: true,
        },
    ],
    [
        {
            type: TYPE_TEXT,
            name: "comment",
            label: "Примечание",
            placeholder: "Введите примечание",
            max: 255,
            required: false,
        }
    ]
])