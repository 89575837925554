import HttpService from '@/services/api/http.service';
import {DOC_TYPE_MASK_PARAM_VALUES_GET, DOC_TYPES_MASK_PARAMS_GET, LINK_TYPES_ACTIVE} from '@/configs/endPoints';
import {Parameter} from '@/common/models/parameter';
import CacheGroup from '@/common/consts/cacheGroup';
import {DEFAULT_LINK_TYPE} from '@/services/api/document-master/document-master.consts';
import {LinkType} from '@/common/models/option/link-type';
import TextOption from '@/common/models/option/text-option';

export default class DocumentMasterApiService {
  static async getLinkTypes(linkTypesIds = [], signal) {
    if (!linkTypesIds.length) {
      return [DEFAULT_LINK_TYPE];
    }

    const response = await HttpService.post(LINK_TYPES_ACTIVE, {
      where: `LT_ID IN (${linkTypesIds.join(',')})`,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `LINK_TYPES_${linkTypesIds.join('_')}`});

    return [
      DEFAULT_LINK_TYPE,
      ...response.items.map(item => new LinkType(item)),
    ];
  }

  static async getParameters(typeId, signal) {
    const response = await HttpService.post(DOC_TYPES_MASK_PARAMS_GET, {
      DT_ID: typeId,
    }, signal);

    return response.items.map(item => new Parameter(item));
  }

  static async getParameterOptions(typeId, number, signal) {
    const response = await HttpService.post(DOC_TYPE_MASK_PARAM_VALUES_GET, {
      dt_id: typeId,
      number,
    }, signal, true, {group: CacheGroup.DocumentMaster, key: `MASK_PARAMETER_${typeId}_${number}`});

    return response.items.map(item => new TextOption(item));
  }
}
