<template>
  <field-ui :id="id" :label="label" :required="required" :error="errorMessage" :hint="hint">
    <div ref="fieldRef" class="field" :class="fieldClassName" @mousedown="onFieldMousedown" @click="onFieldClick">
      <div class="content">
        <textarea
          v-focus="focusOnMount"
          ref="textarea"
          class="textarea"
          :class="{'_no-resize': noResize}"
          :rows="rows"
          :autocomplete="autocomplete"
          :value="modelValue"
          :disabled="disabled"
          @input="onInput"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="$emit('keydown', $event)"
        ></textarea>

        <div v-if="$slots.default" class="slot">
          <slot :model-value="modelValue"></slot>
        </div>

        <div v-if="!modelValue && placeholder" class="placeholder">
          {{ placeholder }}
        </div>
      </div>
    </div>
  </field-ui>
</template>

<script>
import {defineComponent} from 'vue';
import Focus from '@/directives/focus';
import FieldUi from '@/components/ui/FieldUi.vue';
import field from '@/mixins/form/field';
import maxLength from '@/mixins/form/maxLength';
import {uniqueId} from 'lodash-es';
import focus from '@/mixins/form/focus';

export default defineComponent({
  name: 'TextareaUi',
  mixins: [field, focus, maxLength],
  components: {
    FieldUi,
  },
  directives: {
    Focus,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: String,
    id: {
      type: String,
      default: uniqueId('textarea-ui-'),
    },
    placeholder: {
      type: String,
      default: 'Введите текст',
    },
    rows: {
      type: Number,
      default: 4,
    },
    autocomplete: String,
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value', 'keydown'],
  computed: {
    control() {
      return this.$refs.textarea;
    },
    fieldClassName() {
      return {
        _focused: !!this.isFocused,
        _disabled: this.disabled,
        _error: !!this.error,
      };
    },
  },
  methods: {
    focus() {
      this.control.focus();
    },
  },
});
</script>

<style scoped lang="scss">
.field {
  padding: 8px 10px;

  border-radius: 8px;
  cursor: text;

  background: var(--color-gray-075) linear-gradient(
      to right,
      rgba(226, 231, 239, 1) 0%,
      rgba(226, 231, 239, 1) v-bind('gradient.start'),
      var(--color-transparent) v-bind('gradient.end'),
      var(--color-transparent) 100%,
  );

  transition: box-shadow var(--transition-fast);

  &:not(._error) {
    &._focused {
      box-shadow: var(--shadow-control);
    }
  }

  &._disabled {
    pointer-events: none;

    .content {
      opacity: 0.5;
    }
  }

  &._error {
    box-shadow: var(--shadow-control-error);
  }
}

.content {
  position: relative;
  display: flex;
}

.textarea {
  flex-grow: 1;
  min-height: 36px;
  resize: vertical;

  &._no-resize {
    resize: none;
  }
}

.slot {
  position: absolute;
  top: 0;
  right: 0;
}

.placeholder {
  position: absolute;
  bottom: 0;
  left: 0;

  pointer-events: none;

  color: var(--color-gray-500);
}
</style>
