import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import VuexAdapter from "@/services/vuexAdapter";
import {DOCS_AUDIT_ALL} from "@/configs/endPoints";
import {DOC_HISTORY_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: DOC_HISTORY_TABLE_NAME,
    title: ' Аудит документов',
    action: VuexAdapter.getNameAction(DOCS_AUDIT_ALL),
    actionLoading: VuexAdapter.getNameLoadingAction(DOCS_AUDIT_ALL),
    getter: VuexAdapter.getNameGetter(DOCS_AUDIT_ALL),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOCS_AUDIT_ALL),
    actionClearStore: VuexAdapter.getNameClearAction(DOCS_AUDIT_ALL),
    actionAbort: VuexAdapter.getNameAbortAction(DOCS_AUDIT_ALL),
    getterError: VuexAdapter.getNameErrorGetter(DOCS_AUDIT_ALL),
    generalField: 'AUDIT_ID',
    searchSmallSelectedField: 'Событие',
    smallGroped: () => import('@/components/table/grouped/DocHistory'),
    limit: 20,
    info: {
        'AUDIT_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID записи аудита',
            conditions: 1,
            order: 0
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            conditions: 1
        },
        'Активен': {
            visible: false,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Активен',
            conditions: 1,
            order: 1,
        },
        'Номер': {
            visible: false,
            title: 'Номер',
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 2,
        },
        'Дата': {
            visible: false,
            title: 'Дата',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата создания черновика документа',
            conditions: 3,
            order: 3,
        },
        'Срок': {
            visible: false,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Общий срок документа (не путать со сроком поручения)',
            conditions: 3,
            order: 4,
        },
        'Исполнен': {
            visible: false,
            title: 'Исполнен',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 5,
        },
        'Проект': {
            visible: false,
            title: 'Проект',
            classes: {},
            type: Constants.string,
            searchSmallField: true,
            description: 'Название проекта документа',
            conditions: 3,
            order: 6,
        },
        'Содержание': {
            visible: false,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание документа',
            conditions: 3,
            order: 7,
        },
        'Контролер': {
            visible: false,
            searchSmallField: true,
            title: 'Контролер',
            classes: {},
            type: Constants.string,
            description: 'Ф.И.О пользователя контролера по документу',
            conditions: 3,
            order: 8,
        },
        'Регистратор': {
            visible: false,
            searchSmallField: true,
            title: 'Регистратор',
            classes: {},
            type: Constants.string,
            description: 'Ф.И.О пользователя регистратора по документу',
            conditions: 3,
            order: 9,
        },
        'Дата время': {
            visible: true,
            title: 'Дата время',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата и время события',
            conditions: 3,
            order: 10,
        },
        'Параметр01': {
            visible: true,
            title: 'Предыдущее значение',
            classes: {},
            type: Constants.string,
            description: 'Предыдущее значение параметра или прочая информация',
            conditions: 3,
            order: 11,
        },
        'Параметр02': {
            visible: true,
            title: 'Следующее значение',
            classes: {},
            type: Constants.string,
            description: 'Следующее значение параметра или прочая информация',
            conditions: 3,
            order: 11,
        },
        'Станция': {
            visible: true,
            title: 'Станция',
            classes: {},
            type: Constants.string,
            description: 'Название компьютера, с которого произошло изменение',
            conditions: 3,
            order: 12,
        },
        'Пользователь': User({order: 13}),
        'Приоритет события': {
            visible: true,
            title: 'Приоритет',
            classes: {},
            type: Constants.integer,
            description: 'Приоритет события',
            conditions: 3,
            order: 14,
        },
        'Событие': {
            visible: true,
            searchSmallField: true,
            title: 'Событие',
            classes: {},
            type: Constants.string,
            description: 'Описание события аудита',
            conditions: 3,
            order: 15,
        },
        'Элемент': {
            visible: true,
            title: 'Элемент',
            classes: {},
            type: Constants.string,
            description: 'Элемент документа, с которым прошли изменения',
            conditions: 3,
            order: 16,
        },
    },
};