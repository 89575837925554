<template>
  <button class="btn btn btn-alpha"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <upload-icon></upload-icon> Загрузить
  </button>
</template>

<script>
import UploadIcon from "@/components/icons/UploadIcon";
export default {
  name: "UploadAlphaButton",
  components: {UploadIcon},
  inheritAttrs: false,
}
</script>