import Constants from '@/configs/tables/constants';
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_ACCESS} from "@/configs/endPoints";
import {ACCESS_DOC_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: ACCESS_DOC_TABLE_NAME,
    title: 'Доступ',
    action: VuexAdapter.getNameAction(DOC_ACCESS),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_ACCESS),
    getter: VuexAdapter.getNameGetter(DOC_ACCESS),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_ACCESS),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_ACCESS),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_ACCESS),
    getterError: VuexAdapter.getNameErrorGetter(DOC_ACCESS),
    generalField: 'ACCESS_ID',
    searchSmallSelectedField: 'Тип',
    smallGroped: () => import('@/components/table/grouped/AccessDoc'),
    limit: 20,
    info: {
        'CustomKey': {
            visible: true,
            configTitle: true,
            classes: {},
            description: 'Уникальный ключ',
            order: 1,
            conditions: 3,
        },
        'ACCESS_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID ключ строки доступа',
            order: 0,
            conditions: 3,
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            order: 4,
        },
        'PROVIDER_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID таблицы источника доступа',
            order: 5,

        },
        'PROVIDER_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID источника доступа',
            order: 6,
        },
        'RECIPIENT_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID таблицы получателя доступа',
            order: 7,
        },
        'RECIPIENT_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID получателя доступа',
            order: 8,
        },
        'Тип': {
            visible: true,
            searchSmallField: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип доступа',
            order: 9,
            conditions: 3,
        },
        'Источник': {
            visible: true,
            searchSmallField: true,
            title: 'Источник',
            classes: {},
            type: Constants.string,
            description: 'Источник доступа',
            order: 10,
            conditions: 3,
        },
        'Объект': {
            visible: true,
            searchSmallField: true,
            title: 'Объект',
            classes: {},
            type: Constants.string,
            description: 'Объект получатель доступа',
            order: 11,
            conditions: 3,
        },
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание к объекту получателя доступа',
            order: 12,
            conditions: 3,
        },
        'Родитель': {
            visible: true,
            title: 'Родитель',
            classes: {},
            type: Constants.string,
            description: 'Объект источника доступа',
            order: 13,
            conditions: 3,
        },
    },
};