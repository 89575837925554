export class AbstarctPreview {

    _entityId;

    _limitLoad = 5;

    setEntityId(entityId) {
        this._entityId = entityId;

        return this;
    }

    fetchDataPreview() {
        throw new Error('Method initPreview not defined');
    }

    fetchPartlyPreview(endPoint) {
        throw new Error('Method fetchPartlyPreview not defined for: ' + endPoint);
    }
}