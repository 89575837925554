<template>
  <div class="table-component-wrapper"
       ref="componentContainer"
  >
    <div class="table-component"
         :id="'table-component-' + configs.name"
         v-infinite-scroll="loadingDataTableAction"
         infinite-scroll-disabled="scrollDisable"
         :infinite-scroll-distance="200"
    >
      <error-alert-ui v-if="error && !generalLoader" class="table-component__error">
        <p v-if="error?.error_message">{{ error.error_message }}</p>
        <p v-else>Ошибка получения данных</p>
        <hr>
        <p class="mb-0">Попробуйте сбросить фильтр или обратитесь в службу поддержки. <a href="#" @click.prevent="clearFilters">Сбросить фильтр</a></p>
      </error-alert-ui>
      <div v-else>
        <template v-if="initialView === 'list'">
          <table class="w-100">
            <TableActiveFiltersSmall ref="tableActiveFiltersSmall"
                                     :configs_table="configs"
                                     v-show="tableInit"
            />
            <TableActiveFilters ref="tableActiveFilters"
                                class="table-resizable"
                                :component_width="componentWidth"
                                :is-small="true"
                                :configs_table="configs"
                                v-show="tableInit"
            />
            <TableBody :configs="configs"
                       :dataTable="$store.getters[configs.getter]"
                       :componentWidth="componentWidth"
                       :small="true"
                       v-show="tableInit"
            />
            <TableFooter :headerHeight="headerHeight"
                         :configs="configs"/>
          </table>
        </template>
        <template v-else>
          <div class="table-wrap"
               :style="tableGeneralLoadStyle">
            <table class="table table-hover table-sm position-relative table-resizable w-100 mb-0 table-design"
                   cellspacing="0">
              <TableActiveFilters ref="tableActiveFilters"
                                  :configs_table="configs"
                                  v-show="tableInit"
              />
              <TableHeadCount ref="tableHeadCount"
                              v-show="tableInit"
                              :configs="configs"/>
              <TableHead ref="tableHead"
                         v-show="tableInit"
                         :data="configs"/>
              <TableBody :configs="configs"
                         v-show="tableInit"
                         :dataTable="$store.getters[configs.getter]"
                         :componentWidth="componentWidth"
                         :small="false"
              ></TableBody>
              <TableFooter :headerHeight="headerHeight"
                           :tableInitData="tableInit"
                           :style="tableGeneralLoadStyle"
                           :configs="configs"/>
            </table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import TableHead from '@/components/table/TableHead';
import TableBody from '@/components/table/TableBody';
import TableFooter from '@/components/table/TableFooter';
import infiniteScroll from 'vue-infinite-scroll';
import TableActiveFilters from '@/components/table/activeFilters/TableActiveFilters';
import TableActiveFiltersSmall from '@/components/table/activeFilters/TableActiveFilterSmall';
import TableHeadCount from "@/components/table/TableHeadCount";
import VuexAdapter from "@/services/vuexAdapter";
import ErrorAlertUi from "@/components/ui/ErrorAlertUi";

export default {
  name: 'TableIndex',
  props: {
    /** Конфигурация таблицы */
    configs: {
      required: true,
      type: Object,
    },
    /** Ширина таблицы */
    componentWidth: {
      required: false,
      type: Number,
    },
    /** Объект микса вызовов акшенов на получение данных */
    requestData: {
      required: false,
    },
    /** Запрос SQL который всегда добавляется к условию WHERE */
    preFilter: {
      required: false,
      type: String,
    },
  },
  components: {
    ErrorAlertUi,
    TableHeadCount,
    TableActiveFilters,
    TableActiveFiltersSmall,
    TableHead,
    TableBody,
    TableFooter
  },
  data: () => ({
    scrollDisable: false,
    tableInit: false,
    tableActiveFiltersSmallHeight: 0,
    tableActiveFiltersHeight: 0,
    tableHeadCountHeight: 0,
    tableHeadHeight: 0,
  }),
  async mounted() {
    this.$refs.componentContainer.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });

    this.$eventBus.$on('table-start-reload-' + this.configs.name, () => {
      this.getDataTableAction();
    });

    this.$eventBus.$on('table-trigger-refresh-only-state', () => {
      this.refreshOnlyStateTableAction();
    });

    this.$eventBus.$on('remove-row-' + this.configs.name, (index) => {
      this.removeRow(index);
    });

    if (!this.$store.getters[this.configs.getterCountByFilter]) {
      await this.getDataTableAction();
    }

    this.tableInit = true;
  },
  updated() {
    this.setHigthHeader()
  },
  computed: {
    headerHeight() {
      return this.tableHeadHeight + this.tableHeadCountHeight + this.tableActiveFiltersHeight + this.tableActiveFiltersSmallHeight;
    },
    error() {
      return this.$store.getters[VuexAdapter.errorTableNameGetter(this.configs.name)];
    },
    tableActiveRowIndex() {
      return this.$store.getters[VuexAdapter.activeRowIndexTableNameGetter(this.configs.name)];
    },
    tableWhereSQLQuery() {
      return this.$store.getters[VuexAdapter.sqlQueryTableNameGetter(this.configs.name)];
    },
    tableOrderBy() {
      return this.$store.getters[VuexAdapter.orderByTableNameGetter(this.configs.name)];
    },
    tableFixCols() {
      return this.$store.getters[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
    generalLoader() {
      return this.$store.getters[VuexAdapter.loaderGeneralTableNameGetter(this.configs.name)];
    },
    initialView() {
      return this.componentWidth <= Constants.tableIndexPoints.md ? 'list' : 'table';
    },
    tableGeneralLoadStyle() {
      if (this.generalLoader && !this.tableInit) {
        return {
          background: 'rgba(226, 231, 239, .4)',
          width: '100%'
        }
      }

      return {};
    },
  },
  methods: {
    setHigthHeader() {
      this.tableActiveFiltersSmallHeight = this.$refs?.tableActiveFiltersSmall?.$el?.clientHeight || 0;
      this.tableActiveFiltersHeight = this.$refs?.tableActiveFilters?.$el?.clientHeight || 0;
      this.tableHeadCountHeight = this.$refs?.tableHeadCount?.$el?.clientHeight || 0;
      this.tableHeadHeight = this.$refs?.tableHead?.$el?.clientHeight || 0;
    },
    getPreData() {
      let data = {};
      if (this.requestData) data = Object.assign(data, this.requestData);
      if (this.preFilter) data.where = JSON.parse(JSON.stringify(this.preFilter));

      return data;
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.$store.dispatch(VuexAdapter.delFilterAndOrderByTableNameAction(this.configs.name));
    },
    async getDataTableAction() {
      return this.$store.dispatch(VuexAdapter.getDataTableNameAction(this.configs.name), this.getPreData());
    },
    async loadingDataTableAction() {
      if (this.$store.getters[this.configs.getter].length === 0 ||
          this.$store.getters[this.configs.getter].length === this.$store.getters[this.configs.getterCountByFilter]
      ) {
        return;
      }
      this.scrollDisable = true;

      await this.$store.dispatch(VuexAdapter.loadTableNameAction(this.configs.name), this.getPreData()).finally(() => {
        this.scrollDisable = false;
      });
    },
    async refreshOnlyStateTableAction() {
      await this.$store.dispatch(VuexAdapter.refreshOnlyStateTableNameAction(this.configs.name, this.getPreData()));
    },
    removeRow(index) {
      this.$delete(this.$store.getters[this.configs.getter], index);
      this.refreshOnlyStateTableAction();
    },
    clearFilters() {
      this.tableDeleteALLFiltersAndOrderBy();
    }
  },
  directives: {
    infiniteScroll,
  },
  watch: {
    tableWhereSQLQuery() {
      this.getDataTableAction();
    },
    tableOrderBy() {
      this.getDataTableAction();
    },
  },
  beforeDestroy() {
    this.$store.dispatch(this.configs.actionAbort);
    this.$eventBus.$off('table-start-reload-' + this.configs.name);
    this.$eventBus.$off('table-trigger-refresh-only-state');
    this.$eventBus.$off('remove-row-' + this.configs.name);
  }
};
</script>
