<template>
  <preview-block title="Маршрут" :amount="tasks.length" :loading="loading" @resize="onResize">
    <template #header-postfix="{isOpen}">
      <div class="header-postfix">
        <transition name="opacity-fast">
          <div v-if="isOpen" class="buttons">
            <button-icon-ui @click="ganttZoomIn">
              <zoom-in-icon></zoom-in-icon>
            </button-icon-ui>
            <button-icon-ui @click="ganttZoomDefault">
              <distribute-spacing-horizontal-icon></distribute-spacing-horizontal-icon>
            </button-icon-ui>
            <button-icon-ui @click="ganttZoomOut">
              <zoom-out-icon></zoom-out-icon>
            </button-icon-ui>
            <button-icon-ui @click="ganttRecenterPosition">
              <today-icon></today-icon>
            </button-icon-ui>
          </div>
        </transition>

        <add-button v-if="showAdd" :show-title="showButtonTitle" @click="add"></add-button>
      </div>

      <add-route-point-modal ref="addModal" v-if="showAdd" :doc-id="doc_id"></add-route-point-modal>
    </template>

    <gantt ref="ganttBlock" :doc="doc" :tasks="tasks" :key="ganttKey" :rp_id="rp_id"></gantt>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import Gantt from '@/components/doc/gantt/GanttBlock';
import ZoomInIcon from '@/components/icons/ZoomInIcon';
import DistributeSpacingHorizontalIcon from '@/components/icons/DistributeSpacingHorizontalIcon';
import ZoomOutIcon from '@/components/icons/ZoomOutIcon';
import TodayIcon from '@/components/icons/TodayIcon';
import AddRoutePointModal from '@/components/modals/RoutePoint/AddRoutePointModal';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import previewBlock from '@/mixins/previewBlock';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';

export default defineComponent({
  name: 'GanttDocumentPreview',
  mixins: [previewBlock],
  components: {
    ButtonIconUi,
    AddButton,
    PreviewBlock,
    AddRoutePointModal,
    TodayIcon,
    ZoomOutIcon,
    DistributeSpacingHorizontalIcon,
    ZoomInIcon,
    Gantt,
  },
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tasks: {
      type: Array,
      required: true,
    },
    rp_id: {
      type: Number,
    },
    doc: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ganttKey() {
      return JSON.stringify(this.tasks);
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
    ganttZoomIn() {
      this.$refs.ganttBlock?.ganttZoomIn();
    },
    ganttZoomDefault() {
      this.$refs.ganttBlock?.ganttZoomDefault();
    },
    ganttZoomOut() {
      this.$refs.ganttBlock?.ganttZoomOut();
    },
    ganttRecenterPosition() {
      this.$refs.ganttBlock?.ganttRecenterPosition();
    },
  },
});
</script>

<style scoped lang="scss">
.header-postfix {
  display: flex;
}

.buttons {
  display: flex;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.button-icon-ui {
  &:not(:last-child) {
    margin-right: 4px;
  }
}
</style>
