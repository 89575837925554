<template>
    <div class="document-sheet--common">
      <div class="document-sheet--common--title">
        {{ doc['Тип'] }}
      </div>
      <div class="document-sheet--common--info mb-1">
        <div class="grey-text">Проект:</div>
        <div class="black-text-bold">{{ doc['Проект'] }}</div>
      </div>
      <div class="document-sheet--common--info mb-3">
        <div class="grey-text">Содержание:</div>
        <div class="black-text-bold" v-html="content"></div>
      </div>
      <div v-if="doc['Комментарий']" class="document-sheet--common--info mb-3">
        <div class="grey-text">Комментарий:</div>
        <div class="black-text">{{ doc['Комментарий'] }}</div>
      </div>
      <div class="document-sheet--common--info mb-3">
        <div class="grey-text">Регистратор:</div>
        <div class="black-text">{{ doc['Регистратор'] }}</div>
      </div>
      <div class="document-sheet--common--info mb-4">
        <div class="grey-text">Контролер:</div>
        <div class="black-text">{{ doc['Контролер'] }}</div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CommonDocumentPreview",
  props: ["doc"],
  created() {
    this.fetchMaskAction(this.doc['Содержание_MASK'])
  },
  updated() {
    this.fetchMaskAction(this.doc['Содержание_MASK'])
  },
  computed: {
    ...mapGetters(['maskStringGetter']),
    content() {
      return this.maskStringGetter(this.doc['Содержание_MASK'], this.doc['Содержание'])
    },
  },
  methods: {
    ...mapActions(['fetchMaskAction'])
  },
}
</script>