export const ADMINISTRATOR__ROLE = 'administrator';
export const CLERK__ROLE = 'clerk';
export const USER__ROLE = 'user';
export const SYNCHRONIZER__ROLE = 'synchronizer';
export const GENERAL_MANAGER__ROLE = 'general_manager';
export const MANAGER__ROLE = 'manager';

export const ROLES = {
    [ADMINISTRATOR__ROLE]: 11, // Администратор
    [CLERK__ROLE]: 21, // Делопроизводитель
    [USER__ROLE]: 22, // Пользователь
    [SYNCHRONIZER__ROLE]: 23, // Пользователь
    [GENERAL_MANAGER__ROLE]: 24, // Менеджер главный
    [MANAGER__ROLE]: 25, // Менеджер
}