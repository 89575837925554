<template>
  <div ref="layout" class="layout-wrapper" :class="classLayoutWrapper">
    <sidebar-general/>
    <mobile-menu/>

    <div class="layout-content">
      <toolbar-general ref="toolbar"/>

      <div ref="frames" class="layout-content-frame" :style="styleFrame">
        <div ref="frameLeft" class="layout-content-frame-left" :style="styleFrameL">
          <router-view :componentWidth="frameLeftWidth"/>
        </div>

        <div ref="frameRight" class="layout-content-frame-right" :style="styleFrameR">
          <transition name="fade">
            <div v-if="rightFrameDragOverFile" class="drag-over-file" :style="styleDragOverFile">
              <div>Перетащите сюда файл</div>
            </div>
          </transition>

          <div v-if="showEmptyPreview" class="document-not-selected">
            Выберите документ для просмотра
          </div>

          <component v-if="showPreview" :is="preview" :componentWidth="frameRightWidth"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {ref} from 'vue';
import {useElementBounding} from '@vueuse/core';
import Constants from '@/configs/constants';
import SidebarGeneral from '@/components/general/SidebarGeneral';
import MobileMenu from '@/components/general/MobileMenu';
import ToolbarGeneral from '@/components/general/ToolbarGeneral';
import DocumentPreview from '@/components/doc/preview/DocumentPreview';
import ClientPreview from '@/components/clients/ClientPreview';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {DOCUMENTS__EDIT} from '@/configs/events';
import Split from 'split.js';

export default defineComponent({
  name: 'ExtendedViewLayout',
  inject: [
    'app',
  ],
  components: {
    SidebarGeneral,
    MobileMenu,
    ToolbarGeneral,
    DocumentPreview,
    ClientPreview,
  },
  data: () => ({
    splitObject: null,
    isSlideMode: false,
    isPreviewActive: false,
    rightFrameDragOverFile: false,
    timerCounterId: null,
    documentLastIdPreview: null,
    timerCounterLimit: 1000 * 60,
    isScrollDisabled: false,
  }),
  provide() {
    const provider = {};
    const self = this;
    Object.defineProperty(provider, 'layout', {
      enumerable: true,
      get: () => self,
    });
    return provider;
  },
  setup() {
    const layout = ref(null);
    const {width: layoutWidth, height: layoutHeight} = useElementBounding(layout);

    const toolbar = ref(null);
    const {height: toolbarHeight} = useElementBounding(toolbar);

    const frameLeft = ref(null);
    const {width: frameLeftWidth, right: frameLeftRight, left: frameLeftLeft} = useElementBounding(frameLeft);

    const frameRight = ref(null);
    const {width: frameRightWidth} = useElementBounding(frameRight);

    return {
      layout,
      layoutWidth,
      layoutHeight,

      toolbar,
      toolbarHeight,

      frameLeft,
      frameLeftWidth,
      frameLeftRight,
      frameLeftLeft,

      frameRight,
      frameRightWidth,
    };
  },
  mounted() {
    this.$eventBus.$on('search-active-change', this.onSearchActiveChange);

    /* Counters */
    this.getCommissionsCountAction();
    this.getControlsCountAction();
    this.timerCounterId = setInterval(() => {
      this.getCommissionsCountAction();
      this.getControlsCountAction();
    }, this.timerCounterLimit);

    /* Drag&Drop files */
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable && this.accessToEdit) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
        document.querySelector('.layout-content-frame-right').addEventListener(evt, function (e) {
          e.preventDefault();
          e.stopPropagation();
          this.rightFrameDragOverFile = e.type === 'dragover' || e.type === 'dragenter';
        }.bind(this), false);
      }.bind(this));
      document.querySelector('.layout-content-frame-right').addEventListener('drop', function (e) {
        const files = [];
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          files.push(e.dataTransfer.files[i]);
        }
        this.$eventBus.$emit('document-preview-drop-file', files);
      }.bind(this));
    }

    /* Frames */
    this.initFrames();
  },
  beforeDestroy() {
    this.$eventBus.$off('search-active-change', this.onSearchActiveChange);
  },
  destroyed() {
    /* Counters */
    clearInterval(this.timerCounterId);

    /* Split mode */
    this.destroySplit();
  },
  computed: {
    ...mapGetters([
      'accessToEvent',
      'splitSizesGetter',
      'hasEntityIdPreviewGetter',
      'isActivePreviewGetter',
    ]),
    showEmptyPreview() {
      return this.frameRightWidth > 4 && !this.hasEntityIdPreviewGetter;
    },
    showPreview() {
      return this.frameRightWidth > 4 && this.hasEntityIdPreviewGetter;
    },
    preview() {
      return (this.$route.meta.preview || 'document') + '-preview';
    },
    accessToEdit() {
      return this.accessToEvent(DOCUMENTS__EDIT);
    },
    classLayoutWrapper() {
      return {
        '_overflow-hidden': this.isScrollDisabled,
        'layout-content-frame-left-active': this.isSlideMode && !this.isActivePreviewGetter,
        'layout-content-frame-right-active': this.isSlideMode && this.isActivePreviewGetter,
      };
    },
    styleFrame() {
      return {
        'height': this.layoutHeight + 'px',
        'margin-top': -(this.toolbarHeight) + 'px',
      };
    },
    styleFrameL() {
      return {
        'height': this.layoutHeight + 'px',
        'padding-top': this.toolbarHeight + 'px',
      };
    },
    styleFrameR() {
      return {
        'height': this.layoutHeight + 'px',
        'padding-top': this.toolbarHeight + 'px',
      };
    },
    styleDragOverFile() {
      return {
        'width': this.frameRightWidth + 'px',
      };
    },
  },
  methods: {
    ...mapMutations([
      'splitSizesMutation',
    ]),
    ...mapActions([
      'cleanAllStoresAction',
      'getCommissionsCountAction',
      'getControlsCountAction',
    ]),
    logOut() {
      this.cleanAllStoresAction();
      this.clearDocumentPreview();
    },
    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return 'draggable' in div || ('ondragstart' in div && 'ondrop' in div);
    },
    initFrames: function () {
      /* Инициализация слайдерного режима */
      if (this.app.appWidth <= Constants.resolutionPoints.small) {
        this.destroySplit();
        this.isSlideMode = true;
        /* Инициализация сплит режима */
      } else {
        this.initSplit();
        this.isSlideMode = false;
      }
    },
    initSplit: function () {
      if (this.splitObject) {
        return;
      }

      let _this = this;
      _this.splitObject = Split(['.layout-content-frame-left', '.layout-content-frame-right'], {
        sizes: this.splitSizesGetter,
        minSize: [350, 4],
        gutterSize: 20,
        gutterAlign: 'center',
        snapOffset: 20,
        direction: 'horizontal',
        cursor: 'col-resize',
        elementStyle: function (dimension, size, gutterSize) {
          return {
            'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)',
          };
        },
        gutterStyle: function (dimension, gutterSize) {
          return {
            'flex-basis': gutterSize + 'px',
          };
        },
        onDrag: () => {
          this.$eventBus.$emit('layout-resize');
        },
        onDragEnd: (sizes) => {
          this.$eventBus.$emit('layout-resize-end');
          this.splitSizesMutation(sizes);
        },
      });
    },
    destroySplit: function () {
      if (this.splitObject) {
        this.splitObject.destroy();
      }
      this.splitObject = null;
    },
    clearDocumentPreview: function () {
      this.$eventBus.$emit('document-preview-clear', false);
    },
    onSearchActiveChange(isOpen) {
      this.isScrollDisabled = isOpen;
    },
  },
  watch: {
    'app.appWidth'() {
      this.initFrames();
    },
  },
});
</script>
