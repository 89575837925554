<template>
  <div class="form--input-number">
    <label v-if="label" v-html="label"/>
    <div class="form--input-number--wrapper" :style="styleWrapper" :class="additionalClasses">
      <div v-if="!hideButtons" v-no-select @click.prevent="back" :class="{active: !disableBack}" class="input-number--button back">
        <minus-icon/>
      </div>
      <input
          v-model="modelProxy"
          type="number"
          @change="change"
          @input="input"
          class="ui-input"
          :disabled="disabled"
          :placeholder="placeholder"
          :readonly="readonly"
          :name="name"
          style="background: none"
          @keydown="checkNegativeInput($event)"
          @keypress.enter.prevent
          @focus="inputFocus(true); $emit('focus', $event)"
          @blur="inputFocus(false); $emit('blur', $event)"
      >
      <div v-if="!hideButtons" v-no-select @click.prevent="next" :class="{active: !disableNext}" class="input-number--button next">
        <plus-icon/>
      </div>
    </div>
    <span
        v-if="caption || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : caption }}
		</span>
  </div>

</template>

<script>
import MinusIcon from "@/components/icons/MinusIcon";
import PlusIcon from "@/components/icons/PlusIcon";

export default {
  name: 'NumberInputForm',
  components: {PlusIcon, MinusIcon},
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  data: () => ({
    modelProxy: undefined,
    focus: false,
  }),
  props: {
    /**
     * Значение поля
     * тип может быть любой
     * @type {any}
     */
    modelValue: {},
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    initValue: {
      type: Number,
      required: false,
    },
    // допускаются ли отрицательные
    allowNegative: {
      type: Boolean,
      default: true,
    },

    /**
     * Аттрибут [name]
     * @type {string}
     */
    name: {
      type: String,
      default: undefined,
    },
    /**
     * Длина
     * @type {string}
     */
    length: {
      type: Number,
      default: undefined
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Аттрибут [readonly]
     * @type {boolean}
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    returnString: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    /**
     * Аттрибут [placeholder]
     * @type {string}
     */
    placeholder: {
      type: String,
      default: ' ',
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: '',
    },
    classes: {
      type: Array,
      default: undefined
    },
  },
  created() {
    this.modelProxy = this.modelValue || this.initValue;
    if (!this.modelValue && this.initValue) {
      this.change();
    }
  },
  computed: {
    styleWrapper() {
      const styles = {};

      if (this.length && this.modelProxy) {
        const lengthData = String(this.modelProxy)?.length || 0;
        const percent = lengthData * 100 / this.length;
        const percentTwo = percent >= 95 ? 100 : percent + 5;

        styles['background'] = `linear-gradient(90deg, rgba(226, 231, 239, 1) 0%, rgba(226, 231, 239, 1) ${percent}%, rgba(243, 246, 251, 1) ${percentTwo}%, rgba(243, 246, 251, 1) 100%)`
      }

      return styles;
    },
    disableBack() {
      return Number.isInteger(this.modelProxy) && this.modelProxy - 1 < this.min;
    },
    disableNext() {
      return Number.isInteger(this.modelProxy) && this.modelProxy + 1 > this.max;
    },
    additionalClasses() {
      const res = [];

      if (this.error) res.push('error');
      if (this.focus) res.push('focus');
      if (this.hideButtons) res.push('hide-buttons');

      if (Array.isArray(this.classes))  {
        this.classes.forEach(c => {
          res.push(c);
        })
      }

      return res;
    },
  },

  methods: {
    inputFocus(focus) {
      this.focus = focus;
    },
    checkNegativeInput($event) {
      return !this.allowNegative && $event.key === '-' ? $event.preventDefault() : null;
    },
    back() {
      if (!this.disableBack && !this.disabled) {
        this.modelProxy = Number.isInteger(this.modelProxy) ? this.modelProxy - 1 : (this.min || 0);
        this.change();
      }

    },
    next() {
      if (!this.disableNext && !this.disabled) {
        this.modelProxy = Number.isInteger(this.modelProxy) ? this.modelProxy + 1 : (this.min || 0);
        this.change();
      }
    },
    input(event) {
      const inputValue = event.target.value;
      if (!/^\d*\.?\d*$/.test(inputValue)) {
        event.target.value = this.modelProxy;
      }

      if (this.min && this.modelProxy < this.min) {
        this.modelProxy = this.min;
      }
      if (this.max && this.modelProxy > this.max) {
        this.modelProxy = this.max;
      }
      if (this.length && String(this.modelProxy).length > this.length) {
        this.modelProxy = Number(String(this.modelProxy).slice(0, this.length)) || 0;
      }
    },
    change() {
      this.returnString
          ? this.$emit('change', String(this.modelProxy))
          : this.$emit('change', this.modelProxy);
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.modelProxy = newVal;
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>