<template>
  <div class="document-sheet--common pb-3">
    <div class="document-sheet--common--title">
      {{ client['Название'] }}
    </div>
    <div class="document-sheet--common--info mb-1">
      <div class="grey-text">Менеджер:</div>
      <div class="black-text">{{ client['Менеджер'] }}</div>
    </div>
    <div class="document-sheet--common--info mb-1">
      <div class="grey-text">Регион:</div>
      <div class="black-text">{{ client['Регион'] }}</div>
    </div>
    <div class="document-sheet--common--info mb-1">
      <div class="grey-text">Сегмент:</div>
      <div class="black-text">{{ client['Сегмент'] }}</div>
    </div>
    <div v-if="client['Примечание']" class="document-sheet--common--info mb-1">
      <div class="grey-text">Примечание:</div>
      <div class="black-text">{{ client['Примечание'] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonClientPreview",
  props: ['client']
}
</script>