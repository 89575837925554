<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'EmptyLayout',
    };
</script>