<template>
  <preview-block title="Комментарии" :amount="amount" :loading="loading" @resize="onResize">
    <template #header-postfix>
      <add-button v-if="showAdd" :show-title="showButtonTitle" @click="add"></add-button>
      <add-comment-modal ref="addModal" :parent-id="clientId" :parent-table-id="parentTableId"/>
    </template>

    <uploader-block-client-preview
      :end-point="endPoint"
      :client-id="clientId"
      :component="() => import('@/components/clients/rows/CommentRow')"
    >
      <template #list-is-empty>Список комментариев пуст</template>
    </uploader-block-client-preview>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import {CLIENTS_COMMENTS_GET} from '@/configs/endPoints';
import AddCommentModal from '@/components/modals/Comment/AddCommentModal';
import {CLIENTS_TABLE} from '@/configs/dbTables';
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import previewBlock from '@/mixins/previewBlock';

export default defineComponent({
  name: 'CommentsClientPreview',
  mixins: [previewBlock, clientsPreviewBlock],
  components: {
    AddButton,
    PreviewBlock,
    AddCommentModal,
    UploaderBlockClientPreview,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_COMMENTS_GET;
    },
  },
});
</script>
