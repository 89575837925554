<template>
  <button v-bind="$attrs"
          v-on="$listeners"
          class="show-more-button">
    Показать ещё
    <chevron-icon/>
  </button>
</template>

<script>
import ChevronIcon from "@/assets/svg/chevron.svg?component";

export default {
  name: "ShowMoreButton",
  components: {ChevronIcon}
}
</script>
