<template>
  <combobox-ui
    ref="combobox"
    :class="classes"
    :model-value="modelValue"
    :color="color"
    :options="getOptions || options"
    :label="label"
    :required="required"
    :placeholder="placeholder"
    :no-clear="noClear"
    :hint="!error ? hint : undefined"
    :error="error"
    :multiple="multiple"
    :disabled="disabled"
    :with-custom-option="withCustomOption"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @update:model-value="onUpdate"
  ></combobox-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import field from '@/mixins/form/field';
import {only} from '@/common/utils/props-validators';

/**
 * @deprecated Используйте ComboboxUi напрямую
 */
export default defineComponent({
  name: 'ComboboxLegacyUi',
  components: {ComboboxUi},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {},
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'dark-gray', 'white'),
    },
    options: {
      type: Array,
      default: () => [],
    },
    getOptions: Function,
    placeholder: String,
    codeOnlyValue: {
      type: Boolean,
      default: false,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    withCustomOption: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: undefined,
    },
  },
  emits: ['change', 'focus', 'blur'],
  mixins: [field],
  computed: {
    modelValue() {
      const options = this.getOptions ? this.$refs.combobox?.comboboxOptions : this.options;

      if (!this.value || !options) {
        return this.multiple ? [] : null;
      }

      if (!this.multiple) {
        return options.find(option => option.code === (this.codeOnlyValue ? this.value : this.value.code)) || null;
      }

      return this.value.filter(valueOption => options
        .some(option => option.code === (this.codeOnlyValue ? valueOption : valueOption.code)),
      );
    },
  },
  methods: {
    onUpdate(newValue) {
      if (!this.codeOnlyValue) {
        this.$emit('change', newValue);
        return;
      }

      if (this.multiple) {
        this.$emit('change', newValue.map(item => item.code));
      } else {
        this.$emit('change', newValue?.code || null);
      }
    },
  },
});
</script>
