<template>
  <component :is="tag" class="card-ui">
    <header class="header">
      <a class="title" :href="href" target="_blank" v-html="title"></a>

      <button-icon-ui :disabled="disabled" @click="$emit('remove')">
        <close-icon></close-icon>
      </button-icon-ui>
    </header>

    <component class="icon" :is="icon"></component>

    <combobox-legacy-ui
      code-only-value
      no-clear
      color="dark-gray"
      :options="options"
      :disabled="disabled"
      :value="option"
      @change="$emit('change', $event)"
    ></combobox-legacy-ui>
  </component>
</template>

<script>
import {defineComponent} from 'vue';
import CloseIcon from '@/assets/svg/close.svg?component';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import ComboboxLegacyUi from '@/components/ui/ComboboxLegacyUi.vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'CardUi',
  components: {
    ComboboxLegacyUi,
    CloseIcon,
    ButtonIconUi,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
    title: {
      type: String, // TODO: не использовать v-html
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: true, // TODO: default
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    option: {
      type: Number,
      required: true,
    },
  },
  emits: ['change', 'remove'],
});
</script>

<style scoped lang="scss">
.card-ui {
  padding: 4px;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: var(--color-gray-075);
}

.button-icon-ui {
  flex-shrink: 0;
}

.header {
  margin-bottom: 8px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  padding: 4px 0 0 4px;

  // TODO
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  align-self: center;
  margin-bottom: 8px;

  /* TODO: Перекрытие стилей */
  width: 60px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
