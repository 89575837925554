import {TYPE_SELECT, TYPE_INTEGER, TYPE_DATE, TYPE_TEXT, TYPE_SWITCH} from "@/configs/form";

export default (Vue) => ([
    [
        {
            type: TYPE_SWITCH,
            name: "responsibleFrom",
            options: [
                {
                    label: 'Сервер',
                    value: 'server',
                },
                {
                    label: 'Все',
                    value: 'all',
                },
            ],
        },
    ],
    [
        {
            type: TYPE_SELECT,
            name: "responsible",
            placeholder: "Исполнитель",
            options: () => Vue.optionsResponsible,
            required: true,
        },
    ],
    [
        {
            type: TYPE_SELECT,
            name: "rpt_id",
            placeholder: "Тип",
            options: () => Vue.typesOptions,
            required: true,
        },
    ],
    [
        {
            type: TYPE_SELECT,
            name: "links",
            hidden:  () => !Vue.showRunAfter,
            placeholder: "Выполнять после",
            options: () => Vue.selectedOptionsAfterRunRoutePointGetter,
            disabled: () =>  !Vue.selectedOptionsAfterRunRoutePointGetter.length,
            required: false,
            multiple: true,
        },
    ],
    [
        {
            type: TYPE_SWITCH,
            name: "termFrom",
            options: [
                {
                    label: 'Срок в днях',
                    value: 'days',
                },
                {
                    label: 'Дата',
                    value: 'end_date',
                },
            ],
            title: 'Срок',
            classes: ['mt-3']
        },
    ],
    [
        {
            type: TYPE_INTEGER,
            name: "days",
            min:1,
            max:360,
            initValue: 1,
            classes: ['w-50'],
            hidden: (data) => {
                return data.termFrom !== 'days'
            }
        },
        {
            type: TYPE_DATE,
            name: "end_date",
            classes: ['w-50'],
            copyHandler: () => Vue.copyEndDate,
            hidden: (data) => data.termFrom !== 'end_date',
        },
    ],
    [
        {
            type: TYPE_TEXT,
            name: 'content',
            label: 'Описание',
            placeholder: 'Добавьте описание',
            length: 100,
            required: true,
            slot: 'copy-content',
        }
    ],
    [
        {
            type: TYPE_TEXT,
            name: 'comment',
            label: 'Примечание',
            placeholder: 'Добавьте примечание',
            length: 250,
            slot: 'copy-comment',
        }
    ]
]);
