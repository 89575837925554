export default class Revision {
  constructor(value) {
    this.value = value;
    this.text = this.getText(value);
  }

  getText(value) {
    const ending = value % 10 === 3 && value % 100 !== 13 ? 'ья' : 'ая';
    return `${value}-${ending} редакция`;
  }
}
