<template>
  <div class="toolbar-wrapper" :class="{ 'toolbar-collapsed': isCollapsedState }" @click="onToolbarClick">
    <div class="d-flex align-items-center" v-if="showButtonMenu || showButtonBack">
      <div class="mr-1 ml-2 align-self-center">
        <button v-if="showButtonMenu" class="btn btn-mobile-menu" @click="mobileMenuToggle">
          <menu-bars-icon/>
        </button>

        <button v-if="showButtonBack" class="btn btn-mobile-menu" @click="setActivePreview(false)">
          <left-circle-icon style="flex-shrink: 0;"/>
        </button>
      </div>
    </div>

    <div class="toolbar-content">
      <div class="d-flex align-items-center justify-content-between">
        <div @click="scrollTop" class="page-title">
          {{ textHeader }}
        </div>

        <template v-if="showAdd">
          <add-button
            class="button-add m-down-hide"
            type="primary"
            :show-title="isLarge"
            @click="$refs.createDocModal.show()"
          >
            {{ addButtonTitle }}
          </add-button>

          <create-doc-modal ref="createDocModal"></create-doc-modal>
        </template>

        <reload-button v-if="$route.meta.table" @click="reloadTable"></reload-button>

        <component v-if="posibleShowInformer" :is="$route.meta.informer" view="informer"></component>
      </div>

      <toolbar-right :is-collapsed="isCollapsedState"/>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import ToolbarRight from '@/components/general/ToolbarRight';
import ReloadButton from '@/components/buttons/ReloadButton';
import MenuBarsIcon from '@/components/icons/MenuBarsIcon';
import LeftCircleIcon from '@/components/icons/LeftCircleIcon';
import {mapActions, mapGetters} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_ADD, DOC_ROUTE_POINT_ADD} from '@/configs/endPoints';
import {VCHT_ORG_INDENT} from '@/configs/orgIdents';
import CreateDocModal from '@/components/modals/Doc/CreateDocModal.vue';
import AddButton from '@/components/buttons/AddButton.vue';

export default {
  name: 'ToolbarGeneral',
  inject: [
    'app',
    'layout',
  ],
  components: {
    AddButton,
    CreateDocModal,
    ToolbarRight,
    ReloadButton,
    MenuBarsIcon,
    LeftCircleIcon,
  },
  computed: {
    ...mapGetters(['isActivePreviewGetter']),
    isSmall() {
      return this.app.appWidth < Constants.resolutionPoints.small;
    },
    isLarge() {
      return this.app.appWidth > Constants.resolutionPoints.large;
    },
    textHeader() {
      return this.isSmall ? this.$route.meta.titleSmall : this.$route.meta.title;
    },
    addButtonTitle() {
      return this.isSmall ? '' : 'Добавить';
    },
    showButtonBack() {
      return this.layout?.isSlideMode && this.isActivePreviewGetter;
    },
    showButtonMenu() {
      return this.layout?.isSlideMode && !this.showButtonBack;
    },
    posibleShowInformer() {
      return this.$route.meta.informer !== undefined
        && this.app.appWidth >= Constants.resolutionPoints.tinys;
    },
    isCollapsedState() {
      return !!this.layout?.isSlideMode || this.app.appHeight < Constants.resolutionPoints.tiny;
    },
    showAdd() {
      return ['controls'].includes(this.$route.name)
        && (process.env.VUE_APP_MODE === 'DEV' || this.$store.getters['auth/getOrgIdent'] === VCHT_ORG_INDENT);
    },
  },
  methods: {
    ...mapActions({
      refreshPreview: 'refreshPreviewAction',
      setActivePreview: 'setActivePreviewAction',
      docRoutePointAdd: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD),
      docAddAction: VuexAdapter.getNameAction(DOC_ADD),
    }),
    mobileMenuToggle() {
      this.$eventBus.$emit('mobile-menu-show');
    },
    scrollTop() {
      this.layout?.frameLeft.scrollTo({behavior: 'smooth', top: 0});
      this.layout?.frameRight.scrollTo({behavior: 'smooth', top: 0});
    },
    reloadTable() {
      this.$eventBus.$emit('table-start-reload-' + this.$route.meta?.table);
      this.refreshPreview();
    },
    onToolbarClick() {
      this.$eventBus.$emit('toolbar-click');
    },
  },
};
</script>

<style scoped lang="scss">
.button-add {
  margin-right: 8px;
}
</style>
