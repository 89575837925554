<template>
  <div>
    <span class="gitReleaseVersion" v-if="versionControlNumberGetter">
      Версия <strong>{{ versionControlNumberGetter }}</strong>
    </span>
    <span class="gitReleaseDate" v-if="versionControlReleaseDateGetter">
      Дата релиза: {{ versionControlReleaseDateGetter }}
    </span>
    <span class="gitReleaseCommitHash" v-if="versionControlHashGetter">
      {{ versionControlHashGetter }}
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'GitReleaseData',
  computed: {
    ...mapGetters([
      'versionControlNumberGetter',
      'versionControlReleaseDateGetter',
      'versionControlHashGetter',
    ]),
  },
};
</script>
