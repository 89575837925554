<template>
  <div class="performance-step-master">
    <div class="performance-step-master__items">
      <div v-for="performance in performanceShowed" :key="performance.model" class="performance-step-master__item">
        <div class="performance-step-master__item--icon">
          <loading-status-master-icon v-if="isStatus(performance.model, LOADING_MASTER_STEP_STATUS)"/>
          <stop-status-master-icon v-if="isStatus(performance.model, STOP_MASTER_STEP_STATUS)"/>
          <ready-status-master-icon v-if="isStatus(performance.model, READY_MASTER_STEP_STATUS)"/>
          <error-status-master-icon v-if="isStatus(performance.model, ERROR_MASTER_STEP_STATUS)"/>
        </div>

        <div class="performance-step-master__item--label">
          {{ performance.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import {
  ERROR_MASTER_STEP_STATUS,
  LOADING_MASTER_STEP_STATUS,
  READY_MASTER_STEP_STATUS,
  STOP_MASTER_STEP_STATUS,
} from '@/configs/master/masterStepLoadingStatus';
import LoadingStatusMasterIcon from '@/components/icons/master/statuses/LoadingStatusMasterIcon.vue';
import StopStatusMasterIcon from '@/components/icons/master/statuses/StopStatusMasterIcon.vue';
import ReadyStatusMasterIcon from '@/components/icons/master/statuses/ReadyStatusMasterIcon.vue';
import ErrorStatusMasterIcon from '@/components/icons/master/statuses/ErrorStatusMasterIcon.vue';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_ADD, DOC_FILE_MOVE, DOC_LINK_ADD, DOC_ROUTE_ADD, SYSTEM_COMMENT_ADD} from '@/configs/endPoints';
import {COPY_ROUTE, STANDARD_ROUTE} from '@/configs/routeTypes';
import {CLIENTS_TABLE, DOCS_TABLE} from '@/configs/dbTables';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {CALENDAR_DAYS_TERM_FROM_MASTER, DATE_TERM_FROM_MASTER} from '@/configs/master/termFromMaster';
import {fromObjectToArray} from '@/services/utilsFunctions';
import FileApiService from '@/services/api/file/file-api.service';

export default defineComponent({
  name: 'StepExecution',
  mixins: [step],
  components: {ErrorStatusMasterIcon, ReadyStatusMasterIcon, StopStatusMasterIcon, LoadingStatusMasterIcon},
  data() {
    return {
      ERROR_MASTER_STEP_STATUS,
      READY_MASTER_STEP_STATUS,
      STOP_MASTER_STEP_STATUS,
      LOADING_MASTER_STEP_STATUS,
      errors: [],
      stop: false,
      performance: [
        {
          label: 'Сохранение регистрационной карточки документа',
          model: 'doc',
          required: true,
          handler: async () => {

            let mask_params = null;

            if (this.formDataProxy.mask) {
              mask_params = fromObjectToArray(this.formDataProxy.parametersFormData);
            }

            const res = await this.addDoc({
              dt_id: this.formDataProxy.type.code,
              p_id: this.formDataProxy.p_id,
              controller_id: this.formDataProxy.controller_id,
              content: this.formDataProxy.content,
              comment: this.formDataProxy.comment,
              mask_params,
            });

            this.$set(this.formDataProxy, 'doc_id', res.data.doc_id);
            this.change();
          },
        },
        {
          label: 'Организация связей',
          model: 'links',
          handler: async () => {
            for (const document of this.formDataProxy.documents) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: DOCS_TABLE,
                parent_id: document.DOC_ID,
                lt_id: document.LT_ID,
              });
            }
            for (const client of this.formDataProxy.clients) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: CLIENTS_TABLE,
                parent_id: client.CLIENT_ID,
                lt_id: client.LT_ID,
              });
            }
          },
          hide: () => !this.formDataProxy.documents?.length && !this.formDataProxy.clients?.length,
        },
        {
          label: 'Создание маршрута для документа',
          model: 'temps',
          handler: async () => {
            const routePointsResponsibles = [];

            this.formDataProxy.routes_responsible_unspecified_steps.forEach((_, number) => {
              const days = this.formDataProxy['routes_responsible_description_term_' + number] === CALENDAR_DAYS_TERM_FROM_MASTER
                ? this.formDataProxy['routes_responsible_description_term_days' + number] : null;

              const end_date = this.formDataProxy['routes_responsible_description_term_' + number] === DATE_TERM_FROM_MASTER
                ? this.formDataProxy['routes_responsible_description_term_date' + number] : null;

              routePointsResponsibles.push({
                RESPONSIBLE_TABLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_TABLE_ID,
                RESPONSIBLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_ID,
                description: this.formDataProxy['routes_responsible_description_' + number],
                comment: this.formDataProxy['routes_responsible_description_' + number],
                days,
                end_date,
              });
            });

            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_doc_id,
              routePointsResponsibles,
            });
          },
          hide: () => this.formDataProxy.type_route !== STANDARD_ROUTE,
        },
        {
          label: 'Копирование маршрута из документа',
          model: 'copy_route',
          handler: async () => {
            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_copy_doc_id.code,
            });
          },
          hide: () => this.formDataProxy.type_route !== COPY_ROUTE,
        },
        {
          label: 'Добавление резолюций',
          model: 'resolution',
          handler: async () => {
            await this.addCommentsAction({
              parent_table_id: DOCS_TABLE,
              parent_id: this.formDataProxy.doc_id,
              comment: this.formDataProxy.resolution,
            });
          },
          hide: () => !this.formDataProxy.resolution?.length,
        },
        {
          label: 'Добавление файлов',
          model: 'files',
          handler: async () => {

            for (const file of this.formDataProxy.user_files) {
              await this.docFileMove({
                file_id: file.F_ID,
                doc_id: this.formDataProxy.doc_id,
              });
            }

            for (const file of this.formDataProxy.files) {
              if (file?.F_ID) {
                continue;
              }

              // TODO: Обработать ошибки
              await FileApiService.upload({
                parentTableId: DOCS_TABLE,
                parentId: this.formDataProxy.doc_id,
                file: {
                  name: file.name,
                  size: file.size,
                  content: file.base64,
                },
              });
            }
          },
          hide: () => !this.formDataProxy.files.length && !this.formDataProxy.user_files.length,
        },
        {
          label: 'Создание маршрута для документа',
          model: 'routes',
          handler: async () => {

          },
          hide: () => true,
        },
      ],
    };
  },
  async created() {
    if (!this.formDataProxy.created) {
      for (const step of this.performanceShowed) {
        this.setStepStatus(step.model, LOADING_MASTER_STEP_STATUS);
      }
      this.togglePaging(false);

      await this.load();

      this.togglePaging(true);

      if (!this.stop) {
        this.setCreatedDoc();
      }
    }
  },
  computed: {
    performanceShowed() {
      return this.performance.filter(p => !p?.hide || !p.hide());
    },
    isStatus() {
      return (model, status) => {
        return this.formDataProxy.loadingStatuses[model] === status;
      };
    },
  },
  methods: {
    ...mapActions({
      addDoc: VuexAdapter.getNameAction(DOC_ADD),
      addStandardRoute: VuexAdapter.getNameAction(DOC_ROUTE_ADD),
      addCommentsAction: VuexAdapter.getNameAction(SYSTEM_COMMENT_ADD),
      addDocLink: VuexAdapter.getNameAction(DOC_LINK_ADD),
      docFileMove: VuexAdapter.getNameAction(DOC_FILE_MOVE),
    }),
    async load() {

      this.errors = [];

      for (const step of this.performanceShowed) {
        if (this.stop) {
          this.setStepStatus(step.model, STOP_MASTER_STEP_STATUS);

          continue;
        }
        try {
          await step.handler();

          this.setStepStatus(step.model, READY_MASTER_STEP_STATUS);

        } catch (e) {
          console.error(e);
          this.errors.push(e.error_message || 'Ошибка в шаге: ' + step.label);

          this.setStepStatus(step.model, ERROR_MASTER_STEP_STATUS);

          if (step?.required) {
            this.stop = true;
          }
        }
      }

      if (this.errors.length) {
        this.$notify({
          title: 'Ошибки при создании документа',
          type: ERROR_NOTIFY_TYPE,
          text: this.errors.join('<br>'),
        });
      }
    },
    setStepStatus(model, status) {
      this.$set(this.formDataProxy.loadingStatuses, model, status);
      this.change();
    },
    setCreatedDoc() {
      this.$set(this.formDataProxy, 'created', true);
      this.change();
    },
  },
});
</script>
