export default {
  Type: 0,
  Content: 1,
  Deadline: 2,
  Barcode: 3,
  Resolutions: 4,
  Files: 5,
  Links: 6,
  Counterparties: 7,
  Route: 8,
  Contractor: 9,
  Execution: 10,
  Launch: 11,
}
