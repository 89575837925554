<template>
  <div class="file-icon-info"
       data-tooltip-position="bottom-left"
       data-tooltip-size="auto"
       data-tooltip-text-align="left"
       role="tooltip">
    <note-file-icon :fill="fill"/>
    <div class="tooltip-aria-label">
      {{ note }}
    </div>
  </div>
</template>

<script>
import NoteFileIcon from "@/components/icons/NoteFileIcon.vue";

export default {
  name: "FileNote",
  components: {NoteFileIcon},
  props: {
    note: {
      type: String,
      required: true,
    },
    sub: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    fill() {
      return this.sub ? 'grey' : '#13A86E';
    },
  }
}
</script>
