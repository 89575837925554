import Constants from "@/configs/tables/constants";
import VuexAdapter from "@/services/vuexAdapter";
import {CATEGORIES_GET, CLIENTS_GET, CLIENTS_TYPES_GET, MANAGERS_GET, REGIONS_GET, SEGMENTS_GET} from "@/configs/endPoints";
import {CLIENTS_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: CLIENTS_TABLE_NAME,
    title: 'Список контрагентов',
    action: VuexAdapter.getNameAction(CLIENTS_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(CLIENTS_GET),
    getter: VuexAdapter.getNameGetter(CLIENTS_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(CLIENTS_GET),
    actionClearStore: VuexAdapter.getNameClearAction(CLIENTS_GET),
    actionAbort: VuexAdapter.getNameAbortAction(CLIENTS_GET),
    getterError: VuexAdapter.getNameErrorGetter(CLIENTS_GET),
    generalField: 'CLIENT_ID',
    previewField: 'CLIENT_ID',
    searchSmallSelectedField: 'Название',
    smallGroped: () => import('@/components/table/grouped/ClientGrouped'),
    limit: 20,
    info: {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
            order: 0,
        },
        'CLIENT_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            component: () => import('@/components/table/columns/EyeClients'),
            classes: {},
            description: 'ID контрагента',
            order: 0,
            conditions: 3,
        },
        'PARENTS': {
            visible: false,
            system: true,
            classes: {},
            order: 2,
            type: Constants.string,
            description: 'Строка ID родителей экранированных # (последний код всегда равен ID точки маршрута)',
        },
        'TYPE_ID': {
            visible: true,
            title: 'Тип',
            type: Constants.asyncIdentifier,
            searchSmallFieldDictionary: true,
            optionAction: VuexAdapter.getNameAction(CLIENTS_TYPES_GET),
            optionGetter: VuexAdapter.getNameSelectGetter(CLIENTS_TYPES_GET),
            conditions: 1,
            classes: {},
            description: 'ID типа контрагента',
            fieldKeyValue: 'Тип',
            optionKeyValue: false,
            order: 7,
        },
        'Штрих': {
            visible: true,
            title: 'Штрих код',
            searchSmallField: true,
            classes: {},
            type: Constants.string,
            description: 'Штрих код карточки контрагента',
            order: 4,
            conditions: 3,
        },
        'Родитель': {
            visible: true,
            title: 'Родитель',
            searchSmallField: true,
            classes: {},
            type: Constants.string,
            description: 'Наименование контрагента, которому подчинена текущая запись',
            order: 5,
            conditions: 3,
        },
        'Номер': {
            visible: true,
            title: 'Номер',
            classes: {},
            type: Constants.integer,
            description: 'Номер контрагента, равен его ID',
            order: 6,
            conditions: 3,
        },
        'Тип': {
            visible: false,
            system: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип контрагента',
            order: 3,
            conditions: 3,
        },
        'Название': {
            visible: true,
            searchSmallField: true,
            title: 'Название',
            classes: {},
            type: Constants.string,
            description: 'Название контрагента',
            order: 8,
            conditions: 3,
        },
        'Регион': {
            visible: true,
            searchSmallFieldDictionary: true,
            title: 'Регион',
            classes: {},
            description: 'Регион контрагента',
            type: Constants.asyncIdentifier,
            optionAction: VuexAdapter.getNameAction(REGIONS_GET),
            optionGetter: VuexAdapter.getNameSelectGetter(REGIONS_GET),
            conditions: 1,
            fieldKeyValue: 'Регион',
            optionKeyValue: true,
            order: 9,
        },
        'Сегмент': {
            visible: true,
            searchSmallFieldDictionary: true,
            title: 'Сегмент',
            classes: {},
            type: Constants.asyncIdentifier,
            optionAction: VuexAdapter.getNameAction(SEGMENTS_GET),
            optionGetter: VuexAdapter.getNameSelectGetter(SEGMENTS_GET),
            conditions: 1,
            fieldKeyValue: 'Сегмент',
            optionKeyValue: true,
            description: 'Сегмент контрагента',
            order: 10,
        },
        'Приоритет': {
            visible: true,
            searchSmallFieldDictionary: true,
            title: 'Приоритет',
            classes: {},
            type: Constants.asyncIdentifier,
            optionAction: VuexAdapter.getNameAction(CATEGORIES_GET),
            optionGetter: VuexAdapter.getNameSelectGetter(CATEGORIES_GET),
            conditions: 1,
            fieldKeyValue: 'Приоритет',
            optionKeyValue: true,
            description: 'Приоритет контрагента',
            order: 11,

        },
        'Скидка': {
            visible: true,
            title: 'Скидка',
            classes: {},
            type: Constants.integer,
            description: 'Скидка контрагента',
            order: 12,
            conditions: 3,
        },
        'Менеджер': {
            visible: true,
            searchSmallField: true,
            title: 'Менеджер',
            classes: {},
            type: Constants.asyncIdentifier,
            optionAction: VuexAdapter.getNameAction(MANAGERS_GET),
            optionGetter: VuexAdapter.getNameSelectGetter(MANAGERS_GET),
            conditions: 1,
            fieldKeyValue: 'Менеджер',
            optionKeyValue: true,
            description: 'Менеджер контрагента',
            order: 13,
        },
        'Город': {
            visible: true,
            searchSmallField: true,
            title: 'Город',
            classes: {},
            type: Constants.string,
            description: 'Город контрагента',
            order: 14,
            conditions: 3,
        },
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание контрагента',
            order: 15,
            conditions: 3,
        },
        'ПФ': {
            visible: true,
            searchSmallField: true,
            title: 'ПФ',
            classes: {},
            type: Constants.string,
            description: 'Правовая форма контрагента',
            order: 16,
            conditions: 3,
        },
        'ИНН': {
            visible: true,
            searchSmallField: true,
            title: 'ИНН',
            classes: {},
            type: Constants.string,
            description: 'ИНН контрагента',
            order: 17,
            conditions: 3,
        },
        'ОГРН': {
            visible: true,
            searchSmallField: true,
            title: 'ОГРН',
            classes: {},
            type: Constants.string,
            description: 'ОГРН контрагента',
            order: 18,
            conditions: 3,
        },
        'КПП': {
            visible: true,
            searchSmallField: true,
            title: 'КПП',
            classes: {},
            type: Constants.string,
            description: 'КПП контрагента',
            order: 19,
            conditions: 3,
        },
        'ОКАТО': {
            visible: true,
            searchSmallField: true,
            title: 'ОКАТО',
            classes: {},
            type: Constants.string,
            description: 'ОКАТО контрагента',
            order: 20,
            conditions: 3,
        },
        'ОКПО': {
            visible: true,
            searchSmallField: true,
            title: 'ОКПО',
            classes: {},
            type: Constants.string,
            description: 'ОКПО контрагента',
            order: 21,
            conditions: 3,
        },
        'Должность': {
            visible: true,
            searchSmallField: true,
            title: 'Должность',
            classes: {},
            type: Constants.string,
            description: 'Должность контрагента',
            order: 22,
            conditions: 3,
        },
        'ДУЛ': {
            visible: true,
            searchSmallField: true,
            title: 'ДУЛ',
            classes: {},
            type: Constants.string,
            description: 'Документ удостоверяющий личность контрагента',
            order: 23,
            conditions: 3,
        },
        'Создан': {
            visible: true,
            title: 'Создан',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата создания контрагента',
            order: 24,
            conditions: 3,
        },
    }
}