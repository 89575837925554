<template>
  <div class="file-pdf-preview">
    <loader-ui v-if="status === LOADING_STATUS" position="static" color="white" text="Загрузка данных"></loader-ui>

    <file-error v-else-if="status === ERROR_STATUS" @retry="load"></file-error>

    <iframe v-else-if="status === READY_STATUS" class="iframe" :src="pdfUrl" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
// TODO: Рефакторинг
import {defineComponent} from 'vue';
import {mapState} from 'vuex';
import axios from 'axios';
import FileError from '@/components/doc/files/FileError.vue';
import FileApiService from '@/services/api/file/file-api.service';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import abort from '@/mixins/abort';

const LOADING_STATUS = 1;
const ERROR_STATUS = 2;
const READY_STATUS = 3;

const INTERVAL = 4000;
const INTERVAL_MAX_COUNT = 20;

export default defineComponent({
  name: 'FilePdfPreview',
  mixins: [abort],
  components: {
    LoaderUi,
    FileError,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      interval: null,
      iframeContentDocument: null,
      intervalCount: 0,
      status: LOADING_STATUS,
      LOADING_STATUS,
      ERROR_STATUS,
      READY_STATUS,
    };
  },
  async created() {
    this.load();
  },
  computed: {
    ...mapState('server', ['storageUrl']),
    pdfUrl() {
      return `${this.storageUrl}${this.file['Файл']}.pdf#toolbar=0&scrollbar=1`;
    },
  },
  methods: {
    load() {
      this.status = LOADING_STATUS;
      this.intervalCount = 0;

      axios.get(this.pdfUrl, {
        signal: this.abortController.signal,
      })
        .then(() => {
          this.status = READY_STATUS;
        }).catch(() => {
        FileApiService.addView(this.file['F_ID'], undefined, false)
          .then(() => {
            this.interval = setInterval(() => {
              this.intervalCount++;
              axios.get(this.pdfUrl, {
                signal: this.abortController.signal,
              })
                .then(() => {
                  this.status = READY_STATUS;
                }).catch(() => {
                if (this.intervalCount >= INTERVAL_MAX_COUNT) {
                  this.status = ERROR_STATUS;
                  clearInterval(this.interval);
                }
              });
            }, INTERVAL);
          })
          .catch(() => {
            this.status = ERROR_STATUS;
          });
      });
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
});
</script>

<style scoped lang="scss">
.iframe {
  border: none;
}
</style>
