<template>
  <div v-show="show" class="d-flex justify-content-end align-self-center flex-wrap w-100">
    <solution-route-point-modal ref="solutionRoutePointModal" :configs="solutionConfig"/>
    <button v-for="button in buttons" :key.prop="button"
            type="button"
            class="btn ml-1 mt-2 text-nowrap"
            v-on:click="applyRoutePointSolution(button.key)"
            :class="[button.class_button]">
      <component :is="button.icon"></component>
      {{ button.name_button }}
    </button>
  </div>
</template>

<script>
import routePointCommissionsSolution from "@/mixins/routePointCommissionsSolution";
import ConstantsRPS from '@/configs/routePointStatuses';
import {mapGetters} from "vuex";
import VuexAdapter from "@/services/vuexAdapter";
import SolutionRoutePointModal from "@/components/modals/RoutePoint/SolutionRoutePointModal";

export default {
  name: "SolutionCommissionsButtons",
  components: {SolutionRoutePointModal},
  mixins: [routePointCommissionsSolution],
  props: {
    doc: Object,
  },
  data: () => ({
    excluded_statuses: [
      ConstantsRPS.rp_status_type_completed_id,
      ConstantsRPS.rp_status_type_approved_id,
      ConstantsRPS.rp_status_type_rejected_id,
    ]
  }),
  computed: {
    ...mapGetters({
      activeRow: VuexAdapter.activeRowTableNameGetter('commissions'),
    }),
    show() {
      return Object.keys(this.buttons).length;
    },
    rp_id_solution() {
      return this.activeRow['RP_ID'];
    },
    doc_id_solution() {
      return this.activeRow['DOC_ID'];
    },
    buttons() {
      const buttons = {};
      if (this.activeRow['RP_ID'] && this.activeRow['DOC_ID'] === this.doc['DOC_ID']) {
        for (let key in this.route_point_solution) {
          if (this.route_point_solution[key]['routePointType'] === this.activeRow['RPT_ID']) {
            if (this.activeRow['RPT_ID'] === this.route_point_types.assignment &&
                this.activeRow['RPS_ID'] === ConstantsRPS.rp_status_type_completed_id) {
              continue;
            }
            buttons[key] = {
              icon: this.route_point_solution[key]['icon'],
              name_button: this.route_point_solution[key]['name_button'],
              class_button: this.route_point_solution[key]['class_button'],
              key
            };
          }
        }
      }

      return buttons;
    },
  },
  created() {
    this.events_solution.push('table-start-reload-commissions');
  },
}
</script>