<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33366 5.33301C6.70185 5.33301 7.00033 5.63148 7.00033 5.99967V9.99967C7.00033 10.3679 6.70185 10.6663 6.33366 10.6663C5.96547 10.6663 5.66699 10.3679 5.66699 9.99967V5.99967C5.66699 5.63148 5.96547 5.33301 6.33366 5.33301ZM9.66699 5.33301C10.0352 5.33301 10.3337 5.63148 10.3337 5.99967V9.99967C10.3337 10.3679 10.0352 10.6663 9.66699 10.6663C9.2988 10.6663 9.00033 10.3679 9.00033 9.99967V5.99967C9.00033 5.63148 9.2988 5.33301 9.66699 5.33301Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 2.00033C4.68662 2.00033 2.00033 4.68662 2.00033 8.00033C2.00033 11.314 4.68662 14.0003 8.00033 14.0003C8.36852 14.0003 8.66699 14.2988 8.66699 14.667C8.66699 15.0352 8.36852 15.3337 8.00033 15.3337C3.95024 15.3337 0.666992 12.0504 0.666992 8.00033C0.666992 3.95024 3.95024 0.666992 8.00033 0.666992C8.36852 0.666992 8.66699 0.965469 8.66699 1.33366C8.66699 1.70185 8.36852 2.00033 8.00033 2.00033Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 2.00033C11.3134 2.00033 13.9997 4.68662 13.9997 8.00033C13.9997 11.314 11.3134 14.0003 7.99967 14.0003C7.63148 14.0003 7.33301 14.2988 7.33301 14.667C7.33301 15.0352 7.63148 15.3337 7.99967 15.3337C12.0498 15.3337 15.333 12.0504 15.333 8.00033C15.333 3.95024 12.0498 0.666992 7.99967 0.666992C7.63148 0.666992 7.33301 0.965469 7.33301 1.33366C7.33301 1.70185 7.63148 2.00033 7.99967 2.00033Z"/>
  </svg>

</template>

<script>
export default {
  name: "PauseIcon"
}
</script>