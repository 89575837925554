<template>
  <div class="sign--table--body">
    <row-table-sign v-for="file in filesForSignGetter" :key="file.file_id" :file="file"></row-table-sign>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import RowTableSign from '@/components/sign/RowTableSign';

export default defineComponent({
  name: 'BodyTableSign',
  components: {RowTableSign},
  computed: {
    ...mapGetters(['filesForSignGetter']),
  },
});
</script>
