<template>
    <div id="dropzone-file" :class="{'drop-file': dragOverFile}">
      <div v-if="dragOverFile">
        Бросайте файлы сюда
      </div>
      <div class="upload-file-list" v-else-if="files.length">
        <file-upload-block v-for="(file, key) in this.files"
                           v-bind:key="key"
                           @delete="delFile(key)"
                           @loaded="loaded(key)"
                           @hasErrorFilesUploaded="$emit('hasErrorFilesUploaded')"
                           :doc_id="doc_id"
                           :comment="comment"
                           :is-last="isLastFile(key)"
                           :file="file"/>
      </div>
      <div class="files-empty" v-else>
        <div class="btn-upload"><upload-icon/>Загрузить файлы</div>
        <div>Выберите и загрузите файл с компьютера</div>
        <div>или перетащите в это поле</div>
      </div>
      <input type="file" id="dropzoneFiles" multiple title="" @change="addFile()"/>
    </div>
</template>

<script>
import FileUploadBlock from "@/components/doc/files/FileUploadBlock";
import UploadIcon from "@/components/icons/UploadIcon";
export default {
  name: "FileDropZone",
  props: ['files_pre', 'doc_id', 'comment'],
  components: {UploadIcon, FileUploadBlock},
  data: function () {
    return {
      files: [],
      dragOverFile: false,
    }
  },

  mounted() {
    if (this.files_pre !== undefined && this.files_pre.length) {
      this.addFiles(this.files_pre);
    }

    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if( this.dragAndDropCapable ){
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
        document.querySelector('#dropzone-file').addEventListener(evt, function(e){
          e.preventDefault();
          e.stopPropagation();
          this.dragOverFile = e.type == 'dragover' || e.type == 'dragenter';
        }.bind(this), false);
      }.bind(this));
      document.querySelector('#dropzone-file').addEventListener('drop', function(e){
        if (e.dataTransfer.files.length) {
          this.addFiles(e.dataTransfer.files)
        }
      }.bind(this));
    }
  },
  methods: {
    isLastFile(key) {
      return key === (this.files.length - 1);
    },
    determineDragAndDropCapable(){
      let div = document.createElement('div');
      return 'draggable' in div || ( 'ondragstart' in div && 'ondrop' in div )
    },
    upload() {
      this.$emit('upload');

      if (!this.files.length) {
        setTimeout(() => this.$emit('close'), 100);
      }
    },
    delFile(key) {
      this.$delete(this.files, key);
    },
    cleanFileInput() {
      document.getElementById('dropzoneFiles').value = '';
    },
    addFile() {
      const files = document.getElementById('dropzoneFiles').files;
      this.addFiles(files)
    },
    loaded(key) {
      this.$emit('loaded')
      this.$set(this.files[key], 'loaded', true);
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].error !== undefined && this.files[i].error) {
          this.$emit('hasError');
        }
        if (this.files[i].loaded == undefined) {
          return;
        }
      }

      setTimeout(() => this.$emit('close'), 600);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    addFiles(files) {
      files.forEach((file) => {
        for (let key in this.files) {
          if (this.files[key].name === file.name) {
            return;
          }
        }
        if (!parseFloat(file.size)) {
            this.$emit('hasErrorFilesUploaded', `Пустое содержимое файла "${file.name}"`);
            return;
        }
        let i = this.files.push({
          name: file.name,
          size: file.size,
          base64: null,
        });
        this.toBase64(file).then((base64) => {
          this.$set(this.files[i - 1], 'base64', base64.split('base64,')[1]);
        });
      });
      this.cleanFileInput();
    },
  }
}
</script>