<template>
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2.50016C5.8579 2.50016 2.50004 5.85803 2.50004 10.0002C2.50004 14.1423 5.8579 17.5002 10 17.5002C10.4603 17.5002 10.8334 17.8733 10.8334 18.3335C10.8334 18.7937 10.4603 19.1668 10 19.1668C4.93743 19.1668 0.833374 15.0628 0.833374 10.0002C0.833374 4.93755 4.93743 0.833496 10 0.833496C10.4603 0.833496 10.8334 1.20659 10.8334 1.66683C10.8334 2.12707 10.4603 2.50016 10 2.50016Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99996 2.50016C14.1421 2.50016 17.5 5.85803 17.5 10.0002C17.5 14.1423 14.1421 17.5002 9.99996 17.5002C9.53972 17.5002 9.16663 17.8733 9.16663 18.3335C9.16663 18.7937 9.53972 19.1668 9.99996 19.1668C15.0626 19.1668 19.1666 15.0628 19.1666 10.0002C19.1666 4.93755 15.0626 0.833496 9.99996 0.833496C9.53972 0.833496 9.16663 1.20659 9.16663 1.66683C9.16663 2.12707 9.53972 2.50016 9.99996 2.50016Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9107 6.91058C7.23614 6.58514 7.76378 6.58514 8.08921 6.91058L13.0892 11.9106C13.4147 12.236 13.4147 12.7637 13.0892 13.0891C12.7638 13.4145 12.2361 13.4145 11.9107 13.0891L6.9107 8.08909C6.58527 7.76366 6.58527 7.23602 6.9107 6.91058Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0892 6.91058C13.4147 7.23602 13.4147 7.76366 13.0892 8.08909L8.08921 13.0891C7.76378 13.4145 7.23614 13.4145 6.9107 13.0891C6.58527 12.7637 6.58527 12.236 6.9107 11.9106L11.9107 6.91058C12.2361 6.58514 12.7638 6.58514 13.0892 6.91058Z"/>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon"
}
</script>
