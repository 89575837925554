export default {
    resolutionPoints: {
        'large': 1200,
        'medium': 980,
        'small': 770,
        'tiny': 480,
        'tinys': 400,
        'baby': 320,
    },
    tableIndexPoints: {
        'md': 800,
        'sm': 480,
    },
};