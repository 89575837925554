import {defineComponent} from 'vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:model-value', 'show-loader', 'hide-loader', 'toggle-paging', 'back'],
  data() {
    return {
      formDataProxy: this.modelValue,
      isLoading: false,
    };
  },
  methods: {
    validate() {
      if (this.isLoading) {
        return false;
      }

      if (this.$refs?.formMask && this.$refs?.formMask?.validate) {
        return this.$refs.formMask.validate();
      }

      if (this.$refs?.form && this.$refs?.form?.validate) {
        return this.$refs.form.validate();
      }

      return true;
    },
    change() {
      this.$emit('update:model-value', this.formDataProxy);
    },
    showLoader() {
      this.$emit('show-loader');
    },
    hideLoader() {
      this.$emit('hide-loader');
    },
    togglePaging(value) {
      this.$emit('toggle-paging', value);
    },
    back() {
      this.$emit('back');
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.formDataProxy = value;
      },
      deep: true,
      immediate: true,
    },
  },
});
