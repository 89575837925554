import HttpService from '@/services/api/http.service';
import axios from 'axios';
import Vue from "vue";
import {DOC_FILES_GET, DOC_SIGNATURES_FILES_GET, SYSTEM_FILE_GET, SYSTEM_FILE_SIGN_MULTIPLE} from '@/configs/endPoints';
import FileApiService from '@/services/api/file/file-api.service';

const crc_32 = require('js-crc').crc32;
const {Base64} = require('js-base64');

export default {
  actions: {
    /* Получение списка фалов для подписания */
    async getFilesForSignAction(ctx, data) {
      return await HttpService.post(DOC_FILES_GET, data, ctx.getters.filesForSignConfigAxiosGetter).then(response => {
        const length = response.items.length;

        for (let i = 0; i < length; i++) {
          const file = response.items[i];
          file.sign = null;
          file.active = false;
          file.base64 = null;

          ctx.commit('filesForSignMutations', file);
          ctx.dispatch('getFileForSignCreateHashAction', file)
        }
      });
    },

    async applyFilesForSignAction(ctx, doc_id) {
      const files = ctx.getters.filesForSignSelectedGetter;

      if (!files.length) {
        return;
      }

      const items = [];
      files.forEach(file => {
        items.push({
          "f_id": file.F_ID,
          "sign_content": file.base64,
          "sign_type": "CRC32"
        });
      });

      const response = await HttpService.post(SYSTEM_FILE_SIGN_MULTIPLE, {items}, ctx.getters.filesForSignConfigAxiosGetter);

      const file_ids = response.file_ids;

      const where = `F_ID in (${file_ids.join(',')})`;

      const limit = 40;

      let offset = 40;

      let i = 0;

      let lastCount = 0;

      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (offset < i) {
            clearInterval(interval);

            reject('Ошибка подписания документов');
          }

          i++;

          HttpService.post(DOC_SIGNATURES_FILES_GET, {
            doc_id,
            where,
          }, ctx.getters.filesForSignConfigAxiosGetter).then(response => {
            if (lastCount !== response.count) {
              lastCount = response.count;
              offset += limit;
            }
            if (response.count === file_ids.length) {
              clearInterval(interval);
              resolve()
            }
          });

        }, 1000);
      });
    },

    async getFileForSignCreateHashAction(ctx, file) {
      const limit = 30;

      let i = 0;
      let addPreview = false;

      const getFile = () => {
        HttpService.post(SYSTEM_FILE_GET, {
          file_id: file.F_ID,
        }, ctx.getters.filesForSignConfigAxiosGetter).then(response => {

          const data = Base64.toUint8Array(response.file_base64);
          const crc32code = crc_32(data);
          const base64 = Base64.encode(crc32code);

          ctx.commit('filesForSignSetHashMutations', {
            file_id: file['F_ID'],
            base64
          });

        }).catch((e) => {
          i++;
          if (!addPreview && !axios.isCancel(e)) {
            FileApiService.addView(file['F_ID'], undefined, false);
            addPreview = true;
          }

          if (limit < i) {
            return;
          }

          setTimeout(getFile, 2000);
        });
      };

      getFile();
    },

    clearSignBlockAction(ctx) {
      if (ctx.state.filesForSignAbortController) {
        ctx.state.filesForSignAbortController.abort();
      }

      ctx.commit('clearSignBlockMutation');
    },
  },
  mutations: {
    clearSignBlockMutation(state) {
      state.filesForSignLoading = false;
      state.filesForSign = [];
      state.filesForSignAbortController = null;
    },
    filesForSignMutations(state, file) {
      state.filesForSign.push(file)
    },
    filesForSignSetHashMutations(state, {file_id, base64}) {
      state.filesForSign.forEach(file => {
        if (file['F_ID'] === file_id) {
          Vue.set(file, 'base64', base64);
        }
      })
    },
    filesForSinSetActive(state, bool) {
      state.filesForSign.forEach(file => {
        if (file.base64) {
          Vue.set(file, 'active', bool);
        }
      })
    },
  },
  state: {
    filesForSign: [],
    filesForSignAbortController: null,
  },
  getters: {
    filesForSignGetter(state) {
      return state.filesForSign;
    },
    filesForSignHasRemarkGetter(state) {
      return state.filesForSign.some(file => !!file['Примечание']);
    },
    filesForSignLengthGetter(state) {
      return state.filesForSign.length;
    },
    filesForSignAllLoadGetter(state) {
      return state.filesForSign.length === state.filesForSign.filter(file => file?.base64).length;
    },
    filesForSignConfigAxiosGetter(state) {
      Vue.set(state, 'filesForSignAbortController', new AbortController());
      return state.filesForSignAbortController.signal;
    },
    filesForSignSelectedGetter(state) {
      return state.filesForSign.filter(file => file.active);
    },
    hasFilesForSignSelectedGetter(state) {
      return state.filesForSign.filter(file => file.active).length > 0;
    },
  },
}
