<template>
  <div class="document-sheet--header d-flex w-100">
    <div class="mr-4">
      <img src="/img/logo-icon.svg?v2" alt="АЛЬФА ДОК" class="document-sheet-logo"/>
    </div>
    <div class="d-flex flex-wrap justify-content-between w-100">
      <div class="d-flex align-items-center">
        <div class="grey-text">№</div>
        <a :href="`/client/${client['CLIENT_ID']}`" target="_blank" class="d-block ml-2 black-text">{{ numberClient }}</a>
        <div class="ml-3 grey-text">создан</div>
        <div class="ml-2 black-text">{{ createDate }}</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="grey-text">Приоритет:</div>
        <div class="ml-2 black-text">{{ client['Приоритет'] }}</div>
        <div class="ml-2 grey-text">Тип:</div>
        <div class="ml-2 black-text">{{ client['Тип'] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDate} from "@/services/utilsFunctions";

export default {
  name: "HeaderClientPreview",
  props: ['client'],
  computed: {
    numberClient() {
      return this.client['Номер'] && this.client['Номер'] !== '' ? this.client['Номер'] : '-'
    },
    createDate() {
      return this.getDate(this.client['Создан'])
    }
  },
  methods: {
    getDate,
  }
}
</script>