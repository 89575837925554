<template>
    <div class="table-sheet-loader">
        <div class="text-center">
            <div class="spinner-alpha-container m-3" style="width: 15%;">
                <i class="spinner-alpha"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TableSheetLoader',
    }
</script>