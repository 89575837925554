<template>
    <thead>
    <tr>
        <td colspan="1000"
            class="table-head-count p-0 pb-2">
            <span v-if="isNotReady">Идет подсчет строк...</span>
            <span v-else>Найдено {{ countFormatted }} {{ row }}</span>
        </td>
    </tr>
    </thead>
</template>

<script>
import {morph, number_format} from '@/services/utilsFunctions';

export default {
    name: 'TableHeadCount',
    props: ['configs'],
    computed: {
        count() {
            return this.$store.getters[this.configs.getterCountByFilter] || 0;
        },
        countFormatted() {
            return number_format(this.count, 0, '.', ' ')
        },
        row() {
            return morph(this.count, ['запись', 'записи', 'записей']);
        },
        isNotReady() {
            return this.$store.getters[this.configs.getterCountByFilter] === null;
        },
    },
}
</script>