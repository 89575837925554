<template>
  <tr @click="clickRowMobile"
      ref="rowTable"
      class="table-small-row"
      :class="{
        'table-row-active': isSelectedRow,
      }">
    <td
      v-if="configs.generalField !== undefined && configs.info[configs.generalField].component !== undefined"
      class="table-small-row--menu"
      :style="getRowStyles"
    >
      <component
        :is="configs.info[configs.generalField].component"
        :dataRow="row"
        :indexRow="index"
        :config_g="configs"
        :small="true"
      />
    </td>
    <td class="table-small-row--info">
      <error-alert-ui v-if="!configs?.smallGroped">
        Компонент не задан
      </error-alert-ui>
      <component v-else
                 :is="configs.smallGroped"
                 :dataTable="$store.getters[configs.getter]"
                 :configs="configs"
                 :rowStyles="getRowStyles"
                 :row="row"
                 :componentWidth="componentWidth"/>
    </td>
  </tr>
</template>

<script>
import VuexAdapter from "@/services/vuexAdapter";
import {tColorToRGB} from "@/services/utilsFunctions";
import ErrorAlertUi from "@/components/ui/ErrorAlertUi";
import {mapActions} from "vuex";

export default {
  name: "TableRowSmall",
  components: {ErrorAlertUi},
  props: [
    'row',
    'index',
    'configs',
    'componentWidth',
  ],
  computed: {
    getRowStyles() {
      let styles = {};

      if (this.configs.getterColor !== undefined && this.configs.previewField !== undefined && this.configs.getterColor) {

        if (this.row[this.configs.generalField] !== undefined && this.$store.getters[this.configs.getterColor][this.row[this.configs.generalField]] !== undefined) {
          let colorConfig = this.$store.getters[this.configs.getterColor][this.row[this.configs.generalField]];
          if (!colorConfig['БлокЦвета'] && colorConfig['Цвет']) {
            styles['background-color'] = this.tColorToRGB(`#${colorConfig['Цвет']}`)
          }
        }
      }

      return styles;
    },
    tableActiveRow() {
      return this.$store.getters[VuexAdapter.activeRowTableNameGetter(this.configs.name)];
    },
    hasTableActiveRow() {
      return this.tableActiveRow && Object.keys(this.tableActiveRow).length > 0;
    },
    isSelectedRow() {
      return this.hasTableActiveRow && this.tableActiveRow[this.configs.generalField] === this.row[this.configs.generalField]
    },
  },
  mounted() {
    if (this.isSelectedRow || !this.hasTableActiveRow) {
      this.clickRow(true);
    }
  },
  methods: {
    ...mapActions([
      'setActivePreviewAction'
    ]),
    tColorToRGB,
    clickRowMobile() {
      this.setActivePreviewAction();
      this.clickRow(false);
    },
    clickRow(scroll = true) {
      this.tableSetActiveRow({
        row: this.row,
        index: this.index,
      });
      if (this.configs?.previewField && this.row[this.configs?.previewField]) {
        this.$store.dispatch('setEntityIdPreviewAction', this.row[this.configs.previewField]);
      }
      if (scroll) {
        this.$nextTick(() => {
          if (undefined !== this.$refs.rowTable?.scrollIntoView) {
            this.$refs.rowTable.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'start',
            });
          }
        },);
      }
    },
    tableSetActiveRow(data) {
      this.$store.commit(VuexAdapter.activeRowTableNameMutation(this.configs.name), data)
    },
  }
}
</script>
