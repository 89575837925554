export default {
    data() {
        return {
            errorMessage: null,
        }
    },
    methods: {
        show() {
            this.$refs.modal.show();
        },
        hide() {
            this.$refs.modal.hide();
            this.errorMessage = null;
        },
        showLoader() {
            this.$refs.modal.showLoader();
        },
        hideLoader() {
            this.$refs.modal.hideLoader();
        },
    },
}
