<template>
  <div class="form--file">
    <label v-if="label" v-html="label"></label>

    <div class="form--file-wrapper"
         @dragover="dragOverFile = true"
         @dragenter="dragOverFile = true"
         @drag="dragOverFile = false"
         @dragstart="dragOverFile = false"
         @dragend="dragOverFile = false"
         @dragleave="dragOverFile = false"
         @drop="dragOverFile = false">
      <div v-if="isEmpty" class="form--file-empty">
        <div class="btn-upload">
          <upload-icon></upload-icon>
          Загрузить файлы
        </div>

        <div>Выберите и загрузите файл с компьютера</div>
        <div>или перетащите в это поле</div>

        <input class="input" :disabled="disabled" type="file" ref="inputFile" multiple @change="addFile()">
      </div>

      <div v-else class="form--file-list">
        <div class="form--file-list__item" v-for="(file, key) in files" :key="file.base64">
          <div class="form--file-list__item-name">{{ file.name }}</div>
          <div class="form--file-list__item-icon">
            <exel-form-file-icon
              v-if="['xls', 'xlsm', 'xlsx', 'xltx', 'xlsb', 'xlam'].includes(getExtension(file.name))"
            ></exel-form-file-icon>
            <word-form-file-icon
              v-else-if="['doc', 'docx', 'docm'].includes(getExtension(file.name))"
            ></word-form-file-icon>
            <pdf-form-file-icon v-else-if="['pdf'].includes(getExtension(file.name))"></pdf-form-file-icon>
            <default-form-file-icon v-else></default-form-file-icon>
          </div>

          <div class="form--file-list__item-footer">
            <div class="form--file-list__item-footer__size">{{ fileSize(file.size) }}</div>
            <div class="form--file-list__item-footer__remove">
              <div v-if="!disabled" class="form--file-list__item-footer__remove-icon" @click="remove(file)">
                <remove-form-file-icon></remove-form-file-icon>
              </div>
            </div>
          </div>

          <div v-if="isLastFile(key)" class="form--file-list__item--add-icon">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M15.9945 1H7.00573C3.69209 1 1.00586 3.68623 1.00586 6.99987V24.9995C1.00586 28.3131 3.69209 30.9993 7.00573 30.9993H15.9945H16.0055H24.9943C28.3079 30.9993 30.9941 28.3131 30.9941 24.9995V6.99987C30.9941 3.68623 28.3079 1 24.9943 1H16.0055H15.9945ZM16.0001 7.8332C15.1742 7.83615 14.5055 8.50657 14.5055 9.33316V14.4997H9.33899C8.51058 14.4997 7.83903 15.1713 7.83903 15.9997C7.83903 16.8281 8.51058 17.4996 9.33899 17.4996H14.5055V22.6662C14.5055 23.4928 15.1741 24.1632 16 24.1662C16.8259 24.1632 17.4945 23.4928 17.4945 22.6662V17.4996H22.661C23.4894 17.4996 24.161 16.8281 24.161 15.9997C24.161 15.1713 23.4894 14.4997 22.661 14.4997H17.4945V9.33315C17.4945 8.50661 16.8259 7.83622 16.0001 7.8332Z"
              ></path>
            </svg>
          </div>
        </div>

        <input class="input" :disabled="disabled" type="file" ref="inputFile" multiple @change="addFile()">
      </div>

      <div class="form--file-wrapper__drop-file" v-if="dragOverFile">Бросайте файлы сюда</div>
    </div>

    <span
      v-if="caption || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : caption }}
		</span>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import UploadIcon from '@/components/icons/UploadIcon';
import {fileSize, randomKey} from '@/services/utilsFunctions';
import ExelFormFileIcon from '@/components/icons/files/form/ExelFormFileIcon';
import WordFormFileIcon from '@/components/icons/files/form/WordFormFileIcon';
import PdfFormFileIcon from '@/components/icons/files/form/PdfFormFileIcon';
import DefaultFormFileIcon from '@/components/icons/files/form/DefaultFormFileIcon';
import RemoveFormFileIcon from '@/components/icons/files/form/RemoveFormFileIcon';

export default defineComponent({
  name: 'FileForm',
  components: {
    RemoveFormFileIcon,
    DefaultFormFileIcon,
    PdfFormFileIcon,
    WordFormFileIcon,
    ExelFormFileIcon,
    UploadIcon,
  },
  data() {
    return {
      files: [],
      dragOverFile: false,
      inputFileId: randomKey('input-file'),
    };
  },
  props: {
    value: {},
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: '',
    },
    maxHeight: Number,
  },
  created() {
    this.files = this.value || [];
  },
  computed: {
    isEmpty() {
      return this.files.length === 0;
    },
    maxHeightStyle() {
      return this.maxHeight && `${this.maxHeight}px`;
    },
  },
  methods: {
    fileSize,
    isLastFile(key) {
      return key === this.files.length - 1 && !this.disabled;
    },
    getExtension(fileName) {
      return fileName.split('.').pop();
    },
    remove(file) {
      this.files = this.files.filter(f => f != file);

      this.$emit('emitValue', this.files);
    },
    async addFile() {
      if (this.disabled) {
        return;
      }
      const files = this.$refs.inputFile.files;

      for (const file of files) {
        const data = {
          name: file.name,
          size: file.size,
          base64: await this.toBase64(file),
        };

        if (!this.files.includes(data)) {
          this.files.push(data);
        }

        this.$emit('emitValue', this.files);
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split('base64,')[1]);
        reader.onerror = error => reject(error);
      });
    },
  },
});
</script>

<style scoped lang="scss">
.input {
  position: absolute;
  inset: -8px;

  opacity: 0;
  cursor: pointer;
}

.form--file {
  margin-bottom: 5px;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-gray-600);
  }

  &-wrapper {
    max-height: v-bind(maxHeightStyle);
    overflow-y: auto;

    position: relative;
    border: dashed 1px var(--color-gray-400);
    border-radius: 8px;
    padding: 8px;
    transition: color 600ms, border 600ms, fill 600ms;

    &__drop-file {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--color-gray-100);
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--color-gray-600);
      border-radius: 8px;
    }
  }

  &-list {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 8px;
    padding-right: 34px;

    &__item {
      display: flex;
      flex-direction: column;
      background: var(--color-gray-075);
      border-radius: 8px;
      padding: 10px;
      height: 138px;
      position: relative;
      max-width: 100%;

      &--add-icon {
        position: absolute;
        right: -40px;
        top: 50px;

        path {
          fill: var(--color-gray-700);
        }
      }

      &-name {
        max-width: 100%;
        display: -webkit-box;
        height: 34px;
        max-height: 34px;
        font-size: 12px;
        line-height: 16px;
        color: #1470EF;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__size {
          font-size: 12px;
          color: var(--color-gray-1000);
          font-weight: 400;
        }

        &__remove {
          position: relative;
          width: 24px;
          height: 24px;
        }

        &__remove-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          transition: all 100ms;
          cursor: pointer;

          svg {
            fill: none;

            :deep(path) {
              stroke: var(--color-gray-700)
            }
          }

          &:hover {
            svg {
              :deep(path) {
                stroke: var(--color-gray-1000) !important;
              }
            }
          }
        }
      }
    }
  }

  &-empty {
    position: relative;
    transition: color 600ms, border 600ms, fill 600ms;
    color: var(--color-gray-600);

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-upload {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      width: 180px;
      height: 36px;
      border: 1px solid var(--color-gray-800);
      border-radius: 8px;
      margin-bottom: 20px;
    }

    .icon,
    :first-child,
    :nth-child(2) {
      transition: color 600ms, border 600ms, fill 600ms;
      color: var(--color-gray-1000);
      fill: var(--color-gray-1000);
    }

    :nth-last-child(2) {
      margin-bottom: 5px;
    }
  }
}
</style>
