<script setup>
import {computed, onBeforeUnmount, ref, watch} from 'vue';
import {only} from '@/common/utils/props-validators';
import {autoUpdate, flip, limitShift, offset, shift, size, useFloating} from '@floating-ui/vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
    validator: only('div', 'ul'),
  },
  placement: {
    type: String,
    default: 'bottom-start',
    validator: only(
      ...['top', 'right', 'bottom', 'left'].flatMap(side => [`${side}-start`, `${side}`, `${side}-end`]),
    ),
  },
  offset: {
    type: Number,
  },
  noOverflow: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  shrink: {
    type: Boolean,
    default: false,
  },
  closeIfInnerClick: {
    type: Boolean,
    default: false,
  },
  closeIfOutsideClick: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['open', 'close']);

const toggle = () => isOpen.value = !isOpen.value;

const show = () => {
  if (!isOpen.value) {
    toggle();
  }
};

const hide = () => {
  if (isOpen.value) {
    toggle();
  }
};

const onDropdownClick = () => {
  if (props.closeIfInnerClick) {
    hide();
  }
};

const onDocumentClick = (event) => {
  if (props.closeIfOutsideClick && !rootRef.value.contains(event.target)) {
    hide();
  }
};

const isOpen = ref(false);

const rootRef = ref(null);
const anchorRef = ref(null);
const dropdownRef = ref(null);

const {floatingStyles: dropdownStyles} = useFloating(anchorRef, dropdownRef, {
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(props.offset),
    shift({
      limiter: limitShift(),
    }),
    flip(),
    props.shrink && size({
      apply({availableWidth, availableHeight, elements}) {
        Object.assign(elements.floating.style, {
          maxWidth: `${availableWidth}px`,
          maxHeight: `${availableHeight - 6}px`,
        });
      },
    }),
  ],
});

const dropdownClass = computed(() => ({
  '_flex': props.shrink,
  '_full-width': props.fullWidth,
  '_no-overflow': props.noOverflow,
}));

onBeforeUnmount(() => document.removeEventListener('click', onDocumentClick, {capture: true}));

watch(
  isOpen,
  (next, prev) => {
    if (prev !== undefined) {
      emit(next ? 'open' : 'close');
    }

    const method = next ? 'addEventListener' : 'removeEventListener';
    document[method]('click', onDocumentClick, {capture: true});
  },
  {immediate: true},
);

defineExpose({toggle, show, hide});
</script>

<template>
  <div ref="rootRef" class="dropdown-ui">
    <div ref="anchorRef">
      <slot name="anchor" :toggle="toggle" :show="show" :hide="hide" :is-open="isOpen"></slot>
    </div>

    <transition name="opacity-fast">
      <component
        :is="tag"
        v-if="isOpen"
        ref="dropdownRef"
        class="dropdown"
        :class="dropdownClass"
        :style="dropdownStyles"
        @click="onDropdownClick"
      >
        <slot></slot>
      </component>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.dropdown-ui {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 1;

  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--shadow);

  &._flex {
    display: flex;
    flex-direction: column;
  }

  &._full-width {
    width: 100%;
  }

  &:not(._no-overflow) {
    overflow: hidden;
  }
}
</style>
