<template>
  <modal-ui ref="modal" title="Внимание" popup>
    <template #body>
      <div v-html="message"/>
    </template>

    <template #footer>
      <close-button @click="hide"/>

      <stop-doc-button @click="continueClosing">
        <template #title>Продолжить</template>
        <template #icon>
          <check-icon/>
        </template>
      </stop-doc-button>
    </template>
  </modal-ui>
</template>

<script>
import CloseButton from "@/components/buttons/CloseButton";
import StopDocButton from "@/components/buttons/StopDocButton";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: "AttentionModal",
  mixins: [modal],
  components: {ModalUi, CheckIcon, StopDocButton, CloseButton},
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    continueClosing() {
      this.hide();
      this.$emit('continueClosing');
    },
  }
}
</script>
