const splitSizes = [50, 50];

export default {
    mutations: {
        splitSizesMutation(state, sizes) {
            state.splitSizes = [...sizes];
        },
    },
    state: {
        splitSizes,
    },
    getters: {
        splitSizesGetter(state) {
            return state.splitSizes;
        },
    },
}