<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99996 0.166504C6.4602 0.166504 6.83329 0.5396 6.83329 0.999837V5.1665H11C11.4602 5.1665 11.8333 5.5396 11.8333 5.99984C11.8333 6.46007 11.4602 6.83317 11 6.83317H6.83329V10.9998C6.83329 11.4601 6.4602 11.8332 5.99996 11.8332C5.53972 11.8332 5.16663 11.4601 5.16663 10.9998V6.83317H0.999959C0.539722 6.83317 0.166626 6.46007 0.166626 5.99984C0.166626 5.5396 0.539722 5.1665 0.999959 5.1665H5.16663V0.999837C5.16663 0.5396 5.53972 0.166504 5.99996 0.166504Z"/>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon"
}
</script>