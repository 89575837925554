import StepBarcode from '@/components/document-master/steps/StepBarcode.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Barcode,
  title: 'Назначение штрих-кода документа',
  tooltip: 'Штрих-код используется для поиска документов и поточного сканирования. Назначить его следует с использованием сканера',
  menuTitle: 'Штрих-код',
  component: StepBarcode,
};
