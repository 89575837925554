<template>
    <button type="button"
            class="btn close-button"
            v-bind="$attrs"
            v-on="$listeners">
        <slot name="icon">
            <close-icon/>
        </slot>
        <slot name="title">
            Отмена
        </slot>
    </button>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon';

export default {
    name: 'CloseButton',
    components: {
        CloseIcon,
    },
    inheritAttrs: false,
};
</script>