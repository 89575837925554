<template>
  <preview-block title="Дополнительная информация" :loading="loading">
    <tabs-ui>
      <template #title="{title, data}">
        <span class="title">{{ title }}</span>
        <badge-ui :value="data"></badge-ui>
      </template>

      <tab-ui v-if="showClientsTab" title="Контрагенты" :data="docContractorsCountGetter">
        <uploader-block-document-preview
          :end-point="DOCS_CLIENTS_GET"
          :doc-id="doc_id"
          :component="() => import('@/components/doc/contractors/ContractorRow')"
        >
          <template #list-is-empty>Список контрагентов пуст</template>
        </uploader-block-document-preview>
      </tab-ui>

      <tab-ui v-if="showDocumentsViewTab" title="Просмотры" :data="docViewsCountGetter">
        <uploader-block-document-preview
          :end-point="DOCS_VIEWS_ALL"
          :doc-id="doc_id"
          :component="() => import('@/components/doc/preview/rows/ViewRowDocumentPreview')"
        >
          <template #list-is-empty>Список просмотров пуст</template>
        </uploader-block-document-preview>
      </tab-ui>

      <tab-ui title="Документы" :data="documentsLinksCountGetter">
        <uploader-block-document-preview
          :end-point="DOC_LINKS_GET"
          :doc-id="doc_id"
          :component="() => import('@/components/table/grouped/DocumentsLinks')"
        >
          <template #list-is-empty>Список связанных документов пуст</template>
        </uploader-block-document-preview>
      </tab-ui>
    </tabs-ui>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import UploaderBlockDocumentPreview from '@/components/doc/preview/UploaderBlockDocumentPreview';
import BadgeUi from '@/components/ui/BadgeUi.vue';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_LINKS_GET, DOCS_CLIENTS_GET, DOCS_VIEWS_ALL} from '@/configs/endPoints';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__VIEWS} from '@/configs/events';
import TabsUi from '@/components/ui/TabsUi.vue';
import TabUi from '@/components/ui/TabUi.vue';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';

export default defineComponent({
  name: 'AdditionalInfoDocumentPreview',
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewBlock,
    TabsUi,
    TabUi,
    BadgeUi,
    UploaderBlockDocumentPreview,
  },
  data() {
    return {
      DOCS_CLIENTS_GET,
      DOCS_VIEWS_ALL,
      DOC_LINKS_GET,
    };
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    ...mapGetters({
      docViewsCountGetter: VuexAdapter.getNameCountGetter(DOCS_VIEWS_ALL),
      documentsLinksCountGetter: VuexAdapter.getNameCountGetter(DOC_LINKS_GET),
      docContractorsCountGetter: VuexAdapter.getNameCountGetter(DOCS_CLIENTS_GET),
    }),
    showDocumentsViewTab() {
      return this.accessToEvent(DOCUMENTS__VIEWS);
    },
    showClientsTab() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  &:not(:last-child) {
    margin-right: 4px;
  }
}
</style>
