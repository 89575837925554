<template>
  <thead class="thead-table-active-filters">
  <tr>
    <td colspan="1000" class="p-0">
      <div class="flex-wrap align-items-center table-active-filters"
           :class="{
                    'd-none': !(hasFilters || (hasOrderBy && !isSmall)),
                    'd-flex': hasFilters || (hasOrderBy && !isSmall),
                    'small': isSmall,
                 }">
        <div class="d-flex flex-wrap align-items-center" v-if="hasFilters">
          <div class="mr-3">
            <button type="button"
                    class="table-active-filters--delete-all"
                    @click="tableDeleteALLFiltersAndOrderBy">
              <close-only-icon/>
            </button>
          </div>
          <div class="table-active-filters--title mr-3 text-nowrap">
            Фильтр:
          </div>
          <div v-for="(col, key_column, index) in tableFilterData"
               v-bind:key.prop="col"
               class="mr-1 table-active-filters--filter" :style="getMaxWidth">
            <div v-if="configs_table.info[key_column] !== undefined"
                 class="d-flex align-items-center"
                 data-tooltip-position="bottom"
                 data-tooltip-size="auto"
                 data-tooltip-text-align="center"
                 role="tooltip"
            >
              <button type="button"
                      v-on:click="tableDeleteFilter(key_column)">
                <component :is="configs_table.info[key_column].type"
                           :key_column="key_column"
                           :config="col"
                           :configs_table="configs_table.info[key_column]"
                />
                <close-only-icon/>
              </button>
              <div v-if="index < (countFilters - 1)" class="ml-2 mr-1"><b>{{ tableWhereConditionFormatted }}</b></div>
              <div class="tooltip-aria-label">
                <component :is="configs_table.info[key_column].type"
                           :key_column="key_column"
                           :config="col"
                           :configs_table="configs_table.info[key_column]"
                />
              </div>
            </div>
            <div v-else-if="key_column === 'search'"
                 class="d-flex align-items-center"
                 data-tooltip-position="bottom"
                 data-tooltip-size="auto"
                 data-tooltip-text-align="center"
                 role="tooltip"
            >
              <button type="button"
                      v-on:click="tableDeleteFilter(key_column)">
                <search-filter-badge :config="col"
                                     :configs_table_info="configs_table.info"/>
                <close-only-icon/>
              </button>
              <div v-if="index < (countFilters - 1)" class="ml-2 mr-1"><b>{{ tableWhereConditionFormatted }}</b></div>
              <div class="tooltip-aria-label">
                <search-filter-badge :config="col"
                                     :configs_table_info="configs_table.info"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isSmall && hasOrderBy" class="d-flex align-items-center">
          <div class="table-active-filters--title mr-3 ml-3">
            Сортировка:
          </div>
          <div class="mr-2 table-active-filters--filter sortable-filter">
            <button type="button"
                    v-on:click="tableDelOrderBy">
              <span class="field-title sortable-field-title">{{ configs_table.info[tableOrderBy.key].title }}</span>
              <span v-if="tableOrderBy.value === 'DESC'">↓</span>
              <span v-else>↑</span>
              <close-only-icon/>
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </thead>
</template>

<script>
import Constants from '@/configs/constants';
import {library as fontLibrary} from '@fortawesome/fontawesome-svg-core';
import {faFilter, faSortAmountDown} from '@fortawesome/free-solid-svg-icons';
import SearchFilterBadge from "@/components/table/filterBadges/SearchFilterBadge";
import CloseOnlyIcon from "@/components/icons/CloseOnlyIcon";
import VuexAdapter from "@/services/vuexAdapter";
import {TABLE_CONDITION_AND} from "@/configs/tables/table";

fontLibrary.add(faFilter, faSortAmountDown);

export default {
  name: 'TableActiveFilters',
  props: {
    configs_table: Object,
    component_width: Number,
    isSmall: {
      default: false,
      type: Boolean
    },
  },
  data: () => ({
    filtersConfig: {},
  }),
  computed: {
    tableFilterData() {
      return this.$store.getters[VuexAdapter.filterTableNameGetter(this.configs_table.name)];
    },
    tableWhereCondition() {
      return this.$store.getters[VuexAdapter.conditionTableNameGetter(this.configs_table.name)];
    },
    tableOrderBy() {
      return this.$store.getters[VuexAdapter.orderByTableNameGetter(this.configs_table.name)];
    },
    tableWhereConditionFormatted() {
      return this.tableWhereCondition === TABLE_CONDITION_AND ? 'И' : 'ИЛИ';
    },
    hasFilters() {
      return Object.keys(this.tableFilterData).length > 0;
    },
    hasOrderBy() {
      return Object.keys(this.tableOrderBy).length > 0;
    },
    countFilters() {
      return Object.keys(this.tableFilterData).length;
    },
    getMaxWidth() {
      let mw = this.component_width < Constants.resolutionPoints.tiny
              ? this.component_width / 2
              : this.component_width - 15;
      return 'max-width: ' + Math.ceil(mw) + 'px';
    },
  },
  methods: {
    tableDelOrderBy() {
      this.$store.dispatch(VuexAdapter.delOrderByTableNameAction(this.configs_table.name));
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.$store.dispatch(VuexAdapter.delFilterAndOrderByTableNameAction(this.configs_table.name));
    },
    tableDeleteFilter(key_column) {
      this.$store.dispatch(VuexAdapter.delFilterTableNameAction(this.configs_table.name), key_column);
    },
  },
  components: {
    CloseOnlyIcon,
    SearchFilterBadge,
    Date: () => import('@/components/table/filterBadges/DateFilterBadge'),
    Flag: () => import('@/components/table/filterBadges/FlagFilterBadge'),
    Identifier: () => import('@/components/table/filterBadges/IdentifierFilterBadge'),
    Integer: () => import('@/components/table/filterBadges/IntegerFilterBadge'),
    String: () => import('@/components/table/filterBadges/StringFilterBadge'),
    AsyncIdentifier: () => import('@/components/table/filterBadges/AsyncIdentifier'),
  }
};
</script>