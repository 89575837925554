<template>
  <a
    class="file-truncated-name"
    :class="{_muted: isMuted, _disabled: isFileDownloading}"
    href="#"
    :aria-label="file.nameWithExtension"
    data-tooltip-position="top"
    data-tooltip-size="auto"
    :role="showTooltip && 'tooltip'"
    @mouseenter="setShowTooltip"
    @click.prevent="downloadFile"
  >
    <span ref="nameRef" class="title">{{ file.name }}</span>
    <span>.{{ file.extension }}</span>
  </a>
</template>

<script>
import downloadFile from '@/mixins/downloadFile';
import {defineComponent} from 'vue';
import DocumentFile from '@/common/models/document-file';

export default defineComponent({
  name: 'FileTruncatedName',
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [downloadFile],
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    setShowTooltip() {
      this.showTooltip = this.$refs.nameRef.scrollWidth > this.$refs.nameRef.clientWidth + 1;
    },
  },
});
</script>

<style scoped lang="scss">
.file-truncated-name {
  min-width: 0;
  display: flex;

  white-space: nowrap;

  &._disabled {
    pointer-events: none;

    &:not(._muted) {
      color: var(--color-gray-400);
    }
  }

  &._muted {
    color: var(--color-gray-600);
    font-size: 10px;
    line-height: 14px;
  }

  &::after {
    /* TODO: Избавиться от !important */
    width: 230px !important;
    word-wrap: break-word;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
