<template>
  <div v-show="show" class="d-flex justify-content-end align-self-center flex-wrap w-100">
    <start-doc-modal ref="startDocModal" :events="events" :doc-id="doc['DOC_ID']"/>
    <pause-doc-modal ref="pauseDocModal" :events="events" :doc-id="doc['DOC_ID']"/>
    <stop-doc-modal ref="stopDocModal" :events="events" :doc-id="doc['DOC_ID']"/>
    <button v-for="button in buttons" :key.prop="button"
            type="button"
            class="btn ml-1 mt-2 text-nowrap btn-icon-right"
            v-on:click="button.method"
            :class="[button.class_button]">
      {{ button.title }}
      <component :is="button.icon"></component>
    </button>
  </div>
</template>

<script>
import controlsDocSolution from "@/mixins/controlsDocSolution";
import StopCircleIcon from "@/components/icons/StopCircleIcon";
import PlayCircleIcon from "@/components/icons/PlayCircleIcon";
import StartDocModal from "@/components/modals/Doc/StartDocModal";
import PauseDocModal from "@/components/modals/Doc/PauseDocModal";
import StopDocModal from "@/components/modals/Doc/StopDocModal";
import {mapState} from 'vuex';

export default {
  name: "SolutionControlsButtons",
  components: {StopDocModal, PauseDocModal, StartDocModal, PlayCircleIcon, StopCircleIcon},
  mixins: [controlsDocSolution],
  props: {
    doc: Object,
  },
  data: () => ({
    buttons: {},
  }),
  created() {
    this.setData();
  },
  computed: {
    ...mapState('user', ['userId']),
    show() {
      return Object.keys(this.buttons).length;
    },
  },
  methods: {
    setData() {
      this.buttons = {};
      if (this.doc['DOC_ID'] && this.doc['CONTROLLER_ID'] === this.userId) {
        for (let key in this.route_point_solution) {
          if (this.route_point_solution[key]['routePointType'] === this.doc['RPT_ID']) {
            if ((this.doc['Активен'] === 1 && key === 'start') ||
              (this.doc['Активен'] === 0 && key === 'pause')) {
              continue;
            }
            this.$set(this.buttons, key, {
              icon: this.route_point_solution[key]['icon'],
              title: this.route_point_solution[key]['title'],
              class_button: this.route_point_solution[key]['class_button'],
              method: this.route_point_solution[key]['method'],
              key
            });
          }
        }
      }
    }
  },
  watch: {
    'doc.DOC_ID'() {
      this.setData();
    }
  },
}
</script>
