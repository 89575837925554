import StepExecution from '@/components/document-master/steps/StepExecution.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Execution,
  title: 'Добавление данных…',
  tooltip: 'После создания карточки документа ошибки при её заполнении можно исправить вручную',
  menuTitle: 'Выполнение',
  onlyNext: true,
  component: StepExecution,
};
