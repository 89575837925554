<template>
    <svg width="16" height="16" viewBox="0 0 612 792" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.562,396c0,163.758,132.68,296.438,296.438,296.438S602.438,559.758,602.438,396S469.758,99.562,306,99.562
	S9.562,232.242,9.562,396z M545.062,396c0,132.082-106.98,239.062-239.062,239.062S66.938,528.082,66.938,396
	S173.918,156.938,306,156.938S545.062,263.918,545.062,396z M212.46,373.906l102.634-102.634c7.093-7.094,18.563-7.094,25.583,0
	l17.056,17.056c7.093,7.094,7.093,18.564,0,25.582l-72.674,72.826l72.75,72.749c7.094,7.094,7.094,18.564,0,25.584l-17.056,17.131
	c-7.095,7.094-18.565,7.094-25.583,0L212.536,399.564C205.366,392.471,205.366,381,212.46,373.906L212.46,373.906z"/>
    </svg>
</template>

<script>
export default {
    name: 'LeftCircleIcon'
}
</script>