<template>
  <div class="file-error">
    <file-error-icon class="icon"></file-error-icon>

    <p class="text">{{ text }}</p>

    <button-link-ui v-if="!noRetry" @click="$emit('retry')">
      Попробовать снова
      <retry-icon></retry-icon>
    </button-link-ui>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import FileErrorIcon from '@/assets/svg/file-error.svg?component';
import RetryIcon from '@/assets/svg/retry.svg?component';
import ButtonLinkUi from '@/components/ui/ButtonLinkUi.vue';

export default defineComponent({
  name: 'FileError',
  components: {
    ButtonLinkUi,
    FileErrorIcon,
    RetryIcon,
  },
  props: {
    text: {
      type: String,
      default: 'Ошибка отображения файла',
    },
    noRetry: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['retry'],
});
</script>

<style scoped lang="scss">
.file-error {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-white);
}

.icon {
  margin-bottom: 12px;

  /* TODO: Избавиться от перекрытия стилей */
  width: auto;
  height: auto;
}

.text {
  margin-bottom: 0; // TODO: Избавиться от перекрытия стилей
  color: var(--color-gray-700);
}
</style>
