<template>
  <div class="start-parameters-step-master">
    <div class="start-parameters-step-master__header">
      <div class="start-parameters-step-master__header__label">
        Номер документа :
      </div>

      <div class="start-parameters-step-master__header__number">
        <a :href="'/document/'+ this.docId" target="_blank">{{ docId }}</a>
      </div>

      <div class="start-parameters-step-master__header__copy" @click="copy">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_107_20087)">
            <path
              d="M15.5945 2H4.68537C3.68537 2 2.86719 2.81818 2.86719 3.81818V16.5455H4.68537V3.81818H15.5945V2ZM18.3217 5.63636H8.32173C7.32173 5.63636 6.50355 6.45455 6.50355 7.45455V20.1818C6.50355 21.1818 7.32173 22 8.32173 22H18.3217C19.3217 22 20.1399 21.1818 20.1399 20.1818V7.45455C20.1399 6.45455 19.3217 5.63636 18.3217 5.63636ZM18.3217 20.1818H8.32173V7.45455H18.3217V20.1818Z"
              fill="#5F6A7D"/>
          </g>
        </svg>
      </div>
    </div>

    <form-builder
      ref="form"
      :fields="fields"
      v-model="formDataProxy"
      @change="change"
    ></form-builder>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import {
  RADIO_CARDS,
  TYPE_BOOL,
  TYPE_BOOL_TILE,
  TYPE_DATE,
  TYPE_INTEGER,
  TYPE_SELECT,
  TYPE_SUB_TEXT,
  TYPE_SWITCH,
} from '@/configs/form';
import {DELAYED_START_CONTROL_TYPE, NOT_START_CONTROL_TYPE, START_NOW_CONTROL_TYPE} from '@/configs/controlTypes';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {
  AT_ONCE_START_CONTROL_TYPE,
  BEHIND_START_CONTROL_TYPE,
  THROUGH_START_CONTROL_TYPE,
} from '@/configs/master/startControlTypeMaster';
import {
  CALENDAR_DAYS_TERM_FROM_MASTER,
  DATE_TERM_FROM_MASTER,
  NONE_TERM_FROM_MASTER,
  WORKING_DAYS_TERM_FROM_MASTER,
} from '@/configs/master/termFromMaster';
import {NONE_ROUTE} from '@/configs/routeTypes';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {
  CONTROL_GET,
  DOC_ACTIVATION_SET,
  DOC_CONTROLLER_SET,
  DOC_STARTDATE_SET,
  SYSTEM_GET_DEADLINE,
  SYSTEM_USER_ID,
} from '@/configs/endPoints';
import {getDateFromJSDate} from '@/services/utilsFunctions';
import {DateTime} from 'luxon';
import {ERROR_NOTIFY_TYPE, SUCCESS_NOTIFY_TYPE} from '@/configs/notifyTypes';
import {CONTROLS_TABLE_NAME} from '@/configs/tables/table';
import {ErrorHelper} from '@/services/errorHelper';

const notify = (value, color = '#ffd130') => (`
  <svg class="mb-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="8" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4298 17.7511L12.4329 5.74819C12.24 5.41727 11.7619 5.41727 11.569 5.7482L4.57214 17.7511C4.37783 18.0845 4.61828 18.5029 5.00411 18.5029H18.9978C19.3836 18.5029 19.6241 18.0845 19.4298 17.7511ZM13.7288 4.99278C12.9572 3.66907 11.0447 3.66908 10.2731 4.99278L3.27625 16.9957C2.49902 18.329 3.46079 20.0029 5.00411 20.0029H18.9978C20.5411 20.0029 21.5029 18.329 20.7257 16.9957L13.7288 4.99278Z" fill="${color}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8551 9.74664C12.9236 9.35705 12.6239 9 12.2283 9C11.9096 9 11.64 9.23578 11.5976 9.55166L11 14H12.107L12.8551 9.74664ZM11.5 17C12.3284 17 13 16.5523 13 16C13 15.4477 12.3284 15 11.5 15C10.6716 15 10 15.4477 10 16C10 16.5523 10.6716 17 11.5 17Z" fill="${color}"/>
  </svg>
  ${value}
`);

export default defineComponent({
  name: 'StepLaunch',
  mixins: [step],
  components: {FormBuilder},
  data() {
    return {
      fields: [
        {
          type: RADIO_CARDS,
          name: 'control_type',
          hidden: () => this.noneRoute,
          disabled: () => this.hasRoutesResponsibleSkip,
          options: [
            {
              code: START_NOW_CONTROL_TYPE,
              label: 'Запустить сразу и поставить на контроль',
              full: true,
            },
            {
              code: NOT_START_CONTROL_TYPE,
              label: 'Не запускать',
              activeColor: '#FFBCBC',
            },
            {
              code: DELAYED_START_CONTROL_TYPE,
              label: 'Отложенный запуск',
              activeColor: '#FFDB65',
            },
          ],
        }, {
          type: TYPE_SUB_TEXT,
          classes: ['mt-4'],
          label: notify('Внимание, документ не будет запущен в работу, запустите его в карточке.'),
          hidden: () => this.formDataProxy.control_type !== NOT_START_CONTROL_TYPE || this.noneRoute,
        },
        [
          {
            type: TYPE_SELECT,
            name: 'start_control_type',
            options: () => this.startControlTypes,
            required: true,
            codeOnlyValue: true,
            clearable: false,
            classes: () => {
              const classes = [];

              if (this.formDataProxy.start_control_type === AT_ONCE_START_CONTROL_TYPE) {
                classes.push('start_control_type-p');
              }

              return classes;
            },
            hidden: () => this.formDataProxy.control_type !== START_NOW_CONTROL_TYPE || this.noneRoute,
          },
        ],
        [
          {
            type: TYPE_SWITCH,
            name: 'start_control_type_term',
            hidden: () => this.hiddenStartControlTypeTerm,
            options: [
              {
                label: 'В рабочих днях',
                value: WORKING_DAYS_TERM_FROM_MASTER,
              },
              {
                label: 'В днях',
                value: CALENDAR_DAYS_TERM_FROM_MASTER,
              },
              {
                label: 'Дата',
                value: DATE_TERM_FROM_MASTER,
              },
            ],
          },
        ],
        [
          {
            type: TYPE_INTEGER,
            name: 'start_control_type_term_days',
            min: 1,
            max: 360,
            initValue: 1,
            hidden: () => this.hiddenStartControlTypeTerm || this.modelValue.start_control_type_term === DATE_TERM_FROM_MASTER,
          },
          {
            type: TYPE_DATE,
            name: 'start_control_type_term_days_date',
            onlyInput: () => this.modelValue.start_control_type_term !== DATE_TERM_FROM_MASTER,
            hidden: () => this.hiddenStartControlTypeTerm,
            required: true,
            disabled: () => this.modelValue.start_control_type_term !== DATE_TERM_FROM_MASTER,
            validator: (value) => {
              if (!value) return true;

              const data = new DateTime.fromSQL(value).toJSDate();

              if (data < new Date()) {
                return 'Не может быть меньше текущей даты';
              }

              return true;
            },
          },
        ],
        [
          {
            type: TYPE_BOOL,
            name: 'clear_control_start',
            label: 'Снять документ с контроля до момента запуска',
            hidden: () => this.formDataProxy.control_type !== DELAYED_START_CONTROL_TYPE,
          },
        ],
        [
          {
            type: TYPE_BOOL_TILE,
            name: 'open_document',
            icon: () => import('@/components/icons/tile/FolderIcon.vue'),
            label: 'Открыть документ',
          },
          {
            type: TYPE_BOOL_TILE,
            name: 'add_favorite',
            icon: () => import('@/components/icons/tile/FavoriteIcon.vue'),
            label: 'Добавить<br>в избранное',
            disabled: true,
          },
        ],
      ],
    };
  },
  computed: {
    hasRoutesResponsibleSkip() {
      return this.formDataProxy.routes_responsible_unspecified_steps.some((i, n) => {
        return this.formDataProxy['routes_responsible_skip_' + n];
      });
    },
    noneRoute() {
      return this.formDataProxy.type_route === NONE_ROUTE;
    },
    docId() {
      return Number(this.formDataProxy?.doc_id);
    },
    hiddenStartControlTypeTerm() {
      if (this.noneRoute) {
        return true;
      }

      if (this.formDataProxy.control_type === START_NOW_CONTROL_TYPE && this.formDataProxy.start_control_type !== AT_ONCE_START_CONTROL_TYPE) {
        return false;
      }

      return this.formDataProxy.control_type !== DELAYED_START_CONTROL_TYPE;
    },
    startControlTypes() {
      const data = [
        {
          label: 'Сразу',
          code: AT_ONCE_START_CONTROL_TYPE,
        },
        {
          label: 'Через',
          code: THROUGH_START_CONTROL_TYPE,
        },
      ];

      if (this.formDataProxy.termFrom !== NONE_TERM_FROM_MASTER) {
        data.push({
          label: 'За',
          code: BEHIND_START_CONTROL_TYPE,
        });
      }

      return data;
    },
  },
  created() {
    if (this.hasRoutesResponsibleSkip) {
      this.$set(this.formDataProxy, 'control_type', NOT_START_CONTROL_TYPE);
      this.change();
    }
  },
  methods: {
    ...mapActions({
      controlsDocActivationAction: VuexAdapter.getNameAction(DOC_ACTIVATION_SET),
      fetchDeadlineAction: VuexAdapter.getNameAction(SYSTEM_GET_DEADLINE),
      startDateAction: VuexAdapter.getNameAction(DOC_STARTDATE_SET),
      setControllerAction: VuexAdapter.getNameAction(DOC_CONTROLLER_SET),
      getSystemUserIdAction: VuexAdapter.getNameAction(SYSTEM_USER_ID),
    }),
    copy() {
      navigator.clipboard.writeText(this.docId);
    },
    async ready() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.showLoader();

      try {
        // Запустить сразу и поставить на контроль СРАЗУ
        if (this.formDataProxy.control_type === START_NOW_CONTROL_TYPE && this.formDataProxy.start_control_type === AT_ONCE_START_CONTROL_TYPE) {
          await this.controlsDocActivationAction({
            doc_id: this.docId,
            active: 1,
          });
        }

        // Запустить сразу и поставить на контроль ЧЕРЕЗ
        if (this.formDataProxy.control_type === START_NOW_CONTROL_TYPE && this.formDataProxy.start_control_type === THROUGH_START_CONTROL_TYPE) {
          await this.controlsDocActivationAction({
            doc_id: this.docId,
            active: 1,
          });
          await this.startDateAction({
            doc_id: this.docId,
            start_date: this.formDataProxy.start_control_type_term_days_date,
          });

          const res = await this.getSystemUserIdAction({});

          await this.setControllerAction({
            doc_id: this.docId,
            login_id: res.data.login_id,
          });
        }

        // Отложенный запуск
        if (this.formDataProxy.control_type === DELAYED_START_CONTROL_TYPE) {

          await this.startDateAction({
            doc_id: this.docId,
            start_date: this.formDataProxy.start_control_type_term_days_date,
          });

          if (this.formDataProxy.clear_control_start) {
            const res = await this.getSystemUserIdAction({});

            await this.setControllerAction({
              doc_id: this.docId,
              login_id: res.data.login_id,
            });
          }
        }
      } catch (error) {
        this.$notify({
          title: 'Ошибка запуска документа',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });

        this.hideLoader();

        this.$set(this.formDataProxy, 'errors', true);
        this.change();

        return;
      }

      this.$notify({
        title: 'Параметры запуска документа успешно установлены',
        type: SUCCESS_NOTIFY_TYPE,
      });

      if (this.formDataProxy?.open_document) {
        await this.$router.push('/document/' + this.docId);

        return;
      }

      await this.$store.dispatch(VuexAdapter.refreshOnlyStateTableNameAction(CONTROLS_TABLE_NAME), {}).then(() => {
        const getter = VuexAdapter.getNameGetter(CONTROL_GET);

        const data = this.$store.getters[getter];

        data.forEach((row, index) => {
          if (row['DOC_ID'] === this.docId) {
            this.$store.commit(VuexAdapter.activeRowTableNameMutation(CONTROLS_TABLE_NAME), {row, index});
          }
        });
      });

      this.$emit('close');
    },
    async setStartControlTypeTermDaysDate() {
      let date = null;
      const number = this.formDataProxy.start_control_type_term_days;

      if (this.formDataProxy.control_type === START_NOW_CONTROL_TYPE && this.formDataProxy.start_control_type === BEHIND_START_CONTROL_TYPE) {
        const dateField = this.formDataProxy.termFrom === DATE_TERM_FROM_MASTER
          ? DATE_TERM_FROM_MASTER : this.formDataProxy.termFrom + '_VIEW';

        if (this.formDataProxy.start_control_type_term === CALENDAR_DAYS_TERM_FROM_MASTER) {
          const currentDate = new DateTime.fromSQL(this.formDataProxy[dateField]).toJSDate();
          currentDate.setDate(currentDate.getDate() - number);
          date = getDateFromJSDate(currentDate, 'yyyy-MM-dd');
        }
        if (this.formDataProxy.start_control_type_term === WORKING_DAYS_TERM_FROM_MASTER) {
          const r = await this.fetchDeadlineAction({
            count_day: -Math.abs(number),
            date_start: this.formDataProxy[dateField],
          });

          date = r.data.date.split('.').reverse().join('-');
        }
      } else {
        if (this.formDataProxy.start_control_type_term === CALENDAR_DAYS_TERM_FROM_MASTER) {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + number);
          date = getDateFromJSDate(currentDate, 'yyyy-MM-dd');
        }

        if (this.formDataProxy.start_control_type_term === WORKING_DAYS_TERM_FROM_MASTER) {
          const r = await this.fetchDeadlineAction({
            count_day: number,
          });

          date = r.data.date.split('.').reverse().join('-');
        }
      }

      this.$set(this.formDataProxy, 'start_control_type_term_days_date', date);
      this.change();
    },
  },
  watch: {
    ['formDataProxy.start_control_type_term_days']: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.setStartControlTypeTermDaysDate();
        }
      },
      deep: true,
      immediate: true,
    },
    ['formDataProxy.start_control_type_term']: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.setStartControlTypeTermDaysDate();
        }
      },
    },
  },
});
</script>
