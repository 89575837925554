<template>
  <div>
    <div class="solution-autocorrect" v-if="true">
      <solution-autocorrect-users @add-solution-autocorrect="addText"/>
      <solution-autocorrect-replace @add-solution-autocorrect="addText"/>
      <solution-autocorrect-date @add-solution-autocorrect="addText"/>
    </div>
    <div class="solution-autocorrect-selected">
       <div v-for="user in users" v-bind:key="user.user_id"
             class="mr-2 mt-1 solution-autocorrect-selected--badge">
            <div v-on:click="addText(user.label);" class="selected--badge-title">{{ user.label }}</div>
            <div v-on:click.prevent="delUserAutocorrect(user.user_id)" class="selected--badge-delete"><canceled-icon/></div>
        </div>
       <div v-for="row in replaces" v-bind:key="row.fav_id"
             class="mr-2 mt-1 solution-autocorrect-selected--badge">
             <div v-on:click="addText(row.label)" class="selected--badge-title">{{ row.label }}</div>
             <div v-on:click.prevent="delReplaceAutocorrect(row.fav_id)" class="selected--badge-delete"><canceled-icon/></div>
        </div>
       <div v-for="(comment, index) in coomentAutocorrectGetter" v-bind:key="index"
             class="mr-2 mt-1 solution-autocorrect-selected--badge">
             <div v-on:click="addText(comment)" class="selected--badge-title">{{ comment }}</div>
             <div v-on:click.prevent="delCommentAutocorrect(comment)" class="selected--badge-delete"><canceled-icon/></div>
        </div>
    </div>
  </div>
</template>

<script>
import SolutionAutocorrectUsers from "@/components/doc/solution/autocorrect/SolutionAutocorrectUsers";
import SolutionAutocorrectReplace from "@/components/doc/solution/autocorrect/SolutionAutocorrectReplace";
import SolutionAutocorrectDate from "@/components/doc/solution/autocorrect/SolutionAutocorrectDate";
import {mapActions, mapGetters} from "vuex";
import CanceledIcon from "@/components/icons/CanceledIcon.vue";
import VuexAdapter from "@/services/vuexAdapter";
import {USERS_ACTIVE} from "@/configs/endPoints";
export default {
  name: "SolutionAutocorrect",
  components: {CanceledIcon, SolutionAutocorrectDate, SolutionAutocorrectReplace, SolutionAutocorrectUsers},
  methods: {
    addText(text) {
      this.$emit('add-solution-autocorrect', text)
    },
    ...mapActions(['delReplaceAutocorrect', 'delUserAutocorrect', 'delCommentAutocorrect']),
  },
  computed: {
    ...mapGetters(['usersAutocorrectGetter', 'autocorrectReplaceObjListGetter', 'autocorrectReplaceGetter', 'coomentAutocorrectGetter']),
    ...mapGetters({
      usersActiveObjListGetter: VuexAdapter.getNameObjGetter(USERS_ACTIVE)
    }),
    replaces() {
      let data = [];

      for (let n in this.autocorrectReplaceGetter) {
        let fav_id = this.autocorrectReplaceGetter[n];
        if (this.autocorrectReplaceObjListGetter[fav_id] !== undefined) {
          data.push({
            fav_id,
            label: this.autocorrectReplaceObjListGetter[fav_id]['Замена']
          })
        }
      }

      return data;
    },
    users() {
      let data = [];

      for (let n in this.usersAutocorrectGetter) {
        let user_id = this.usersAutocorrectGetter[n];
        if (this.usersActiveObjListGetter[user_id] !== undefined) {
          data.push({
            user_id,
            label: this.usersActiveObjListGetter[user_id]['ФИО']
          })
        }
      }

      return data;
    }
  },
}
</script>