<template>
  <badge-ui :value="counter" :color="color"/>
</template>

<script>
import BadgeUi from '@/components/ui/BadgeUi.vue';

export default {
  name: "MenuCounter",
  components: {BadgeUi},
  props: {
    getter: {
      type: String,
      required: true,
    },
  },
  computed: {
    counters() {
      return this.$store.getters[this.getter];
    },
    counterNew() {
      return Math.max(this.counters.count_new, 0);
    },
    counterExpired() {
      return Math.max(this.counters.count_expired, 0);
    },
    counterAll() {
      return Math.max(this.counters.count, 0);
    },
    counter() {
      return this.counterNew || this.counterExpired || this.counterAll;
    },
    color() {
      if (this.counterNew) {
        return 'blue';
      } else if (this.counterExpired) {
        return 'red';
      } else {
        return 'gray';
      }
    }
  }
}
</script>
