<template>
  <div class="search-multiple-ui">
    <search-legacy-ui
      ref="search"
      :value-field="searchValueField"
      :key-field="searchKeyField"
      :end-point="searchEndPoint"
      :placeholder="searchPlaceholder"
      :disabled-formatter="disabledFormatter"
      :disabled="disabled"
      v-model="searchModel"
      @change="search"
    ></search-legacy-ui>

    <div class="wrapper">
      <card-list-ui
        v-if="modelProxy.length"
        :list="modelProxy"
        :disabled="disabled"
        :options="options"
        :icon="icon"
        :get-key="getKey"
        :get-title="getTitle"
        :get-href="getHref"
        :get-option="getOption"
        @change="changeSelect"
        @remove="remove"
      ></card-list-ui>

      <button-ui v-else color="black" mode="outline" @click="$refs.search.focus()">
        <template #prefix>
          <component :is="addIcon"></component>
        </template>
        <span>{{ addText }}</span>
      </button-ui>

      <loader-ui v-if="loading"></loader-ui>
    </div>
  </div>
</template>

<script>
// TODO: Рефакторинг
import {defineComponent} from 'vue';
import SearchLegacyUi from '@/components/ui/SearchLegacyUi.vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import CardListUi from '@/components/ui/CardListUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';

export default defineComponent({
  name: 'SearchMultipleUi',
  components: {
    ButtonUi,
    CardListUi,
    LoaderUi,
    SearchLegacyUi,
  },
  data() {
    return {
      searchModel: null,
      modelProxy: [],
      loading: false,
    };
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    searchValueField: {
      type: String,
      required: true,
    },
    selectKey: {
      type: String,
      required: true,
    },
    searchKeyField: {
      type: String,
      required: true,
    },
    disabledFormatter: {
      type: Function,
      required: true,
    },
    searchEndPoint: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    searchHandler: {
      type: Function,
      default: async (id) => {
        return {id};
      },
    },
    addIcon: {
      type: Object,
      required: true,
    },
    addText: {
      type: String,
      default: 'Добавить',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemTitleHandler: {
      type: Function,
      default: item => item,
    },
    itemTitleHrefHandler: {
      type: Function,
      default: () => '#',
    },
    afterAddHandler: {
      type: Function,
      default: item => item,
    },
    maxHeight: Number,
  },
  created() {
    this.modelProxy = this.modelValue || [];
  },
  computed: {
    isEmpty() {
      return !this.modelValue || this.modelValue.length === 0;
    },
    maxHeightStyle() {
      return this.maxHeight && `${this.maxHeight}px`;
    },
    overflow() {
      return this.maxHeight && `auto`;
    },
  },
  methods: {
    async search(option) {
      if (!option) {
        return;
      }
      this.$nextTick(() => this.searchModel = null);

      if (this.modelProxy.some(i => i[this.searchKeyField] === option.code)) {
        return;
      }

      this.loading = true;
      const item = await this.searchHandler(option.code);
      this.modelProxy.push(this.afterAddHandler(item));
      this.loading = false;
      this.change();
    },
    remove(item) {
      this.modelProxy = this.modelProxy.filter(i => i[this.searchKeyField] !== item[this.searchKeyField]);
      this.change();
    },
    change() {
      this.$emit('change', this.modelProxy);
    },
    changeSelect(e, item) {
      this.$set(item, this.selectKey, e);
      this.change();
    },
    getKey(item) {
      return item[this.searchKeyField];
    },
    getTitle(item) {
      return this.itemTitleHandler(item);
    },
    getHref(item) {
      return this.itemTitleHrefHandler(item);
    },
    getOption(item) {
      return item[this.selectKey];
    },
  },
});
</script>

<style scoped lang="scss">
.search-ui {
  margin-bottom: 8px;
}

.wrapper {
  position: relative;
  overflow-y: v-bind(overflow);

  max-height: v-bind(maxHeightStyle);
  min-height: 120px;

  display: flex;
  justify-content: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;
}

.button-ui {
  margin: auto;
  align-self: center;
}

.loader-ui {
  border-radius: 8px;
}
</style>
