<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57739 0.744078C6.90283 0.418641 7.43047 0.418641 7.7559 0.744078L9.42257 2.41074C9.74801 2.73618 9.74801 3.26382 9.42257 3.58926L7.7559 5.25592C7.43047 5.58136 6.90283 5.58136 6.57739 5.25592C6.25195 4.93049 6.25195 4.40285 6.57739 4.07741L6.78722 3.86758C3.91589 4.43219 1.74998 6.96316 1.74998 10C1.74998 12.077 2.76227 13.9175 4.32403 15.0553C4.69601 15.3263 4.77786 15.8476 4.50685 16.2195C4.23583 16.5915 3.71458 16.6734 3.3426 16.4024C1.3688 14.9643 0.083313 12.6322 0.083313 10C0.083313 6.02708 3.00984 2.73769 6.82477 2.16996L6.57739 1.92259C6.25195 1.59715 6.25195 1.06951 6.57739 0.744078ZM11.4931 3.78045C11.7641 3.40847 12.2854 3.32662 12.6574 3.59763C14.6312 5.03568 15.9166 7.36776 15.9166 10C15.9166 13.9729 12.9901 17.2623 9.17519 17.83L9.42257 18.0774C9.74801 18.4028 9.74801 18.9305 9.42257 19.2559C9.09713 19.5814 8.56949 19.5814 8.24406 19.2559L6.57739 17.5893C6.25195 17.2638 6.25195 16.7362 6.57739 16.4107L8.24406 14.7441C8.56949 14.4186 9.09713 14.4186 9.42257 14.7441C9.74801 15.0695 9.74801 15.5972 9.42257 15.9226L9.21274 16.1324C12.0841 15.5678 14.25 13.0368 14.25 10C14.25 7.92301 13.2377 6.08254 11.6759 4.9447C11.304 4.67369 11.2221 4.15243 11.4931 3.78045Z"/>
  </svg>
</template>

<script>
export default {
  name: "ReloadIcon"
}
</script>