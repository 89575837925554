<template>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 36.8333H6.5V32.5H13C15.3932 32.5 17.3333 34.4401 17.3333 36.8333V41.1667C17.3333 43.5599 15.3932 45.5 13 45.5H6.5V41.1667H13V36.8333Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0833 36.8333H22.75V32.5H27.0833C30.6732 32.5 33.5833 35.4101 33.5833 39V43.3333C33.5833 46.9232 30.6732 49.8333 27.0833 49.8333H22.75V45.5H27.0833C28.28 45.5 29.25 44.5299 29.25 43.3333V39C29.25 37.8034 28.28 36.8333 27.0833 36.8333Z" fill="#DADFF1"/>
    <path d="M4.33203 34.6667C4.33203 33.47 5.30208 32.5 6.4987 32.5C7.69531 32.5 8.66536 33.47 8.66536 34.6667V47.6667C8.66536 48.8633 7.69531 49.8333 6.4987 49.8333C5.30208 49.8333 4.33203 48.8633 4.33203 47.6667V34.6667Z" fill="#DADFF1"/>
    <path d="M36.832 34.6667C36.832 33.47 37.8021 32.5 38.9987 32.5C40.1953 32.5 41.1654 33.47 41.1654 34.6667V47.6667C41.1654 48.8633 40.1953 49.8333 38.9987 49.8333C37.8021 49.8333 36.832 48.8633 36.832 47.6667V34.6667Z" fill="#DADFF1"/>
    <path d="M45.5013 32.5C46.6979 32.5 47.668 33.47 47.668 34.6667C47.668 35.8633 46.6979 36.8333 45.5013 36.8333L39.0013 36.8333C37.8047 36.8333 36.8346 35.8633 36.8346 34.6667C36.8346 33.47 37.8047 32.5 39.0013 32.5L45.5013 32.5Z" fill="#DADFF1"/>
    <path d="M45.5013 39C46.6979 39 47.668 39.97 47.668 41.1667C47.668 42.3633 46.6979 43.3333 45.5013 43.3333L39.0013 43.3333C37.8047 43.3333 36.8346 42.3633 36.8346 41.1667C36.8346 39.97 37.8047 39 39.0013 39L45.5013 39Z" fill="#DADFF1"/>
    <path d="M20.582 34.6667C20.582 33.47 21.5521 32.5 22.7487 32.5C23.9453 32.5 24.9154 33.47 24.9154 34.6667V47.6667C24.9154 48.8633 23.9453 49.8333 22.7487 49.8333C21.5521 49.8333 20.582 48.8633 20.582 47.6667V34.6667Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9772 2.16602L30.3333 2.16602C30.908 2.16602 31.4591 2.39429 31.8654 2.80062L44.8654 15.8006C45.2717 16.2069 45.5 16.758 45.5 17.3327V27.2994C45.5 28.496 44.5299 29.466 43.3333 29.466C42.1367 29.466 41.1667 28.496 41.1667 27.2994V18.2301L29.4359 6.49935H19.0667C17.2107 6.49935 15.9491 6.50104 14.9739 6.58071C14.024 6.65832 13.5382 6.799 13.1994 6.97166C12.384 7.38711 11.7211 8.05002 11.3056 8.86539C11.133 9.20426 10.9923 9.69003 10.9147 10.6399C10.835 11.6151 10.8333 12.8768 10.8333 14.7327V25.9994C10.8333 27.196 9.86329 28.166 8.66667 28.166C7.47005 28.166 6.5 27.196 6.5 25.9994L6.5 14.6432C6.49997 12.8991 6.49995 11.4597 6.59575 10.2871C6.69526 9.06918 6.90882 7.94966 7.44461 6.8981C8.27551 5.26736 9.60135 3.94153 11.2321 3.11063C12.2836 2.57483 13.4032 2.36128 14.621 2.26177C15.7936 2.16597 17.2331 2.16599 18.9772 2.16602Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3346 2.75C31.5313 2.75 32.5013 3.72005 32.5013 4.91667V13.8662C32.5013 14.5086 32.503 14.8602 32.5236 15.1128C32.5245 15.1229 32.5253 15.1324 32.5261 15.1414C32.5351 15.1422 32.5446 15.143 32.5547 15.1438C32.8073 15.1645 33.1588 15.1662 33.8013 15.1662H42.7508C43.9474 15.1662 44.9175 16.1362 44.9175 17.3328C44.9175 18.5295 43.9474 19.4995 42.7508 19.4995H33.8013C33.7783 19.4995 33.7552 19.4995 33.7322 19.4995C33.1854 19.4996 32.6528 19.4996 32.2018 19.4628C31.7065 19.4223 31.1221 19.3268 30.534 19.0272C29.7186 18.6117 29.0557 17.9488 28.6403 17.1335C28.3406 16.5454 28.2452 15.961 28.2047 15.4657C28.1678 15.0147 28.1679 14.4821 28.168 13.9353C28.168 13.9123 28.168 13.8892 28.168 13.8662V4.91667C28.168 3.72005 29.138 2.75 30.3346 2.75Z" fill="#DADFF1"/>
  </svg>
</template>

<script>
export default {
  name: "PdfFormFileIcon"
}
</script>
