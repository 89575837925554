import Constants from '@/configs/tables/constants';
import DT_ID from "@/configs/tables/cols/DT_ID";
import CONTROLLER_ID from "@/configs/tables/cols/CONTROLLER_ID";
import Registrator from "@/configs/tables/cols/Registrator";
import Project from "@/configs/tables/cols/Project";
import VuexAdapter from "@/services/vuexAdapter";
import {DOCS_GET} from "@/configs/endPoints";
import {DOCUMENTS_TABLE_NAME} from "@/configs/tables/table";
import store from "@/store";
import {DOCUMENTS__REGISTRATION_DATA} from "@/configs/events";

export default () => {

    const accessToRegistrationData = store?.getters.accessToEvent(DOCUMENTS__REGISTRATION_DATA) || false;

    const info = {
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
            order: 0,
        },
        'DOC_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            component: () => import('@/components/table/columns/EyeDocuments'),
            classes: {'table-design-rpID': true},
            description: 'ID документа',
            conditions: 1,
            order: 0
        },
        'DS_ID': {
            visible: true,
            searchSmallFieldDictionary: true,
            title: 'Статус',
            classes: {'table-design-type': true},
            type: Constants.identifier,
            description: 'ID статуса документа',
            values: {
                '1': {
                    title: 'Черновик',
                    component: () => import('@/components/table/badges/BadgeLight'),
                },
                '2': {
                    title: 'На паузе',
                    component: () => import('@/components/table/badges/BadgeSecondary'),
                },
                '3': {
                    title: 'Выполняется',
                    component: () => import('@/components/table/badges/BadgeSuccess'),
                },
                '4': {
                    title: 'Просрочен',
                    component: () => import('@/components/table/badges/BadgeDanger'),
                },
                '5': {
                    title: 'Остановлен',
                    component: () => import('@/components/table/badges/BadgeDisabled'),
                },
                '6': {
                    title: 'Стандартный маршрут',
                    component: () => import('@/components/table/badges/BadgeCoordination'),
                },
            },
            order: 1,
        },
        'DT_ID': DT_ID({order: 2}),
        'REGISTRAR_ID': {
            system: true,
            visible: false,
            type: Constants.integer,
            classes: {},
            description: 'ID регистратора',
            order: 3,
        },
        'CONTROLLER_ID': CONTROLLER_ID({
            order: 4,
            searchSmallFieldDictionary: true,
        }),
        'Активен': {
            visible: true,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Флаг: активен ли документ или нет (документ может быть неактивным, если это черновик или на паузе)',
            conditions: 1,
            order: 5,
        },
        'Тип': {
            visible: false,
            system: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип документа',
            conditions: 3,
            order: 6,
        },
        'Номер': {
            visible: true,
            title: 'Номер',
            searchSmallField: true,
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 7,
        },
        'Дата': {
            visible: true,
            title: 'Дата',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата создания черновика документа',
            conditions: 3,
            order: 8,
        },
        'Статус': {
            visible: false,
            system: true,
            title: 'Статус документа',
            classes: {},
            type: Constants.string,
            description: 'Статус документа',
            conditions: 3,
            order: 9,
        },
        'Контролер': {
            visible: false,
            system: true,
            title: 'Контролер',
            searchSmallField: true,
            classes: {},
            type: Constants.string,
            description: 'Ф.И.О пользователя контролера по документу',
            conditions: 3,
            order: 10,
        },
        'Регистратор': Registrator({order: 11, searchSmallField: true,}),
        'Проект': Project({order: 12, searchSmallField: true,}),
        'Содержание': {
            visible: true,
            title: 'Содержание',
            searchSmallField: true,
            classes: {},
            type: Constants.string,
            maskField: 'Содержание_MASK',
            description: 'Содержание документа',
            conditions: 3,
            order: 13,
        },
        'Срок': {
            visible: true,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Общий срок документа (не путать со сроком поручения)',
            conditions: 3,
            order: 14,
        },
        'Запуск': {
            visible: false,
            title: 'Запуск в работу',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата автоматического запуска документа в работу, если установлен отсроченный запуск',
            conditions: 3,
            order: 17,
        },
        'Исполнен': {
            visible: false,
            title: 'Исполнен',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Исполнен',
            conditions: 3,
            order: 18,
        },
        'Содержание_MASK': {
            system: true,
            visible: false,
            classes: {},
            type: Constants.string,
            description: 'Маска поля ввода "Содержание", используется для форматированного ввода текста в поле содержание',
            order: 19,
        },
        'Содержание_MASK_PARAMS': {
            system: true,
            visible: false,
            classes: {},
            type: Constants.string,
            description: 'Параметры маски поля ввода "Содержание", используется для форматированного ввода текста в поле содержание',
            order: 20,
        },
        'Содержание_MASK_COND': {
            system: true,
            visible: false,
            classes: {},
            type: Constants.string,
            description: 'Условия маски поля ввода "Содержание", используется для форматированного ввода текста в поле содержание',
            order: 21,
        },
        'Комментарий': {
            visible: true,
            searchSmallField: true,
            title: 'Комментарий',
            classes: {},
            type: Constants.string,
            description: 'Комментарий документа',
            conditions: 3,
            order: 22,
        },
    };

    if (accessToRegistrationData) {
        info['Регистрация'] = {
            visible: true,
            title: 'Регистрация',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата регистрации документа, если эта функция используется',
            conditions: 3,
            order: 15,
        };
        info['Рег.номер'] = {
            visible: true,
            searchSmallField: true,
            title: 'Рег.номер',
            classes: {},
            type: Constants.string,
            description: 'Регистрационный номер документа, если эта функция используется',
            conditions: 3,
            order: 16,
        };
    }

    return {
        name: DOCUMENTS_TABLE_NAME,
        title: 'Поиск документов',
        action: VuexAdapter.getNameAction(DOCS_GET),
        actionLoading: VuexAdapter.getNameLoadingAction(DOCS_GET),
        getter: VuexAdapter.getNameGetter(DOCS_GET),
        getterCountByFilter: VuexAdapter.getNameCountGetter(DOCS_GET),
        actionClearStore: VuexAdapter.getNameClearAction(DOCS_GET),
        actionAbort: VuexAdapter.getNameAbortAction(DOCS_GET),
        getterError: VuexAdapter.getNameErrorGetter(DOCS_GET),
        generalField: 'DOC_ID',
        previewField: 'DOC_ID',
        searchSmallSelectedField: 'Содержание',
        smallGroped: () => import('@/components/table/grouped/Documents'),
        limit: 20,
        info,
    };
}
