import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import VuexAdapter from "@/services/vuexAdapter";
import {DOCS_VIEWS_ALL} from "@/configs/endPoints";
import {VIEWS_DOC_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: VIEWS_DOC_TABLE_NAME,
    title: 'Просмотры',
    action: VuexAdapter.getNameAction(DOCS_VIEWS_ALL),
    actionLoading: VuexAdapter.getNameLoadingAction(DOCS_VIEWS_ALL),
    getter: VuexAdapter.getNameGetter(DOCS_VIEWS_ALL),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOCS_VIEWS_ALL),
    actionClearStore: VuexAdapter.getNameClearAction(DOCS_VIEWS_ALL),
    actionAbort: VuexAdapter.getNameAbortAction(DOCS_VIEWS_ALL),
    getterError: VuexAdapter.getNameErrorGetter(DOCS_VIEWS_ALL),
    generalField: 'KEY_ID',
    searchSmallSelectedField: 'Пользователь',
    smallGroped: () => import('@/components/table/grouped/ViewsDoc'),
    limit: 20,
    info: {
        'KEY_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID-ключ',
            order: 0
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
        },
        'Активен': {
            visible: false,
            title: 'Активен',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Активен',
            conditions: 1,
            order: 1,
        },
        'Документ': {
            visible: false,
            title: 'Документ',
            classes: {},
            type: Constants.integer,
            description: 'Документ',
            conditions: 1,
            order: 2,
        },
        'ДатаДокумента': {
            visible: false,
            title: 'Дата документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата документа',
            order: 3,
            conditions: 3,
        },
        'Срок': {
            visible: false,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Срок поручения',
            order: 4,
            conditions: 3,
        },
        'Исполнен': {
            visible: false,
            title: 'Дата остановки документа',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата остановки документа (чаще всего когда все поручения по нему выполнены)',
            conditions: 3,
            order: 5,
        },
        'Контролер': {
            visible: false,
            title: 'Контролер',
            classes: {},
            type: Constants.string,
            description: 'Ф.И.О пользователя контролера по документу',
            conditions: 3,
            order: 6,
        },
        'Регистратор': {
            visible: false,
            title: 'Регистратор',
            classes: {},
            type: Constants.string,
            description: 'Ф.И.О пользователя регистратора по документу',
            conditions: 3,
            order: 7,
        },
        'Проект': {
            visible: false,
            title: 'Проект',
            searchSmallField: true,
            classes: {},
            type: Constants.string,
            description: 'Название проекта документа',
            conditions: 3,
            order: 8,
        },
        'Содержание': {
            visible: false,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание документа',
            conditions: 3,
            order: 9,
        },
        'Пользователь': User({
            order: 10,
            searchSmallField: true,
        }),
        'Дата': {
            visible: true,
            title: 'Дата просмотра',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy HH:mm',
            description: 'Дата просмотра',
            conditions: 3,
            order: 11,
        },
        'Активно': {
            visible: true,
            title: 'Активно',
            classes: {'text-center': true},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Дата просмотра',
            conditions: 3,
            order: 12,
        },
    },
};