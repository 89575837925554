import Constants from '@/configs/constants';
import {getDate} from "@/services/utilsFunctions";
import {mapGetters, mapActions} from "vuex";
import infiniteScroll from 'vue-infinite-scroll';

export default {
    props: [
        'componentWidth',
    ],
    methods: {
        getDate,
        containerScroll: function () {
            this.$eventBus.$emit('document-preview-scroll-bottom');
        },
        ...mapActions([
            'refreshPreviewAction',
            'initPreviewAction',
            'setEntityIdPreviewAction',
        ]),
    },
    computed: {
        ...mapGetters([
            'docLastRoutePointDeclineGetter',
            'entityIdPreviewGetter',
            'initFirstDataPreviewGetter',
            'accessToEvent',
        ]),
        classDocumentSheetContainer: function () {
            return {
                'document-sheet-container': true,
                'document-sheet-container-md': this.componentWidth > Constants.resolutionPoints.tiny && this.componentWidth <= Constants.resolutionPoints.small,
                'document-sheet-container-sm': this.componentWidth <= Constants.resolutionPoints.tiny,
            }
        },
    },
    directives: {
        infiniteScroll,
    },
    beforeDestroy() {
        this.setEntityIdPreviewAction(null);
    },
    watch: {
        '$route'() {
            this.setEntityIdPreviewAction(null);
        },
        entityIdPreviewGetter(val) {
            if (val) {
                this.refreshPreviewAction();
            }
        },
    }
}