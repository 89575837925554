import Constants from "@/configs/tables/constants";
import VuexAdapter from "@/services/vuexAdapter";
import {PROJECTS_GET} from "@/configs/endPoints";

const data = {
    visible: true,
    title: 'Проект',
    classes: {},
    type: Constants.asyncIdentifier,
    optionAction: VuexAdapter.getNameAction(PROJECTS_GET),
    optionGetter: VuexAdapter.getNameSelectGetter(PROJECTS_GET),
    description: 'Название проекта документа',
    conditions: 1,
    fieldKeyValue: 'Проект',
    optionKeyValue: true,
}

export default function (mix = {}) {
    return Object.assign(Object.create(data), mix)
}