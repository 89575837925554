<template>
  <div class="file-name-button">
    <spinner-ui v-if="$refs.nameRef?.disabled"></spinner-ui>
    <component v-else class="file-icon" :is="file.icon"></component>
    <file-signature-icon v-if="withSign && file.signCount" :count="file.signCount"></file-signature-icon>
    <file-truncated-name ref="nameRef" :file="file"></file-truncated-name>
  </div>
</template>

<script>
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';
import FileSignatureIcon from '@/components/icons/FileSignatureIcon.vue';
import {defineComponent} from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import DocumentFile from '@/common/models/document-file';

export default defineComponent({
  name: "FileNameButton",
  components: {SpinnerUi, FileSignatureIcon, FileTruncatedName},
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    withSign: {
      type: Boolean,
      default: false,
    }
  },
});
</script>

<style scoped lang="scss">
.file-name-button {
  min-width: 0;

  display: flex;
  align-items: center;
}

.spinner-ui {
  flex-shrink: 0;
  margin-right: 8px;
}

.file-icon {
  flex-shrink: 0;
  margin-right: 8px;

  /* FIXME: Отказаться от перекрытия стилей */
  fill: var(--color-gray-700);
}
</style>
