import StepRoute from '@/components/document-master/steps/StepRoute.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Route,
  title: 'Выбор маршрута документа',
  tooltip: 'Маршрут документа — это последовательность подписания/выполнения документа. В большинстве случаев следует использовать стандартный маршрут, если нет подходящего — обратитесь к администратору пространства документооборота',
  menuTitle: 'Маршрут',
  component: StepRoute,
};
