<template>
  <div class="spinner-alpha-container-sm float-left ml-2">
    <i class="spinner-alpha spinner-alpha-sm"></i>
  </div>
</template>

<script>
export default {
  name: "LoadingStatusMasterIcon"
}
</script>