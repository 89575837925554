import axios from 'axios';

const VERSION_CONTROL_TIMEOUT = 1000 * 25;

export default {
  actions: {
    reloadVersionControlAction(ctx) {
      ctx.dispatch('cleanAllStoresAction');
      window.location.reload(true);
    },
    startVersionControlAction(ctx) {
      const url = new URL(document.location.href);
      const interval = setInterval(() => {
        if (interval && ctx.state.versionControlChanged) {
          clearInterval(interval);

          return;
        }

        axios.get(url.origin + `/version.json?t=${Date.now()}`).then(r => {
          ctx.commit('versionControlMutation', r.data);
        });

      }, VERSION_CONTROL_TIMEOUT);
    },
  },
  mutations: {
    versionControlMutation(state, data) {
      if (!state.versionControlChanged &&
        (state.versionControlNumber !== data.version ||
          state.versionControlReleaseDate !== data.date ||
          state.versionControlHash !== data.hash)
      ) {
        state.versionControlChanged = true;
      }
    },
  },
  state: {
    versionControlChanged: false,
    versionControlNumber: process.env.VUE_APP_VERSION,
    versionControlReleaseDate: process.env.VUE_APP_GIT_RELEASE_DATE,
    versionControlHash: process.env.VUE_APP_GIT_RELEASE_COMMIT_HASH,
  },
  getters: {
    versionControlNumberGetter(state) {
      return state.versionControlNumber;
    },
    versionControlReleaseDateGetter(state) {
      return state.versionControlReleaseDate;
    },
    versionControlHashGetter(state) {
      return state.versionControlHash;
    },
    versionControlChangedGetter(state) {
      return state.versionControlChanged;
    },
  },
};
