import Constants from "@/configs/tables/constants";
import routePointTypes from "@/configs/routePointTypes";

const assignment = String(routePointTypes.assignment);
const agreement = String(routePointTypes.agreement);
const notification = String(routePointTypes.notification);

const data = {
    visible: true,
    title: 'Тип',
    classes: {},
    type: Constants.identifier,
    values: {},
    component: () => import('@/components/table/columns/doc/TypeDoc'),
    description: 'ID типа поручения (Поручение/Уведомление/Согласование)',
    order: 3,
    conditions: 1,
}

data.values[assignment] = {
    title: 'Поручение',
    class: 'badge-assignment',
};

data.values[agreement] = {
    title: 'Согласование',
    class: 'badge-coordination',
};

data.values[notification] = {
    title: 'Уведомление',
    class: 'badge-notification',
};

export default function (mix = {}) {
    return Object.assign(Object.create(data), mix)
}