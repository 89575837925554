<template>
  <modal-ui ref="modal" title="Внимание" popup @hidden="$emit('hidden')">
    <template #body>
      У документа есть неподтвержденные поручения. Считать их завершенными?
    </template>

    <template #footer>
      <close-button @click="$emit('close', false)">
        <template #title>Нет</template>
      </close-button>

      <stop-doc-button @click="$emit('close', true)">
        <template #title>Да</template>
      </stop-doc-button>
    </template>
  </modal-ui>
</template>

<script>
import StopDocButton from '@/components/buttons/StopDocButton.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'CloseDocOrdersModal',
  components: {ModalUi, CloseButton, StopDocButton},
  mixins: [modal],
  emits: ['close', 'hidden'],
});
</script>
