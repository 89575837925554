<template>
  <div v-if="controlsCountGetter.count_new > 0"
       class="indicator-badge-count-new">
  </div>
  <div v-else-if="controlsCountGetter.count_expired > 0"
       class="indicator-badge-count-expired">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CounterControlsIconSmall",
  computed: {
    ...mapGetters(['controlsCountGetter']),
  }
}
</script>
