<template>
  <combobox-ui ref="combobox" placeholder="Шаблон" :options="templates" v-model="template" no-clear></combobox-ui>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';

export default {
  name: 'SolutionAutocorrectReplace',
  components: {ComboboxUi},
  data() {
    return {
      template: null,
    };
  },
  mounted() {
    this.setAutocorrectReplacesSelectAction({limit: 50}).then(() => {
      this.autocorrectReplaceGetter.forEach(fav_id => {
        if (this.autocorrectReplaceObjListGetter[fav_id] === undefined) {
          this.delReplaceAutocorrect(fav_id);
        }
      });
    });
  },
  computed: {
    ...mapGetters(['autocorrectReplaceSelectGetter', 'autocorrectReplaceGetter', 'autocorrectReplaceObjListGetter']),
    templates() {
      return this.autocorrectReplaceSelectGetter;
    },
  },
  watch: {
    template(template) {
      if (template) {
        this.addReplaceAutocorrect(template.code);
        this.$emit('add-solution-autocorrect', template.label);
        this.$refs.combobox.clear();
      }
    },
  },
  methods: {
    ...mapActions(['setAutocorrectReplacesSelectAction', 'delReplaceAutocorrect', 'addReplaceAutocorrect']),
  },
};
</script>
