import StepCounterparties from '@/components/document-master/steps/StepCounterparties.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Counterparties,
  title: 'Ссылки на контрагентов',
  tooltip: 'Установка связей документа с контрагентами облегчит его поиск в будущем. Контрагента можно найти по названию или номеру',
  menuTitle: 'Контрагенты',
  component: StepCounterparties,
};
