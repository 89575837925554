import route_point_types from "@/configs/routePointTypes";
import {DOC_ROUTE_POINT_ACCEPT, DOC_ROUTE_POINT_DECLINE, DOC_ROUTE_POINT_MARK_FINISH, DOC_ROUTE_POINT_MARK_VIEW} from "@/configs/endPoints";
import VuexAdapter from "@/services/vuexAdapter";

export default {
    data: () => ({
        solutionConfig: null,
        events_solution: [
            'counter-reload-commissions',
            'preview-reload-gantt'
        ],
        route_point_types,
        route_point_solution: {
            confirm_familiarization: {
                icon: () => import('@/components/icons/ConfirmFamiliarizationIcon'),
                title: 'Подтвердить ознакомление',
                name_button: 'Подтвердить ознакомление',
                class_button: 'btn-primary',
                action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_VIEW),
                routePointType: route_point_types.notification
            },
            agreement: {
                icon: () => import('@/components/icons/AgreementIcon'),
                title: 'Согласовать документ',
                name_button: 'Одобрить',
                class_button: 'btn-primary',
                action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ACCEPT),
                routePointType: route_point_types.agreement
            },
            reject: {
                icon: () => import('@/components/icons/CloseIcon'),
                title: 'Отклонить документ',
                name_button: 'Отклонить',
                class_button: 'btn-danger',
                action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_DECLINE),
                routePointType: route_point_types.agreement
            },
            report_back: {
                icon: () => import('@/components/icons/ReportBackIcon'),
                title: 'Отчитаться о выполнении',
                name_button: 'Отчитаться о выполнении',
                class_button: 'btn-success',
                action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_FINISH),
                routePointType: route_point_types.assignment
            }
        }
    }),
    methods: {
        applyRoutePointSolution(key) {
            this.solutionConfig = {
                ...this.route_point_solution[key],
                rp_id: this.rp_id_solution,
                doc_id: this.doc_id_solution,
                events: this.events_solution,
            }

            this.$refs.solutionRoutePointModal.show();
        },
    },
}