<template>
  <div class="step-type">
    <form-builder ref="form" :fields="fields" v-model="formDataProxy" @change="change"></form-builder>

    <div v-if="showAttention" class="warning">
      <warn-icon></warn-icon>
      Данный проект не привязан по умолчанию для данного типа документа.
      <a @click="changeToDefaultProject" href="#">
        Исправить.
      </a>
    </div>

    <div class="background">
      <create-doc-icon></create-doc-icon>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import step from '@/mixins/document-master/step';
import FormBuilder from '@/components/form/FormBuilder.vue';
import {TYPE_SELECT} from '@/configs/form';
import {mapActions, mapGetters} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_TYPES_ACTIVE_GET, PROJECTS_GET} from '@/configs/endPoints';
import dbAdapter from '@/mixins/dbAdapter';
import {TABLE_SORT_ASC} from '@/configs/tables/table';
import WarnIcon from '@/assets/svg/warn.svg?component';
import CreateDocIcon from '@/assets/svg/create-doc.svg?component';

export default defineComponent({
  name: 'StepType',
  mixins: [step, dbAdapter],
  components: {
    FormBuilder,
    CreateDocIcon,
    WarnIcon,
  },
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_SELECT,
            name: 'type',
            placeholder: 'Тип',
            options: () => this.types,
            required: true,
          },
        ],
        [
          {
            type: TYPE_SELECT,
            name: 'p_id',
            placeholder: 'Проект',
            options: () => this.projects,
            required: true,
            codeOnlyValue: true,
            disabled: () => !this.hasDtId,
          },
        ],
      ],
    };
  },
  async mounted() {
    this.showLoader();
    const getFieldName = this.dbAdapter[this.generateKey];
    const data = {
      orderby: `${getFieldName('Название')} ${TABLE_SORT_ASC}`,
    };
    await this.fetchTypesAction(data);
    await this.fetchProjectsAction(data);
    this.hideLoader();
  },
  computed: {
    ...mapGetters({
      'typesGetter': VuexAdapter.getNameGetter(DOC_TYPES_ACTIVE_GET),
      'projectsGetter': VuexAdapter.getNameGetter(PROJECTS_GET),
    }),
    showAttention() {
      if (!this.modelValue.type.code || !this.modelValue.p_id) {
        return false;
      }

      const type = this.typesGetter.findLast(type => type.DT_ID === this.modelValue.type.code);

      return !!(type.P_ID_default && this.modelValue.p_id !== type.P_ID_default);
    },
    types() {
      return this.typesGetter.filter(type => type.DT_ID !== 2 && type['Активен']).map(type => {
        return {
          code: type.DT_ID,
          label: type['Название'],
        };
      });
    },
    projects() {
      return this.projectsGetter.filter(project => {
        if (this.selectedExtP.length) {
          return !!(project['Активен'] && this.selectedExtP.P_IDS.includes(project.P_ID));
        }

        return project['Активен'];
      }).map(type => {
        return {
          code: type.P_ID,
          label: type['Название'],
        };
      });
    },
    hasDtId() {
      return this.formDataProxy?.type.code;
    },
    selectedExtP() {
      const data = [];
      this.typesGetter.filter(type => type.DT_ID == this.formDataProxy?.type.code).forEach(type => {
        const p = type?.P_ID || [];
        p.forEach(p => data.push(Number(p)));
      });

      return data;
    },
  },
  methods: {
    ...mapActions({
      'fetchTypesAction': VuexAdapter.getNameAction(DOC_TYPES_ACTIVE_GET),
      'fetchProjectsAction': VuexAdapter.getNameAction(PROJECTS_GET),
    }),
    changeToDefaultProject() {
      const type = this.typesGetter.findLast(type => type.DT_ID === this.modelValue.type.code);

      this.$set(this.formDataProxy, 'p_id', type.P_ID_default);

      this.change();
    },
  },
  watch: {
    'modelValue.p_id': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          const label = this.projects.find(p => p.code === newVal)?.label;

          this.$set(this.formDataProxy, 'p_name', label);

          this.change();
        }
      },
    },
    'modelValue.type.code': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          const type = this.typesGetter.findLast(type => type.DT_ID === newVal);

          this.$set(this.formDataProxy, 'mask', type['Маска']);
          this.$set(this.formDataProxy, 'parameters', null);
          this.$set(this.formDataProxy, 'parametersFormData', {});
          this.$set(this.formDataProxy, 'lt_ids', type?.LT_IDS || []);

          if (this.formDataProxy.clients.length) {
            this.formDataProxy.clients.forEach(client => {
              this.$set(client, 'LT_ID', 1);
            });
          }

          if (this.formDataProxy.documents.length) {
            this.formDataProxy.documents.forEach(document => {
              this.$set(document, 'LT_ID', 1);
            });
          }

          if (newVal && type.P_ID_default) {
            this.$set(this.formDataProxy, 'p_id', type.P_ID_default);
          } else {
            this.$set(this.formDataProxy, 'p_id', null);
          }

          this.change();
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
.step-type {
  position: relative;
}

.warning {
  position: absolute;

  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  color: var(--color-gray-600);
}

.background {
  padding: 72px 0 56px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
