import {DocumentPreview} from "@/services/preview/DocumentPreview";
import {ClientPreview} from "@/services/preview/ClientPreview";
import Vue from 'vue';

const intefaces = {
    [DocumentPreview.intefaceIdent]: DocumentPreview,
    [ClientPreview.intefaceIdent]: ClientPreview,
}

export default {
    actions: {

        /**
         * Установка ID сущности
         *
         * @param ctx
         * @param entityId
         */
        setEntityIdPreviewAction(ctx, entityId) {
            ctx.state.entityIdPreview = entityId;
            if (!entityId) {
                ctx.state.isActivePreview = false;
            }
        },

        /**
         * Установка активности для мобильной версии
         *
         * @param ctx
         * @param isActive
         */
        setActivePreviewAction(ctx, isActive = true) {
            ctx.state.isActivePreview = isActive;
        },

        /**
         * Только для первичной инициализации
         *
         * @param ctx
         * @param interfacePreview
         * @return Promise
         */
        initPreviewAction(ctx, interfacePreview) {
            if (!intefaces[interfacePreview]) {
                throw new Error('Unknown interface ' . interfacePreview);
            }

            const className = intefaces[interfacePreview];

            Vue.set(ctx.state, 'interfacePreview', new className(ctx.state.entityIdPreview));
            Vue.set(ctx.state, 'initFirstDataPreview', false);

            return ctx.state.interfacePreview
                .setEntityId(ctx.state.entityIdPreview)
                .fetchDataPreview().finally(() => {
                Vue.set(ctx.state, 'initFirstDataPreview', true);
            });
        },

        /**
         * Частичное обновление
         * @param ctx
         * @param endPoint
         * @return {*}
         */
        refreshPartlyPreviewAction(ctx, endPoint) {
            return ctx.state.interfacePreview
                .setEntityId(ctx.state.entityIdPreview)
                .fetchPartlyPreview(endPoint)
        },

        /**
         * Обновить превью
         *
         * @param ctx
         */
        refreshPreviewAction(ctx) {
            return ctx.state.interfacePreview
                .setEntityId(ctx.state.entityIdPreview)
                .fetchDataPreview()
                .finally(() => {
                    Vue.set(ctx.state, 'initFirstDataPreview', true);
                });
        },
    },
    mutations: {
        cleanPreviewMutations(state) {
            state.entityIdPreview = null;
            state.isActivePreview = false;
            state.initFirstDataPreview = false;
            state.interfacePreview = null;
        }
    },
    state: {
        /**
         * ID Сущности превью
         *
         * @property int
         */
        entityIdPreview: null,

        /**
         * Флаг активности для мобильной версии
         *
         * @property bool
         */
        isActivePreview: false,

        /**
         * Флаг первичной инициализации
         *
         * @property bool
         */
        initFirstDataPreview: false,

        /**
         * Реализация класса AbstarctPreview
         *
         * @property AbstarctPreview
         */
        interfacePreview: null,
    },
    getters: {
        hasEntityIdPreviewGetter(state) {
            return state.entityIdPreview !== null;
        },
        isActivePreviewGetter(state) {
            return state.isActivePreview;
        },
        entityIdPreviewGetter(state) {
            return state.entityIdPreview;
        },
        initFirstDataPreviewGetter(state) {
            return state.initFirstDataPreview;
        },
    },
}