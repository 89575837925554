import {AbstarctPreview} from "@/services/preview/AbstarctPreview";
import store from '../../store';
import VuexAdapter from "@/services/vuexAdapter";
import {
    DOC_COMMENTS_GET,
    DOC_FILES_GET,
    DOC_GANTT_ITEMS,
    DOC_LINKS_GET,
    DOCS_CLIENTS_GET,
    DOCS_GET,
    DOCS_VIEWS_ALL
} from "@/configs/endPoints";

export class DocumentPreview extends AbstarctPreview {
    static intefaceIdent = 'DOCUMENT_PREVIEW';

    fetchDataPreview() {
        return new Promise((resolve, reject) => {
            this._abort().finally(() => {
                this._fetch()
                    .then(() => resolve())
                    .catch(e => reject(e));
            });
        });
    }

    fetchPartlyPreview(endPoint) {
        switch (endPoint) {
            case DOCS_GET:
                return store.dispatch(VuexAdapter.getNameRowAction(endPoint), this._entityId);
            case DOC_GANTT_ITEMS:
                return store.dispatch(VuexAdapter.getNameAction(DOC_GANTT_ITEMS), {doc_id: this._entityId});
            case DOC_COMMENTS_GET:
                return store.dispatch(VuexAdapter.getNameAction(DOC_COMMENTS_GET), {doc_id: this._entityId, limit: this._limitLoad});
            case DOC_FILES_GET:
                return store.dispatch('previewFiles/fetch', this._entityId);
        }
    }

    _fetch() {
        const promices = [
            store.dispatch(VuexAdapter.getNameRowAction(DOCS_GET), this._entityId),
            store.dispatch(VuexAdapter.getNameAction(DOCS_VIEWS_ALL), {doc_id: this._entityId, limit: this._limitLoad}),
            store.dispatch(VuexAdapter.getNameAction(DOC_COMMENTS_GET), {doc_id: this._entityId, limit: this._limitLoad}),
            store.dispatch(VuexAdapter.getNameAction(DOCS_CLIENTS_GET), {doc_id: this._entityId, limit: this._limitLoad}),
            store.dispatch(VuexAdapter.getNameAction(DOC_LINKS_GET), {doc_id: this._entityId, limit: this._limitLoad}),
            store.dispatch('previewFiles/fetch', this._entityId),
            store.dispatch(VuexAdapter.getNameAction(DOC_GANTT_ITEMS), {doc_id: this._entityId}),
        ];

        return Promise.all(promices);
    }

    _abort() {
        const promices = [
            store.dispatch(VuexAdapter.getNameAbortRowAction(DOCS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(DOCS_VIEWS_ALL)),
            store.dispatch(VuexAdapter.getNameAbortAction(DOC_COMMENTS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(DOCS_CLIENTS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(DOC_LINKS_GET)),
            store.dispatch(VuexAdapter.getNameAbortAction(DOC_GANTT_ITEMS)),
        ];

        return Promise.all(promices);
    }
}
