/* eslint-disable no-useless-escape */

import { helpers, required, between } from '@vuelidate/validators';

import ERRORS from './errors';

const subtractYears = (count, date = new Date()) =>
	new Date(date.setFullYear(date.getFullYear() - count));

const VALIDATORS = {
	regex: (reg, errorMessage) => {
		return helpers.withMessage(errorMessage, helpers.regex(reg));
	},
	after: (year, errorMessage) => {
		return helpers.withMessage(
			errorMessage,
			(v) => new Date(v) > new Date(`${year}`)
		);
	},
	before: (year, errorMessage) => {
		return helpers.withMessage(
			errorMessage,
			(v) => new Date(v) < new Date(`${year}`)
		);
	},
};

const RULES = {
	required: helpers.withMessage(ERRORS.required, required),
	email: VALIDATORS.regex(
		/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
		ERRORS.incorrectEmail
	),
	phone: VALIDATORS.regex(
		/^(\+?\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{2}[-]?\d{2}$/,
		ERRORS.incorrectPhone
	),
	date: helpers.withMessage(
		ERRORS.incorrectDate,
		(v) => new Date(v) > subtractYears(120) && new Date(v) < new Date()
	),
	percent: helpers.withMessage(
		ERRORS.incorrectPercent,
		between(0, 100)
	),
	url: VALIDATORS.regex(
		/^(http(s)?:\/\/)?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+([/?#][^\s]*)?$/,
		ERRORS.incorrectURL
	),
	truthy: helpers.withMessage(
		ERRORS.incorrectData,
		(value) => value === true
	),
};

export default RULES;

export { VALIDATORS };
