<template>
  <button class="btn btn-reload"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <reload-icon/>
  </button>
</template>

<script>
import ReloadIcon from "@/components/icons/ReloadIcon";
export default {
  name: "ReloadButton",
  components: {ReloadIcon}
}
</script>