import Constants from '@/configs/tables/constants';
import User from "@/configs/tables/cols/User";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_FILES_SIGNATURES_GET} from "@/configs/endPoints";
import {FILES_SIGNATURES_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: FILES_SIGNATURES_TABLE_NAME,
    title: 'ЭП',
    action: VuexAdapter.getNameAction(DOC_FILES_SIGNATURES_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_FILES_SIGNATURES_GET),
    getter: VuexAdapter.getNameGetter(DOC_FILES_SIGNATURES_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_FILES_SIGNATURES_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_FILES_SIGNATURES_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_FILES_SIGNATURES_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOC_FILES_SIGNATURES_GET),
    generalField: 'F_ID',
    searchSmallSelectedField: 'Файл',
    smallGroped: () => import('@/components/table/grouped/FilesSignatures'),
    limit: 20,
    info: {
        'F_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID файла',
            conditions: 3,
            order: 0
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
        },
        'Номер': {
            visible: true,
            searchSmallField: true,
            title: 'Номер',
            classes: {'text-center': true},
            type: Constants.integer,
            description: 'Номер файла в документе',
            order: 1,
            conditions: 3,
        },
        'Файл': {
            visible: true,
            searchSmallField: true,
            title: 'Файл',
            classes: {},
            type: Constants.string,
            description: 'Название файла',
            order: 2,
            conditions: 3,
        },
        'Редакция': {
            visible: true,
            title: 'Редакция',
            classes: {'text-center': true},
            type: Constants.integer,
            description: 'Редакция файла',
            order: 3,
            conditions: 3,
        },
        'Пользователь': User({
            order: 4,
            searchSmallFieldDictionary: true,
        }),
        'ЭП': {
            visible: true,
            title: 'ЭП',
            classes: {},
            type: Constants.string,
            description: 'Метка простой цифровой подписи файла',
            order: 5,
            conditions: 3,
        },
    },
};