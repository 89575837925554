<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    library.add([faExclamation, faSpinner]);

    export default {
        name: 'fontAwesomeDynamicIcon',
        props: FontAwesomeIcon.props,
        data() {
            return {
                fetched: null,
                fetchedIcon: null,
                iconTypes: {
                    fas: 'solid',
                    far: 'regular',
                    fal: 'light',
                },
            };
        },
        computed: {
            iconDefinition() {
                if (this.fetchedIcon) {
                    return this.fetchedIcon;
                }

                if (library.definitions[this.iconPrefix] && library.definitions[this.iconPrefix][this.iconName]) {
                    return {
                        icon: library.definitions[this.iconPrefix][this.iconName],
                        iconName: this.iconName,
                        prefix: this.iconPrefix,
                    };
                }

                return null;
            },
            iconFileName() {
                return this.camelize(`fa-${this.iconName}`);
            },
            iconName() {
                return Array.isArray(this.icon) ? this.icon[1] : this.icon;
            },
            iconPrefix() {
                return Array.isArray(this.icon) ? this.icon[0] : 'fas';
            },
            iconType() {
                return this.iconTypes[this.iconPrefix];
            },
        },
        methods: {
            fetchIcon() {
                this.fetched = false;
                return import(`@fortawesome/free-${this.iconType}-svg-icons/${this.iconFileName}.js`)
                    .then((response) => {
                        this.fetched = true;

                        if (response && response.definition) {
                            this.fetchedIcon = response.definition;
                            library.add(response.definition);
                        }
                    })
                    .catch(() => {
                        this.fetched = true;
                    });
            },
            camelize(str) {
                return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                    return index === 0 ? word.toLowerCase() : word.toUpperCase();
                }).replace(/\s+|-+/g, '');
            },
        },
        created() {
            if (!this.iconDefinition) {
                this.fetchIcon();
            }
        },
        watch: {
            icon() {
                if (!this.iconDefinition) {
                    this.fetchIcon();
                }
            },
        },
        render() {
            if (!this.iconDefinition) {
                if (!this.fetched) {
                    return this.$createElement(FontAwesomeIcon, {
                        props: {
                            spin: true,
                            icon: faSpinner,
                        },
                    });
                }

                return this.$createElement(FontAwesomeIcon, {
                    props: {
                        icon: faExclamation,
                    },
                });
            }

            return this.$createElement(FontAwesomeIcon, {
                props: {
                    ...this.$options.propsData,
                    icon: this.iconDefinition,
                },
            });
        },
    };
</script>