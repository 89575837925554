<template>
  <header class="document-master-header">
    <transition name="slide" mode="out-in">
      <div
        :key="title"
        class="ad-tooltip _title"
        :aria-label="title"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        :role="showTitleTooltip ? 'tooltip' : null"
      >
        <h2 ref="title" class="title">{{ title }}</h2>
      </div>
    </transition>

    <div
      v-if="tooltip"
      class="ad-tooltip _icon"
      :aria-label="tooltip"
      data-tooltip-position="bottom-left"
      data-tooltip-size="auto"
      role="tooltip"
    >
      <info-icon></info-icon>
    </div>
  </header>
</template>

<script>
import {defineComponent} from 'vue';
import InfoIcon from '@/assets/svg/info.svg?component';

export default defineComponent({
  name: 'DocumentMasterHeader',
  components: {
    InfoIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: String,
  },
  data() {
    return {
      showTitleTooltip: false,
    };
  },
  mounted() {
    const title = this.$refs.title;
    this.showTitleTooltip = title && title.scrollWidth > title.offsetWidth;
  },
});
</script>

<style scoped lang="scss">
.document-master-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-right: 8px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* TODO: Перекрытие стилей */
.ad-tooltip {
  &::after {
    font-size: var(--font-size-l) !important;
    line-height: var(--line-height-xl) !important;
  }

  &._title {
    min-width: 0;

    &::after {
      white-space: nowrap !important;
    }
  }

  &._icon {
    cursor: pointer;

    &::after {
      width: 350px !important;
      text-align: left;
    }
  }
}
</style>
