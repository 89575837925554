<template>
  <div class="field-ui">
    <label v-if="label" class="label" :class="{_bold: required}" :for="id">{{ label }}</label>

    <slot></slot>

    <span class="text" v-if="hint">{{ hint }}</span>

    <span class="text text_error" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FieldUi',
  props: {
    id: String,
    label: String,
    hint: String,
    error: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.field-ui {
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}

.label {
  &._bold {
    font-weight: var(--font-weight-extra-bold);
  }
}

.text {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);

  &_error {
    color: var(--color-red-900);
  }
}
</style>
