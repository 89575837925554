<template>
  <button-ui :size="size" :color="color" :mode="mode" v-bind="$attrs" v-on="$listeners">
    <template #prefix>
      <plus-icon></plus-icon>
    </template>

    <span v-if="showTitle">Добавить</span>
  </button-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import PlusIcon from '@/assets/svg/plus.svg?component';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'AddButton',
  components: {
    ButtonUi,
    PlusIcon,
  },
  props: {
    type: {
      type: String,
      default: 'secondary',
      validator: only('primary', 'secondary'),
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    size() {
      return this.type === 'primary' ? 'm' : 's';
    },
    color() {
      return this.type === 'primary' ? 'yellow' : 'gray';
    },
    mode() {
      return this.type === 'primary' ? 'fill' : 'outline';
    },
  },
});
</script>
