<template>
    <span class="table-badge badge-warning">{{value}}</span>
</template>

<script>
    export default {
        name: 'BadgeWarning',
        props: ['value'],
    };
</script>
