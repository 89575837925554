<template>
  <search-multiple-ui
    v-model="formDataProxy.documents"
    @change="change"
    search-placeholder="Введите номер документа"
    :search-end-point="DOC_CHECK"
    :disabled-formatter="item => item['Доступен'] !== 1"
    search-key-field="DOC_ID"
    search-value-field="Содержание"
    :search-handler="searchHandler"
    :icon="icon"
    :add-icon="addIcon"
    add-text="Добавить ссылки"
    :item-title-handler="itemTitleHandler"
    :item-title-href-handler="itemTitleHrefHandler"
    :options="documentLinkTypes"
    select-key="LT_ID"
    :after-add-handler="afterAddHandler"
    :max-height="326"
  ></search-multiple-ui>
</template>

<script>
import {defineComponent} from 'vue';
import {DOC_CHECK, DOCS_GET} from '@/configs/endPoints';
import SearchMultipleUi from '@/components/ui/SearchMultipleUi.vue';
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOCS_TABLE} from '@/configs/dbTables';
import LinkIcon from '@/assets/svg/link.svg?component';
import AddLinkIcon from '@/assets/svg/add-link.svg?component';
import stepWithLinkTypes from '@/mixins/document-master/stepWithLinkTypes';

export default defineComponent({
  name: 'StepLinks',
  mixins: [stepWithLinkTypes],
  components: {
    SearchMultipleUi,
  },
  data() {
    return {
      DOC_CHECK,
      icon: LinkIcon,
      addIcon: AddLinkIcon,
      searchHandler: async (id) => {
        return await this.docAction({where: 'DOC_ID = ' + id})
          .then((r) => {
            return r.data.items[0];
          });
      },
      itemTitleHandler: (item) => {
        const length = 40;
        const content = item['Содержание'].length > length ? item['Содержание'].substring(0, length) + '...' : item['Содержание'];

        return `<span>${content}</span><br>№${item['Номер']}`;
      },
      itemTitleHrefHandler: (item) => {
        return '/document/' + item['DOC_ID'];
      },
      // TODO: Дублирование StepContent
      afterAddHandler: (item) => {
        const ltIdsInSelect = this.documentLinkTypes
          .map(linkType => linkType.code)
          .filter(linkType => linkType !== 1);

        if (!ltIdsInSelect.length) {
          this.$set(item, 'LT_ID', 1);
        } else {
          const key = this.formDataProxy.documents.length;
          this.$set(item, 'LT_ID', ltIdsInSelect[key] || 1);
        }

        return item;
      },
    };
  },
  computed: {
    documentLinkTypes() {
      return this.linkTypes.filter(linkType => !linkType.appliedIds || linkType.appliedIds.includes(DOCS_TABLE));
    },
  },
  methods: {
    ...mapActions({
      'docAction': VuexAdapter.getNameAction(DOCS_GET),
    }),
  },
});
</script>
