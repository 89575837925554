import Constants from '@/configs/tables/constants';
import RPT_ID from "@/configs/tables/cols/RPT_ID";
import RPS_ID from "@/configs/tables/cols/RPS_ID";
import RESPONSIBLE_ID from "@/configs/tables/cols/RESPONSIBLE_ID";
import Controller from "@/configs/tables/cols/Controller";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_ROUTES_POINTS_GET} from "@/configs/endPoints";
import {ROUTES_POINTS_TABLE_NAME} from "@/configs/tables/table";

export default {
    name: ROUTES_POINTS_TABLE_NAME,
    title: 'Маршрут',
    action: VuexAdapter.getNameAction(DOC_ROUTES_POINTS_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(DOC_ROUTES_POINTS_GET),
    getter: VuexAdapter.getNameGetter(DOC_ROUTES_POINTS_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(DOC_ROUTES_POINTS_GET),
    actionClearStore: VuexAdapter.getNameClearAction(DOC_ROUTES_POINTS_GET),
    actionAbort: VuexAdapter.getNameAbortAction(DOC_ROUTES_POINTS_GET),
    getterError: VuexAdapter.getNameErrorGetter(DOC_ROUTES_POINTS_GET),
    generalField: 'RP_ID',
    searchSmallSelectedField: 'Описание',
    smallGroped: () => import('@/components/table/grouped/RoutePoints'),
    limit: 20,
    info: {
        'RP_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            classes: {},
            description: 'ID точки маршрута',
            conditions: 1,
            order: 0,
        },
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
            order: 1,
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            order: 2,
        },
        'RESPONSIBLE_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID таблицы сущности исполнителя (пользователь/должность/группа)',
            order: 3,
        },
        'Индикатор': {
            visible: true,
            title: 'Индикатор',
            classes: {'text-center': true, 'table-design-indicator': true},
            fieldDataId: 'RPS_ID',
            fieldDataValue: 'Индикатор',
            type: Constants.integer,
            component: () => import('@/components/table/columns/IndicatorColumn'),
            description: 'Число, отображающее количество рабочих дней до истечения срока поручения (если число отрицателельное, то срок уже прошел)',
            order: 4,
            conditions: 3
        },
        'RPT_ID': RPT_ID({
            order: 5,
            classes: {'table-design-type': true},
            searchSmallFieldDictionary: true,
        }),
        'RPS_ID': RPS_ID({
            order: 6,
            searchSmallFieldDictionary: true,
        }),
        'Описание': {
            visible: true,
            searchSmallField: true,
            title: 'Описание',
            classes: {},
            type: Constants.string,
            description: 'Описание поручения',
            order: 7,
            conditions: 3,
        },
        'Срок': {
            visible: true,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Срок точки маршрута',
            conditions: 3,
            order: 8,
        },
        'Дней': {
            visible: true,
            title: 'Дней',
            classes: {'text-center': true},
            type: Constants.integer,
            description: 'Срок точки маршрута в рабочих днях, если он был указан при создании маршрута',
            order: 9,
            conditions: 3,
        },
        'RESPONSIBLE_ID': RESPONSIBLE_ID({
            order: 10,
            searchSmallFieldDictionary: true,
            fieldKeyValue: 'Исполнитель',
        }),
        'LOGIN_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID пользователя исполнителя поручения',
            order: 11,
        },
        'PARENTS': {
            visible: false,
            system: true,
            classes: {},
            type: Constants.string,
            description: 'Строка ID родителей экранированных # (последний код всегда равен ID точки маршрута)',
            order: 12,
        },
        'Уровень': {
            visible: true,
            title: 'Уровень',
            classes: {},
            type: Constants.integer,
            description: 'Уровень точки маршрута, начиная с 0',
            order: 13,
        },
        'Номер': {
            visible: true,
            title: 'Номер',
            type: Constants.integer,
            classes: {},
            description: 'Внутренний номер документа (равен ID документа)',
            conditions: 3,
            order: 14,
        },
        'Предыдущий': {
            visible: false,
            system: true,
            title: 'Предыдущий',
            type: Constants.string,
            classes: {},
            description: 'Предыдущие номера точек маршрута через запятую, которые должны быть выполнены до запуска текущей точки',
            conditions: 3,
            order: 15,
        },
        'Тип': {
            visible: false,
            system: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип документа',
            conditions: 3,
            order: 16,
        },
        'LINKS': {
            visible: false,
            system: true,
            classes: {},
            type: Constants.string,
            description: 'LINKS',
            conditions: 3,
            order: 17,
        },
        'Родитель': {
            visible: false,
            system: true,
            classes: {},
            type: Constants.string,
            description: 'Родитель',
            conditions: 3,
            order: 18,
        },
        'Выполнено': {
            visible: true,
            title: 'Выполнено',
            classes: {'text-right': true},
            format: 'dd.LL.yyyy',
            type: Constants.date,
            description: 'Дата/время выполнения точки маршрута',
            order: 19,
            conditions: 3,
        },
        'Исполнитель': {
            visible: false,
            system: true,
            searchSmallField: true,
            title: 'Исполнитель',
            classes: {},
            type: Constants.string,
            description: 'Исполнитель точки маршрута',
            conditions: 3,
            order: 20,
        },
        'Контролер': Controller({order: 21}),
        'Статус': {
            visible: false,
            system: true,
            title: 'Статус',
            classes: {},
            type: Constants.string,
            description: 'Статус документа',
            conditions: 3,
            order: 22,
        },
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание поручения',
            order: 23,
            conditions: 3,
        },
        'Создана': {
            visible: true,
            title: 'Создана',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Создана',
            conditions: 3,
            order: 24,
        },
        'Флаг': {
            visible: false,
            system: true,
            title: 'Флаг',
            classes: {},
            type: Constants.flag,
            component: () => import('@/components/table/flags/DefaultFlag'),
            description: 'Флаг: является ли текущий пользователь контролёром данной точки',
            conditions: 1,
            order: 25,
        },
    },
};