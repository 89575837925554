<template>
   <div class="document-sheet">
     <transition name="document-sheet">
       <div v-if="!ready"
            class="document-sheet-loader d-flex justify-content-center align-items-center">
         <div class="text-center">
           <div class="spinner-alpha-container mb-3">
             <i class="spinner-alpha"></i>
           </div>
         </div>
       </div>
     </transition>
     <slot></slot>
   </div>
</template>

<script>
export default {
  name: "SheetDocumentPreview",
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
