<template>
  <field-ui class="input-ui" :id="id" :label="label" :required="required" :error="errorMessage" :hint="hint">
    <div ref="fieldRef" class="field" :class="className" @mousedown="onFieldMousedown" @click="onFieldClick">
      <div class="content">
        <slot name="prefix"/>

        <input
          v-maska="mask"
          ref="input"
          class="input"
          :id="id"
          type="text"
          :value="modelValue"
          :placeholder="placeholder"
          :inputmode="inputmode"
          :autocomplete="autocomplete"
          :disabled="disabled"
          @maska="onInput"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="$emit('keydown', $event)"
        >

        <button-icon-ui
          v-if="!noClear && modelValue"
          title="Нажмите чтобы очистить"
          tabindex="-1"
          @click="onClear"
        >
          <close-icon></close-icon>
        </button-icon-ui>

        <slot name="postfix"/>
      </div>
    </div>
  </field-ui>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/common/utils/props-validators';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import CloseIcon from '@/assets/svg/close.svg?component';
import FieldUi from '@/components/ui/FieldUi.vue';
import {uniqueId} from 'lodash-es';
import field from '@/mixins/form/field';
import {vMaska} from 'maska/vue';
import maxLength from '@/mixins/form/maxLength';
import focus from '@/mixins/form/focus';

export default defineComponent({
  name: 'InputUi',
  mixins: [field, focus, maxLength],
  components: {
    FieldUi,
    ButtonIconUi,
    CloseIcon,
  },
  directives: {
    Maska: vMaska,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: String,
    id: {
      type: String,
      default: uniqueId('input-ui-'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    autocomplete: String,
    mask: {
      type: [String, Object, null],
      default: null,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value', 'clear', 'keydown'],
  computed: {
    control() {
      return this.$refs.input;
    },
    className() {
      return [{
        _prefix: !!this.$slots.prefix,
        _postfix: !!this.$slots.postfix || !this.noClear,
        _focused: !!this.isFocused,
        _disabled: this.disabled,
        _error: !!this.error,
      },
        `_size-${this.size}`,
        `_color-${this.color}`,
      ];
    },
  },
  methods: {
    focus() {
      this.control.focus();
    },
    onClear() {
      this.$emit('update:model-value', '');
      this.$emit('clear');
    },
  },
});
</script>

<style scoped lang="scss">
.field {
  border-radius: 8px;
  cursor: text;

  background: linear-gradient(
      to right,
      rgba(226, 231, 239, 1) 0%,
      rgba(226, 231, 239, 1) v-bind('gradient.start'),
      var(--color-transparent) v-bind('gradient.end'),
      var(--color-transparent) 100%,
  );

  transition: box-shadow var(--transition-fast);

  &._size-m {
    height: 36px;
    padding: 0 12px;
  }

  &._size-l {
    height: 48px;
    padding: 0 16px;

    &._prefix {
      padding-left: 12px;
    }

    &._postfix {
      padding-right: 12px;
    }

    .input {
      font-size: var(--font-size-xl);
      line-height: var(--font-size-xl);
    }
  }

  &._color-gray {
    background-color: var(--color-gray-075);

    &:not(._error) {
      &._focused {
        box-shadow: var(--shadow-control);
      }
    }
  }

  &._color-white {
    background-color: var(--color-white);
    box-shadow: var(--shadow);
  }

  &._prefix {
    padding-left: 8px;

    .input {
      padding-left: 4px;
    }
  }

  &._postfix {
    padding-right: 8px;

    .input {
      padding-right: 4px;
    }
  }

  &._disabled {
    pointer-events: none;

    .content {
      opacity: 0.5;
    }
  }

  &._error {
    box-shadow: var(--shadow-control-error);
  }
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
}

.input {
  flex-grow: 1;
  align-self: stretch;

  &::placeholder {
    color: var(--color-gray-500);
  }
}
</style>
