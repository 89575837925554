<template>
  <transition-group name="list" tag="nav" class="document-master-steps">
    <button
      v-for="(step, index) in menu"
      :key="step"
      class="item"
      :class="{_active: isActive(index)}"
      :disabled="isDisabled(index)"
      @click="$emit('change', index)"
    >
      {{ step }}
    </button>
  </transition-group>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'DocumentMasterSteps',
  props: {
    page: {
      type: Number,
      required: true,
    },
    lastVisitedPage: {
      type: Number,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  computed: {
    background() {
      return `translateY(${this.page * 32}px)`;
    },
  },
  methods: {
    isActive(index) {
      return index === this.page;
    },
    isDisabled(index) {
      return this.disabled || this.isActive(index) || index > this.lastVisitedPage;
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-steps {
  position: relative;
  padding: 8px;

  background-color: var(--color-gray-075);
  border-radius: 8px;

  &::after {
    content: '';
    display: block;

    position: absolute;
    top: 8px;
    left: 10px;
    right: 8px;
    height: 32px;

    border-left: var(--border-2-gray-700);
    border-radius: 8px;

    color: var(--color-gray-700);
    background-color: var(--color-gray-100);

    transform: v-bind(background);
    transition: transform var(--transition);
  }
}

.item {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  padding: 8px 12px;

  text-align: left;
  border-radius: 8px;

  transition: color var(--transition), transform var(--transition);

  &._active {
    color: var(--color-gray-700);
    transform: translateX(2px);
  }

  &:disabled {
    &:not(._active) {
      color: var(--color-gray-400);
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: opacity var(--transition);
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}
</style>
