<template>
  <dropdown-ui tag="ul" placement="bottom-end" close-if-outside-click close-if-inner-click>
    <template #anchor="{toggle, isOpen}">
      <button-ui size="s" color="gray" mode="outline" @click="toggle">
        <span>Отправить по маршруту повторно</span>
        <font-awesome-dynamic-icon class="icon icon_postfix-s" :icon="['fas', 'route']"/>
        <chevron-icon class="icon_postfix" v-rotate="isOpen"/>
      </button-ui>
    </template>

    <dropdown-item-ui tag="li" @click="restartRoute(true)">
      <font-awesome-dynamic-icon class="icon icon_prefix" :icon="['fas', 'route']"/>
      <span>Повторить маршрут полностью</span>
    </dropdown-item-ui>

    <dropdown-item-ui tag="li" @click="restartRoute(false)">
      <font-awesome-dynamic-icon class="icon icon_prefix" :icon="['fas', 'route']"/>
      <span>Повторить маршрут с отклоненной точки</span>
    </dropdown-item-ui>
  </dropdown-ui>
</template>

<script>
import {mapActions} from 'vuex';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_ROUTE_ADD, DOCS_GANTT_ITEMS} from '@/configs/endPoints';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ChevronIcon from '@/assets/svg/chevron.svg?component';
import Rotate from '@/directives/rotate';
import {ErrorHelper} from '@/services/errorHelper';

export default {
  name: 'ButtonRoutePointRestart',
  components: {
    ButtonUi,
    DropdownItemUi,
    DropdownUi,
    ChevronIcon,
  },
  directives: {
    Rotate,
  },
  props: ['rp_id', 'doc_id'],
  methods: {
    ...mapActions({
      docRouteAddAction: VuexAdapter.getNameAction(DOC_ROUTE_ADD),
      docsGanttItemsAction: VuexAdapter.getNameAction(DOCS_GANTT_ITEMS),
    }),
    async restartRoute(full = true) {
      try {
        this.$emit('start');

        const dataGantt = await this.docsGanttItemsAction({doc_id: this.doc_id});

        const ganttItems = dataGantt?.data?.items || [];

        const routePointsClone = [];

        let parentId = null;

        ganttItems.forEach(item => {
          if (this.rp_id == item.RP_ID) {
            parentId = item.PARENT_ID;
          }
        });

        if (parentId == null) {
          throw new Error('Отправление повторно, доступно только для стандартного маршрута');
        }

        let startClone = full;

        ganttItems.forEach(item => {
          if (!startClone && this.rp_id == item.RP_ID) {
            startClone = true;
          }
          if (parentId == item.PARENT_ID && startClone) {
            routePointsClone.push(item.RP_ID);
          }
        });

        const data = {
          DOC_ID: this.doc_id,
          RP_ID: routePointsClone,
          PARENT_DOC_ID: this.doc_id,
        };

        await this.docRouteAddAction(data);

        this.$emit('success');
      } catch (error) {
        this.$notify({
          title: 'Ошибка создания маршрута',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(error),
        });
      }
    },
  },
};
</script>
