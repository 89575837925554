<template>
  <button class="btn btn btn-alpha"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <slot name="icon">
      <edit-icon/>
    </slot>
    <slot name="title">
      Сохранить
    </slot>
  </button>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon";

export default {
  name: "SaveButton",
  components: {EditIcon},
  inheritAttrs: false,
}
</script>
