<template>
  <button class="btn btn btn-alpha"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <slot name="icon">
      <plus-icon/>
    </slot>
    <slot name="title">
      Добавить
    </slot>
  </button>
</template>

<script>
import {defineComponent} from 'vue';
import PlusIcon from '@/components/icons/PlusIcon';

/**
 * @deprecated Используйте AddButton или ButtonUi
 **/
export default defineComponent({
  name: 'AddButtonLegacy',
  components: {PlusIcon},
  inheritAttrs: false,
});
</script>
