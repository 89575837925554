<template>
  <modal-ui ref="modal" :size="step.size || 'm'" prevent-closure @close="close">
    <template #body>
      <component
        ref="step"
        :is="step.noWrapper ? step.component : 'document-master-wrapper'"
        :step="step"
        v-model="formData"
        @close="close(true)"
        @show-loader="showLoader"
        @hide-loader="hideLoader"
        @toggle-paging="disabled = !$event"
        @back="goTo(page - 1)"
        @contractors-change="onChangeContractors"
      >
        <template #menu>
          <document-master-steps
            :menu="menu"
            :page="page"
            :last-visited-page="lastVisitedPage"
            :disabled="menuDisabled"
            @change="goTo"
          ></document-master-steps>
        </template>

        <template #header>
          <document-master-header :title="step.title" :tooltip="step.tooltip"></document-master-header>
        </template>

        <template #footer>
          <document-master-footer
            v-if="!disabled"
            :only-next="step.onlyNext"
            :is-first="isFirstPage"
            :is-last="isLastPage"
            :disabled="disabled"
            @close="close"
            @ready="ready"
            @back="goTo(page - 1)"
            @next="goTo(page + 1)"
          ></document-master-footer>
        </template>
      </component>

      <attention-modal
        ref="attention"
        message="Вы собираетесь остановить процесс<br>создания документа.<br>Данная операция не обратима!<br>Продолжить?"
        @continueClosing="close(true)"
      ></attention-modal>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import DocumentMasterSteps from '@/components/document-master/DocumentMasterSteps.vue';
import DocumentMasterHeader from '@/components/document-master/DocumentMasterHeader.vue';
import DocumentMasterFooter from '@/components/document-master/DocumentMasterFooter.vue';
import AttentionModal from '@/components/modals/AttentionModal.vue';
import INITIAL_STEPS from '../../../common/consts/document-master/steps';
import FORM_DATA from '../../../common/consts/document-master/formData';
import DocumentMasterWrapper from '@/components/document-master/DocumentMasterWrapper.vue';
import STEP_FILES from '@/common/consts/document-master/steps/stepFiles';
import STEP_LINKS from '@/common/consts/document-master/steps/stepLinks';
import STEP_BARCODE from '@/common/consts/document-master/steps/stepBarcode';
import STEP_COUNTERPARTIES from '@/common/consts/document-master/steps/stepCounterparties';
import STEP_RESOLUTION from '@/common/consts/document-master/steps/stepResolution';
import STEP_CONTRACTOR from '@/common/consts/document-master/steps/stepContractor';
import StepCode from '@/common/consts/document-master/stepCode';
import TypeCode from '@/common/consts/document-master/typeCode';
import {mapMutations} from 'vuex';
import CacheGroup from '@/common/consts/cacheGroup';

export default defineComponent({
  name: 'CreateDocModal',
  mixins: [modal],
  components: {
    AttentionModal,
    DocumentMasterFooter,
    DocumentMasterHeader,
    DocumentMasterSteps,
    DocumentMasterWrapper,
    ModalUi,
  },
  data() {
    return {
      page: 0,
      lastVisitedPage: 0,
      steps: INITIAL_STEPS,
      formData: JSON.parse(JSON.stringify(FORM_DATA)), // TODO: Иммутабельность
      disabled: false,
      isDirty: false,
    };
  },
  computed: {
    menu() {
      return this.steps.map(step => step.menuTitle);
    },
    step() {
      return this.steps[this.page];
    },
    isFirstPage() {
      return this.page === 0;
    },
    isLastPage() {
      return this.page === this.steps.length - 1;
    },
    menuDisabled() {
      return this.disabled || this.step.onlyNext;
    },
  },
  watch: {
    formData() {
      this.isDirty = true;
    },
    'formData.type': {
      handler(type) {
        this.setStepTitle(type);
      },
      immediate: true,
    },
    'formData.step_attach_files'(value) {
      this.toggleStep(STEP_FILES, value);
    },
    'formData.step_link_document'(value) {
      this.toggleStep(STEP_LINKS, value);
    },
    'formData.step_assign_barcode'(value) {
      this.toggleStep(STEP_BARCODE, value);
    },
    'formData.step_add_counterparty_links'(value) {
      this.toggleStep(STEP_COUNTERPARTIES, value);
    },
    'formData.step_add_resolution'(value) {
      this.toggleStep(STEP_RESOLUTION, value);
    },
  },
  methods: {
    ...mapMutations('cache', {cacheRemove: 'remove'}),
    goTo(page) {
      if (page < this.page || this.$refs.step.validate()) {
        this.lastVisitedPage = Math.max(this.lastVisitedPage, page);
        this.page = page;
      }
    },
    close(force) {
      if (!force && (this.disabled || this.step.onlyNext)) {
        return;
      }

      if (force || !this.isDirty) {
        this.reset();
        this.hide();
      } else {
        this.$refs.attention.show();
      }
    },
    ready() {
      this.$refs.step.ready();
    },
    toggleStep(step, value) {
      if (value) {
        this.addStep(step);
      } else {
        this.removeStep(step.code);
      }
    },
    addStep(step) {
      const index = this.steps.findIndex(current => current.code > step.code);

      if (index >= 0) {
        this.steps = [...this.steps.slice(0, index), step, ...this.steps.slice(index)];
      } else {
        this.steps = [...this.steps, step];
      }
    },
    removeStep(code) {
      this.steps = this.steps.filter(current => current.code !== code);
    },
    setStep(code, data) {
      this.steps = this.steps.map(step => step.code === code ? {...step, ...data} : step);
    },
    setStepTitle(type) {
      const title = type.code === TypeCode.Document ? 'Создание документа' : `Создание "${type.label}"`;
      this.setStep(StepCode.Content, {title});
    },
    onChangeContractors(contractors) {
      const contractorSteps = contractors.map((contractor, number) => ({
        ...STEP_CONTRACTOR,
        title: `Выбор исполнителей ${number + 1} из ${contractors.length}`,
        menuTitle: `Исполнитель ${number + 1}/${contractors.length}`,
        item: contractor,
        number,
      }));

      const steps = this.steps.filter(current => current.code !== STEP_CONTRACTOR.code);
      const index = steps.findIndex(current => current.code > STEP_CONTRACTOR.code);
      this.steps = [...steps.slice(0, index), ...contractorSteps, ...steps.slice(index)];
    },
    reset() {
      this.page = 0;
      this.lastVisitedPage = 0;
      this.steps = INITIAL_STEPS;
      this.formData = JSON.parse(JSON.stringify(FORM_DATA)); // TODO: Иммутабельность
      this.disabled = false;
      this.isDirty = false;
      this.cacheRemove({group: CacheGroup.DocumentMaster});
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-steps {
  position: relative;
  z-index: 1;

  flex: 0 0 210px;
  margin-right: 16px;
}

.document-master-header {
  margin-bottom: 20px;
}
</style>
