import {defineComponent} from 'vue';

export default defineComponent({
  data() {
    return {
      resizeObserver: new ResizeObserver(this.resizeObserverHandler),
    };
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.resize.$el || this.$refs.resize);
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  methods: {
    resizeObserverHandler(event) {
      const contentRect = event[0].contentRect;
      this.onResize({width: contentRect.width, height: contentRect.height});
    },
    onResize() {
      console.warn('Метод onResize не определен!');
    },
  },
});
