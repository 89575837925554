export default {
    bind(el) {
        el.classList.add('no-select');
        el.addEventListener('mousedown', function(event) {
            event.preventDefault();
        });
    },
    unbind(el) {
        el.removeEventListener('mousedown', function(event) {
            event.preventDefault();
        });
    }
};