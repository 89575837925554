<template>
  <div v-if="commissionsCountGetter.count_new > 0"
       class="indicator-badge-count-new">
  </div>
  <div v-else-if="commissionsCountGetter.count_expired > 0"
       class="indicator-badge-count-expired">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CounterCommissionsIconSmall",
  computed: {
    ...mapGetters(['commissionsCountGetter']),
  }
}
</script>