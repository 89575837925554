<template>
  <svg :width="size" :height="size" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="size" :height="size" rx="4" :fill="fill"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.72153 3.4936C5.98183 3.24394 5.19358 3.70658 5.03642 4.48262L4.42857 7.48397C4.36429 7.8014 4.41683 8.13178 4.57623 8.41245L7.7001 13.9128C8.05955 14.5458 8.85566 14.7626 9.47825 14.3972L12.9469 12.3614C13.5695 11.996 13.7828 11.1867 13.4233 10.5538L10.2995 5.05337C10.1401 4.7727 9.88487 4.56125 9.58231 4.45914L6.72153 3.4936ZM3.66339 4.19526C3.98981 2.58348 5.62695 1.62261 7.16324 2.14112L10.024 3.10666C10.6524 3.31876 11.1824 3.75791 11.5135 4.34083L14.6373 9.84123C15.3839 11.1557 14.9409 12.8366 13.6478 13.5955L10.1792 15.6313C8.88609 16.3903 7.23264 15.9399 6.48608 14.6254L3.36221 9.12498C3.03114 8.54205 2.92202 7.85588 3.05554 7.1966L3.66339 4.19526Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85819 7.47304C8.14554 7.30439 8.24399 6.93086 8.07809 6.63875C7.91219 6.34664 7.54476 6.24655 7.25741 6.4152C6.97006 6.58385 6.8716 6.95738 7.03751 7.24949C7.20341 7.54161 7.57084 7.64169 7.85819 7.47304ZM8.5591 8.70718C9.51694 8.14501 9.84512 6.89993 9.29211 5.92622C8.7391 4.95251 7.51433 4.61889 6.55649 5.18106C5.59866 5.74323 5.27048 6.98831 5.82349 7.96202C6.37649 8.93573 7.60127 9.26935 8.5591 8.70718Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97007 13.1667C7.77652 12.8259 7.89138 12.3901 8.22662 12.1934L11.6952 10.1576C12.0305 9.9608 12.4592 10.0776 12.6527 10.4184C12.8463 10.7592 12.7314 11.1949 12.3962 11.3917L8.92753 13.4275C8.59229 13.6243 8.16362 13.5075 7.97007 13.1667Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.83592 10.8947C7.64237 10.5539 7.75723 10.1181 8.09247 9.92136L9.82678 8.90345C10.162 8.70669 10.5907 8.82346 10.7842 9.16426C10.9778 9.50506 10.8629 9.94084 10.5277 10.1376L8.79338 11.1555C8.45814 11.3523 8.02947 11.2355 7.83592 10.8947Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "NoteFileIcon",
  props: {
    fill: {
      type: String,
      default: '#13A86E'
    },
    size: {
      type: Number,
      default: 18
    },
    count: {
      type: Number,
      default: 1
    }
  },
  computed: {
    viewBox() {
      return `0 0 ${ this.size } ${ this.size }`
    },
  }
}
</script>