<template>
  <component
    :is="tag"
    class="dropdown-item-ui"
    :class="classList"
    v-bind="$attrs"
    v-on="!disabled && $listeners"
  >
    <slot></slot>
    <check-icon v-if="type === 'list' && checked" class="icon icon_postfix-s"></check-icon>
  </component>
</template>

<script>
import {defineComponent} from 'vue';
import CheckIcon from '@/assets/svg/check.svg?component';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'DropdownItemUi',
  components: {
    CheckIcon,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
    type: {
      type: String,
      default: 'context',
      validator: only('list', 'context'),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    classList() {
      return [{
        _rounded: this.rounded,
        _checked: this.checked,
        _active: this.active,
        _disabled: this.disabled,
      },
        `_type-${this.type}`,
      ];
    },
  },
});
</script>

<style scoped lang="scss">
.dropdown-item-ui {
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: color var(--transition-fast), background-color var(--transition-fast);

  &:not(&._disabled) {
    &._active,
    &._type-context:hover {
      background-color: var(--color-gray-100);
    }
  }

  &._checked {
    background-color: var(--color-yellow-200);

    &:not(&._disabled) {
      &._active,
      &._type-context:hover {
        background-color: var(--color-yellow-400);
      }
    }
  }

  &._type-context {
    height: 32px;
    padding: 0 8px;

    color: var(--color-gray-500);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;

    &:not(&._disabled) {
      &._active,
      &:hover {
        color: var(--color-gray-600);

        .icon {
          color: var(--color-gray-600); /* TODO: Отказаться от перекрытия стилей */
          fill: var(--color-gray-600);
        }
      }
    }

    .icon {
      color: var(--color-gray-500); /* TODO: Отказаться от перекрытия стилей */
      fill: var(--color-gray-500);
      transition: fill var(--transition-fast), color var(--transition-fast); /* TODO: Отказаться от перекрытия стилей */
    }
  }

  &._type-list {
    padding: 10px 12px;
    justify-content: space-between;
    color: var(--color-gray-1000);
  }

  &._rounded {
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &._disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

/* TODO: Отказаться от перекрытия стилей */
.icon {
  flex-shrink: 0;

  fill: var(--color-gray-1000);
  display: block;
  width: auto;
}
</style>
