import Constants from '@/configs/tables/constants';
import RESPONSIBLE_ID from "@/configs/tables/cols/RESPONSIBLE_ID";
import Controller from "@/configs/tables/cols/Controller";
import Registrator from "@/configs/tables/cols/Registrator";
import Project from "@/configs/tables/cols/Project";
import RPS_ID from "@/configs/tables/cols/RPS_ID";
import RPT_ID from "@/configs/tables/cols/RPT_ID";
import VuexAdapter from "@/services/vuexAdapter";
import {COMMISSIONS_GET} from "@/configs/endPoints";
import {COMMISSIONS_TABLE_NAME} from "@/configs/tables/table";
import store from "@/store";
import {DOCUMENTS__REGISTRATION_DATA} from "@/configs/events";

export default () => {

    const accessToRegistrationData = store?.getters.accessToEvent(DOCUMENTS__REGISTRATION_DATA) || false;


    const info = {
        'RP_ID': {
            visible: true,
            configTitle: true,
            type: Constants.eye,
            component: () => import('@/components/table/columns/EyeCommissions'),
            classes: {
                'table-design-rpID': true
            },
            description: 'ID поручения',
            order: 0,
            conditions: 3,
            colspan: 1
        },
        'TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            description: 'ID таблицы',
            classes: {},
            order: 1,
        },
        'Новое': {
            visible: true,
            searchSmallFieldDictionary: true,
            title: 'Новое',
            classes: {'text-center': true, 'table-design-new': true, 'table-design-indicator': true},
            type: Constants.flag,
            component: () => import('@/components/table/flags/NoveltyFlag'),
            description: 'Флаг: прочитано ли пользователем поручение',
            order: 2,
            conditions: 1,
        },
        'Индикатор': {
            visible: true,
            title: 'Индикатор',
            classes: {'text-center': true, 'table-design-indicator': true},
            fieldDataId: 'RPS_ID',
            fieldDataValue: 'Индикатор',
            type: Constants.integer,
            component: () => import('@/components/table/columns/IndicatorColumn'),
            description: 'Число, отображающее количество рабочих дней до истечения срока поручения (если число отрицателельное, то срок уже прошел)',
            order: 3,
            conditions: 3,
        },
        'RPT_ID': RPT_ID({
            order: 4,
            searchSmallFieldDictionary: true,
            classes: {'table-design-type': true},
        }),
        'RPS_ID': RPS_ID({
            order: 5,
            searchSmallFieldDictionary: true,
        }, 'commissions'),
        'Описание': {
            visible: true,
            searchSmallField: true,
            title: 'Описание',
            classes: {
                'table-design-description': true
            },
            type: Constants.string,
            description: 'Описание поручения',
            order: 6,
            conditions: 3,
        },
        'Срок': {
            visible: true,
            title: 'Срок',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Срок поручения',
            order: 7,
            conditions: 3,
        },
        'Дней': {
            visible: true,
            title: 'Дней',
            classes: {'text-left': true},
            type: Constants.integer,
            description: 'Срок поручения в рабочих днях (может быть пустым, если срок указан явно через дату)',
            order: 8,
            conditions: 3,
        },
        'Документ': {
            visible: true,
            searchSmallField: true,
            title: 'Документ',
            type: Constants.integer,
            classes: {'text-left': true},
            description: 'Внутренний номер документа (равен ID документа)',
            order: 9,
            conditions: 3,
        },
        'Проект': Project({order:10}),
        'Дата': {
            visible: true,
            title: 'Дата',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy, HH:mm',
            description: 'Дата создания черновика документа',
            order: 11,
            conditions: 3,
        },
        'Контролер': Controller({order:12}),
        'Содержание': {
            visible: true,
            searchSmallField: true,
            title: 'Содержание',
            classes: {},
            type: Constants.string,
            description: 'Содержание',
            maskField: 'Содержание_MASK',
            order: 13,
            conditions: 3,
        },
        'Регистратор': Registrator({
            order:14,
            visible: false,
        }),
        'Примечание': {
            visible: true,
            searchSmallField: true,
            title: 'Примечание',
            classes: {},
            type: Constants.string,
            description: 'Примечание поручения',
            order: 17,
            conditions: 3,
        },
        'DOC_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID документа',
            order: 18,
        },
        'RESPONSIBLE_TABLE_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID таблицы сущности исполнителя (пользователь/должность/группа)',
            order: 19,
        },
        'RESPONSIBLE_ID': RESPONSIBLE_ID({
            order: 20,
            visible: false,
            system: true,
        }),
        'LOGIN_ID': {
            visible: false,
            system: true,
            type: Constants.integer,
            classes: {},
            description: 'ID пользователя исполнителя поручения',
            order: 21,
        },
        'Статус': {
            visible: false,
            system: true,
            title: 'Статус документа',
            classes: {},
            type: Constants.string,
            description: 'Статус документа',
            order: 22,
            conditions: 3,
        },
        'Тип': {
            visible: false,
            system: true,
            title: 'Тип',
            classes: {},
            type: Constants.string,
            description: 'Тип поручения',
            order: 23,
            conditions: 3,
        },
        'Содержание_MASK': {
            visible: false,
            system: true,
            classes: {},
            order: 24,
            type: Constants.string,
            description: 'Маска поля ввода "Содержание", используется для форматированного ввода текста в поле содержание',
        },
        'PARENTS': {
            visible: false,
            system: true,
            classes: {},
            order: 25,
            type: Constants.string,
            description: 'Строка ID родителей экранированных # (последний код всегда равен ID точки маршрута)',
        },
    };

    if (accessToRegistrationData) {
        info['Регистрация'] = {
            visible: true,
            title: 'Регистрация',
            classes: {},
            type: Constants.date,
            format: 'dd.LL.yyyy',
            description: 'Дата регистрации документа, если эта функция используется',
            order: 15,
            conditions: 3,
        };
        info['Рег.номер'] = {
            visible: true,
            searchSmallField: true,
            title: 'Рег.номер',
            classes: {},
            type: Constants.string,
            description: 'Регистрационный номер документа, если эта функция используется',
            order: 16,
            conditions: 3,
        };
    }

    return {
        name: COMMISSIONS_TABLE_NAME,
        title: 'Список поручений',
        action: VuexAdapter.getNameAction(COMMISSIONS_GET),
        actionLoading: VuexAdapter.getNameLoadingAction(COMMISSIONS_GET),
        getter: VuexAdapter.getNameGetter(COMMISSIONS_GET),
        getterCountByFilter: VuexAdapter.getNameCountGetter(COMMISSIONS_GET),
        actionClearStore: VuexAdapter.getNameClearAction(COMMISSIONS_GET),
        actionAbort: VuexAdapter.getNameAbortAction(COMMISSIONS_GET),
        getterError: VuexAdapter.getNameErrorGetter(COMMISSIONS_GET),
        actionColor: 'CommissionsColorAction',
        getterColor: 'commissionsColorGetter',
        searchSmallSelectedField: 'Содержание',
        generalField: 'RP_ID',
        previewField: 'DOC_ID',
        smallGroped: () => import('@/components/table/grouped/Commissions'),
        limit: 20,
        info,
    };
}